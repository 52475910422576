import "./Button.scss";
import cx from "classnames";

import React, { forwardRef } from "react";
import useAsyncCallback from "hooks/useAsyncCallback";

export default forwardRef(function Button(
  {
    size = "md",
    variant = "contained",
    color = "text",
    importance = "primary",
    spacing = 2,
    lead,
    trail,
    children,
    onClick,
    className,
    disabled,
    ...props
  },
  ref
) {
  const [{ isPending, showPending }, asyncOnClick] = useAsyncCallback(onClick, [
    onClick,
  ]);
  const prefix = variant === "text" ? "link" : "btn";
  return (
    <button
      ref={ref}
      className={cx(
        `${prefix}`,
        `${prefix}--${size}`,
        `${prefix}--${color}`,
        `space-x-${spacing}`,
        {
          "font-bold": prefix === "btn" && importance === "primary",
        },
        className
      )}
      onClick={asyncOnClick}
      disabled={isPending || disabled}
      {...props}
    >
      {showPending && <div className="loader loader--sm" />}
      {lead}
      <span>{children}</span>
      {trail}
    </button>
  );
});
