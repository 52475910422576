import * as _ from "utils";

import React from "react";
import useListContext from "hooks/useListContext";
import useListSummarize from "hooks/useListSummarize";
import useTranslation from "hooks/useTranslation";
import useUsers from "hooks/useUsers";

import ListProvider from "containers/ListProvider";

import SEO from "components/SEO";

const ListHeader = () => {
  const { t } = useTranslation();
  const [list] = useListContext();
  const participatingUsers = useUsers(list.participants);
  const summarize = useListSummarize();

  const nonChefs = list.participants.filter(_.isNonChef);

  const who =
    nonChefs.length > 0
      ? summarize(participatingUsers)
      : t("common.others", { count: participatingUsers.length });

  const description = t("description.metaDescription", {
    context: _.toContext([
      ["me", nonChefs.some(_.isCurrentUser)],
      ["past", list.hasOccurred],
      ["zero", list.participants.length === 0],
    ]),
    count: list.participants.length,
    who,
  });

  return (
    <SEO
      title={[
        list.title,
        list.hasDeadline &&
          `(${
            list.isClosed
              ? t`label.isClosed`
              : `${t`description.closes`} ${_.formatDate(list.closesAt)}`
          })`,
      ]
        .filter(Boolean)
        .join(" ")}
      description={description}
      image={list.image}
    />
  );
};

const withList = (Component) => (props) => (
  <ListProvider listId={props.listId}>
    <ListHeader />
    <Component {...props} />
  </ListProvider>
);

export default withList;
