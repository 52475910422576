import React from "react";
import useListContext from "hooks/useListContext";
import useCourseContext from "hooks/useCourseContext";

import * as Icons from "components/Icons";
import { IconButton } from "components/UI";

export default ({ courseOption, currentParticipantId }) => {
  const [list] = useListContext();
  const [, { onChangeOption }] = useCourseContext();

  const isParticipant = list.participants.some(
    (participant) => participant.id === currentParticipantId
  );

  const votes = isParticipant
    ? courseOption.votes.filter(
        (vote) =>
          vote === currentParticipantId ||
          vote.participantId === currentParticipantId
      )
    : [];

  return (
    <div className="flex items-center space-x-2">
      <IconButton
        size="xs"
        color="card"
        disabled={votes <= 0 || !isParticipant}
        onClick={() =>
          onChangeOption(courseOption.id, (option) => {
            const index = option.votes.findIndex(
              (vote) =>
                vote === currentParticipantId ||
                vote.participantId === currentParticipantId
            );

            return {
              ...option,
              votes: option.votes.filter((_, i) => i !== index),
            };
          })
        }
      >
        <Icons.Min size={0.75} />
      </IconButton>
      <p className="text-xs">{votes.length}</p>
      <IconButton
        size="xs"
        color="card"
        disabled={!currentParticipantId}
        onClick={() =>
          onChangeOption(courseOption.id, (option) => ({
            ...option,
            votes: option.votes.concat(currentParticipantId),
          }))
        }
      >
        <Icons.Plus size={0.75} />
      </IconButton>
    </div>
  );
};
