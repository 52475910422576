import React from "react";

import InfiniteList from "components/InfiniteList";
import Expense from "./Expense";

const renderExpense = (props) => (expense) => (
  <Expense key={expense.id} expense={expense} {...props} />
);

export default ({ expenses, ...props }) => (
  <div className="space-y-4">
    <InfiniteList items={expenses} renderItem={renderExpense(props)} />
  </div>
);
