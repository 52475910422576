import React, { useCallback, useState } from "react";
import useIdle from "hooks/useIdle";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { BaseInput } from "components/UI";
import CollapsibleContainer from "components/CollapsibleContainer";

export default function Location({ isEditing }) {
  const { t } = useTranslation();
  const [list, listResource] = useListContext();
  const [location, setLocation] = useState(list.location);
  const flush = useCallback(
    () =>
      location !== listResource.rawData.location &&
      listResource.update({ location }),
    [location, listResource]
  );
  const touch = useIdle(flush, 500);

  return (
    <CollapsibleContainer
      icon={Icons.Location}
      isVisible={Boolean(isEditing || list.location)}
    >
      {isEditing ? (
        <BaseInput
          placeholder={t`list.location`}
          value={location || ""}
          onChange={(event) => {
            setLocation(event.target.value);
            touch();
          }}
        />
      ) : list.location ? (
        <p className="hyphenate">{list.location}</p>
      ) : (
        <p className="text-caption">{t`list.location`}</p>
      )}
    </CollapsibleContainer>
  );
}
