import BaseResourceCache from "./BaseResourceCache";
import APIResource from "./APIResource";

export default class APIResourceCache extends BaseResourceCache {
  getKey(entry) {
    return JSON.stringify(entry);
  }

  createResource(entry) {
    return new APIResource(entry);
  }
}
