import * as _ from "utils";

import React from "react";
import useAuth from "hooks/useAuth";
import useTranslation from "hooks/useTranslation";

import withAuth from "hocs/withAuth";

import SimpleLayout from "layouts/Simple";

import * as Icons from "components/Icons";
import MarketingInfo from "components/MarketingInfo";

const SettingsIcon = ({ icon: Icon, color }) => (
  <div className={`bg-${color} p-2 rounded-md`}>
    <Icon className="block" />
  </div>
);

export default withAuth(() => {
  const [{ currentUser }, { signOut }] = useAuth();
  const { t } = useTranslation();

  return (
    <SimpleLayout title={t`settings.title`}>
      <div>
        <div className="divide-y divide-accent">
          <div
            className="flex items-center justify-space-between py-2 space-x-4 cursor-pointer"
            onClick={() => _.pushModal("/settings/auth")}
          >
            <span className="flex items-center space-x-4">
              <SettingsIcon color="primary" icon={Icons.Lock} />
              <span>{t`settings.auth.title`}</span>
              {currentUser.isAnonymous && (
                <span className="bg-error rounded-full w-4 h-4 text-xs text-center">
                  !
                </span>
              )}
            </span>
            <Icons.Caret direction="right" color="accent" />
          </div>
          {process.env.REACT_APP_EXPENSES_ENABLED && (
            <div
              className="flex items-center justify-space-between py-2 space-x-4 cursor-pointer"
              onClick={() => _.pushModal("/settings/payments")}
            >
              <span className="flex items-center space-x-4">
                <SettingsIcon
                  color="green"
                  icon={() => (
                    <strong className="block w-4 h-4 leading-none text-center">
                      €
                    </strong>
                  )}
                />
                <span>{t`settings.payments.title`}</span>
              </span>
              <Icons.Caret direction="right" color="accent" />
            </div>
          )}
          <div
            className="flex items-center justify-space-between py-2 space-x-4 cursor-pointer"
            onClick={() => _.pushModal("/settings/language")}
          >
            <span className="flex items-center space-x-4">
              <SettingsIcon color="green" icon={Icons.Localization} />
              <span>{t`settings.language.title`}</span>
            </span>
            <Icons.Caret direction="right" color="accent" />
          </div>
          <div
            className="flex items-center justify-space-between py-2 space-x-4 cursor-pointer"
            onClick={() => _.pushModal("/settings/color-mode")}
          >
            <span className="flex items-center space-x-4">
              <SettingsIcon color="green" icon={Icons.Empty} />
              <span>{t`settings.colorMode.title`}</span>
            </span>
            <Icons.Caret direction="right" color="accent" />
          </div>
          <div
            className="flex items-center justify-space-between py-2 space-x-4 cursor-pointer"
            onClick={() => _.pushModal("/settings/notifications")}
          >
            <span className="flex items-center space-x-4">
              <SettingsIcon color="secondary" icon={Icons.Bell} />
              <span>{t`settings.notifications.title`}</span>
            </span>
            <Icons.Caret direction="right" color="accent" />
          </div>
          <a
            className="flex items-center justify-space-between py-2 space-x-4 cursor-pointer"
            href="https://forms.gle/ma6CjcHmrJSAGP6AA"
            target="_blank"
            rel="noreferrer noopener"
          >
            <span className="flex items-center space-x-4">
              <SettingsIcon color="purple" icon={Icons.Comment} />
              <span>Feedback</span>
            </span>
          </a>
          <a
            className="flex items-center justify-space-between py-2 space-x-4 cursor-pointer"
            href="https://ikeetmee.nl/support"
            target="_blank"
            rel="noreferrer noopener"
          >
            <span className="flex items-center space-x-4">
              <SettingsIcon color="purple" icon={Icons.Email} />
              <span>Support</span>
            </span>
          </a>
          <div
            className="flex items-center justify-space-between py-2 space-x-4 cursor-pointer"
            onClick={() => _.pushModal("/updates")}
          >
            <span className="flex items-center space-x-4">
              <SettingsIcon color="yellow" icon={Icons.Star} />
              <span>Updates</span>
            </span>
            <Icons.Caret direction="right" color="accent" />
          </div>
          <div
            className="flex items-center justify-space-between py-2 space-x-4 cursor-pointer"
            onClick={signOut}
          >
            <div className="flex items-center space-x-4">
              <SettingsIcon color="text" icon={Icons.Door} />
              <div>
                <p>{t`profile.logout`}</p>
                {currentUser.isAnonymous && (
                  <p className="text-xs text-caption">{t`settings.anonymousLogout`}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <MarketingInfo />
      </div>
    </SimpleLayout>
  );
});
