import cx from "classnames";

import React, { useEffect, useRef } from "react";
import useUser from "hooks/useUser";

import { Avatar } from "components/UI";

export default function GraphicMember({ member, position, hasImage }) {
  const avatarRef = useRef(null);
  const [user] = useUser(member);

  useEffect(() => {
    const avatar = avatarRef.current;
    if (avatar) {
      setTimeout(
        () => avatar.classList.add(`group-graphic__member--${position}`),
        50
      );
      return () => {
        avatar.classList.remove(`group-graphic__member--${position}`);
      };
    }
  }, [user, position]);

  return (
    user.exists && (
      <div
        ref={avatarRef}
        className={cx("group-graphic__member", {
          "group-graphic__member--side": hasImage,
        })}
      >
        <Avatar user={user} size={2.5} />
      </div>
    )
  );
}
