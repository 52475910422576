import "./ParticipationActions.scss";
import * as _ from "utils";
import { LIST_TYPES, PARTICIPANT_ROLES } from "statics.json";
import cx from "classnames";

import React, { useCallback, useRef } from "react";
import useClickOutside from "hooks/useClickOutside";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";
import useUpstreamState from "hooks/useUpstreamState";

import * as Icons from "components/Icons";
import { IconButton, Button, ToolTip } from "components/UI";

export default function ParticipationActions({ className }) {
  const actionButtonRef = useRef();

  const { t } = useTranslation();
  const [list, listResource] = useListContext();

  const [hasResponded, setHasResponded] = useUpstreamState(
    _.hasResponded(list.myParticipation)
  );

  const unfocus = useCallback(() => {
    if (
      _.isParticipant(list?.myParticipation) ||
      _.isNonParticipant(list?.myParticipation)
    ) {
      setHasResponded(true);
    }
  }, [list, setHasResponded]);

  const containerRef = useRef(null);
  useClickOutside(containerRef, unfocus);

  const tryRespond = async (response) => {
    await listResource.respond({
      userContainer: list?.myParticipation,
      response,
      showChoices: false,
    });
    setHasResponded(true);
  };

  return (
    <div className={cx("participation-actions__position", className)}>
      <div
        ref={containerRef}
        className="rounded-md shadow-sm text-center max-w-sm p-2 m-auto bg-card"
      >
        <div className="flex items-center">
          <div
            className={cx("box justify-center m-auto", {
              "cursor-pointer": !list.isClosed,
            })}
            onClick={list.isClosed ? null : () => setHasResponded(false)}
          >
            {hasResponded && list.myParticipation ? (
              _.isChef(list.myParticipation) ? (
                <p className="flex items-center space-x-2 text-primary">
                  <Icons.Chef />
                  <span>
                    {t(
                      `form.you${
                        list.type === LIST_TYPES.ORDER ? "Order" : "Cook"
                      }`
                    )}
                  </span>
                </p>
              ) : _.isNonParticipant(list.myParticipation) ? (
                <p className="flex items-center space-x-2 text-error">
                  <Icons.Cross />
                  <span>{t`form.youDontJoin`}</span>
                </p>
              ) : (
                <p className="flex items-center space-x-2 text-success">
                  <Icons.Check />
                  <span>{t`form.youJoin`}</span>
                </p>
              )
            ) : (
              <p>{t`description.wantToJoin`}</p>
            )}
          </div>
          {hasResponded && (
            <>
              <IconButton
                ref={actionButtonRef}
                size="xs"
                variant="text"
                onClick={() => setHasResponded(false)}
                disabled={list.isClosed}
              >
                <Icons.Undo />
              </IconButton>
              <ToolTip
                originRef={actionButtonRef}
              >{t`action.changeReaction`}</ToolTip>
            </>
          )}
        </div>
        <div className="participation-actions__actions">
          <div className="flex items-center space-x-2 collapse">
            {!hasResponded &&
              (list.chefs.length === 0 || _.isChef(list.myParticipation)) && (
                <Button
                  onClick={() => tryRespond(PARTICIPANT_ROLES.CHEF)}
                  color="primary"
                  size="sm"
                  disabled={list.isClosed}
                  lead={<Icons.Chef />}
                >
                  {t(
                    `form.${list.type === LIST_TYPES.COOK ? "ICook" : "IOrder"}`
                  )}
                </Button>
              )}
            {hasResponded ? (
              <>
                {list.groupId
                  ? !_.isNonParticipant(list.myParticipation) &&
                    list.allowPlusOnes && (
                      <Button
                        color="secondary"
                        size="sm"
                        disabled={list.isClosed}
                        onClick={() =>
                          _.pushModal(
                            `/l/${list.id}/invite/${list.myParticipation.id}`
                          )
                        }
                        lead={<Icons.Plus />}
                      >
                        {t`action.plusOne`}
                      </Button>
                    )
                  : _.canEdit(list) && (
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={() => _.pushModal(`/l/${list.id}/invite`)}
                        lead={<Icons.Plus />}
                      >
                        {t`action.addParticipants`}
                      </Button>
                    )}
                {process.env.REACT_APP_EXPENSES_ENABLED && (
                  <Button
                    onClick={() => _.pushModal(`/l/${list.id}/new-expense`)}
                    color="primary"
                    size="sm"
                    lead={<span>€</span>}
                  >{t`action.addExpense`}</Button>
                )}
              </>
            ) : (
              <>
                <Button
                  onClick={() => tryRespond(PARTICIPANT_ROLES.EATER)}
                  color="success"
                  size="sm"
                  disabled={list.isClosed}
                  lead={<Icons.Check />}
                >
                  {t`action.acceptInvitation`}
                </Button>
                <Button
                  onClick={() => tryRespond(PARTICIPANT_ROLES.NON_EATER)}
                  color="error"
                  size="sm"
                  disabled={list.isClosed}
                  lead={<Icons.Cross />}
                >
                  {t`action.declineInvitation`}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      {list.isClosed && (
        <ToolTip
          originRef={containerRef}
          maxWidth={200}
        >{t`description.listClosedEditParticipation`}</ToolTip>
      )}
    </div>
  );
}
