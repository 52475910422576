import AuthResource from "./AuthResource";
import ConstResource from "./ConstResource";
import ContactsResource from "./ContactsResource";
import DeviceResource from "./DeviceResource";
import MessagingResource from "./MessagingResource";
import NetworkResource from "./NetworkResource";

import StorageResourceCache from "./StorageResourceCache";
import APIResourceCache from "./APIResourceCache";

export const authResource = new AuthResource();
export const contactsResource = new ContactsResource();
export const deviceResource = new DeviceResource();
export const messagingResource = new MessagingResource();
export const networkResource = new NetworkResource();

export const storageResourceCache = new StorageResourceCache();
export const apiResourceCache = new APIResourceCache();

export { ConstResource };
