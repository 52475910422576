import * as _ from "utils";
import queryString from "query-string";

import React from "react";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";

import { Button } from "components/UI";
import ListCalendarItem from "components/Calendar/ListCalendarItem";

export default function RecurringListSelection({ plannedList }) {
  const { t } = useTranslation();
  const [, listResource] = useListContext();

  return (
    <div className="p-4 space-y-4 rounded bg-card shadow-sm">
      <div>
        <h3>{t`list.collision.title`}</h3>
        <p className="text-caption text-xs">{t`list.collision.description`}</p>
      </div>
      <div className="pointer-events-none">
        <ListCalendarItem list={plannedList} />
      </div>
      <div className="flex justify-flex-end space-x-4">
        <Button
          variant="text"
          onClick={() => listResource.update({ regularMomentId: null })}
        >
          {t`list.collision.ignore`}
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            listResource.delete();
            if (plannedList.id) {
              _.replaceModal(`/l/${plannedList.id}`);
            } else {
              const params = queryString.stringify({
                groupId: plannedList.groupId || null,
                regularMomentId: plannedList.regularMomentId || null,
                occursAt_date: plannedList.occursAt.date,
                role: "none",
              });

              _.replaceModal(`/new-list/?${params}`);
            }
          }}
        >
          {t`list.collision.goToOther`}
        </Button>
      </div>
    </div>
  );
}
