import React from "react";
import { GroupRecencyContext } from "contexts";
import useGroupRecency from "hooks/useGroupRecency";

export default function GroupRecencyProvider({ children }) {
  const value = useGroupRecency();

  return (
    <GroupRecencyContext.Provider value={value}>
      {children}
    </GroupRecencyContext.Provider>
  );
}
