import { addDays, isBefore, startOfToday } from "date-fns";

import { useCallback, useEffect, useState } from "react";
import { useCollection, useDoc } from "react-firestore";

const today = startOfToday();
const listPlanned = (groupId) => (list) =>
  list.exists &&
  (list.isSetup || list.isShared) &&
  list.groupId === groupId &&
  !isBefore(new Date(list.occursAt.date), today);

export default function usePlannedList({ listId, groupId, date }) {
  const [group, groupResource] = useDoc("groups", groupId);
  const listPlannedForGroup = useCallback(listPlanned(groupId), [groupId]);
  const listsInGroup = useCollection("events", { filter: listPlannedForGroup });
  const [recurringListSuggestion, setRecurringListSuggestion] = useState(null);

  const plannedList = recurringListSuggestion
    ? listsInGroup.find(
        (groupList) =>
          groupList.id !== listId &&
          groupList.occursAt.date === recurringListSuggestion.occursAt.date &&
          groupList.regularMomentId === recurringListSuggestion.regularMomentId
      ) || recurringListSuggestion
    : listsInGroup.find(
        (groupList) =>
          groupList.id !== listId && groupList.occursAt.date === date
      );

  useEffect(() => {
    const startDate = new Date(date);
    const endDate = addDays(startDate, 1);
    const recurringListInstances = group.exists
      ? groupResource.getRecurringListInstances(endDate)
      : [];

    setRecurringListSuggestion(
      recurringListInstances.find(
        (recurringList) =>
          !isBefore(recurringList.calendarDate, startDate) &&
          isBefore(recurringList.calendarDate, endDate)
      )
    );
  }, [group.exists, groupResource, date]);

  return plannedList;
}
