import React from "react";
import { CourseContext } from "contexts";
import useCourse from "hooks/useCourse";

import withAuth from "hocs/withAuth";

import NotFoundPage from "pages/404";

const CourseProvider = ({ courseId, children }) => {
  const value = useCourse(courseId);

  return value[0] ? (
    <CourseContext.Provider value={value}>{children}</CourseContext.Provider>
  ) : (
    <NotFoundPage />
  );
};

export default withAuth(CourseProvider);
