import * as _ from "utils";

import React from "react";
import useListContext from "hooks/useListContext";
import useParticipantUtils from "hooks/useParticipantUtils";
import useTranslation from "hooks/useTranslation";

import UserDetail from "components/User/Detail";

export default function UserTree({
  participants,
  groupParticipants,
  i18nTemplates,
}) {
  const { t } = useTranslation();
  const [{ showGuestList, myParticipation }] = useListContext();
  const participantUtils = useParticipantUtils();

  const visibleParticipants =
    showGuestList || _.isChef(myParticipation)
      ? participants
      : participants.filter(
          (p) =>
            myParticipation &&
            (_.isSameUser(p)(myParticipation) ||
              p?.parentId === myParticipation?.id)
        );
  const invisibleParticipants = _.setMinusByKey("id")(
    participants,
    visibleParticipants
  );

  const rootParticipants = groupParticipants
    ? visibleParticipants
        .filter((p) => !p.parentId)
        .map((p) => ({
          ...p,
          children: visibleParticipants.filter(
            (child) => child.parentId === p.id
          ),
        }))
    : visibleParticipants;

  return (
    <div className="space-y-4">
      {rootParticipants.map((participant) => (
        <UserDetail
          key={participant.id}
          userContainer={participant}
          badge={participantUtils.getStatusBadge(participant)}
          showDietaryPreferences
          actions={participantUtils.getActions(participant)}
        >
          {participant.children?.map((child) => (
            <UserDetail
              key={child.id}
              userContainer={child}
              badge={participantUtils.getStatusBadge(child)}
              showDietaryPreferences
              actions={participantUtils.getActions(child, participant.id)}
            />
          ))}
        </UserDetail>
      ))}
      {invisibleParticipants.length > 0 && (
        <p className="text-caption">
          {t(i18nTemplates.invisibleParticipants, {
            count: invisibleParticipants.length,
          })}
        </p>
      )}
    </div>
  );
}
