import React, { useEffect, useRef, useState } from "react";
import useGroupSharing from "hooks/useGroupSharing";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";

import withGroup from "hocs/withGroup";

import { BottomModalLayout } from "layouts/Modal";

import * as Icons from "components/Icons";
import { IconButton, Button, ToolTip } from "components/UI";
import WhatsAppFormattedText from "components/WhatsAppFormattedText";

export default withGroup(() => {
  const { t } = useTranslation();
  const [{ message }, { shareViaWhatsApp, copyLink }] = useGroupSharing();
  const [, { onClose }] = usePageContext();

  const actionButtonRef = useRef(null);
  const [state, setState] = useState(null);

  useEffect(() => {
    if (state) {
      const t = setTimeout(() => setState(null), 1000);
      return () => clearTimeout(t);
    }
  }, [state]);

  return (
    <BottomModalLayout>
      <div className="rounded-b-none space-y-4 p-4 rounded-md bg-card">
        <div>
          <h2>{t`label.example`}</h2>
          <WhatsAppFormattedText>{message}</WhatsAppFormattedText>
        </div>
        <div className="flex space-x-4">
          <Button
            color="whatsapp"
            onClick={() => {
              shareViaWhatsApp();
              onClose();
            }}
          >
            WhatsApp
          </Button>
          <IconButton
            ref={actionButtonRef}
            color="card"
            onClick={async () => {
              const success = await copyLink();
              setState(success ? "success" : "failed");
            }}
          >
            <Icons.Copy />
          </IconButton>
          <ToolTip originRef={actionButtonRef}>
            {state === "failed"
              ? t`label.copyFailed`
              : state === "success"
              ? t`label.copySuccessful`
              : t`action.copyLink`}
          </ToolTip>
        </div>
      </div>
    </BottomModalLayout>
  );
});
