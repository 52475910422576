import React from "react";

import CourseProvider from "containers/CourseProvider";

const withCourse = (Component) => (props) => (
  <CourseProvider courseId={props.courseId}>
    <Component {...props} />
  </CourseProvider>
);

export default withCourse;
