import cx from "classnames";

import React from "react";

import * as Icons from "components/Icons";
import { IconButton } from "components/UI";
import FormattedRegularMoment from "components/FormattedRegularMoment";

export default ({ item, onEdit }) => (
  <div className="flex items-center justify-space-between space-x-2">
    <div>
      {item.title && <p>{item.title}</p>}
      <p className={cx({ "text-xs text-caption": item.title })}>
        <FormattedRegularMoment item={item} />
      </p>
    </div>
    {onEdit && (
      <IconButton variant="text" size="xs" onClick={onEdit}>
        <Icons.Pen />
      </IconButton>
    )}
  </div>
);
