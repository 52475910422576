import * as _ from "utils";

import React from "react";
import useAuth from "hooks/useAuth";
import useTranslation from "hooks/useTranslation";
import { useFormState } from "react-use-form-state";

import * as Icons from "components/Icons";
import { Button, Field } from "components/UI";

const Introduction = () => {
  const { t } = useTranslation();
  const [, { signInAnonymously }] = useAuth();
  const [formState, { text }] = useFormState();

  const onSubmit = async (event) => {
    event.preventDefault();
    await signInAnonymously(formState.values);
    _.popModal("/");
  };

  return (
    <div className="space-y-4 py-4">
      <div className="flex">
        <Button
          onClick={() => _.navigate(-1)}
          color="primary"
          variant="text"
          size="xs"
          importance="tertiary"
          lead={<Icons.Caret direction="left" />}
        >
          {t`label.back`}
        </Button>
      </div>
      <form className="space-y-4" onSubmit={onSubmit}>
        <div className="space-y-2">
          <h1>{t`auth.signUpAnonymously`}</h1>
          <p className="text-caption">{t`auth.signUpAnonymouslyDescription`}</p>
        </div>
        <Field
          size="sm"
          variant="contained"
          label={t`auth.yourName`}
          {...text("name")}
          required
        />
        <Button
          className="w-full"
          type="submit"
          color="primary"
          disabled={!formState.values.name}
        >
          {t`auth.createAccount`}
        </Button>
      </form>
    </div>
  );
};

export default Introduction;
