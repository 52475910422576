import React from "react";
import useListContext from "hooks/useListContext";
import useUsers from "hooks/useUsers";

import { Avatar } from "components/UI";

export default ({ courseOption }) => {
  const [list] = useListContext();

  const participantIds = list.participants.map(({ id }) => id);
  const participantsWhoChoseOption = list.participants.filter((participant) =>
    courseOption.votes.some(
      (vote) => vote === participant.id || vote.participantId === participant.id
    )
  );
  const usersWhoChoseOption = useUsers(participantsWhoChoseOption);

  return (
    <div className="flex items-center space-x-1">
      <p className="text-xs text-caption">
        {
          courseOption.votes.filter(
            (vote) =>
              participantIds.includes(vote) ||
              participantIds.includes(vote.participantId)
          ).length
        }
        x
      </p>
      <div className="flex space-x--1">
        {usersWhoChoseOption.map((user) => (
          <Avatar key={user.id} user={user} size={1} />
        ))}
      </div>
    </div>
  );
};
