import "./NavBar.scss";

import React, { useCallback, useRef } from "react";
import usePageScroll from "hooks/usePageScroll";

export default function NavBar({ children }) {
  const ref = useRef(null);

  const onScroll = useCallback((scrollTop) => {
    const node = ref.current;
    if (node) {
      node.classList.toggle("navbar--down", scrollTop > 0);
      node.classList.toggle("bg-card", scrollTop > 0);
    }
  }, []);
  usePageScroll(onScroll);

  return (
    <div ref={ref} className="navbar">
      <div className="flex-grow max-w-2xl mx-auto">{children}</div>
    </div>
  );
}
