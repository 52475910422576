import * as _ from "utils";
import firebase from "firebase/app";
import { Capacitor, Plugins } from "@capacitor/core";
import { cfaSignOut } from "capacitor-firebase-auth";
import { authResource, deviceResource } from "resources";

import { useMemo } from "react";
import useResource from "./useResource";

const { SignInWithApple, CapacitorFirebaseAuth } = Plugins;

const appleProvider = new firebase.auth.OAuthProvider("apple.com");
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.addScope(
  "https://www.googleapis.com/auth/user.phonenumbers.read"
);
googleProvider.addScope("https://www.googleapis.com/auth/contacts.readonly");

const createUserDoc = async (params) => {
  const userDocRef = _.getCurrentUserRef();

  const user = {
    uid: userDocRef.id,
    name: "",
    email: "",
    avatar: null,
    preferences: [],
    settings: {
      notifications: {
        whenAddedToList: true,
        whenRequiredChoice: true,
      },
    },
    createdAt: new Date(),
    updatedAt: new Date(),
    ...params,
  };
  user.userIdentifiers = _.getUserIdentifiers(user);
  await userDocRef.set(user);
};

const updateUserDoc = async (update) => {
  const userDocRef = _.getCurrentUserRef();
  await userDocRef.update({ ...update, updatedAt: new Date() });
};

const refreshUser = async () => {
  if (_.auth.currentUser.isAuthenticated) {
    await _.db.runTransaction(async (tx) => {
      const userDocRef = _.getCurrentUserRef();
      const userDoc = await tx.get(userDocRef);
      if (userDoc.exists) {
        const user = userDoc.data();

        const updatedUser = {
          uid: userDocRef.id,
          name: user.name || _.getUserField("displayName"),
          email: user.email || _.getUserField("email"),
          avatar: user.avatar || _.getUserField("photoURL"),
          updatedAt: new Date(),
        };

        updatedUser.userIdentifiers = _.getUserIdentifiers(updatedUser);

        await tx.update(userDocRef, updatedUser);
      } else {
        const email = _.getUserField("email");
        await tx.set(userDocRef, {
          uid: userDocRef.id,
          name: _.getUserField("displayName"),
          email,
          avatar: _.getUserField("photoURL"),
          preferences: [],
          userIdentifiers:
            typeof email === "string"
              ? [userDocRef.id, _.md5(email)]
              : [userDocRef.id],
          settings: {
            notifications: {
              whenAddedToList: true,
              whenRequiredChoice: true,
            },
          },
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }
    });
  }
};

const signInAnonymously = async ({ name }) => {
  await _.auth.signInAnonymously();
  await createUserDoc({ name });
  _.track("account_login", { method: "anonymous" });
};

const signUpWithEmail = async ({ name, email, password }) => {
  await _.auth.createUserWithEmailAndPassword(email, password);
  await createUserDoc({ name, email });
  _.track("account_signup", { method: "password" });
};

const signOut = () =>
  Capacitor.isNative
    ? new Promise((resolve, reject) => {
        cfaSignOut().subscribe(resolve, reject);
      })
    : _.auth.signOut();

const authenticateWithProvider = async (provider) => {
  if (provider.providerId === "apple.com") {
    const { response } = await SignInWithApple.authorize({
      clientId: "com.wouterraateland.ikeetmee",
      redirectURI: `${process.env.PUBLIC_URL}/auth/login`,
    });
    if (response?.identityToken) {
      return appleProvider.credential(
        response.identityToken,
        response.authorizationCode
      );
    }
  } else {
    const result = await CapacitorFirebaseAuth.signIn({
      providerId: provider.providerId,
    });
    if (result?.idToken) {
      return provider.credential(
        provider.providerId === "facebook.com"
          ? { accessToken: result.idToken }
          : result.idToken
      );
    }
  }
};

const signInWithProvider = async (provider) => {
  if (Capacitor.isNative) {
    const credential = await authenticateWithProvider(provider);
    if (provider) {
      await _.auth.signInWithCredential(credential);
    }
  } else if (_.isMobile) {
    await _.auth.signInWithRedirect(provider);
  } else {
    await _.auth.signInWithPopup(provider);
  }
  await refreshUser();
  _.track("account_login", { method: provider.providerId });
};

const signInWithApple = () => signInWithProvider(appleProvider);
const signInWithFacebook = () => signInWithProvider(facebookProvider);
const signInWithGoogle = () => signInWithProvider(googleProvider);

const signInWithEmail = async ({ email, password }) => {
  await _.auth.signInWithEmailAndPassword(email, password);
  await refreshUser();
  _.track("account_login", { method: "pasword" });
};

const linkProvider = async (provider) => {
  if (Capacitor.isNative) {
    const credential = await authenticateWithProvider(provider);
    if (credential) {
      await _.auth.currentUser.linkWithCredential(credential);
    }
  } else if (_.isMobile) {
    await _.auth.currentUser.linkWithRedirect(provider);
  } else {
    await _.auth.currentUser.linkWithPopup(provider);
  }
  await refreshUser();
  _.track("account_link", { method: provider.providerId });
};

const linkApple = () => linkProvider(appleProvider);
const linkGoogle = () => linkProvider(googleProvider);
const linkFacebook = () => linkProvider(facebookProvider);
const linkEmail = async (email, password) => {
  await _.auth.currentUser.linkWithCredential(
    firebase.auth.EmailAuthProvider.credential(email, password)
  );
  await updateUserDoc({ email });
  await refreshUser();
  _.track("account_link", { method: "pasword" });
};

const unlinkProvider = async (providerId) => {
  if (_.auth.currentUser?.providerData.length < 2) {
    _.track("account_unlink", { error: true, method: providerId });
    throw Error("auth/delete_only_provider");
  } else {
    await _.auth.currentUser.unlink(providerId);
    await refreshUser();
    _.track("account_unlink", { method: providerId });
  }
};

const unlinkApple = () => unlinkProvider("apple.com");
const unlinkGoogle = () => unlinkProvider("google.com");
const unlinkFacebook = () => unlinkProvider("facebook.com");

const updateEmail = async (email) => {
  await _.auth.currentUser.updateEmail(email);
  await updateUserDoc({
    email,
    userIdentifiers:
      typeof email === "string"
        ? [_.auth.currentUser.uid, _.md5(email)]
        : [_.auth.currentUser.uid],
  });
  authResource.refresh();
  _.track("account_email_update");
};

const updatePassword = async (password) => {
  await _.auth.currentUser.updatePassword(password);
  authResource.refresh();
  _.track("account_password_update");
};

export default function useAuth() {
  const auth = useResource(authResource);
  const device = useResource(deviceResource);

  const support = useMemo(
    () =>
      Capacitor.isNative
        ? {
            link: false,
            methods:
              device.platform === "ios"
                ? [
                    "anonymous",
                    "email",
                    "apple.com",
                    "facebook.com",
                    "google.com",
                  ]
                : ["anonymous", "email", "facebook.com", "google.com"],
          }
        : {
            link: true,
            methods: [
              "anonymous",
              "email",
              "apple.com",
              "facebook.com",
              "google.com",
            ],
          },
    [device.platform]
  );

  return [
    auth,
    {
      signInAnonymously,
      signInWithApple,
      signInWithGoogle,
      signInWithFacebook,
      signInWithEmail,
      signUpWithEmail,
      signOut,
      linkApple,
      linkGoogle,
      linkFacebook,
      linkEmail,
      unlinkApple,
      unlinkGoogle,
      unlinkFacebook,
      updateEmail,
      updatePassword,
    },
    support,
  ];
}
