import * as _ from "utils";

import React, { useCallback } from "react";
import { useDoc, useCollection } from "react-firestore";
import useSettlementContext from "hooks/useSettlementContext";
import useTranslation from "hooks/useTranslation";
import useUsers from "hooks/useUsers";

import withSettlement from "hocs/withSettlement";

import SimpleLayout from "layouts/Simple";

import InfiniteList from "components/InfiniteList";
import ListCalendarItem from "components/Calendar/ListCalendarItem";
import BalancingTransactions from "components/Expenses/BalancingTransactions";
import ExpenseList from "components/Expenses/ExpenseList";
import TransactionThumb from "components/Transactions/Thumb";

const renderTransaction = (transaction) => (
  <TransactionThumb key={transaction.id} transaction={transaction} />
);

const byCreatedAt = _.firstBy((entity) => entity.createdAt.toMillis(), -1);

const currentUserParticipates = (transaction) =>
  _.isCurrentUser(transaction.from) || _.isCurrentUser(transaction.to);

export default withSettlement(({ settlementId }) => {
  const { moment, t } = useTranslation();
  const inSettlement = useCallback(
    (entity) => entity.exists && entity.settlementId === settlementId,
    [settlementId]
  );
  const [settlement] = useSettlementContext();
  const [group] = useDoc("groups", settlement.groupId);
  const [list] = useDoc("events", settlement.listId);

  const transactions = useCollection("transactions", {
    filter: inSettlement,
  });
  const yourTransactions = transactions.filter(currentUserParticipates);

  const expenses = useCollection("expenses", {
    filter: inSettlement,
    sort: byCreatedAt,
  });

  const involvedUserContainers = transactions
    .reduce(
      (userContainers, { from, to }) => [
        ...userContainers,
        !userContainers.some(_.isSameUser(from)) && from,
        !userContainers.some(_.isSameUser(to)) && to,
      ],
      []
    )
    .filter(Boolean);
  const involvedUsers = useUsers(involvedUserContainers);

  return (
    <SimpleLayout
      title={
        group.name || list.title
          ? t(`settlements.ofTitle`, {
              title: group.name || list.title,
              date: moment(settlement.createdAt.toDate()).format("ll"),
            })
          : list.exists
          ? t(`settlements.ofList`, {
              date: moment(settlement.createdAt.toDate()).format("ll"),
            })
          : t`transactions.title`
      }
    >
      <div className="space-y-8">
        {list.exists && (
          <div className="pointer-events-none">
            <ListCalendarItem list={list} />
          </div>
        )}
        {yourTransactions.length > 0 && (
          <div className="space-y-4">
            <h2>{t`settlements.yourTransactions`}</h2>
            <InfiniteList
              items={yourTransactions}
              renderItem={renderTransaction}
            />
          </div>
        )}
        <div className="space-y-4">
          <h2>{t`settlements.allTransactions`}</h2>
          <BalancingTransactions
            transactions={transactions}
            involvedUsers={involvedUsers}
          />
        </div>
        <div className="space-y-4">
          <h2>{t`settlements.expenses`}</h2>
          <ExpenseList expenses={expenses} showList showDate />
        </div>
      </div>
    </SimpleLayout>
  );
});
