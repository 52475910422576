import moment from "moment";

import React from "react";

const CalendarMonth = ({ month }) => (
  <h2 className="calendar__month">
    <mark>
      {month.format(month.year() === moment().year() ? "MMMM" : "MMMM YYYY")}
    </mark>
  </h2>
);

export default CalendarMonth;
