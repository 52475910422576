import * as _ from "utils";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import React, { useState } from "react";
import useGroupContext from "hooks/useGroupContext";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";

import withGroup from "hocs/withGroup";

import { PopupModalLayout } from "layouts/Modal";

import { Button, Field, NumberInput } from "components/UI";
import RegularMomentInput from "components/RegularMomentInput";
import ChecklistInput from "components/Task/ChecklistInput";
import AssigneeOrder from "components/Task/AssigneeOrder";

const initialState = {
  title: "",
  checklist: [""],
  leeway: 0,
  frequency: 1,
  frequencyPeriod: "week",
  dateType: "day",
  dayOfWeek: moment().weekday(),
  dayOfMonth: moment().date(),
  nth: "first",
  assigneesPerTask: 1,
  startDate: {
    date: moment().format("YYYY-MM-DD"),
    time: null,
  },
  weekDays: Array(7).fill(false),
};

export const CreateRecurringTask = () => {
  const { t } = useTranslation();
  const [, { onClose }] = usePageContext();
  const [group, groupResource] = useGroupContext();
  const [state, setState] = useState({
    ...initialState,
    assignedMemberIds: group.members.map((member) => member.id),
    unassignedMemberIds: [],
  });

  return (
    <PopupModalLayout>
      <div className="space-y-4">
        <div className="p-4 space-y-8 rounded-md bg-card">
          <div className="space-y-4">
            <h3>{t`task.newRecurring`}</h3>
            <Field
              label={t`label.title`}
              placeholder={t`task.titleSuggestions`}
              variant="contained"
              required
              size="sm"
              value={state.title || ""}
              onChange={({ target }) =>
                setState((state) => ({ ...state, title: target.value }))
              }
              onBlur={() =>
                setState((state) => ({
                  ...state,
                  title: state.title?.trim() ?? null,
                }))
              }
            />
            <Field
              label={t`task.assigneesPerTask`}
              variant="contained"
              type="number"
              required
              size="sm"
              decimals={0}
              value={state.assigneesPerTask || 0}
              onChange={({ target }) =>
                setState((state) => ({
                  ...state,
                  assigneesPerTask: target.value,
                }))
              }
              component={NumberInput}
            />
            <Field
              label={t`task.leeway`}
              variant="contained"
              type="number"
              required
              size="sm"
              decimals={0}
              value={state.leeway || 0}
              onChange={({ target }) =>
                setState((state) => ({
                  ...state,
                  leeway: target.value,
                }))
              }
              component={NumberInput}
            />
            <h3>{t`task.checklist`}</h3>
            <ChecklistInput value={state} onChange={setState} />
            <h3>{t`task.frequency`}</h3>
            <RegularMomentInput value={state} onChange={setState} />
          </div>
          <AssigneeOrder value={state} onChange={setState} />
        </div>
        <div className="flex justify-flex-end space-x-4">
          <Button color="background" onClick={onClose}>
            {t`action.cancel`}
          </Button>
          <Button
            color="primary"
            disabled={
              state.assigneesPerTask <= 0 ||
              !state.title ||
              !_.isValidRegularMoment(state)
            }
            onClick={() => {
              const {
                assignedMemberIds,
                unassignedMemberIds,
                checklist,
                ...params
              } = state;
              groupResource.createRecurringTask({
                ...params,
                checklist: checklist.filter((item) => item !== ""),
                possibleAssignees: assignedMemberIds.map((memberId) => ({
                  id: uuidv4(),
                  userIdentifiers: group.members.find(
                    (member) => member.id === memberId
                  ).userIdentifiers,
                })),
              });
              onClose();
            }}
          >
            {t`action.create`}
          </Button>
        </div>
      </div>
    </PopupModalLayout>
  );
};

export default withGroup(CreateRecurringTask);
