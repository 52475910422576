import React, { Fragment } from "react";

const STRONG_REGEX = /\*(\S([\s\S]*?\S)*?)\*/g;
const ITALIC_REGEX = /_(\S([\s\S]*?\S)*?)_/g;
const URL_REGEX = /https:\/\/(\S*)/g;
const LINEBREAK_REGEX = /\r?\n|\r/g;

const REGEXES = [
  { type: "strong", regex: STRONG_REGEX },
  { type: "italic", regex: ITALIC_REGEX },
  { type: "url", regex: URL_REGEX },
  { type: "linebreak", regex: LINEBREAK_REGEX },
];

const getFirstMatch = (string) =>
  REGEXES.map(({ type, regex }) => ({
    type,
    match: regex.exec(string),
  }))
    .filter(({ match }) => !!match)
    .sort((a, b) => a.match.index - b.match.index)[0];

const generateAst = (string) => {
  const { type, match } = getFirstMatch(string) || {};

  return match
    ? {
        type: "array",
        value: [
          generateAst(string.slice(0, match.index)),
          { type, value: generateAst(match[1]) },
          generateAst(string.slice(match.index + match[0].length)),
        ]
          .filter((node) => node !== null)
          .flatMap((node) => (node.type === "array" ? node.value : [node]))
          .map((node, i) => ({ ...node, nodeId: i })),
      }
    : string
    ? {
        type: "string",
        value: string,
      }
    : null;
};

const render = ({ nodeId, type, value }) => {
  switch (type) {
    case "array":
      return value.map(render);
    case "strong":
      return (
        <Fragment key={nodeId}>
          <span className="text-accent">*</span>
          <strong>{render(value)}</strong>
          <span className="text-accent">*</span>
        </Fragment>
      );
    case "italic":
      return (
        <Fragment key={nodeId}>
          <span className="text-accent">_</span>
          <em>{render(value)}</em>
          <span className="text-accent">_</span>
        </Fragment>
      );
    case "url":
      return (
        <span key={nodeId} className="text-primary">
          https://{render(value)}
        </span>
      );
    case "br":
      return <br />;
    case "string":
    default:
      return value;
  }
};

export default function WhatsAppFormattedText({ children }) {
  if (typeof children !== "string") {
    throw Error("children should be a string");
  }

  const ast = generateAst(children);

  return <p className="break-words">{render(ast)}</p>;
}
