import "./Expenses.scss";
import * as _ from "utils";

import React, { useCallback } from "react";
import { useCollection, useDoc } from "react-firestore";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Button } from "components/UI";
import ExpenseList from "components/Expenses/ExpenseList";
import ExpensesEmptyState from "components/Expenses/EmptyState";

const byCreatedAt = _.firstBy((entity) => entity.createdAt.toMillis(), -1);

export default () => {
  const { t } = useTranslation();
  const [list] = useListContext();
  const [group] = useDoc("groups", list.groupId);
  const isRelatedToList = useCallback(
    (entity) => entity.exists && entity.listId === list.id,
    [list.id]
  );
  const expenses = useCollection("expenses", {
    filter: isRelatedToList,
    sort: byCreatedAt,
  });
  const settlements = useCollection("settlements", { filter: isRelatedToList });

  const expensesToSettleInGroup = group.exists
    ? expenses.filter((expense) => expense.groupId === group.id)
    : [];
  const expensesToSettleDirectly = expenses.filter(
    (expense) => !group.exists || expense.groupId !== group.id
  );
  const unsettledExpensesToSettleDirectly = expensesToSettleDirectly.filter(
    (expense) => !expense.isSettled
  );

  return (
    <div id="expenses" className="space-y-4">
      {expensesToSettleInGroup.length > 0 && (
        <div className="p-2 space-y-2 bg-card rounded-md shadow-sm">
          <p className="font-bold">
            {t(`expense.toSettleInGroup`, { group: group.name })}
          </p>
          <ExpenseList expenses={expensesToSettleInGroup} showSettled />
          {group.myMembership && (
            <div className="flex justify-flex-end">
              <Button
                variant="text"
                color="primary"
                trail={<Icons.Arrow />}
                onClick={() => _.pushModal(`/g/${group.id}/expenses`)}
              >
                {t(`expense.allExpensesInGroup`, { group: group.name })}
              </Button>
            </div>
          )}
        </div>
      )}
      {expensesToSettleDirectly.length > 0 && (
        <div className="p-2 space-y-2 bg-card rounded-md shadow-sm">
          <p className="font-bold">{t`expense.toSettleDirectly`}</p>
          {unsettledExpensesToSettleDirectly.length === 0 && (
            <ExpensesEmptyState />
          )}
          <ExpenseList
            expenses={unsettledExpensesToSettleDirectly}
            showSettled
          />
          <div className="flex space-y-4 flex-col items-flex-end sm:flex-row sm:justify-flex-end sm:items-center sm:space-y-0 sm:space-x-4">
            {settlements.length > 0 && (
              <Button
                variant="text"
                color="primary"
                onClick={() => _.pushModal(`/l/${list.id}/settlements`)}
                trail={<Icons.Arrow direction="right" />}
              >{t`expense.viewSettlements`}</Button>
            )}
            {unsettledExpensesToSettleDirectly.length > 0 && (
              <Button
                size="sm"
                color="primary"
                className="w-full sm:w-auto"
                onClick={() => _.pushModal(`/l/${list.id}/settle`)}
              >{t`expense.newSettlement`}</Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
