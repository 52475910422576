import * as _ from "utils";

import { useEffect } from "react";
import usePageContext from "hooks/usePageContext";

const Redirect = ({ to, uri }) => {
  const [{ depth, isCurrent }] = usePageContext();
  useEffect(() => {
    if (isCurrent) {
      _.navigate(`${uri}/${to}`.replace(/\/\//g, "/"), {
        depth,
        replace: true,
      });
    }
  }, [depth, uri, to, isCurrent]);

  return null;
};

export default Redirect;
