import cx from "classnames";

import React, { forwardRef } from "react";

import BaseInput from "./BaseInput";

const Field = forwardRef(
  (
    {
      component: Component = BaseInput,
      detail,
      labelLocation,
      className,
      htmlFor,
      ...inputProps
    },
    ref
  ) => {
    return labelLocation === "inline" ? (
      <label
        className={cx(
          "flex flex-grow items-flex-start space-x-2 text-left",
          className
        )}
        htmlFor={htmlFor}
      >
        <input ref={ref} {...inputProps} />
        <div>
          {inputProps.label && <p>{inputProps.label}</p>}
          {detail && <p className="text-xs text-caption">{detail}</p>}
          {inputProps.error && (
            <p className="text-xs text-error">{inputProps.error}</p>
          )}
        </div>
      </label>
    ) : labelLocation === "inline-reverse" ? (
      <label
        className={cx(
          "flex flex-grow items-flex-start space-x-2 text-left",
          className
        )}
      >
        <div className="flex-grow">
          {inputProps.label && (
            <p className="whitespace-normal">{inputProps.label}</p>
          )}
          {detail && <p className="text-xs text-caption">{detail}</p>}
          {inputProps.error && (
            <p className="text-xs text-error">{inputProps.error}</p>
          )}
        </div>
        <Component ref={ref} {...inputProps} />
      </label>
    ) : (
      <label className={cx("block flex-grow text-left", className)}>
        <Component ref={ref} {...inputProps} />
        {inputProps.error && (
          <p className="text-xs text-error">{inputProps.error}</p>
        )}
      </label>
    );
  }
);

export default Field;
