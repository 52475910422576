import "./index.scss";

import React, { useCallback, useMemo } from "react";
import useTranslation from "hooks/useTranslation";

import InfiniteList from "components/InfiniteList";
import CalendarMonth from "./CalendarMonth";
import CalendarDay from "./CalendarDay";
import CalendarItem from "./CalendarItem";

export default function Calendar({
  lists,
  tasks,
  alwaysShowToday,
  direction,
  loadPage,
  ...props
}) {
  const items = [...lists, ...tasks];
  const { moment } = useTranslation();
  const dateItems = useMemo(() => {
    const undatedItems = [];
    const itemsPerDayMap = alwaysShowToday
      ? {
          [moment().format("YYYY-MM-DD")]: [],
        }
      : {};
    const monthsMap = alwaysShowToday
      ? { [moment().format("YYYY-MM")]: true }
      : {};

    items.forEach((item) => {
      const date = moment(item.calendarDate);
      const day = date.format("YYYY-MM-DD");
      const month = date.format("YYYY-MM");
      if (date.isValid()) {
        itemsPerDayMap[day] = (itemsPerDayMap[day] || []).concat(item);
        monthsMap[month] = true;
      } else {
        undatedItems.push(item);
      }
    });

    const itemsPerDay = Object.entries(itemsPerDayMap)
      .sort((a, b) => direction * (a[0] > b[0] ? 1 : -1))
      .map(([day, items]) => ({
        key: day,
        type: "day",
        date: moment(day, "YYYY-MM-DD"),
        items,
      }));
    const months = Object.keys(monthsMap)
      .sort((a, b) => direction * (a > b ? 1 : -1))
      .map((month) => ({
        key: month,
        type: "month",
        date: moment(month, "YYYY-MM"),
      }));

    const dateItems = itemsPerDay;
    for (let i = 0, j = 0; i < dateItems.length && j < months.length; i++) {
      if (
        (direction === -1 &&
          moment(months[j].date).add(1, "month").isAfter(dateItems[i].date)) ||
        (direction === 1 && months[j].date.isBefore(dateItems[i].date))
      ) {
        dateItems.splice(i, 0, months[j]);
        j++;
        i++;
      }
    }

    return undatedItems.concat(dateItems);
  }, [alwaysShowToday, direction, items, moment]);

  const renderItem = useCallback(
    (item) =>
      item.type === "day" ? (
        <CalendarDay
          key={item.key}
          day={item.date}
          items={item.items}
          {...props}
        />
      ) : item.type === "month" ? (
        <CalendarMonth key={item.key} month={item.date} />
      ) : (
        <div key={item.id} className="px-4">
          <CalendarItem item={item} />
        </div>
      ),
    [props]
  );

  return (
    <div className="space-y-4 pb-4">
      <InfiniteList
        items={dateItems}
        renderItem={renderItem}
        loadPage={loadPage}
      />
    </div>
  );
}
Calendar.defaultProps = {
  direction: 1,
  isEditable: false,
  alwaysShowToday: true,
  showGroup: true,
  fadeOccurred: true,
  lists: [],
  tasks: [],
};
