import cx from "classnames";

import React from "react";

import * as Icons from "components/Icons";
import { Button, IconButton } from "components/UI";

export default function Notification({ payload, onClose }) {
  return (
    <div
      className={cx(
        "flex items-center justify-space-between max-w-lg p-4 mx-auto space-x-4 rounded-md",
        `bg-${payload.color || "text"}`
      )}
    >
      <p>{payload.title}</p>
      {payload.action && (
        <Button onClick={payload.action.onAct} size="sm">
          {payload.action.label}
        </Button>
      )}
      {onClose && (
        <IconButton variant="text" onClick={onClose}>
          <Icons.Cross />
        </IconButton>
      )}
    </div>
  );
}
