import { Plugins } from "@capacitor/core";

import React, { useEffect, useState } from "react";

const { IOSAppTracking } = Plugins;

export default function AppTrackingTransparencyProvider({ children }) {
  const [status, setStatus] = useState();

  useEffect(() => {
    IOSAppTracking.requestPermission().then((response) => {
      setStatus(response.status);
    }).catch((error) => {
      setStatus("authorized");
    });
  }, [status]);

  return status === "authorized" ? (
    children
  ) : ["denied", "unrequested", "restricted"].includes(status) ? (
    <div className="h-full p-4 flex flex-col justify-center">
      <p>
        Ikeetmee requires your permission to let you create an account.
        <br />
        To continue using the app, please uninstall and the reinstall the app
        again. Make sure to allow tracking then.
      </p>
    </div>
  ) : null;
}
