import * as _ from "utils";
import { isBefore, startOfToday } from "date-fns";

import React from "react";
import { useCollection } from "react-firestore";

import Calendar from "components/Calendar";

const today = startOfToday();
const listArchived = (list) =>
  list.exists &&
  (list.isSetup || list.isShared) &&
  !_.isNonParticipant(list.myParticipation) &&
  isBefore(new Date(list.occursAt.date), today);

export default function ArchivedLists() {
  const lists = useCollection("events", { filter: listArchived });

  return (
    <Calendar
      lists={lists}
      direction={-1}
      alwaysShowToday={false}
      fadeOccurred={false}
    />
  );
}
