import { addWeeks, isBefore, isSameDay, startOfToday } from "date-fns";

import React, { useEffect, useCallback, useRef, useState } from "react";
import { useCollection } from "react-firestore";
import useGroupContext from "hooks/useGroupContext";

import Calendar from "components/Calendar";

const today = startOfToday();

const listPlanned = (groupId) => (list) =>
  list.exists &&
  (list.isSetup || list.isShared) &&
  list.groupId === groupId &&
  !isBefore(new Date(list.occursAt.date), today);

const taskIn = (groupId) => (task) =>
  task.exists &&
  task.groupId === groupId &&
  (task.recurringTaskId || task.isSetup);

export default ({ groupId }) => {
  const listPlannedForGroup = useCallback(listPlanned(groupId), [groupId]);
  const taskInGroup = useCallback(taskIn(groupId), [groupId]);
  const lists = useCollection("events", { filter: listPlannedForGroup });
  const tasks = useCollection("tasks", { filter: taskInGroup });
  const [, groupResource] = useGroupContext();
  const endDateRef = useRef(addWeeks(new Date(), 1));

  const getUpcomingLists = useCallback(
    (endDate) => {
      const recurringListInstances = groupResource.getRecurringListInstances(
        endDate
      );

      return recurringListInstances
        .filter((list) => !isBefore(list.calendarDate, today))
        .reduce(
          (lists, rl) =>
            lists.some(
              (l) =>
                l.regularMomentId === rl.regularMomentId &&
                l.occursAt.date === rl.occursAt.date
            )
              ? lists
              : [...lists, rl],
          lists
        );
    },
    [groupResource, lists]
  );

  const getUpcomingTasks = useCallback(
    (endDate) => {
      const recurringTaskInstances = groupResource.getRecurringTaskInstances(
        endDate
      );
      return recurringTaskInstances
        .reduce(
          (tasks, instance) =>
            tasks.some(
              (task) =>
                task.recurringTaskId === instance.recurringTaskId &&
                isSameDay(task.calendarDate, instance.calendarDate)
            )
              ? tasks
              : [...tasks, instance],
          tasks
        )
        .filter((task) => !task.isArchived);
    },
    [groupResource, tasks]
  );

  const [upcomingLists, setUpcomingLists] = useState(
    getUpcomingLists(endDateRef.current)
  );
  const [upcomingTasks, setUpcomingTasks] = useState(
    getUpcomingTasks(endDateRef.current)
  );

  const loadPage = useCallback(() => {
    endDateRef.current = addWeeks(endDateRef.current, 1);
    setUpcomingLists(getUpcomingLists(endDateRef.current));
    setUpcomingTasks(getUpcomingTasks(endDateRef.current));
  }, [getUpcomingLists, getUpcomingTasks]);

  useEffect(() => {
    setUpcomingLists(getUpcomingLists(endDateRef.current));
  }, [getUpcomingLists]);

  useEffect(() => {
    setUpcomingTasks(getUpcomingTasks(endDateRef.current));
  }, [getUpcomingTasks]);

  return (
    <Calendar
      lists={upcomingLists}
      tasks={upcomingTasks}
      showGroup={false}
      loadPage={
        groupResource.usableRecurringTasks.length > 0 ||
        groupResource.usableRegularMoments.length > 0
          ? loadPage
          : null
      }
    />
  );
};
