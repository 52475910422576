import "./DropDown.scss";
import cx from "classnames";

import React, { useRef } from "react";

import * as Icons from "components/Icons";
import FlyOut from "./FlyOut";

export default function DropDown({
  value,
  placeholder,
  options,
  required,
  onChange,
  className,
  variant = "bordered",
  size = "xs",
  color = "text",
  emphasis,
  disabled,
}) {
  const containerRef = useRef(null);
  const selectedOption = options.find((option) => option.value === value);

  return (
    <>
      <div
        ref={containerRef}
        tabIndex={0}
        className={cx(
          "flex items-center space-x-2 justify-space-between min-w-0",
          "dropdown",
          `text-${selectedOption ? color : "caption"}`,
          { "dropdown--bare": variant === "bare" },
          { [`dropdown--bordered dropdown--${size}`]: variant === "bordered" },
          { "dropdown--disabled": disabled },
          className
        )}
      >
        {typeof selectedOption?.label !== "object" ? (
          <p className={cx({ "font-bold": emphasis && selectedOption })}>
            {selectedOption?.label || value || placeholder}
          </p>
        ) : (
          selectedOption?.label
        )}
        <Icons.Caret
          style={{ height: "0.75em" }}
          strokeWidth={emphasis && selectedOption ? 6 : 3}
          direction="down"
        />
      </div>
      <FlyOut originRef={containerRef} direction="vertical" disabled={disabled}>
        {!required && (
          <FlyOut.Item color="caption" onClick={() => onChange(undefined)}>
            {placeholder}
          </FlyOut.Item>
        )}
        {options.map((option) => (
          <FlyOut.Item
            key={option.value}
            onClick={() => onChange(option.value)}
            {...option}
          >
            {option.label}
          </FlyOut.Item>
        ))}
      </FlyOut>
    </>
  );
}
