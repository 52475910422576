import * as _ from "utils";

import useTranslation from "hooks/useTranslation";

export default function UserName({ user, indicateCurrentUser }) {
  const { t } = useTranslation();

  return `${user?.name?.trim() || user?.email || t`label.nameless`} ${
    indicateCurrentUser && _.isCurrentUser(user) ? ` (${t`label.me`})` : ""
  }`;
}
