import update1 from "./1.jsx";
import update2 from "./2.jsx";
import update3 from "./3.jsx";
import update4 from "./4.jsx";
import update5 from "./5.jsx";
import update6 from "./6.jsx";

let updates = [update1, update2, update3, update4, update5, update6];

export default updates;
