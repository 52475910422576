import * as _ from "utils";

import React from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";

export default ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-space-between h-full space-y-4 p-4">
      <a href="https://ikeetmee.nl" className="font-bold m-safe">
        <Icons.IkEetMee className="text-secondary" strokeWidth={8} />{" "}
        ikeetmee.nl
      </a>
      <div className="w-full max-w-md">{children}</div>
      <p className="rounded-md text-center w-full max-w-xs p-4 bg-card shadow-sm">
        {t`auth.haveAnAccount`}{" "}
        <span
          className="inline-block text-primary"
          tabIndex={0}
          onClick={() => _.navigate("/auth/login")}
        >
          {t`auth.signIn`}
        </span>
      </p>
    </div>
  );
};
