import cx from "classnames";

import React, { forwardRef } from "react";

import BaseInput from "./BaseInput";

const TimeInput = ({ placeholder, className, ...props }) => (
  <>
    <input className={cx("input--masked", className)} type="time" {...props} />
    <div className="input__mask input__mask--hidden absolute inset-0">
      {props.value ? (
        <p>{props.value}</p>
      ) : (
        <p className="text-caption">{placeholder}</p>
      )}
    </div>
  </>
);

export default forwardRef((props, ref) => (
  <BaseInput ref={ref} component={TimeInput} {...props} />
));
