const findMatchingContacts = (query, contacts) => {
  const queryLow = query.toLowerCase();
  return queryLow.length > 0
    ? contacts.reduce(
        (matchingContacts, contact) =>
          contact.email?.toLowerCase().startsWith(queryLow) ||
          contact.name?.toLowerCase().startsWith(queryLow)
            ? matchingContacts.concat(contact)
            : matchingContacts,
        []
      )
    : [];
};

export { findMatchingContacts };
