import * as _ from "utils";
import cx from "classnames";

import React from "react";
import { useDoc } from "react-firestore";
import useTranslation from "hooks/useTranslation";
import useUser from "hooks/useUser";

import * as Icons from "components/Icons";
import { Avatar } from "components/UI";
import UserName from "components/User/Name";
import MonetaryLiteral from "./MonetaryLiteral";

export default ({ expense, showList, showDate, showSettled }) => {
  const { t, moment } = useTranslation();
  const [user] = useUser({ userIdentifiers: expense.creditorUserIdentifiers });
  const [list] = useDoc("events", expense.listId);

  return (
    <div
      className={cx(
        "clickable flex items-center justify-space-between space-x-2",
        {
          "opacity-75": showSettled && expense.isSettled,
        }
      )}
      onClick={() => _.pushModal(`/ex/${expense.id}`)}
    >
      <div className="flex-grow flex items-center justify-space-between">
        <div>
          <p>{expense.description || <em>{t`expense.noDescription`}</em>}</p>
          <div className="flex space-x-2">
            <Avatar user={user} size={1} />
            <p className="text-xs text-caption">
              <UserName user={user} />
              {showList && list.exists
                ? ` • ${list.title || t`expense.fromList`}`
                : showDate &&
                  ` • ${moment(expense.createdAt.toDate()).format("ll")}`}
              {showSettled && expense.isSettled && ` • ${t`expense.isSettled`}`}
            </p>
          </div>
        </div>
        <MonetaryLiteral cents={expense.cents} />
      </div>
      <Icons.Caret direction="right" className="opacity-75" />
    </div>
  );
};
