import * as _ from "utils";
import { COURSE_FORMATS } from "statics.json";
import { v4 as uuidv4 } from "uuid";

import React from "react";
import useCourseContext from "hooks/useCourseContext";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";

import withCourse from "hocs/withCourse";

import * as Icons from "components/Icons";
import { Button, IconButton } from "components/UI";
import CourseType from "./CourseType";
import CourseOption from "./CourseOption";

export default withCourse(
  ({ isEditing, nth, currentParticipantId, focus, setFocus }) => {
    const { t } = useTranslation();
    const [list] = useListContext();
    const [course, { onChange }] = useCourseContext();

    const isParticipant = list.participants.some(
      (participant) => participant.id === currentParticipantId
    );

    const isInteractive =
      course?.options.length > 1 ||
      [COURSE_FORMATS.POLL, COURSE_FORMATS.ORDER].includes(course?.format);

    const insertOption = (at) => {
      const id = uuidv4();
      onChange((course) => ({
        ...course,
        options: [
          ...course.options.slice(0, at),
          {
            id,
            title: "",
            dietaryInformation: null,
            image: null,
            recipe: null,
            votes: [],
          },
          ...course.options.slice(at),
        ],
      }));

      setFocus(id);
    };

    return course ? (
      <div className="space-y-4 px-4">
        {(isEditing || list.courses.length > 1 || course.type) && (
          <div>
            <div className="course__title mx--4 px-4 space-x-4 justify-space-between">
              {isEditing ? (
                <CourseType
                  nth={nth}
                  value={course.type}
                  onChange={(type) =>
                    onChange((course) => ({ ...course, type: type || null }))
                  }
                />
              ) : (
                <p className="course__title__bg font-bold">
                  {course.type || t(`list.courses.nth.${nth}`)}
                </p>
              )}
              {isEditing && (
                <span className="flex course__title__bg">
                  <IconButton
                    onClick={() =>
                      _.pushModal(`/l/${list.id}/course/${course.id}/settings`)
                    }
                    variant="text"
                  >
                    <Icons.Dots direction="horizontal" />
                  </IconButton>
                </span>
              )}
            </div>
            {(isInteractive || (isEditing && course.everyoneCanAddOptions)) && (
              <p className="text-xs text-caption">
                {t(`list.courses.format.${course.format}`)}
                {isEditing &&
                  course.everyoneCanAddOptions &&
                  ` • ${t`list.courses.participantsCanSuggest`}`}
              </p>
            )}
          </div>
        )}
        {course.options.map((option, i) => (
          <CourseOption
            key={option.id}
            isEditing={isEditing}
            courseOption={option}
            isInteractive={isInteractive}
            hasImage={
              isEditing ||
              course.options.some(
                (option) => option.image || option.recipe?.image
              )
            }
            format={course.format}
            currentParticipantId={currentParticipantId}
            isFocused={focus === option.id}
            onFocus={() => setFocus(option.id)}
            onConfirm={() => insertOption(i + 1)}
          />
        ))}
        {course.options.length === 0 && (
          <p className="text-caption italic text-center">{t`label.noOptionsYet`}</p>
        )}
        {isParticipant && (isEditing || course.everyoneCanAddOptions) && (
          <Button
            variant="text"
            color="primary"
            lead={<Icons.Plus />}
            onClick={() => insertOption(course.options.length)}
          >{t`list.courses.addDish`}</Button>
        )}
      </div>
    ) : null;
  }
);
