import * as _ from "utils";

import React, { useLayoutEffect } from "react";
import { TaskContext } from "contexts";
import { useDoc } from "react-firestore";

import withAuth from "hocs/withAuth";

import NotFoundPage from "pages/404";

const TaskProvider = ({ taskId, children }) => {
  const value = useDoc("tasks", taskId);

  useLayoutEffect(() => {
    _.setAnalyticsContext({ task_id: taskId });
    return () => {
      _.setAnalyticsContext({ task_id: undefined });
    };
  }, [taskId]);

  return value[0].hasExisted ? (
    <TaskContext.Provider value={value}>{children}</TaskContext.Provider>
  ) : (
    <NotFoundPage />
  );
};

export default withAuth(TaskProvider);
