import cx from "classnames";

import React from "react";

import { Tag } from "components/UI";

export default function DietaryPreference({
  emoji,
  name,
  isSelected,
  onClick,
}) {
  return (
    <Tag
      className={cx("text-sm space-x-1", { "cursor-pointer": onClick })}
      style={
        isSelected && onClick
          ? {
              backgroundColor: "var(--primary)",
              color: "var(--primary_contrast)",
            }
          : {}
      }
      onClick={onClick}
    >
      {emoji && (
        <span role="img" aria-label={name} className="text-xs">
          {emoji}
        </span>
      )}
      <span>{name}</span>
    </Tag>
  );
}
