import * as _ from "utils";

import React from "react";
import { useCollection } from "react-firestore";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";

const isSetup = (instance) => instance.isShared || instance.isSetup;
const currentUserParticipated = (list) =>
  (list.isSetup || list.isShared) && _.isParticipant(list.myParticipation);

export default function UserStatistics() {
  const { t } = useTranslation();
  const groups = useCollection("groups", { filter: isSetup });
  const lists = useCollection("events", { filter: currentUserParticipated });
  const timesJoined = lists.length;
  const listsCookedFor = lists.filter((list) => _.isChef(list.myParticipation));
  const timesCooked = listsCookedFor.length;
  const peopleCookedFor = listsCookedFor.reduce(
    (acc, list) => acc + list.participants.length,
    0
  );
  const uniqueContacts = lists.reduce(
    (contacts, list) =>
      list.maybeParticipants.reduce(
        (contacts, maybeParticipant) =>
          contacts.some(_.isSameUser(maybeParticipant))
            ? contacts
            : contacts.concat(maybeParticipant),
        contacts
      ),
    []
  ).length;
  const groupCount = groups.length;

  return (
    <>
      <div className="flex space-x-2 p-2 rounded-md accent-success text-success">
        <Icons.Cutlery size={2} className="mt--4" />
        <p>{t(`profile.statistics.timesJoined`, { timesJoined })}</p>
      </div>
      <div className="flex space-x-2 p-2 rounded-md accent-secondary text-secondary">
        <Icons.Chef size={2} className="mt--4" />
        <p>
          {t(`profile.statistics.timesCooked`, {
            timesCooked,
            peopleCookedFor,
          })}
        </p>
      </div>
      <div className="flex space-x-2 p-2 rounded-md accent-warning text-warning">
        <Icons.Share size={2} className="mt--4" />
        <p>{t(`profile.statistics.uniqueContacts`, { uniqueContacts })}</p>
      </div>
      <div className="flex space-x-2 p-2 rounded-md accent-primary text-primary">
        <Icons.Group size={2} className="mt--4" />
        <p>{t(`profile.statistics.groupCount`, { groupCount })}</p>
      </div>
    </>
  );
}
