import "./polyfills";
import "config/firebase";
import "config/moment";
import "config/sentry";
import "config/i18n";
import "config/firestore";

import React from "react";
import ReactDOM from "react-dom";

import App from "containers/App";

window.excludeGtagPaths = [/\/e\//];

const root = document.getElementById("root");
const Root = <App />;

ReactDOM.render(Root, root);
