import React from "react";
import useTranslation from "hooks/useTranslation";

import { Helmet } from "react-helmet-async";

export default function SEO({ title, description, image }) {
  const { t } = useTranslation();

  return (
    <Helmet>
      {title && typeof title === "string" && (
        <title>{`${title} — ${t`description.IJoinDinner`}`}</title>
      )}
      {description && <meta name="description" content={description} />}

      {title && typeof title === "string" && (
        <meta
          name="og:title"
          content={`${title} — ${t`description.IJoinDinner`}`}
        />
      )}
      {description && <meta name="og:description" content={description} />}
      {image && <meta name="og:image" content={image} />}
    </Helmet>
  );
}
