import React, { useCallback, useState } from "react";
import useIdle from "hooks/useIdle";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";

import { BaseInput } from "components/UI";
import CollapsibleContainer from "components/CollapsibleContainer";

export default function Occasion({ isEditing }) {
  const { t } = useTranslation();
  const [list, listResource] = useListContext();
  const [title, setTitle] = useState(list.title);
  const flush = useCallback(
    () =>
      title !== listResource.rawData.title && listResource.update({ title }),
    [title, listResource]
  );
  const touch = useIdle(flush, 500);
  return (
    <CollapsibleContainer isVisible={Boolean(isEditing || list.title)}>
      {isEditing ? (
        <BaseInput
          className="font-black text-2xl"
          placeholder={t`list.occasion`}
          value={title || ""}
          onChange={(event) => {
            setTitle(event.target.value);
            touch();
          }}
        />
      ) : list.title ? (
        <h1 className="text-2xl">{list.title}</h1>
      ) : (
        <h1 className="text-2xl text-caption">{t`list.occasion`}</h1>
      )}
    </CollapsibleContainer>
  );
}
