import * as _ from "utils";
import { COURSE_FORMATS } from "statics.json";
import { useCallback, useMemo } from "react";

import useListContext from "./useListContext";

export default function useCourse(courseId) {
  const [list, listResource] = useListContext();

  const course = useMemo(() => list.courses.find(({ id }) => id === courseId), [
    list.courses,
    courseId,
  ]);

  const onChange = useCallback((v) => listResource.changeCourse(courseId, v), [
    listResource,
    courseId,
  ]);

  const onDelete = useCallback(() => listResource.deleteCourse(courseId), [
    listResource,
    courseId,
  ]);

  const onAddOption = useCallback(
    (option) =>
      onChange((course) => ({
        ...course,
        options: [...(course.options || []), option],
      })),
    [onChange]
  );

  const onChangeOptions = useCallback(
    (v) =>
      onChange((course) => ({
        ...course,
        options: typeof v === "function" ? v(course.options) : v,
      })),
    [onChange]
  );

  const onChangeOption = useCallback(
    (courseOptionId, v) =>
      onChangeOptions((options) =>
        options.map((option) =>
          option.id === courseOptionId
            ? typeof v === "function"
              ? v(option)
              : v
            : option
        )
      ),
    [onChangeOptions]
  );

  const onDeleteOption = useCallback(
    (courseOptionId) =>
      onChangeOptions((options) =>
        options.filter(({ id }) => id !== courseOptionId)
      ),
    [onChangeOptions]
  );

  const onChangeOptionImage = useCallback(
    async (courseOptionId, image) => {
      onChangeOption(courseOptionId, (option) => ({ ...option, image }));
      const imageURL = await _.uploadFileAsDataUrl(
        _.storage.ref(`courses/${courseOptionId}`),
        image
      );
      onChangeOption(courseOptionId, (option) => ({
        ...option,
        image: imageURL,
      }));
      _.track("course_image_update");
    },
    [onChangeOption]
  );

  const onDeleteOptionImage = useCallback(
    (courseOptionId) =>
      onChangeOption(courseOptionId, (option) => ({ ...option, image: null })),
    [onChangeOption]
  );

  const onSelectOption = useCallback(
    (courseOptionId, participantId) =>
      onChange((course) => ({
        ...course,
        options:
          course.format === COURSE_FORMATS.MULTIPLE_OPTIONS
            ? (course.options || []).map((option) => ({
                ...option,
                votes:
                  option.id === courseOptionId
                    ? option.votes.some((vote) => vote === participantId)
                      ? option.votes
                      : option.votes.concat(participantId)
                    : option.votes.filter((vote) => vote !== participantId),
              }))
            : (course.options || []).map((option) =>
                option.id === courseOptionId
                  ? {
                      ...option,
                      votes: option.votes.some((vote) => vote === participantId)
                        ? option.votes.filter((vote) => vote !== participantId)
                        : option.votes.concat(participantId),
                    }
                  : option
              ),
      })),
    [onChange]
  );

  const onDecreaseOptionAmount = useCallback(
    (courseOptionId, participantId) =>
      onChangeOption(courseOptionId, (option) => ({
        ...option,
        votes: _.pipe(
          (votes) => votes.findIndex((vote) => vote === participantId),
          (firstVoteIdx) => option.votes.filter((v, i) => i !== firstVoteIdx)
        )(option.votes),
      })),
    [onChangeOption]
  );

  const onIncreaseOptionAmount = useCallback(
    (courseOptionId, participantId) =>
      onChangeOption(courseOptionId, (option) => ({
        ...option,
        votes: option.votes.concat(participantId),
      })),
    [onChangeOption]
  );

  return [
    course,
    {
      onChange,
      onDelete,
      onAddOption,
      onChangeOption,
      onDeleteOption,
      onSelectOption,
      onChangeOptionImage,
      onDeleteOptionImage,
      onDecreaseOptionAmount,
      onIncreaseOptionAmount,
    },
  ];
}
