import React from "react";

import TaskProvider from "containers/TaskProvider";

const withTask = (Component) => (props) => (
  <TaskProvider taskId={props.taskId}>
    <Component {...props} />
  </TaskProvider>
);

export default withTask;
