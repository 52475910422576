import * as _ from "utils";

import React, { useLayoutEffect } from "react";
import { useDoc, useQueries } from "react-firestore";
import { ListContext } from "contexts";

import withAuth from "hocs/withAuth";

import NotFoundPage from "pages/404";

export default withAuth(function ListProvider({ listId, children }) {
  const value = useDoc("events", listId);

  useQueries([
    _.expensesCollection.where("listId", "==", listId),
    _.settlementsCollection.where("listId", "==", listId),
  ]);

  useLayoutEffect(() => {
    _.setAnalyticsContext({ event_id: listId });
    return () => {
      _.setAnalyticsContext({ event_id: undefined });
    };
  }, [listId]);

  return value[0].hasExisted ? (
    <ListContext.Provider value={value}>{children}</ListContext.Provider>
  ) : (
    <NotFoundPage />
  );
});
