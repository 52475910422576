import he from "he";
import { cleanUrl } from "./strings";

const matches = {
  "beaufood.nl": {
    title: "Beaufood",
    normalizerRegex: null,
  },
  "ah.nl": {
    title: "Allerhande",
    normalizerRegex: / - Recept - Allerhande - Albert Heijn/g,
  },
  "watikgegetenheb.blogspot.nl": {
    title: "Wat ik gegeten heb",
    normalizerRegex: null,
  },
  "watikgegetenheb.blogspot.com": {
    title: "Wat ik gegeten heb",
    normalizerRegex: null,
  },
  "dehippevegetarier.nl": {
    title: "de hippe vegetariër",
    normalizerRegex: null,
  },
  "degroenemeisjes.nl": {
    title: "de Groene Meisjes",
    normalizerRegex: null,
  },
  "smulweb.nl": {
    title: "Smulweb",
    normalizerRegex: /. recept \| Smulweb.nl/g,
  },
  youtube: {
    title: "YouTube",
    normalizerRegex: / - YouTube/g,
  },
  "thespiceadventuress.com": {
    title: "The Spice Adventures",
    normalizerRegex: / &#8211; The Spice Adventuress/g,
  },
};

const getRecipeUrlTitle = (url) => {
  if (!url) {
    return url;
  }
  const key = Object.keys(matches).find((key) => url.includes(key));
  return key ? matches[key].title : cleanUrl(url);
};

const normalizeRecipeTitle = (url, title) => {
  if (!url || !title) {
    return title;
  } else {
    const key = Object.keys(matches).find((key) => url.includes(key));
    const regex = matches[key] ? matches[key].normalizerRegex : null;
    return regex ? title.replace(regex, "") : title;
  }
};

const formatCourseOption = (option) =>
  option
    ? he.decode(
        option.recipe
          ? option.recipe.title
            ? normalizeRecipeTitle(option.recipe.url, option.recipe.title)
            : cleanUrl(option.recipe.url)
          : option.title
      )
    : null;

export { getRecipeUrlTitle, normalizeRecipeTitle, formatCourseOption };
