import * as _ from "utils";
import cx from "classnames";

import React, { useCallback, useEffect, useRef } from "react";
import reactFirebase, { useDoc } from "react-firestore";
import useTranslation from "hooks/useTranslation";
import useUsers from "hooks/useUsers";

import { Avatar, BaseInput, DropDown, NumberInput } from "components/UI";
import UserName from "components/User/Name";
import DebtorsInput from "./DebtorsInput";

const withDebtors = (value) => {
  const list = reactFirebase.getDoc("events", value.listId);
  const group = reactFirebase.getDoc("groups", value.groupId);
  const debtors = value.divideAutomatically
    ? undefined
    : (group.exists
        ? group.members
        : list.exists
        ? list.maybeParticipants.filter(
            ({ userIdentifiers }) => userIdentifiers.length > 0
          )
        : []
      ).map((debtor) => ({
        ...debtor,
        parts: list.exists
          ? list.participants
              .map(
                (participant) =>
                  list.participants.find(
                    ({ id }) => id === participant.parentId
                  ) || participant
              )
              .filter(_.isSameUser(debtor)).length
          : 0,
      }));
  return {
    ...value,
    debtors,
  };
};

export default function ExpenseInput({ value, onChange, disabled }) {
  const update = useCallback(
    (obj) => onChange((value) => ({ ...value, ...obj })),
    [onChange]
  );
  const descriptionInputRef = useRef(null);

  const { t } = useTranslation();
  const [list] = useDoc("events", value.listId);
  const [listGroup] = useDoc("groups", list.groupId);
  const [group] = useDoc("groups", value.groupId);

  const possibleCreditors = group.exists
    ? group.members
    : list.exists
    ? list.maybeParticipants.filter(
        ({ userIdentifiers }) => userIdentifiers.length > 0
      )
    : [];
  const currentUserIsPossibleCreditor = possibleCreditors.some(_.isCurrentUser);
  const possibleCreditorUsers = useUsers(possibleCreditors);

  useEffect(() => {
    if (
      !possibleCreditorUsers.some(
        _.isSameUser({ userIdentifiers: value.creditorUserIdentifiers })
      )
    ) {
      onChange((value) => ({
        ...value,
        creditorUserIdentifiers: possibleCreditorUsers[0].userIdentifiers,
      }));
    }
  }, [onChange, possibleCreditorUsers, value.creditorUserIdentifiers]);

  return (
    <div className="space-y-4">
      {!group.exists &&
        list.exists &&
        list.maybeParticipants.some(
          ({ userIdentifiers }) => !userIdentifiers?.length > 0
        ) && (
          <div className="p-4 rounded-md bg-glow text-text">
            <p>{t`expense.anonymousUsersCannotParticipate`}</p>
          </div>
        )}
      <div className="space-y-2">
        <NumberInput
          autoFocus={!value.cents}
          className="font-bold text-2xl"
          lead="€"
          placeholder="0,00"
          value={
            value.cents ? (value.cents / 100).toFixed(2).replace(".", ",") : ""
          }
          disabled={disabled}
          onChange={(event) =>
            update({
              cents: parseInt(event.target.value?.replace(",", ""), 10) || null,
            })
          }
        />
        <div className="flex items-center space-x-2">
          <p className="whitespace-no-wrap">{t`expense.by`}</p>
          <DropDown
            disabled={disabled}
            required={currentUserIsPossibleCreditor}
            placeholder={t`expense.selectCreditor`}
            options={possibleCreditorUsers.map((user) => ({
              value: user.userIdentifiers,
              label: (
                <div className="flex items-center space-x-2 min-w-0">
                  <Avatar user={user} size={1} />
                  <p className="truncate">
                    <UserName user={user} />
                  </p>
                </div>
              ),
            }))}
            value={
              possibleCreditorUsers.find(
                _.isSameUser({ userIdentifiers: value.creditorUserIdentifiers })
              )?.userIdentifiers
            }
            onChange={(creditorUserIdentifiers) =>
              update({ creditorUserIdentifiers })
            }
          />
        </div>
      </div>
      <BaseInput
        ref={descriptionInputRef}
        variant="contained"
        placeholder={t`expense.description`}
        required
        disabled={disabled}
        value={value.description || ""}
        onChange={(event) => update({ description: event.target.value })}
      />
      {list.exists ? (
        <>
          {listGroup.exists && (
            <div className="space-y-1">
              <h3>{t`expense.settleMethod.title`}</h3>
              <div className="segmented-control">
                {(!disabled || group.exists) && (
                  <label
                    className={cx("segmented-control__segment", {
                      "segmented-control__segment--selected": group.exists,
                    })}
                  >
                    <input
                      type="radio"
                      className="input--hidden"
                      disabled={disabled}
                      checked={group.exists}
                      onChange={(event) =>
                        onChange((value) =>
                          withDebtors({
                            ...value,
                            groupId: event.target.checked ? list.groupId : null,
                          })
                        )
                      }
                    />
                    <p>{t`expense.settleMethod.delay`}</p>
                    <p className="text-xs opacity-75">{t`expense.settleMethod.delayDescription`}</p>
                  </label>
                )}
                {(!disabled || !group.exists) && (
                  <label
                    className={cx("segmented-control__segment", {
                      "segmented-control__segment--selected": !group.exists,
                    })}
                  >
                    <input
                      type="radio"
                      className="input--hidden"
                      checked={!group.exists}
                      disabled={disabled}
                      onChange={(event) =>
                        onChange((value) =>
                          withDebtors({
                            ...value,
                            groupId: event.target.checked ? null : list.groupId,
                          })
                        )
                      }
                    />
                    <p>{t`expense.settleMethod.immediate`}</p>
                    <p className="text-xs opacity-75">{t`expense.settleMethod.immediateDescription`}</p>
                  </label>
                )}
              </div>
            </div>
          )}
          <div className="space-y-1">
            <h3>{t`expense.division.title`}</h3>
            <div className="segmented-control">
              {(!disabled || value.divideAutomatically) && (
                <label
                  className={cx("segmented-control__segment", {
                    "segmented-control__segment--selected":
                      value.divideAutomatically,
                  })}
                >
                  <input
                    type="radio"
                    className="input--hidden"
                    disabled={disabled}
                    checked={value.divideAutomatically}
                    onChange={(event) =>
                      onChange((value) =>
                        withDebtors({
                          ...value,
                          divideAutomatically: event.target.checked,
                        })
                      )
                    }
                  />
                  <p>{t`expense.division.automatic`}</p>
                  <p className="text-xs opacity-75">{t`expense.division.automaticDescription`}</p>
                </label>
              )}
              {(!disabled || !value.divideAutomatically) && (
                <label
                  className={cx("segmented-control__segment", {
                    "segmented-control__segment--selected": !value.divideAutomatically,
                  })}
                >
                  <input
                    type="radio"
                    className="input--hidden"
                    disabled={disabled}
                    checked={!value.divideAutomatically}
                    onChange={(event) =>
                      onChange((value) =>
                        withDebtors({
                          ...value,
                          divideAutomatically: !event.target.checked,
                        })
                      )
                    }
                  />
                  <p>{t`expense.division.manual`}</p>
                  <p className="text-xs opacity-75">{t`expense.division.manualDescription`}</p>
                </label>
              )}
            </div>
          </div>
        </>
      ) : (
        <h3>{t`expense.division.title`}</h3>
      )}
      <DebtorsInput
        value={value}
        onChange={onChange}
        disabled={disabled || value.divideAutomatically}
      />
    </div>
  );
}
