import * as _ from "utils";

import React from "react";
import useCurrentUser from "hooks/useCurrentUser";
import useGroupContext from "hooks/useGroupContext";
import useTranslation from "hooks/useTranslation";

import withGroup from "hocs/withGroup";

import * as Icons from "components/Icons";
import { Field, Message, Toggle } from "components/UI";
import SimpleLayout from "layouts/Simple";
import EnableNotifications from "components/EnableNotifications";

const GroupNotifications = ({ groupId }) => {
  const { t } = useTranslation();
  const [{ myMembership }, groupResource] = useGroupContext();
  const [currentUser] = useCurrentUser();

  return (
    <SimpleLayout
      parentLocation={`/g/${groupId}`}
      title={t`group_notifications.title`}
    >
      <div className="space-y-4">
        <EnableNotifications />
        <Field
          type="checkbox"
          label={t`group_notifications.dayWithoutChef`}
          detail={t`group_notifications.dayWithoutChef_detail`}
          labelLocation="inline"
          disabled={!currentUser.vapidToken}
          checked={myMembership?.notifications?.dayWithoutChef || false}
          onChange={(event) =>
            groupResource.changeMember(myMembership.id, (m) => ({
              ...m,
              notifications: {
                ...m.notifications,
                dayWithoutChef: event.target.checked,
              },
            }))
          }
          component={Toggle}
        />
        <Field
          type="checkbox"
          label={t`group_notifications.dayWithChef`}
          detail={t`group_notifications.dayWithChef_detail`}
          labelLocation="inline"
          disabled={!currentUser.vapidToken}
          checked={myMembership?.notifications?.dayWithChef || false}
          onChange={(event) =>
            groupResource.changeMember(myMembership.id, (m) => ({
              ...m,
              notifications: {
                ...m.notifications,
                dayWithChef: event.target.checked,
              },
            }))
          }
          component={Toggle}
        />
        <Message color="primary" className="p-4">
          {t`group_notifications.common`}
          <span onClick={() => _.pushModal("/settings")}>
            <Icons.Cog />
          </span>
        </Message>
      </div>
    </SimpleLayout>
  );
};

export default withGroup(GroupNotifications);
