import "./Detail.scss";
import { COMMON_PREFERENCES } from "statics.json";

import React, { Children, forwardRef, useMemo, useRef } from "react";
import useTranslation from "hooks/useTranslation";
import useUser from "hooks/useUser";

import * as Icons from "components/Icons";
import { IconButton, Avatar, Button, FlyOut, Tag } from "components/UI";
import UserName from "components/User/Name";

const ActionButton = forwardRef(
  ({ icon: Icon, label, color = "caption", actions }, ref) =>
    label ? (
      <Button ref={ref} lead={<Icon />} size="xs" color={color}>
        {label}
      </Button>
    ) : Icon ? (
      <IconButton ref={ref} size="xs" color={color}>
        <Icon />
      </IconButton>
    ) : actions.length > 0 ? (
      <IconButton ref={ref} variant="text" color={color}>
        <Icons.Dots />
      </IconButton>
    ) : null
);

const Badge = ({ icon: Icon, label, color }) => (
  <Tag className="flex items-center space-x-1" color={color}>
    {Icon && <Icon size={0.75} />}
    <span>{label}</span>
  </Tag>
);

export default function UserDetail({
  userContainer,
  detailed,
  showDietaryPreferences,
  badge,
  badges,
  children,
  actions = [],
  actionButton,
}) {
  const actionButtonRef = useRef(null);
  const { t } = useTranslation();
  const [user] = useUser(userContainer);

  const roleCount = userContainer?.roles?.length;

  const allBadges = useMemo(() => {
    let allBadges = [];
    if (badge) {
      allBadges.push(badge);
    }
    if (badges) {
      allBadges = allBadges.concat(badges);
    }
    if (!user.userIdentifiers?.length > 0) {
      allBadges.push({
        label: t`label.anonymous`,
      });
    }
    if (!(roleCount > 0) && userContainer?.hasSeen === false) {
      allBadges.push({
        label: t`label.hasnt_seen`,
      });
    }
    if (showDietaryPreferences && user.preferences) {
      user.preferences.forEach((preference) =>
        allBadges.push({
          icon: () => (
            <span
              role="img"
              className="text-text"
              aria-label={t(`preference.${preference}`)}
            >
              {COMMON_PREFERENCES.find((p) => p.name === preference)?.emoji}
            </span>
          ),
          label: t(`preference.${preference}`),
        })
      );
    }
    return allBadges;
  }, [
    badge,
    badges,
    user.userIdentifiers.length,
    user.preferences,
    roleCount,
    userContainer.hasSeen,
    showDietaryPreferences,
    t,
  ]);

  return (
    <div className="relative z-0 flex-grow w-full">
      <div
        className="flex items-center space-x-4"
        onClick={() => {
          if (actionButtonRef.current) {
            actionButtonRef.current.click();
          }
        }}
      >
        <Avatar user={user} size={2.5} />
        <div className="flex-grow w-0">
          <p className="truncate">
            <UserName user={user} indicateCurrentUser />
          </p>
          {detailed && user.name && user.email && (
            <p className="text-xs text-caption truncate">{user.email}</p>
          )}
          {allBadges.length > 0 && (
            <div className="flex flex-wrap wrapping-box--2 collapse text-xs">
              {allBadges.map((badge) => (
                <Badge key={badge.label} {...badge} />
              ))}
            </div>
          )}
        </div>
        <ActionButton
          ref={actionButtonRef}
          actions={actions}
          {...actionButton}
        />
        {actions.length > 0 && (
          <FlyOut originRef={actionButtonRef}>
            {actions.map(({ icon, label, onAct, disabled, color }, i) => (
              <FlyOut.Item
                key={i}
                onClick={() => onAct()}
                disabled={disabled}
                color={color}
              >
                {icon} {label}
              </FlyOut.Item>
            ))}
          </FlyOut>
        )}
      </div>
      {Children.map(children, (child, i) => (
        <div className="user__child" key={i}>
          {child}
        </div>
      ))}
    </div>
  );
}
