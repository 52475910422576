import * as _ from "utils";

import React, { useState } from "react";
import useAuth from "hooks/useAuth";
import useTranslation from "hooks/useTranslation";
import { useFormState } from "react-use-form-state";

import * as Icons from "components/Icons";
import { Button, Field, Message } from "components/UI";

const getErrorField = (errorCode) => {
  switch (errorCode) {
    case "auth/email-already-in-use":
      return "email";
    case "auth/weak-password":
      return "password";
    default:
      return null;
  }
};

const CreateAccountForm = () => {
  const { t } = useTranslation();
  const [, { signUpWithEmail }] = useAuth();
  const [formState, { text, email, password }] = useFormState();
  const [error, setError] = useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();
    await signUpWithEmail(formState.values);
  };

  const safe = (f) => async (...args) => {
    try {
      await f(...args);
    } catch (e) {
      setError(e);
    }
  };

  const errorMessage = error && t(`errors.${error.code}`);
  const errorField = error && getErrorField(error.code);

  return (
    <div className="space-y-4 py-4">
      {error && errorField === null && (
        <Message color="error" className="p-4">
          {t(`errors.${error.code}`)}
        </Message>
      )}
      <div className="flex">
        <Button
          onClick={() => _.navigate(-1)}
          variant="text"
          color="primary"
          size="xs"
          importance="tertiary"
          lead={<Icons.Caret direction="left" />}
        >
          {t`label.back`}
        </Button>
      </div>
      <form className="space-y-4" onSubmit={safe(onSubmit)}>
        <h1>{t`auth.signUpWithEmail`}</h1>
        <Field
          size="sm"
          variant="contained"
          label={t`auth.yourName`}
          {...text("name")}
          required
          error={errorField === "name" && errorMessage}
        />
        <Field
          size="sm"
          variant="contained"
          label={t`auth.emailAddress`}
          {...email("email")}
          required
          error={errorField === "email" && errorMessage}
        />
        <Field
          size="sm"
          variant="contained"
          label={t`auth.password`}
          {...password("password")}
          required
          error={errorField === "password" && errorMessage}
        />
        <Button
          className="w-full"
          type="submit"
          color="primary"
          disabled={
            !formState.values.name ||
            !formState.values.email ||
            !formState.values.password
          }
        >
          {t`auth.createAccount`}
        </Button>
      </form>
    </div>
  );
};

export default CreateAccountForm;
