import React from "react";
import { Feature } from "./components";

export default (
  <div key={2}>
    <h2 className="text-caption font-black uppercase text-lg">Update 2</h2>
    <ul className="space-y-2">
      <Feature>
        Want to invite someone who doesn't use ikeetmee yet? You can now easily
        invite them to both lists and groups by their email address.
      </Feature>
    </ul>
  </div>
);
