import React from "react";
import { Bugfix, Financial } from "./components";

export default (
  <div key={5}>
    <h2 className="text-caption font-black uppercase text-lg">
      Update 5 - Expenses
    </h2>
    <ul className="space-y-2">
      <Financial>
        You can now manage expenses for dinners and in groups directly from the
        app. Try splitting grocery costs on your next dinner.
      </Financial>
      <Bugfix>Many small UX improvements and bugfixes.</Bugfix>
    </ul>
  </div>
);
