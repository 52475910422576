import React from "react";
import useCurrentUser from "hooks/useCurrentUser";
import useTranslation from "hooks/useTranslation";

import withAuth from "hocs/withAuth";

import SimpleLayout from "layouts/Simple";

import { Field, Toggle } from "components/UI";
import EnableNotifications from "components/EnableNotifications";

export default withAuth(() => {
  const { t } = useTranslation();
  const [currentUser, currentUserResource] = useCurrentUser();

  return (
    <SimpleLayout
      title={t`settings.notifications.title`}
      prevLabel={t`settings.title`}
    >
      <div className="space-y-4">
        <EnableNotifications />
        <Field
          type="checkbox"
          label={t`settings.notifications.whenAddedToList`}
          detail={t`settings.notifications.whenAddedToList_detail`}
          labelLocation="inline-reverse"
          className="items-center justify-space-between"
          disabled={!currentUser.vapidToken}
          checked={
            currentUser?.settings?.notifications?.whenAddedToList || false
          }
          onChange={(event) =>
            currentUserResource.update({
              "settings.notifications.whenAddedToList": event.target.checked,
            })
          }
          component={Toggle}
        />
        <Field
          type="checkbox"
          label={t`settings.notifications.whenSomeoneCooks`}
          detail={t`settings.notifications.whenSomeoneCooks_detail`}
          labelLocation="inline-reverse"
          className="items-center justify-space-between"
          disabled={!currentUser.vapidToken}
          checked={
            currentUser?.settings?.notifications?.whenSomeoneCooks || false
          }
          onChange={(event) =>
            currentUserResource.update({
              "settings.notifications.whenSomeoneCooks": event.target.checked,
            })
          }
          component={Toggle}
        />
        <Field
          type="checkbox"
          label={t`settings.notifications.whenRequiredChoice`}
          detail={t`settings.notifications.whenRequiredChoice_detail`}
          labelLocation="inline-reverse"
          className="items-center justify-space-between"
          disabled={!currentUser.vapidToken}
          checked={
            currentUser?.settings?.notifications?.whenRequiredChoice || false
          }
          onChange={(event) =>
            currentUserResource.update({
              "settings.notifications.whenRequiredChoice": event.target.checked,
            })
          }
          component={Toggle}
        />
        <p className="p-4 rounded-md bg-glow text-text">{t`settings.notifications.perGroup`}</p>
      </div>
    </SimpleLayout>
  );
});
