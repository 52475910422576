import * as _ from "utils";
import firebase from "firebase/app";

import React from "react";
import { useQueries } from "react-firestore";
import useAuth from "hooks/useAuth";

const RunningQueries = ({ userIdentifiers }) => {
  useQueries([
    _.listsCollection.where(
      "participantUserIdentifiers",
      "array-contains-any",
      userIdentifiers
    ),
    _.groupsCollection.where(
      "memberUserIdentifiers",
      "array-contains-any",
      userIdentifiers
    ),
    _.transactionsCollection.where(
      "from.userIdentifiers",
      "array-contains-any",
      userIdentifiers
    ),
    _.transactionsCollection.where(
      "to.userIdentifiers",
      "array-contains-any",
      userIdentifiers
    ),
    _.usersCollection.where(
      firebase.firestore.FieldPath.documentId(),
      "==",
      _.auth.currentUser.uid
    ),
  ]);

  return null;
};

export default function FirestoreQueryManager() {
  const [{ currentUser, isAuthenticated }] = useAuth();

  return isAuthenticated ? (
    <RunningQueries userIdentifiers={currentUser.userIdentifiers} />
  ) : null;
}
