import "./Notifications.scss";

import React from "react";
import useNotifications from "hooks/useNotifications";

import Portal from "containers/Portal";

import Notification from "components/Notification";
import NetworkStatus from "./NetworkStatus";

export default function Notifications() {
  const [{ messages }, { messagingResource }] = useNotifications();

  return (
    <Portal>
      <div
        className="fixed bottom-safe left-safe right-safe m-2 space-y-2"
        style={{ zIndex: 100 }}
      >
        {messages.map((message) => (
          <Notification
            key={message.id}
            payload={message.payload}
            onClose={() => messagingResource.closeMessage(message.id)}
          />
        ))}
        <NetworkStatus />
      </div>
    </Portal>
  );
}
