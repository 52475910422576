import * as _ from "utils";

import { useEffect } from "react";
import useAuth from "hooks/useAuth";
import useLocallyStoredState from "hooks/useLocallyStoredState";

import updates from "updates";

const UpdatesManager = () => {
  const [{ isAuthenticated }] = useAuth();
  const [updatesSeen, setUpdatesSeen] = useLocallyStoredState(
    "updates-seen",
    updates.length
  );

  useEffect(() => {
    if (isAuthenticated && updatesSeen < updates.length) {
      setUpdatesSeen(updates.length);
      _.pushModal("/latest-update");
    }
  }, [isAuthenticated, updatesSeen, setUpdatesSeen]);

  return null;
};

export default UpdatesManager;
