import * as _ from "utils";

import React from "react";
import useGroupContext from "hooks/useGroupContext";
import useMemberUtils from "hooks/useMemberUtils";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Button } from "components/UI";
import UserDetail from "components/User/Detail";

export default function GroupMembers() {
  const { t } = useTranslation();
  const [group] = useGroupContext();
  const memberUtils = useMemberUtils();

  return (
    <>
      <h2 id="members">
        {t(`group.members`, {
          count: group.members.length,
        })}
      </h2>
      <div className="space-y-4">
        {group.members.map((member) => (
          <UserDetail
            key={member.userIdentifiers[0]}
            userContainer={member}
            detailed
            badge={memberUtils.getRoleBadge(member)}
            actions={memberUtils.getActions(member)}
          />
        ))}
      </div>
      {_.isAdmin(group.myMembership) && (
        <div className="flex justify-flex-end">
          <Button
            color="card"
            size="sm"
            lead={<Icons.Plus />}
            onClick={() => _.pushModal(`/g/${group.id}/members`)}
          >{t`group.addMembers`}</Button>
        </div>
      )}
    </>
  );
}
