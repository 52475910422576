import React from "react";
import useNotifications from "hooks/useNotifications";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Button, Message, WrappingBox } from "components/UI";
import { PopupModalLayout } from "layouts/Modal";

export default function RequestNotificationPermission() {
  const { t } = useTranslation();
  const [, { requestPermission }] = useNotifications();

  const [, { onClose }] = usePageContext();

  return (
    <PopupModalLayout>
      <div className="p-4 space-y-4 rounded-md bg-card">
        <div>
          <h2>{t`notifications.prompt.title`}</h2>
          <p className="text-caption">{t`notifications.prompt.body`}</p>
        </div>
        <Message color="primary" className="p-4">
          {t`notifications.prompt.disclaimer`}
          <Icons.Cog />
        </Message>
        <WrappingBox spacing={4} className="justify-flex-end">
          <Button
            color="primary"
            size="md"
            onClick={() => {
              requestPermission();
              onClose();
            }}
          >{t`notifications.prompt.cta`}</Button>
          <Button
            color="card"
            size="md"
            onClick={onClose}
          >{t`notifications.prompt.maybeLater`}</Button>
        </WrappingBox>
      </div>
    </PopupModalLayout>
  );
}
