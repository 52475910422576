import * as _ from "utils";
import { LIST_TYPES } from "statics.json";

import React from "react";
import useTranslation from "hooks/useTranslation";
import useUsers from "hooks/useUsers";

import Concatenation from "components/Concatenation";
import UserName from "components/User/Name";

export default function ChefsText({ list }) {
  const { t } = useTranslation();
  const chefUsers = useUsers(list.chefs);

  return (
    <>
      <Concatenation
        items={chefUsers
          .sort(_.currentUserLast)
          .map((user) =>
            _.isCurrentUser(user) ? (
              _.capitalize(t`label.I`)
            ) : (
              <UserName key={user.id} user={user} />
            )
          )}
        connective={t`common.and`}
      />{" "}
      {t(
        `list.${list.type === LIST_TYPES.COOK ? "cook" : "order"}${
          _.isChef(list.myParticipation) ? "" : "s"
        }`,
        {
          context: _.toContext([
            ["past", list.hasOccurred],
            ["zero", chefUsers.length === 0],
          ]),
          count: chefUsers.length,
        }
      )}
    </>
  );
}
