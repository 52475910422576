import { COMMON_PREFERENCES } from "statics.json";
import cx from "classnames";

import React, { useRef } from "react";
import useTranslation from "hooks/useTranslation";

import { ToolTip } from "components/UI";

export default ({ preference, className, ...props }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  return (
    <>
      <span
        ref={ref}
        className={cx("text-xs", className)}
        role="img"
        aria-label={t(`preference.${preference}`)}
        {...props}
      >
        {COMMON_PREFERENCES.find(({ name }) => name === preference).emoji}{" "}
      </span>
      <ToolTip originRef={ref}>{t(`preference.${preference}`)}</ToolTip>
    </>
  );
};
