const cache = {};

class InviteResource {
  pending = true;
  data = { exists: false };
  error = null;
  promise = null;

  constructor(params) {
    this.promise = new Promise(async (resolve) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/createInvite`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          body: JSON.stringify(params),
        }
      );
      this.data = await response.json();
      this.pending = false;
      resolve(this.data);
    });
  }

  read() {
    if (this.pending) {
      throw this.promise;
    } else if (this.error) {
      throw this.error;
    } else {
      return this.data;
    }
  }
}

export default function useInvite({ type, listId, groupId, parentId }) {
  const key = `${type === "list" ? listId : type === "group" ? groupId : null}${
    parentId ? `-${parentId}` : ""
  }`;

  if (!key) {
    return null;
  }

  if (!cache[key]) {
    cache[key] = new InviteResource({ type, listId, groupId, parentId });
  }

  return cache[key].read();
}
