import * as _ from "utils";

import React, { useCallback, useState } from "react";
import useIdle from "hooks/useIdle";
import useListContext from "hooks/useListContext";
import useRegularMoment from "hooks/useRegularMoment";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { DateInput, TimeInput } from "components/UI";
import CollapsibleContainer from "components/CollapsibleContainer";
import FormattedRegularMoment from "components/FormattedRegularMoment";

export default function OccursAt({ isEditing }) {
  const { t } = useTranslation();
  const [list, listResource] = useListContext();
  const [regularMoment] = useRegularMoment(list.regularMomentId);

  const [date, setDate] = useState(list.occursAt?.date);
  const [time, setTime] = useState(list.occursAt?.time);
  const flush = useCallback(
    () =>
      (date !== listResource.rawData.occursAt?.date ||
        time !== listResource.rawData.occursAt?.time) &&
      listResource.update({ occursAt: date || time ? { date, time } : null }),
    [date, time, listResource]
  );
  const touch = useIdle(flush, 500);

  return (
    <CollapsibleContainer
      icon={Icons.Clock}
      className="max-w-xs"
      isVisible={Boolean(isEditing || list?.occursAt?.date)}
    >
      {isEditing ? (
        <div className="flex items-center space-x-2">
          <DateInput
            autoFocus={!date}
            className="w-0"
            format="ll"
            placeholder={t`list.date`}
            required
            value={date}
            onChange={(date) => {
              setDate(date);
              touch();
            }}
            disabled={regularMoment.exists}
          />
          <div className="flex-grow-0">
            <TimeInput
              placeholder={t`list.time`}
              value={time || ""}
              onChange={(event) => {
                setTime(event.target.value || null);
                touch();
              }}
            />
          </div>
          <div className="w-4 h-4" />
        </div>
      ) : (
        <p>{_.capitalize(_.formatDate(list.occursAt))}</p>
      )}
      {regularMoment.exists && (
        <p className="flex items-center space-x-1 text-xs text-caption">
          <Icons.Repeat size={0.75} />
          <span className="truncate">
            <FormattedRegularMoment item={regularMoment} short />
          </span>
        </p>
      )}
    </CollapsibleContainer>
  );
}
