import BaseResource from "./BaseResource";

import { Plugins } from "@capacitor/core";

const { Device } = Plugins;

class DeviceResource extends BaseResource {
  constructor() {
    super();
    this.fetch();
  }
  async fetch() {
    this.onNext(await Device.getInfo());
  }
}

export default DeviceResource;
