import "./Modal.scss";

import React, { Suspense } from "react";
import useCSSTransition from "hooks/useCSSTransition";
import usePageContext from "hooks/usePageContext";

const ModalLayout = (props) => {
  const [{ isOpen, timeout }, { onClose }] = usePageContext();
  const containerRef = useCSSTransition(isOpen, { timeout, appear: true });

  const tryClose = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      ref={containerRef}
      onClick={tryClose}
      className="modal__container fade-in"
      {...props}
    />
  );
};

export const BottomModalLayout = ({ children, ...props }) => {
  const [{ isOpen, timeout }] = usePageContext();
  const modalRef = useCSSTransition(isOpen, { timeout, appear: true });
  return (
    <ModalLayout>
      <div
        ref={modalRef}
        className="w-full max-w-2xl mx-auto fixed slide-up"
        style={{ left: 0, bottom: 0, right: 0 }}
        {...props}
      >
        <Suspense fallback={<div className="loader absolute inset-0 m-auto" />}>
          {children}
        </Suspense>
      </div>
    </ModalLayout>
  );
};

export const PopupModalLayout = ({ children, ...props }) => {
  const [{ isOpen, timeout }] = usePageContext();
  const modalRef = useCSSTransition(isOpen, { timeout, appear: true });
  return (
    <ModalLayout>
      <div
        ref={modalRef}
        className="w-full max-w-2xl mx-auto p-4 scale-up"
        {...props}
      >
        <Suspense fallback={<div className="loader absolute inset-0 m-auto" />}>
          {children}
        </Suspense>
      </div>
    </ModalLayout>
  );
};

export default ModalLayout;
