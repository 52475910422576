import * as _ from "utils";

import React, { useEffect, useRef, useState } from "react";
import useContentHeight from "hooks/useContentHeight";
import useCurrentUser from "hooks/useCurrentUser";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";

import withAuth from "hocs/withAuth";

import NotFoundPage from "pages/404";
import { BottomModalLayout } from "layouts/Modal";

import * as Icons from "components/Icons";
import { Button, Field, FlyOut, IconButton } from "components/UI";
import FinancialIcon from "components/Transactions/FinancialIcon";

const add32 = (x) => x + 32;

export default withAuth(({ addressId }) => {
  const moreOptionsOriginRef = useRef(null);
  const containerRef = useContentHeight(add32);

  const { t } = useTranslation();
  const [currentUser, currentUserResource] = useCurrentUser();
  const [, { onClose }] = usePageContext();

  const addressInstance = currentUser.paymentAddresses?.find(
    ({ id }) => id === addressId
  );

  const [value, setValue] = useState(addressInstance?.value || "");
  const [name, setName] = useState(addressInstance?.name || "");
  const [hasError, setError] = useState(false);
  const chosenAddress = _.paymentAddresses.find(
    (address) => address.type === addressInstance?.type
  );
  const isValid =
    value &&
    chosenAddress &&
    (typeof chosenAddress.validate !== "function" ||
      chosenAddress.validate(value));

  useEffect(() => {
    if (isValid) {
      setError(false);
    }
  }, [isValid]);

  return addressInstance ? (
    <BottomModalLayout>
      <div
        ref={containerRef}
        className="transition-dimension duration-200 ease-in-out rounded-b-none space-y-4 p-4 rounded-md bg-card overflow-y-auto"
      >
        <div className="flex items-center justify-space-between">
          <h2>{t`settings.payments.editAddress`}</h2>
          <IconButton variant="text" ref={moreOptionsOriginRef}>
            <Icons.Dots direction="vertical" />
          </IconButton>
          <FlyOut originRef={moreOptionsOriginRef}>
            <FlyOut.Item
              color="error"
              onClick={async () => {
                await currentUserResource.update({
                  paymentAddresses: currentUser.paymentAddresses.filter(
                    (address) => address.id !== addressId
                  ),
                });
                onClose();
              }}
            >
              {t`action.delete`}
            </FlyOut.Item>
          </FlyOut>
        </div>
        {addressInstance.type === "misc" && (
          <p>{t`settings.payments.warning`}</p>
        )}
        <Field
          autoFocus={addressInstance.type !== "iban"}
          variant="contained"
          prefix={chosenAddress.prefix}
          label={t(`settings.payments.${addressInstance.type}`)}
          lead={<FinancialIcon size={0.75} type={addressInstance.type} />}
          value={value}
          onChange={(event) =>
            setValue(chosenAddress.formatValue(event.target.value))
          }
          onBlur={() => !isValid && setError(true)}
          error={
            hasError
              ? value
                ? t`settings.payments.invalidIBAN`
                : t`settings.payments.fieldCannotBeEmpty`
              : null
          }
        />
        {addressInstance.type === "iban" && (
          <Field
            variant="contained"
            label={t`settings.payments.inNameOf`}
            placeholder={currentUser.name}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        )}
        <div className="flex flex-col-reverse items-normal sm:flex-row sm:justify-flex-end sm:items-center">
          <Button
            variant="text"
            className="mt-2 sm:mt-0 sm:mr-4"
            onClick={() => onClose()}
          >{t`action.cancel`}</Button>
          <Button
            color="primary"
            size="sm"
            onClick={async () => {
              await currentUserResource.update({
                paymentAddresses: currentUser.paymentAddresses.map((address) =>
                  address.id === addressId
                    ? {
                        ...address,
                        value,
                        name,
                      }
                    : address
                ),
              });
              onClose();
            }}
            disabled={!isValid}
          >{t`action.save`}</Button>
        </div>
      </div>
    </BottomModalLayout>
  ) : (
    <NotFoundPage />
  );
});
