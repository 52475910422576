import { createContext } from "react";

export const CourseContext = createContext(null);
export const CourseOptionContext = createContext(null);
export const ExpenseContext = createContext(null);
export const GroupContext = createContext(null);
export const ListContext = createContext(null);
export const RegularMomentContext = createContext(null);
export const SettlementContext = createContext(null);
export const TaskContext = createContext(null);
export const TransactionContext = createContext(null);

export const ColorModeContext = createContext(null);
export const GroupRecencyContext = createContext(null);
export const NavigationContext = createContext(null);
export const PageContext = createContext(null);
