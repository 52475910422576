import * as _ from "utils";

import React, { useRef, useState } from "react";
import useExpenseContext from "hooks/useExpenseContext";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";

import withExpense from "hocs/withExpense";

import { BottomModalLayout } from "layouts/Modal";

import * as Icons from "components/Icons";
import { Button, FlyOut, IconButton } from "components/UI";
import ExpenseInput from "components/Expenses/Input";

export default withExpense(() => {
  const { t } = useTranslation();
  const [, { onClose }] = usePageContext();
  const [expense, expenseResource] = useExpenseContext();
  const [state, setState] = useState(expenseResource.rawData);

  const moreOptionsOriginRef = useRef(null);

  return (
    <BottomModalLayout>
      <div className="expenses__container rounded-b-none max-h-screen rounded-md bg-card overflow-y-auto">
        <div className="space-y-4 p-4">
          {expense.isSettled ? (
            <div className="flex justify-space-between items-center">
              <div>
                <h2>{t`expense.title`}</h2>
                <p className="text-caption">{t`expense.isSettled`}</p>
              </div>
              <IconButton variant="text" onClick={onClose}>
                <Icons.Cross />
              </IconButton>
            </div>
          ) : (
            <div className="flex justify-space-between items-center">
              <h2>{t`expense.edit`}</h2>
              <IconButton ref={moreOptionsOriginRef} variant="text">
                <Icons.Dots direction="vertical" />
              </IconButton>
              <FlyOut originRef={moreOptionsOriginRef}>
                <FlyOut.Item
                  color="error"
                  onClick={async () => {
                    await expenseResource.delete();
                    onClose();
                  }}
                >{t`expense.delete`}</FlyOut.Item>
              </FlyOut>
            </div>
          )}
          <ExpenseInput
            value={state}
            onChange={setState}
            disabled={expense.isSettled}
          />
        </div>
        {!expense.isSettled && (
          <div className="sticky z-10 bottom-0 flex justify-flex-end space-x-4 px-4 py-2 border-solid border-0 border-t border-accent bg-card">
            <Button variant="text" onClick={onClose}>
              {t`action.cancel`}
            </Button>
            <Button
              color="primary"
              size="sm"
              disabled={!_.isValidExpense(state)}
              onClick={async () => {
                await expenseResource.update(state);
                expenseResource.sendUpdateMessage();
                onClose();
              }}
            >
              {t`action.save`}
            </Button>
          </div>
        )}
      </div>
    </BottomModalLayout>
  );
});
