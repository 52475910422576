import cx from "classnames";

import React, { useEffect, useRef } from "react";
import useCourseOption from "hooks/useCourseOption";

import { ImageInput } from "components/UI";
import CourseOptionContent from "./CourseOptionContent";
import CourseOptionActions from "./CourseOptionActions";
import CourseOptionStatus from "./CourseOptionStatus";
import EditableCourseOption from "./EditableCourseOption";

export default ({
  courseOption,
  isEditing,
  hasImage,
  format,
  currentParticipantId,
  isInteractive,
  isFocused,
  onFocus,
  onConfirm,
}) => {
  const [
    ,
    { onChange, onDelete, onChangeImage, onDeleteImage },
  ] = useCourseOption(courseOption.id);

  const containerRef = useRef(null);
  const flyOutRef = useRef(null);
  const dishRef = useRef(null);

  useEffect(() => {
    if (!isFocused && !courseOption.title) {
      const t = setTimeout(() => onDelete(), 200);
      return () => clearTimeout(t);
    }
  }, [isFocused, courseOption.title, onDelete]);

  return (
    (isFocused || courseOption.title) && (
      <div
        className={cx(
          "flex space-x-4 w-full",
          {
            "course__option--focused p-2 rounded-md border-solid border border-accent bg-background": isFocused,
          },
          {
            clickable: isEditing && !isFocused,
          }
        )}
        tabIndex={isEditing ? 0 : 1}
        onFocus={isEditing ? () => onFocus() : undefined}
        ref={containerRef}
      >
        {(isFocused || hasImage) && (
          <ImageInput
            className={cx("w-12 h-12 flex-shrink-0 rounded-md", {
              "image-input--textured image-input--sm":
                !courseOption.image && !courseOption.recipe?.image,
            })}
            value={courseOption.image || courseOption.recipe?.image}
            disabled={!isFocused && !isEditing}
            showIcon={isFocused || isEditing}
            onChange={(value) => onChangeImage(value)}
            onDelete={() => onDeleteImage()}
          />
        )}
        {isFocused ? (
          <EditableCourseOption
            courseOption={courseOption}
            onConfirm={onConfirm}
            onChange={onChange}
            onDelete={onDelete}
            dishRef={dishRef}
            flyOutRef={flyOutRef}
          />
        ) : (
          <div
            className={cx("flex flex-col justify-center flex-grow space-y-2", {
              "min-h-12": hasImage,
            })}
          >
            <div className="flex items-flex-end justify-space-between">
              <CourseOptionContent
                courseOption={courseOption}
                isEditing={isEditing}
              />
              {!isEditing && isInteractive && (
                <CourseOptionActions
                  courseOption={courseOption}
                  format={format}
                  currentParticipantId={currentParticipantId}
                />
              )}
            </div>
            {!isEditing && isInteractive && (
              <CourseOptionStatus courseOption={courseOption} format={format} />
            )}
          </div>
        )}
      </div>
    )
  );
};
