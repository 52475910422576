import "./CollapsibleContainer.scss";

import cx from "classnames";

import React, { useLayoutEffect, useRef } from "react";

export default function CollapsibleContainer({
  icon: Icon,
  iconClassName = "collapsible-container__icon-container",
  className,
  children,
  isVisible = true,
  offset = 0,
}) {
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  useLayoutEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;

    if (container && content) {
      const resize = () => {
        const css = isVisible
          ? {
              height: `${content.offsetHeight + offset}px`,
            }
          : {
              height: 0,
              opacity: 0,
              "margin-top": 0,
              "pointer-events": "none",
            };
        container.style.cssText = Object.keys(css)
          .map((key) => `${key}: ${css[key]}`)
          .join("; ");
      };
      resize();

      window.addEventListener("resize", resize);
      return () => window.removeEventListener("resize", resize);
    }
  });

  return (
    <div
      ref={containerRef}
      className={cx(
        "collapsible-container__container ease-in-out duration-200 flex items-flex-start space-x-4",
        className
      )}
    >
      {Icon && (
        <div className={iconClassName}>
          <Icon size={1.25} />
        </div>
      )}
      <div ref={contentRef} className="flex-grow min-w-0">
        {children}
      </div>
    </div>
  );
}
