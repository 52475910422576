import * as _ from "utils";

import { useCallback } from "react";
import { useDoc } from "react-firestore";
import useInvite from "./useInvite";
import useTranslation from "./useTranslation";
import useUsers from "./useUsers";

export default function useListSharing(listId) {
  const { t, i18n } = useTranslation();
  const [list, listResource] = useDoc("events", listId);
  const chefUsers = useUsers(list.chefs);
  const invite = useInvite({
    type: "list",
    listId,
    parentId: (list.groupId && list.myParticipation?.id) || null,
  });

  const listDescription = _.getListDescription({
    list,
    chefUsers,
    lang: i18n.language,
  });

  const shareMessage = [
    t`description.greeting`,
    listDescription,
    t("description.wantToJoin", {
      context: _.toContext([["past", list.hasOccurred]]),
    }),
    `${process.env.REACT_APP_SHARE_URL}/i/${invite.id}`,
  ].join(" ");

  const shareNative = useCallback(async () => {
    await navigator.share({
      text: shareMessage,
    });
    listResource.update({ isShared: true });
    _.track("event_share", {
      eventId: list.id,
      method: "native",
    });
  }, [list.id, listResource, shareMessage]);

  const tryShareNative = useCallback(
    async (options) => {
      try {
        await shareNative();
      } catch (error) {
        _.pushModal(`/l/${list.id}/share`, options);
      }
    },
    [list.id, shareNative]
  );

  const shareViaWhatsApp = useCallback(() => {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(shareMessage)}`,
      "_blank"
    );
    listResource.update({ isShared: true });
  }, [listResource, shareMessage]);

  const copyLink = useCallback(async () => {
    _.track("event_share", { eventId: list.id, method: "copy" });

    if (await _.copyTextToClipboard(shareMessage)) {
      listResource.update({ isShared: true });
      return true;
    } else {
      return false;
    }
  }, [list.id, listResource, shareMessage]);

  return [
    { message: shareMessage, listDescription },
    {
      tryShareNative,
      shareViaWhatsApp,
      copyLink,
    },
  ];
}
