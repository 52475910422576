import * as _ from "utils";

import BaseModel from "./Base";

export default class User extends BaseModel {
  static normalize(user) {
    return {
      uid: user.id,
      ...user,
    };
  }

  async updateAvatar(avatarImage) {
    const avatarURL = await _.uploadFileAsDataUrl(
      _.storage.ref(`${this.docRef.id}/avatar.png`),
      avatarImage
    );

    await this.update({ avatar: avatarURL });
    _.track("account_avatar_update");
  }
}
