import * as _ from "utils";

import { LIST_TYPES, GROUP_TYPES, GROUP_ROLES } from "statics";
import firebase from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import BaseModel from "./Base";
import Task from "./Task";

const normalizeRecurringItems = (group) => ({
  ...group,
  regularMoments: group.regularMoments || [],
  recurringTasks: group.recurringTasks || [],
});

const normalizeMyMembership = (group) => ({
  ...group,
  myMembership: group.members.find(_.isCurrentUser),
});

export default class Group extends BaseModel {
  recurringTaskInstances = [];
  usableRecurringTasks = [];
  nextOccurrencePerRecurringTask = [];
  regularMomentInstances = [];
  usableRegularMoments = [];
  nextOccurrencePerRegularMoment = [];

  setRawData(rawData) {
    this.rawData = rawData;
    this.resetRecurringTasks();
    this.resetRegularMoments();
  }

  static normalize(group) {
    return _.pipe(normalizeRecurringItems, normalizeMyMembership)(group);
  }

  static create(params) {
    _.track("group_create");
    return {
      memberUserIdentifiers: _.getUserIdentifiers(_.auth.currentUser),
      members: [
        {
          id: uuidv4(),
          userIdentifiers: _.getUserIdentifiers(_.auth.currentUser),
          role: GROUP_ROLES.ADMIN,
          notifications: {
            dayWithChef: true,
            dayWithoutChef: true,
          },
        },
      ],
      privacy: GROUP_TYPES.PRIVATE,
      createdAt: new Date(),
      updatedAt: new Date(),
      ...params,
    };
  }

  async setImage(image) {
    await this.update({ image: image });
    const imageURL = await _.uploadFileAsDataUrl(
      _.storage.ref(`groups/${this.docRef.id}`),
      image
    );

    await this.update({ image: imageURL });
    _.track("group_update_image");
  }

  async addMember({ userIdentifiers, ...rest }) {
    const id = uuidv4();
    try {
      await _.db.runTransaction(async (tx) => {
        const groupDoc = await tx.get(this.docRef);

        if (!groupDoc.exists) {
          throw Error(`Group ${this.docRef.id} does not exist`);
        }

        if (
          _.intersection(groupDoc.data().memberUserIdentifiers, userIdentifiers)
            .length > 0
        ) {
          throw Error("User is already a member of this group");
        }

        await tx.update(this.docRef, {
          members: firebase.firestore.FieldValue.arrayUnion({
            id,
            userIdentifiers,
            ...rest,
          }),
          memberUserIdentifiers: firebase.firestore.FieldValue.arrayUnion(
            ...userIdentifiers
          ),
        });
      });
    } catch (error) {
      console.error(error);
    }
    _.track("member_invite");
    return id;
  }

  async changeMember(id, v) {
    await this.update({
      members: this.rawData.members.map((member) =>
        member.id === id ? (typeof v === "function" ? v(member) : v) : member
      ),
    });
    _.track("member_update");
  }

  async deleteMember(id) {
    const newGroupMembers = this.rawData.members.filter(
      (member) => member.id !== id
    );
    await this.update({
      members: newGroupMembers,
      memberUserIdentifiers: newGroupMembers.flatMap(
        ({ userIdentifiers }) => userIdentifiers
      ),
    });
    _.track("member_delete");
  }

  async leave() {
    await this.deleteMember(this.rawData.members.find(_.isCurrentUser)?.id);
    _.track("member_leave");
  }

  async setMemberRole(id, role) {
    await this.changeMember(id, (member) => ({ ...member, role }));
  }

  async promoteMember(id) {
    await this.setMemberRole(id, GROUP_ROLES.ADMIN);
    _.track("member_promote");
  }

  async degradeMember(id) {
    await this.setMemberRole(id, GROUP_ROLES.MEMBER);
    _.track("member_degrade");
  }

  async createRegularMoment(regularMoment) {
    await this.update({
      regularMoments: firebase.firestore.FieldValue.arrayUnion({
        id: uuidv4(),
        ...regularMoment,
      }),
    });
    this.resetRegularMoments();
    _.track("recurring_event_create");
  }

  async updateRegularMoment(regularMomentId, regularMoment) {
    await this.update({
      regularMoments: this.rawData.regularMoments.map((m) =>
        m.id === regularMomentId ? regularMoment : m
      ),
    });
    this.resetRegularMoments();
    _.track("recurring_event_update");
  }

  async deleteRegularMoment(regularMomentId) {
    await this.update({
      regularMoments: this.rawData.regularMoments.filter(
        ({ id }) => id !== regularMomentId
      ),
    });
    this.resetRegularMoments();
    _.track("recurring_event_delete");
  }

  async createRecurringTask(recurringTask) {
    await this.update({
      recurringTasks: firebase.firestore.FieldValue.arrayUnion({
        id: uuidv4(),
        ...recurringTask,
      }),
    });
    this.resetRecurringTasks();
    _.track("recurring_task_create");
  }

  async updateRecurringTask(recurringTaskId, updatedRecurringTask) {
    await this.update({
      recurringTasks: this.rawData.recurringTasks.map((recurringTask) =>
        recurringTask.id === recurringTaskId
          ? updatedRecurringTask
          : recurringTask
      ),
    });
    this.resetRecurringTasks();
    _.track("recurring_task_update");
  }

  async deleteRecurringTask(recurringTaskId) {
    await this.update({
      recurringTasks: this.rawData.recurringTasks.filter(
        ({ id }) => id !== recurringTaskId
      ),
    });
    this.resetRecurringTasks();
    _.track("recurring_task_delete");
  }

  resetRegularMoments() {
    this.regularMomentInstances = [];
    this.usableRegularMoments = (this.rawData.regularMoments || []).filter(
      _.isValidRegularMoment
    );
    this.nextOccurrencePerRegularMoment = this.usableRegularMoments.map(
      (regularMoment) => ({
        regularMoment,
        occurrence: 0,
        occursAt: _.getOccurrence(regularMoment, 0),
      })
    );
  }

  getRecurringListInstances(endDate) {
    if (this.usableRegularMoments.length === 0) {
      return [];
    }

    while (
      this.regularMomentInstances.length === 0 ||
      this.regularMomentInstances[this.regularMomentInstances.length - 1]
        .calendarDate < endDate
    ) {
      this.nextOccurrencePerRegularMoment.sort(_.firstBy((m) => m.occursAt));
      const {
        regularMoment,
        occurrence,
        occursAt,
      } = this.nextOccurrencePerRegularMoment[0];

      this.regularMomentInstances.push({
        title: regularMoment.title,
        type: LIST_TYPES.COOK,
        regularMomentId: regularMoment.id,
        occursAt: {
          date: occursAt.format("YYYY-MM-DD"),
          time: null,
        },
        calendarDate: occursAt.toDate(),
        participants: [],
        chefs: [],
        courses: [],
        groupId: this.rawData.id,
      });
      this.nextOccurrencePerRegularMoment[0].occurrence = occurrence + 1;
      this.nextOccurrencePerRegularMoment[0].occursAt = _.getOccurrence(
        regularMoment,
        occurrence + 1
      );
    }

    return this.regularMomentInstances;
  }

  resetRecurringTasks() {
    this.recurringTaskInstances = [];
    this.usableRecurringTasks = (this.rawData.recurringTasks || []).filter(
      _.isValidRegularMoment
    );
    this.nextOccurrencePerRecurringTask = this.usableRecurringTasks.map(
      (recurringTask) => ({
        recurringTask,
        occurrence: 0,
        dueAt: _.getOccurrence(recurringTask, 0).endOf("day"),
      })
    );
  }

  getRecurringTaskInstances(endDate) {
    if (this.usableRecurringTasks.length === 0) {
      return [];
    }

    while (
      this.recurringTaskInstances.length === 0 ||
      this.recurringTaskInstances[this.recurringTaskInstances.length - 1]
        .calendarDate < endDate
    ) {
      this.nextOccurrencePerRecurringTask.sort(_.firstBy((m) => m.dueAt));
      const {
        recurringTask,
        occurrence,
        dueAt,
      } = this.nextOccurrencePerRecurringTask[0];
      const possibleAssignees = recurringTask.possibleAssignees.filter(
        (possibleAssignee) =>
          this.rawData.members.some(_.isSameUser(possibleAssignee))
      );
      const assigneesPerTask = Math.min(
        recurringTask.assigneesPerTask,
        possibleAssignees.length
      );
      this.recurringTaskInstances.push(
        Task.normalize({
          type: "task",
          groupId: this.rawData.id,
          recurringTaskId: recurringTask.id,
          title: recurringTask.title,
          checklist: recurringTask.checklist,
          assignees: [...possibleAssignees, ...possibleAssignees].slice(
            (occurrence * assigneesPerTask) % possibleAssignees.length,
            ((occurrence * assigneesPerTask) % possibleAssignees.length) +
              assigneesPerTask
          ),
          dueAt,
          calendarDate: dueAt.toDate(),
          expiresAt: moment(dueAt).add(recurringTask.leeway, "days"),
        })
      );
      this.nextOccurrencePerRecurringTask[0].occurrence = occurrence + 1;
      this.nextOccurrencePerRecurringTask[0].dueAt = _.getOccurrence(
        recurringTask,
        occurrence + 1
      ).endOf("day");
    }

    return this.recurringTaskInstances;
  }
}
