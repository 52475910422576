import { PARTICIPANT_ROLES } from "statics.json";
import { or, and, neg } from "./functions";

const is = (role) => (participant) => participant?.roles?.includes(role);

const hasAnyRoles = (participant) => participant?.roles?.length > 0;
const isCreator = is(PARTICIPANT_ROLES.CREATOR);
const isParticipant = is(PARTICIPANT_ROLES.EATER);
const isNonParticipant = is(PARTICIPANT_ROLES.NON_EATER);
const isChef = is(PARTICIPANT_ROLES.CHEF);
const isNonChef = and(isParticipant, neg(isChef));
const hasResponded = or(isParticipant, isNonParticipant);
const hasSeen = or(hasResponded, (participant) => participant?.hasSeen);

const isEditor = or(isCreator, isChef);

const canEdit = (list) =>
  isEditor(list.myParticipation) ||
  (list.everyoneCanManageParticipants && (list.hasOccurred || !list.isClosed));

export {
  hasAnyRoles,
  isChef,
  isNonChef,
  isParticipant,
  isNonParticipant,
  isCreator,
  isEditor,
  canEdit,
  hasResponded,
  hasSeen,
};
