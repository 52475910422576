import * as _ from "utils";
import { useEffect } from "react";
import { useLocation } from "@reach/router";

export default function useNavigation() {
  const location = useLocation();

  useEffect(() => {
    const { stack } = _.getLocationState(location);
    if (stack.length === 0) {
      const state = {
        stack: [
          { location: JSON.parse(JSON.stringify(location)), isOpen: true },
        ],
        pointer: 0,
      };
      window.history.replaceState(state, null);
      _.dispatchNavigationState(state);
    } else {
      document.querySelectorAll(".page-root > div").forEach((pageRoot, i) => {
        if (stack[i]?.location.hash.length > 1) {
          const target = document.querySelector(stack[i].location.hash);
          if (target && pageRoot) {
            pageRoot.scrollTo({
              top: target.offsetTop - 2.5 * 16,
              behavior: "smooth",
            });
          }
        }
      });

      const ts = stack.map((stackFrame) =>
        stackFrame.isOpen
          ? null
          : setTimeout(() => {
              const location = _.getLocation();
              const { stack, pointer, ...state } = _.getLocationState(location);
              const i = stack.findIndex(({ key }) => key === stackFrame.key);
              if (i >= 0) {
                const newState = {
                  ...state,
                  stack: stack.filter((_, j) => j !== i),
                  pointer: Math.max(0, pointer > i ? pointer - 1 : pointer),
                };
                window.history.replaceState(newState, null);
                _.dispatchNavigationState(newState);
              }
            }, stackFrame.timeout)
      );
      return () => {
        ts.map((t) => clearTimeout(t));
      };
    }
  }, [location]);

  return _.getLocationState(_.getLocation());
}
