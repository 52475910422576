import { COURSE_FORMATS } from "statics.json";
import React from "react";

import MultipleChoiceCourseStatus from "./MultipleChoiceCourseStatus";
import PollCourseStatus from "./PollCourseStatus";
import OrderCourseStatus from "./OrderCourseStatus";

export default ({ courseOption, format }) => {
  switch (format) {
    case COURSE_FORMATS.MULTIPLE_OPTIONS:
      return <MultipleChoiceCourseStatus courseOption={courseOption} />;
    case COURSE_FORMATS.POLL:
      return <PollCourseStatus courseOption={courseOption} />;
    case COURSE_FORMATS.ORDER:
      return <OrderCourseStatus courseOption={courseOption} />;
    default:
      return null;
  }
};
