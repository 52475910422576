import * as _ from "utils";
import cx from "classnames";

import React from "react";
import { useDoc } from "react-firestore";
import useTaskContext from "hooks/useTaskContext";
import useTranslation from "hooks/useTranslation";
import useUsers from "hooks/useUsers";

import { Avatar } from "components/UI";
import CollapsibleContainer from "components/CollapsibleContainer";
import UserName from "components/User/Name";

export default function Assignees({ isEditing }) {
  const { t } = useTranslation();
  const [task, taskResource] = useTaskContext();
  const [group] = useDoc("groups", task.groupId);
  const memberUsers = useUsers(group.members);
  const selectedMemberUsers = memberUsers.filter((user) =>
    task.assignees.some(_.isSameUser(user))
  );
  const visibleMemberUsers = isEditing ? memberUsers : selectedMemberUsers;

  return (
    <CollapsibleContainer>
      <div className="space-y-2">
        {isEditing && <p>{t`task.selectAssignees`}</p>}
        <div className="flex flex-wrap wrapping-box--2">
          {visibleMemberUsers.map((user) => (
            <div
              key={user.id}
              className={cx(
                "flex space-x-2 items-center",
                isEditing
                  ? task.assignees.some(_.isSameUser(user))
                    ? "cursor-pointer rounded-full pr-2 bg-primary"
                    : "cursor-pointer rounded-full pr-2 accent-text"
                  : undefined
              )}
              onClick={
                isEditing
                  ? () =>
                      taskResource.setAssignees(
                        task.assignees.some(_.isSameUser(user))
                          ? task.assignees.filter(
                              (assignee) => !_.isSameUser(user)(assignee)
                            )
                          : task.assignees.concat(user)
                      )
                  : undefined
              }
            >
              <Avatar user={user} size={1.5} />
              <p>
                <UserName user={user} />
              </p>
            </div>
          ))}
        </div>
        {task.assignees.length === 0 && !isEditing && (
          <p>{t`task.noAssignees`}</p>
        )}
      </div>
    </CollapsibleContainer>
  );
}
