import { COURSE_FORMATS } from "statics.json";
import React from "react";

import MultipleChoiceCourseActions from "./MultipleChoiceCourseActions";
import PollCourseActions from "./PollCourseActions";
import OrderCourseActions from "./OrderCourseActions";

export default ({ courseOption, format, currentParticipantId }) => {
  switch (format) {
    case COURSE_FORMATS.MULTIPLE_OPTIONS:
      return (
        <MultipleChoiceCourseActions
          courseOption={courseOption}
          currentParticipantId={currentParticipantId}
        />
      );
    case COURSE_FORMATS.POLL:
      return (
        <PollCourseActions
          courseOption={courseOption}
          currentParticipantId={currentParticipantId}
        />
      );
    case COURSE_FORMATS.ORDER:
      return (
        <OrderCourseActions
          courseOption={courseOption}
          currentParticipantId={currentParticipantId}
        />
      );
    default:
      return null;
  }
};
