import moment from "moment";
import "moment/locale/nl";

moment.updateLocale("nl", {
  calendar: {
    lastDay: "[Gisteren om] LT",
    sameDay: "[Vandaag om] LT",
    nextDay: "[Morgen om] LT",
    lastWeek: "[Afgelopen] dddd [om] LT",
    nextWeek: "[Komende] dddd [om] LT",
    sameElse: "dddd D MMM [om] LT"
  },
  week: {
    dow: 1,
    doy: 4
  }
});

moment.updateLocale("en", {
  calendar: {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Tomorrow at] LT",
    lastWeek: "[Last] dddd [at] LT",
    nextWeek: "[Next] dddd [at] LT",
    sameElse: "dddd, MMM Do [at] LT"
  },
  week: {
    dow: 1,
    doy: 4
  }
});
