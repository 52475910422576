import "./RegularMomentInput.scss";

import * as _ from "utils";
import { DATE_TYPES, PERIODS } from "statics.json";
import cx from "classnames";

import React, { useCallback } from "react";
import useTranslation from "hooks/useTranslation";

import { DateInput, DropDown, Field } from "components/UI";

function WeekDayToggle({ isChecked, weekDay, onChange }) {
  const { moment } = useTranslation();

  return (
    <span
      className={cx("day-toggle", { "day-toggle--checked": isChecked })}
      onClick={() => onChange(!isChecked)}
    >
      {moment().weekday(weekDay).format("dd").charAt(0)}
    </span>
  );
}

export default function RegularMomentInput({ value, onChange }) {
  const { t, moment } = useTranslation();

  const inputProps = useCallback(
    (key) => ({
      name: key,
      value: value[key],
      onChange: (event) => {
        const value = event.target ? event.target.value : event;
        onChange((v) => ({ ...v, [key]: value }));
      },
    }),
    [value, onChange]
  );

  const periodOptions = Object.values(PERIODS).map((period) => ({
    label: t(`frequency.period.${period}`, { count: value.frequency }),
    value: period,
  }));

  const dayOfMonthOptions = [
    ...Array(31)
      .fill()
      .map((_, i) => ({
        label: `${t(`frequency.period.day`)} ${i + 1}`,
        value: i,
      })),
    { label: t`frequency.lastDay`, value: null },
  ];

  const dayOfWeekOptions = Array(7)
    .fill()
    .map((_, i) => ({
      label: moment().weekday(i).format("dddd"),
      value: i,
    }));

  const nthOptions = [
    { label: t`frequency.nth.first`, value: "first" },
    { label: t`frequency.nth.second`, value: "second" },
    { label: t`frequency.nth.third`, value: "third" },
    { label: t`frequency.nth.fourth`, value: "fourth" },
    { label: t`frequency.nth.last`, value: "last" },
  ];

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-2">
        <p>{_.capitalize(t`frequency.every`)}</p>
        <Field
          required
          type="number"
          variant="contained"
          size="sm"
          className="w-16"
          value={isNaN(value.frequency) ? "" : value.frequency}
          onChange={(event) => {
            const value = event.target ? event.target.value : event;
            onChange((v) => ({
              ...v,
              frequency: _.between(1, 99)(parseInt(value, 10)),
            }));
          }}
        />
        <DropDown
          required
          size="sm"
          {...inputProps("frequencyPeriod")}
          options={periodOptions}
        />
      </div>
      {value.frequencyPeriod === PERIODS.WEEK && (
        <div className="flex justify-space-between">
          {value.weekDays.map((weekDay, i) => (
            <WeekDayToggle
              key={i}
              isChecked={weekDay}
              weekDay={i}
              onChange={(checked) =>
                onChange((v) => ({
                  ...v,
                  weekDays: v.weekDays.map((d, j) => (i === j ? checked : d)),
                }))
              }
            />
          ))}
        </div>
      )}
      {value.frequencyPeriod === PERIODS.MONTH && (
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <input
              type="radio"
              checked={value.dateType === DATE_TYPES.DAY}
              onChange={({ target }) =>
                onChange((v) => ({
                  ...v,
                  dateType: target.checked ? DATE_TYPES.DAY : DATE_TYPES.NTH,
                }))
              }
            />
            <DropDown
              required
              size="sm"
              value={value.dayOfMonth}
              onChange={(dayOfMonth) =>
                onChange((v) => ({
                  ...v,
                  dayOfMonth,
                  dateType: DATE_TYPES.DAY,
                }))
              }
              options={dayOfMonthOptions}
            />
          </div>
          <div className="flex items-center space-x-2">
            <input
              type="radio"
              checked={value.dateType === DATE_TYPES.NTH}
              onChange={({ target }) =>
                onChange((v) => ({
                  ...v,
                  dateType: target.checked ? DATE_TYPES.NTH : DATE_TYPES.DAY,
                }))
              }
            />
            <DropDown
              required
              size="sm"
              value={value.nth}
              onChange={(nth) =>
                onChange((v) => ({ ...v, nth, dateType: DATE_TYPES.NTH }))
              }
              options={nthOptions}
            />
            <DropDown
              required
              size="sm"
              value={value.dayOfWeek}
              onChange={(dayOfWeek) =>
                onChange((v) => ({ ...v, dayOfWeek, dateType: DATE_TYPES.NTH }))
              }
              options={dayOfWeekOptions}
            />
          </div>
        </div>
      )}
      <div className="flex items-center space-x-2">
        <p>
          {_.capitalize(
            value.frequency === "year" ? t`frequency.date` : t`frequency.start`
          )}
        </p>
        <Field
          required
          component={DateInput}
          type="date"
          size="sm"
          variant="contained"
          value={value.startDate.date}
          onChange={(date) =>
            onChange((v) => ({ ...v, startDate: { date, time: null } }))
          }
        />
      </div>
    </div>
  );
}
