import * as _ from "utils";
import { GROUP_ROLES } from "statics.json";
import { v4 as uuidv4 } from "uuid";

import React, { useEffect, useState } from "react";
import useContacts from "hooks/useContacts";
import useCurrentUser from "hooks/useCurrentUser";
import useGroupContext from "hooks/useGroupContext";
import useMemberUtils from "hooks/useMemberUtils";
import useTranslation from "hooks/useTranslation";
import useUser from "hooks/useUser";

import * as Icons from "components/Icons";
import { IconButton, Avatar, BaseInput } from "components/UI";
import InfiniteList from "components/InfiniteList";
import UserThumb from "components/User/Thumb";

function Contact({ userContainer }) {
  const [group, groupResource] = useGroupContext();
  const [user] = useUser(userContainer);
  const member = group.members.find(_.isSameUser(userContainer));

  return (
    <div
      className="flex justify-space-between items-center cursor-pointer hover:bg-glow"
      onClick={() =>
        member
          ? groupResource.deleteMember(member.id)
          : groupResource.addMember({
              id: uuidv4(),
              role: GROUP_ROLES.MEMBER,
              userIdentifiers: user.userIdentifiers,
              ...(user.exists ? {} : { email: user.email }),
            })
      }
    >
      <div className="flex space-x-4 items-center py-2 min-w-0">
        <Avatar user={user} size={2.5} />
        {user.name ? (
          <div>
            <p className="truncate">{user.name}</p>
            <p className="text-xs text-caption truncate">{user.email}</p>
          </div>
        ) : (
          <p className="truncate">{user.email}</p>
        )}
      </div>
      {member ? (
        <IconButton color="success" size="sm">
          <Icons.Check />
        </IconButton>
      ) : (
        <IconButton color="card" size="sm">
          <Icons.Plus />
        </IconButton>
      )}
    </div>
  );
}

const renderContact = (contact) => (
  <Contact key={contact.userIdentifiers[0]} userContainer={contact} />
);

export default function GroupMemberManagement() {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();
  const [group, groupResource] = useGroupContext();

  useEffect(() => {
    const initialMembers = groupResource.rawData.members;

    return () => {
      const eventualMembers = groupResource.rawData.members;
      const newMembers = eventualMembers.filter(
        (member) => !initialMembers.some(_.isSameUser(member))
      );
      _.sendSegmentedMessage(newMembers.map(_.withMessagingInfo), ({ t }) => ({
        title: t("notifications.addedToGroup.title", {
          groupName: groupResource.rawData.name,
          addedBy: currentUser.name,
        }),
        body: t("notifications.addedToGroup.body", {
          groupName: groupResource.rawData.name,
          addedBy: currentUser.name,
        }),
        image: groupResource.rawData.image,
        data: {
          groupId: groupResource.rawData.id,
        },
      }));
    };
  }, [currentUser, groupResource]);

  const [{ contacts }] = useContacts();
  const [query, setQuery] = useState("");
  const queryLower = query.toLowerCase();
  const queryHash = _.md5(queryLower);

  const memberUtils = useMemberUtils();

  const matchingContacts = _.findMatchingContacts(query, contacts);

  return (
    <>
      <div className="flex flex-wrap wrapping-box--2">
        {group.members.map((member) => (
          <UserThumb
            key={member.id}
            userContainer={member}
            actions={memberUtils.getActions(member)}
            badge={memberUtils.getRoleBadge(member)}
          />
        ))}
      </div>
      <BaseInput
        autoFocus
        type="email"
        variant="contained"
        placeholder={
          contacts.length > 0
            ? t`group_setup.members.placeholderWithContacts`
            : t`group_setup.members.placeholderWithoutContacts`
        }
        lead={<Icons.Loop />}
        value={query}
        onChange={(event) => setQuery(event.target.value)}
      />
      <InfiniteList items={matchingContacts} renderItem={renderContact} />
      {_.isEmail(query) &&
        !matchingContacts.some(
          (contact) => contact.email?.toLowerCase() === queryLower
        ) && (
          <Contact
            userContainer={{
              email: queryLower,
              userIdentifiers: [queryHash],
            }}
          />
        )}
    </>
  );
}
