import i18n from "i18next";
import * as Sentry from "@sentry/browser";
import reactFirestore from "react-firestore";
import { receivesNotification, isSameUser } from "./users";
import { groupByKey } from "./arrays";

const getProviderAddress = (provider) => (user) => {
  switch (provider) {
    case "email":
      return user.email;
    case "sms":
      return user.phoneNumber;
    case "fcm":
    case "capacitor":
    case "apn":
    default:
      return user.vapidToken;
  }
};

const getNotificationEndpoint = (provider) => {
  switch (provider) {
    case "apn":
    case "apn-ios":
      return "sendApnNotification";
    case "fcm":
      return "sendFcmNotification";
    case "capacitor":
      return "sendCapacitorNotification";
    case "sms":
      return "sendSmsNotification";
    case "email":
    default:
      return "sendEmailNotification";
  }
};

const sendMessage = async (provider, user, body) => {
  const msg = {
    ...body,
    topic: ["apn-ios", "capacitor"].includes(provider)
      ? "com.wouterraateland.ikeetmee"
      : "web.nl.ikeetmee.www",
  };
  if (Array.isArray(user)) {
    msg.lang = user[0].lang;
    msg.to = user.map(getProviderAddress(provider)).filter(Boolean);
    if (msg.length === 0) {
      return;
    }
  } else {
    msg.lang = user.lang;
    msg.to = getProviderAddress(provider)(user);
    if (!msg) {
      return;
    }
  }
  try {
    await fetch(
      `${process.env.REACT_APP_API_URL}/${getNotificationEndpoint(provider)}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        body: JSON.stringify(msg),
      }
    );
  } catch (error) {
    Sentry.captureException(error, { tags: { event: "Send message" } });
    console.log(error);
  }
};

const withMessagingInfo = (userContainer) => {
  const user = reactFirestore.data.users.find(isSameUser(userContainer));

  const userWithMessagingInfo = {
    ...userContainer,
    ...user,
  };

  if (user) {
    userWithMessagingInfo.lang =
      user.settings?.preferredLanguage || i18n.language;
    userWithMessagingInfo.messagingProvider = user.vapidToken
      ? user.messagingProvider || "fcm"
      : user.email
      ? "email"
      : user.phoneNumber
      ? "sms"
      : null;
  }

  if (!userWithMessagingInfo.lang) {
    userWithMessagingInfo.lang = i18n.language;
  }
  if (!userWithMessagingInfo.messagingProvider) {
    if (userWithMessagingInfo.email) {
      userWithMessagingInfo.messagingProvider = "email";
    } else if (userWithMessagingInfo.phoneNumber) {
      userWithMessagingInfo.messagingProvider = "sms";
    }
  }

  return userWithMessagingInfo;
};

const getUsersToBeNotified = (notification) => (userContainers) =>
  (Array.isArray(userContainers) ? userContainers : [userContainers])
    .map(withMessagingInfo)
    .filter(receivesNotification(notification));

const sendSegmentedMessage = async (users, compose) => {
  const usersByLang = groupByKey("lang")(users);

  const messagePromises = Object.keys(usersByLang).flatMap((lang) => {
    console.log(lang, usersByLang[lang]);
    const t = i18n.getFixedT(lang);

    const usersByProvider = groupByKey("messagingProvider")(usersByLang[lang]);

    return Object.keys(usersByProvider).map((provider) => {
      console.log(provider, usersByProvider[provider]);
      return sendMessage(
        provider,
        usersByProvider[provider],
        compose({ provider, lang, t })
      );
    });
  });

  await Promise.all(messagePromises);
};

export {
  getProviderAddress,
  getNotificationEndpoint,
  sendMessage,
  withMessagingInfo,
  getUsersToBeNotified,
  sendSegmentedMessage,
};
