import * as _ from "utils";

import React, { useCallback } from "react";
import { useCollection } from "react-firestore";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";

import withList from "hocs/withList";

import SimpleLayout from "layouts/Simple";

import InfiniteList from "components/InfiniteList";
import SettlementThumb from "components/Settlements/Thumb";

const renderSettlement = (settlement) => (
  <SettlementThumb key={settlement.id} settlement={settlement} />
);

const sort = _.firstBy((settlement) => settlement.createdAt.toMillis(), -1);

export default withList(() => {
  const { t } = useTranslation();
  const [list] = useListContext();
  const filter = useCallback((settlement) => settlement.listId === list.id, [
    list.id,
  ]);
  const settlements = useCollection("settlements", { filter, sort });

  return (
    <SimpleLayout parentLocation={`/l/${list.id}`} title={t`settlements.title`}>
      <div className="space-y-4">
        <InfiniteList items={settlements} renderItem={renderSettlement} />
      </div>
    </SimpleLayout>
  );
});
