import cx from "classnames";

import React from "react";
import usePageContext from "hooks/usePageContext";

import Portal from "containers/Portal";

export default function FloatingActions(props) {
  const [{ depth, isCurrent }] = usePageContext();
  return (
    <Portal>
      <div
        className="fixed left-safe bottom-safe right-safe max-w-2xl mx-auto pointer-events-none"
        style={{ zIndex: 10 + depth }}
      >
        <div
          className={cx("flex space-x-2 justify-flex-end m-4 sm:my-8", {
            "siblings-pointer-events-auto": isCurrent,
          })}
          {...props}
        />
      </div>
    </Portal>
  );
}
