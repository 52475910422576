import React from "react";
import useListContext from "hooks/useListContext";

export default ({ courseOption }) => {
  const [list] = useListContext();

  const participantCount = Math.max(1, list.participants.length);
  const participantIds = list.participants.map((participant) => participant.id);
  const positiveVotes = courseOption.votes.filter((vote) =>
    vote.participantId
      ? participantIds.includes(vote.participantId) && vote.weight === 1
      : participantIds.includes(vote)
  );
  const indifferentVotes = courseOption.votes.filter(
    (vote) =>
      vote.participantId &&
      participantIds.includes(vote.participantId) &&
      vote.weight === 0
  );

  return (
    <div className="flex items-center space-x-4">
      <div className="flex w-full h-1 overflow-hidden rounded-full bg-glow">
        <div
          className="course__bar h-full bg-success "
          style={{
            width: `${(100 * positiveVotes.length) / participantCount}%`,
          }}
        />
        <div
          className="course__bar h-full bg-warning"
          style={{
            width: `${(100 * indifferentVotes.length) / participantCount}%`,
          }}
        />
      </div>
    </div>
  );
};
