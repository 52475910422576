import "./MarketingInfo.scss";
import { Capacitor } from "@capacitor/core";

import React from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";

import appStoreBadgeNL from "images/app-store-badge-nl.svg";
import appStoreBadgeEN from "images/app-store-badge-en.svg";
import googlePlayBadgeNL from "images/google-play-badge-nl.svg";
import googlePlayBadgeEN from "images/google-play-badge-en.svg";

export default function MarketingInfo() {
  const { i18n } = useTranslation();

  return (
    !Capacitor.isNative && (
      <div className="mt-8 text-center space-y-4">
        <a href="https://ikeetmee.nl" className="text-center font-bold">
          <Icons.IkEetMee className="text-secondary" strokeWidth={8} />{" "}
          ikeetmee.nl
        </a>
        {i18n.language === "nl" ? (
          <div className="flex justify-center space-x-4">
            <a
              href="https://apps.apple.com/app/ikeetmee/id1516188174"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="store-image"
                alt="Download in de App Store"
                src={appStoreBadgeNL}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.wouterraateland.ikeetmee"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="store-image"
                alt="Ontdek het op Google Play"
                src={googlePlayBadgeNL}
              />
            </a>
          </div>
        ) : (
          <div className="flex justify-center space-x-4">
            <a
              href="https://apps.apple.com/app/ikeetmee/id1516188174"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="store-image"
                alt="Download on the App Store"
                src={appStoreBadgeEN}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.wouterraateland.ikeetmee"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="store-image"
                alt="Get it on Google Play"
                src={googlePlayBadgeEN}
              />
            </a>
          </div>
        )}
      </div>
    )
  );
}
