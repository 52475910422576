import * as _ from "utils";

import React, { useState } from "react";
import useGroupContext from "hooks/useGroupContext";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";

import withGroup from "hocs/withGroup";

import { PopupModalLayout } from "layouts/Modal";

import { Button, Field } from "components/UI";
import RegularMomentInput from "components/RegularMomentInput";

export default withGroup(({ recurringListId }) => {
  const { t } = useTranslation();
  const [, { onClose }] = usePageContext();
  const [group, groupResource] = useGroupContext();
  const [state, setState] = useState(
    group.regularMoments.find((m) => m.id === recurringListId)
  );

  return (
    <PopupModalLayout>
      <div className="space-y-4">
        <div className="space-y-4 p-4 rounded-md bg-card">
          <h3>{t`frequency.update`}</h3>
          <Field
            label={t`label.title`}
            placeholder={t`group.regularMomentSuggestions`}
            variant="contained"
            size="sm"
            value={state.title}
            onChange={({ target }) =>
              setState((state) => ({ ...state, title: target.value }))
            }
            onBlur={() =>
              setState((state) => ({
                ...state,
                title: state.title?.trim() ?? null,
              }))
            }
          />
          <RegularMomentInput value={state} onChange={setState} />
          <div className="flex justify-flex-end">
            <Button
              color="error"
              variant="text"
              onClick={() => {
                groupResource.deleteRegularMoment(recurringListId);
                onClose();
              }}
            >
              {t`action.delete`}
            </Button>
          </div>
        </div>
        <div className="flex justify-flex-end space-x-4">
          <Button color="card" onClick={onClose}>
            {t`action.cancel`}
          </Button>
          <Button
            color="primary"
            disabled={!_.isValidRegularMoment(state)}
            onClick={() => {
              groupResource.updateRegularMoment(recurringListId, state);
              onClose();
            }}
          >
            {t`action.confirm`}
          </Button>
        </div>
      </div>
    </PopupModalLayout>
  );
});
