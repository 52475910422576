import { isBefore, isSameDay, startOfToday } from "date-fns";

import React, { useCallback, useEffect, useState } from "react";
import { useCollection } from "react-firestore";
import useGroupContext from "hooks/useGroupContext";
import useTranslation from "hooks/useTranslation";

import Calendar from "components/Calendar";

const today = startOfToday();
const now = new Date();
const listArchived = (groupId) => (list) =>
  list.exists &&
  (list.isSetup || list.isShared) &&
  list.groupId === groupId &&
  isBefore(new Date(list.occursAt.date), today);

const taskArchived = (groupId) => (task) =>
  task.exists &&
  task.groupId === groupId &&
  task.isArchived &&
  (task.recurringTaskId || task.isSetup);

export default ({ groupId }) => {
  const listArchivedForGroup = useCallback(listArchived(groupId), [groupId]);
  const taskArchivedForGroup = useCallback(taskArchived(groupId), [groupId]);
  const { t } = useTranslation();
  const lists = useCollection("events", { filter: listArchivedForGroup });
  const tasks = useCollection("tasks", { filter: taskArchivedForGroup });
  const [, groupResource] = useGroupContext();

  const getArchivedTasks = useCallback(
    (endDate) => {
      const recurringTaskInstances = groupResource.getRecurringTaskInstances(
        endDate
      );
      return recurringTaskInstances
        .filter((task) => task.isArchived)
        .reduce(
          (tasks, instance) =>
            tasks.some(
              (task) =>
                task.recurringTaskId === instance.recurringTaskId &&
                isSameDay(task.calendarDate, instance.calendarDate)
            )
              ? tasks
              : [...tasks, instance],
          tasks
        );
    },
    [groupResource, tasks]
  );

  const [archivedTasks, setArchivedTasks] = useState(getArchivedTasks(now));

  useEffect(() => {
    setArchivedTasks(getArchivedTasks(now));
  }, [getArchivedTasks]);

  return lists.length > 0 ? (
    <Calendar
      lists={lists}
      tasks={archivedTasks}
      showGroup={false}
      alwaysShowToday={false}
      fadeOccurred={false}
      direction={-1}
    />
  ) : (
    <p className="p-8 text-center text-caption">{t`dashboard.emptyDay`}</p>
  );
};
