import * as _ from "utils";

import React, { useRef } from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { BaseInput, Button, IconButton } from "components/UI";

export default function ChecklistInput({ value, onChange }) {
  const { t } = useTranslation();
  const containerRef = useRef(null);

  return (
    <div className="space-y-2" ref={containerRef}>
      {value.checklist.map((item, i) => (
        <div className="flex space-x-2" key={i}>
          <BaseInput
            placeholder={t(`task.itemPlaceholder`, { n: i + 1 })}
            value={item}
            onChange={({ target }) =>
              onChange((value) => ({
                ...value,
                checklist: _.replace(value.checklist, i, target.value),
              }))
            }
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                const { target } = event;
                onChange((value) => ({
                  ...value,
                  checklist: [
                    ...value.checklist.slice(0, i + 1),
                    "",
                    ...value.checklist.slice(i + 1),
                  ],
                }));
                setTimeout(() => {
                  const nextInput = target.parentNode.parentNode.parentNode.nextElementSibling.querySelector(
                    "input"
                  );

                  if (nextInput) {
                    nextInput.focus();
                  }
                }, 0);
              }
            }}
            onKeyDown={(event) => {
              const { target } = event;
              if (event.key === "Backspace" && target.value === "" && i > 0) {
                onChange((value) => ({
                  ...value,
                  checklist: [
                    ...value.checklist.slice(0, i),
                    ...value.checklist.slice(i + 1),
                  ],
                }));

                const prevInput = target.parentNode.parentNode.parentNode.previousElementSibling.querySelector(
                  "input"
                );

                if (prevInput) {
                  prevInput.focus();
                }
              }
            }}
          />
          <IconButton
            variant="text"
            onClick={() =>
              onChange((value) => ({
                ...value,
                checklist: value.checklist.filter((_, j) => j !== i),
              }))
            }
          >
            <Icons.Cross />
          </IconButton>
        </div>
      ))}
      <div className="flex justify-flex-end">
        <Button
          color="card"
          size="sm"
          lead={<Icons.Plus />}
          onClick={() => {
            onChange((value) => ({
              ...value,
              checklist: [...value.checklist, ""],
            }));
            setTimeout(() => {
              const stepInputs = containerRef.current.querySelectorAll("input");
              if (stepInputs?.length > 0) {
                stepInputs[stepInputs.length - 1].focus();
              }
            }, 0);
          }}
        >{t`task.addItem`}</Button>
      </div>
    </div>
  );
}
