import * as _ from "utils";

import React, { useLayoutEffect } from "react";
import { GroupContext } from "contexts";
import { useDoc, useQueries } from "react-firestore";
import useGroupRecencyContext from "hooks/useGroupRecencyContext";

import withAuth from "hocs/withAuth";

import NotFoundPage from "pages/404";

const GroupProvider = ({ groupId, children }) => {
  const value = useDoc("groups", groupId);
  const [, setRecentGroupIds] = useGroupRecencyContext();

  useQueries([
    _.tasksCollection.where("groupId", "==", groupId),
    _.expensesCollection.where("groupId", "==", groupId),
    _.settlementsCollection.where("groupId", "==", groupId),
  ]);

  useLayoutEffect(() => {
    return () => {
      setRecentGroupIds((recentGroupIds) =>
        recentGroupIds[0] === groupId
          ? recentGroupIds
          : [groupId, ...recentGroupIds.filter((id) => id !== groupId)]
      );
    };
  }, [groupId, setRecentGroupIds]);

  useLayoutEffect(() => {
    _.setAnalyticsContext({ group_id: groupId });
    return () => {
      _.setAnalyticsContext({ group_id: undefined });
    };
  }, [groupId]);

  return value[0].hasExisted ? (
    <GroupContext.Provider value={value}>{children}</GroupContext.Provider>
  ) : (
    <NotFoundPage />
  );
};

export default withAuth(GroupProvider);
