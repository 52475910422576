import React from "react";
import useCurrentUser from "hooks/useCurrentUser";
import useDeviceToken from "hooks/useDeviceToken";
import useNotifications from "hooks/useNotifications";
import useTranslation from "hooks/useTranslation";

import { Button, Message } from "components/UI";

export default function EnableNotifications() {
  const { t } = useTranslation();
  const [deviceToken] = useDeviceToken();
  const [notifications, { requestPermission }] = useNotifications();
  const [currentUser] = useCurrentUser();

  const receivingMessagesOnOtherDevice =
    currentUser.vapidToken && currentUser.messagingDeviceToken !== deviceToken;

  return (
    <>
      {receivingMessagesOnOtherDevice && (
        <Message color="primary" className="p-4 space-y-4">
          <p>{t`settings.notifications.otherDevice`}</p>
          {["prompt", "default", "granted"].includes(
            notifications.permission
          ) && (
            <div className="flex justify-flex-end">
              <Button
                size="sm"
                color="primary"
                className="w-full sm:w-auto"
                onClick={requestPermission}
              >{t`settings.notifications.receiveHere`}</Button>
            </div>
          )}
        </Message>
      )}
      {notifications.permission === "unsupported" && (
        <Message color="warning" className="p-4">
          <p>{t`settings.notifications.unsupportedMessage`}</p>
        </Message>
      )}
      {notifications.permission === "denied" && (
        <Message color="error" className="p-4">
          <p>{t`settings.notifications.deniedMessage`}</p>
        </Message>
      )}
      {!receivingMessagesOnOtherDevice &&
        ["prompt", "default"].includes(notifications.permission) && (
          <Message color="warning" className="p-4 space-y-4">
            <p>{t`settings.notifications.defaultMessage`}</p>
            <div className="flex justify-flex-end">
              <Button
                size="sm"
                color="warning"
                className="w-full sm:w-auto"
                onClick={requestPermission}
              >{t`settings.notifications.requestPermission`}</Button>
            </div>
          </Message>
        )}
    </>
  );
}
