import { COMMON_PREFERENCES } from "statics.json";
import React, { useCallback } from "react";

import useTranslation from "hooks/useTranslation";

import { WrappingBox } from "components/UI";
import DietaryPreference from "components/DietaryPreference";

export default function DietaryPreferences({
  dietaryInformation,
  onChange,
  isEditable,
  children,
  ...props
}) {
  const { t } = useTranslation();

  const togglePreference = useCallback(
    (name) => {
      onChange(
        dietaryInformation.includes(name)
          ? dietaryInformation.filter((i) => i !== name)
          : dietaryInformation.concat(name)
      );
    },
    [dietaryInformation, onChange]
  );

  return (
    <WrappingBox spacing={2} {...props}>
      {(isEditable
        ? COMMON_PREFERENCES
        : COMMON_PREFERENCES.filter(({ name }) =>
            dietaryInformation.includes(name)
          )
      ).map(({ name, emoji }) => (
        <DietaryPreference
          key={name}
          onClick={isEditable ? () => togglePreference(name) : null}
          isSelected={dietaryInformation.includes(name)}
          emoji={emoji}
          name={t(`preference.${name}`)}
        />
      ))}
      {children}
    </WrappingBox>
  );
}
