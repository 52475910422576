import * as _ from "utils";

import React, { useEffect, useRef, useState } from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { IconButton, ToolTip } from "components/UI";

export default function CopyButton({ value, label, ...props }) {
  const { t } = useTranslation();
  const buttonRef = useRef(null);
  const [state, setState] = useState(null);

  useEffect(() => {
    const t = setTimeout(() => setState(null), 1000);
    return () => clearTimeout(t);
  }, [state]);

  return (
    <>
      <IconButton
        ref={buttonRef}
        {...props}
        onClick={async () => {
          const success = await _.copyTextToClipboard(value);
          setState(success ? "success" : "failed");
        }}
      >
        <Icons.Copy />
      </IconButton>
      <ToolTip originRef={buttonRef}>
        {state === "failed"
          ? t`label.copyFailed`
          : state === "success"
          ? t`label.copySuccessful`
          : label || t`action.copyLink`}
      </ToolTip>
    </>
  );
}
