import * as _ from "utils";

import React from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";

const Visual = ({ src, children }) => (
  <span className="block relative z-0">
    <span
      className=" hidden sm:block absolute right-0 w-48 h-32 rounded-md bg-center bg-cover shadow-sm"
      style={{
        backgroundImage: `url(${src})`,
        zIndex: -1,
        top: "50%",
        transform: "translate(0, -50%)",
      }}
    />
    {children}
  </span>
);

const DishCopy = ({ dish, interactive }) =>
  dish.recipe && interactive ? (
    <a
      href={dish.recipe.url}
      target="_blank"
      rel="noopener noreferrer"
      className="block break-words text-primary max-w-sm"
    >
      {_.formatCourseOption(dish)}{" "}
      <Icons.OutboundLink
        style={{ height: "0.75em", verticalAlign: "middle" }}
      />
    </a>
  ) : (
    _.formatCourseOption(dish)
  );

const Dish = ({ dish, visual, ...props }) =>
  visual && (dish.image || dish.recipe?.image) ? (
    <Visual src={dish.image || dish.recipe?.image}>
      <DishCopy dish={dish} {...props} />
    </Visual>
  ) : (
    <DishCopy dish={dish} {...props} />
  );

export default function CoursesSummary({ list, ...props }) {
  const { t } = useTranslation();

  const courseCount = list?.courses?.length ?? 0;
  const dishCount =
    courseCount === 1 ? list.courses[0].options?.length ?? 0 : 0;

  return courseCount === 0 ? null : courseCount === 1 ? (
    dishCount === 0 ? null : dishCount === 1 ? (
      <Dish dish={list.courses[0].options[0]} {...props} />
    ) : (
      t("form.dish", { count: dishCount })
    )
  ) : (
    t("form.course", { count: courseCount })
  );
}
