import * as _ from "utils";
import cx from "classnames";

import React, { useCallback, useState } from "react";
import useIdle from "hooks/useIdle";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { DateInput, IconButton, TimeInput } from "components/UI";
import CollapsibleContainer from "components/CollapsibleContainer";

export default function ClosesAt({ isEditing }) {
  const { t } = useTranslation();
  const [list, listResource] = useListContext();
  const [date, setDate] = useState(list.closesAt?.date);
  const [time, setTime] = useState(list.closesAt?.time);
  const flush = useCallback(
    () =>
      (date !== listResource.rawData.closesAt?.date ||
        time !== listResource.rawData.closesAt?.time) &&
      listResource.update({ closesAt: date || time ? { date, time } : null }),
    [date, time, listResource]
  );
  const touch = useIdle(flush, 500);

  return (
    <CollapsibleContainer
      icon={(props) => <Icons.Lock {...props} isLocked={list.isClosed} />}
      className="max-w-xs"
      isVisible={Boolean(isEditing || list.closesAt)}
    >
      {isEditing ? (
        <div className="flex items-center space-x-2">
          <DateInput
            className="w-0"
            placeholder={t`list.closesAt`}
            required
            value={date}
            onChange={(date) => {
              setDate(date);
              touch();
            }}
            onClick={
              date
                ? undefined
                : () => {
                    setDate(list.occursAt.date);
                    setTime(list.occursAt.time || "17:00");
                    touch();
                  }
            }
          />
          <div
            className={cx("flex-grow-0", {
              "opacity-0 pointer-events-none": !date,
            })}
          >
            <TimeInput
              placeholder={t`list.time`}
              required
              value={time || ""}
              onChange={(event) => {
                setTime(event.target.value);
                touch();
              }}
            />
          </div>
          <IconButton
            variant="text"
            onClick={() => {
              setDate(null);
              setTime(null);
              touch();
            }}
            className={
              date || time ? undefined : "opacity-0 pointer-events-none"
            }
          >
            <Icons.Cross />
          </IconButton>
        </div>
      ) : list.closesAt ? (
        list.isClosed ? (
          <>
            <p>{t`label.isClosed`}</p>
            <p className="text-xs text-caption">
              {_.capitalize(_.formatDate(list.closesAt))}
            </p>
          </>
        ) : (
          <p>{_.capitalize(_.formatDate(list.closesAt))}</p>
        )
      ) : (
        <p className="text-caption">{t`list.closesAt`}</p>
      )}
    </CollapsibleContainer>
  );
}
