import cx from "classnames";

import React, { useEffect, useState } from "react";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";

import { Field, Toggle } from "components/UI";
import CollapsibleContainer from "components/CollapsibleContainer";

const permissions = [
  "allowPlusOnes",
  "showGuestList",
  "everyoneCanManageParticipants",
];

export default function ListSettings({ className }) {
  const { t } = useTranslation();
  const [list, listResource] = useListContext();
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!list.showGuestList && list.everyoneCanManageParticipants) {
      listResource.update({ everyoneCanManageParticipants: false });
    }
  }, [listResource, list.showGuestList, list.everyoneCanManageParticipants]);

  return (
    <CollapsibleContainer
      isVisible
      className={cx("p-2", { "cursor-pointer": !isExpanded }, className)}
      offset={16}
    >
      <div
        onClick={(event) => {
          event.stopPropagation();
          setExpanded((isExpanded) => !isExpanded);
        }}
      >
        {isExpanded ? (
          <div className="space-y-4">
            <p className="text-xs text-caption">{t`list.permissions.title`}</p>
            {permissions.map((permission) => (
              <div
                key={permission}
                onClick={(event) => event.stopPropagation()}
              >
                <Field
                  type="checkbox"
                  required
                  label={t(`list.permissions.${permission}`)}
                  labelLocation="inline-reverse"
                  component={Toggle}
                  checked={list[permission]}
                  onChange={() =>
                    listResource.update({ [permission]: !list[permission] })
                  }
                />
              </div>
            ))}
          </div>
        ) : (
          <p className="text-xs text-caption">
            {t`list.permissions.title`}{" "}
            {permissions.some((permission) => list[permission])
              ? permissions
                  .filter((permission) => list[permission])
                  .map((permission) => t(`list.permissions.${permission}`))
                  .join(" • ")
              : t`list.permissions.none`}
          </p>
        )}
      </div>
    </CollapsibleContainer>
  );
}
