import * as _ from "utils";

import React, { useState } from "react";
import useAuth from "hooks/useAuth";
import useTranslation from "hooks/useTranslation";
import { useFormState } from "react-use-form-state";

import * as Icons from "components/Icons";
import { Button, Field, IconButton, Message } from "components/UI";

const getErrorField = (errorCode) => {
  switch (errorCode) {
    case "auth/user-not-found":
      return "email";
    case "auth/wrong-password":
      return "password";
    default:
      return null;
  }
};

const LoginForm = () => {
  const { t } = useTranslation();
  const [formState, { email, password }] = useFormState();
  const [error, setError] = useState(null);
  const [
    ,
    { signInWithApple, signInWithGoogle, signInWithFacebook, signInWithEmail },
    support,
  ] = useAuth();

  const onSubmit = async (event) => {
    event.preventDefault();
    await signInWithEmail(formState.values);
  };

  const safe = (f) => async (...args) => {
    try {
      await f(...args);
    } catch (e) {
      setError(e);
    }
  };

  const errorMessage = error && t(`errors.${error.code}`);
  const errorField = error && getErrorField(error.code);

  return (
    <div className="space-y-4">
      <form onSubmit={safe(onSubmit)} className="space-y-4">
        {error && errorField === null && (
          <Message color="error" className="p-4">
            {t(`errors.${error.code}`)}
          </Message>
        )}
        <Field
          variant="contained"
          size="sm"
          label={t`auth.emailAddress`}
          required
          {...email("email")}
          error={errorField === "email" && errorMessage}
        />
        <Field
          variant="contained"
          size="sm"
          label={t`auth.password`}
          required
          {...password("password")}
          error={errorField === "password" && errorMessage}
        />
        <div className="flex items-center justify-flex-end space-x-4">
          <Button
            variant="text"
            color="primary"
            type="button"
            onClick={() => _.navigate("/auth/amnesia")}
          >{t`auth.forgotPassword`}</Button>
          <Button
            type="submit"
            color="primary"
            disabled={!formState.values.email || !formState.values.password}
          >
            {t`auth.signIn`}
          </Button>
        </div>
      </form>
      <p className="text-center">{t`auth.orSignInWith`}</p>
      <div className="flex justify-center space-x-4">
        {support.methods.includes("apple.com") && (
          <IconButton color="text" onClick={safe(signInWithApple)}>
            <Icons.Apple className="mt--1" />
          </IconButton>
        )}
        <IconButton color="facebook" onClick={safe(signInWithFacebook)}>
          <Icons.Facebook />
        </IconButton>
        <IconButton color="white" onClick={safe(signInWithGoogle)}>
          <Icons.Google />
        </IconButton>
      </div>
    </div>
  );
};

export default () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-space-between h-full space-y-4 p-4">
      <a href="https://ikeetmee.nl" className="font-bold m-safe">
        <Icons.IkEetMee className="text-secondary" strokeWidth={8} />{" "}
        ikeetmee.nl
      </a>
      <div className="space-y-4 w-full max-w-md">
        <h1>{t`auth.signIn`}</h1>
        <LoginForm />
      </div>
      <p className="rounded-md text-center w-full max-w-xs p-4 bg-card shadow-sm">
        {t`auth.haveNoAccount`}{" "}
        <span
          className="inline-block text-primary"
          tabIndex={0}
          onClick={() => _.navigate("/auth/register")}
        >
          {t`auth.createAccountNow`}
        </span>
      </p>
    </div>
  );
};
