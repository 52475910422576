import { COMMON_PREFERENCES } from "statics.json";
import * as _ from "utils";

import React from "react";
import useTranslation from "hooks/useTranslation";
import useUsers from "hooks/useUsers";

const countPreferences = (users) =>
  Object.entries(
    users.reduce(
      (acc, { preferences }) =>
        preferences
          ? preferences.reduce(
              (ps, p) => ({ ...ps, [p]: (ps[p] || 0) + 1 }),
              acc
            )
          : acc,
      {}
    )
  )
    .map(([preference, count]) => ({
      count,
      emoji: COMMON_PREFERENCES.find((p) => p.name === preference)?.emoji,
      preference,
    }))
    .sort(_.firstBy((preference) => preference.count, -1));

export default function DietaryPreferencesSummary({ userContainers }) {
  const { t } = useTranslation();
  const users = useUsers(userContainers);
  const preferences = countPreferences(users);

  return (
    <p className="flex flex-wrap wrapping-box--2 collapse text-xs">
      {preferences.map(({ count, preference, emoji }) => (
        <span
          key={preference}
          className="rounded-full pl-1 pr-2 bg-glow text-text"
        >
          <span
            role="img"
            className="text-text"
            aria-label={t(`preference.${preference}`)}
          >
            {emoji}
          </span>{" "}
          {t(`preference.${preference}`)} x{count}
        </span>
      ))}
    </p>
  );
}
