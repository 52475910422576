import React from "react";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";

import withList from "hocs/withList";

import SimpleLayout from "layouts/Simple";

import Participants from "components/List/Participants";

export default withList(() => {
  const { t } = useTranslation();
  const [list] = useListContext();

  return (
    <SimpleLayout
      title={t`list.participants.title`}
      parentLocation={`/l/${list.id}`}
    >
      <Participants />
    </SimpleLayout>
  );
});
