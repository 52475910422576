import * as _ from "utils";
import i18n from "i18next";
import * as Sentry from "@sentry/browser";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import FirebaseBaseResource from "./FirebaseBaseResource";

const refreshUser = async (uid) => {
  try {
    await _.db.runTransaction(async (tx) => {
      const userDocRef = _.usersCollection.doc(uid);
      const userDoc = await tx.get(userDocRef);
      if (!userDoc.exists) {
        const email = _.getUserField("email");
        await tx.set(userDocRef, {
          uid,
          name: _.getUserField("displayName"),
          email: email,
          avatar: _.getUserField("photoURL"),
          preferences: [],
          userIdentifiers:
            typeof email === "string" ? [uid, _.md5(email)] : [uid],
          settings: {
            preferredLanguage: i18n.language,
            notifications: {
              whenAddedToList: true,
              whenRequiredChoice: true,
            },
          },
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      } else {
        const data = userDoc.data();
        const email = _.getUserField("email");
        const userIdentifiers =
          typeof email === "string" ? [uid, _.md5(email)] : [uid];
        if (data.userIdentifiers !== userIdentifiers) {
          await tx.update(userDocRef, { userIdentifiers });
        }
      }
    });
  } catch (error) {
    console.log(error);
  }
};

class AuthResource extends FirebaseBaseResource {
  refresh() {
    const user = _.auth.currentUser;
    const phoneNumber = parsePhoneNumberFromString(user?.phoneNumber || "");
    const userIdentifiers = user
      ? [
          user.uid,
          typeof user.email === "string" ? _.md5(user.email) : null,
          phoneNumber &&
          phoneNumber.isValid() &&
          typeof phoneNumber.number === "string"
            ? _.md5(phoneNumber.number)
            : null,
        ].filter(Boolean)
      : ["__unused_user_identifier__"];
    this.onNext({
      currentUser: { ...user, userIdentifiers },
      isAuthenticated: !!user,
      userIdentifiers,
    });
  }
  listenContinuously() {
    return _.auth.onIdTokenChanged(
      async (user) => {
        if (typeof gtag === "function") {
          window.gtag("config", "G-6WQ4EZWBSG", {
            user_id: user?.uid,
          });
        }
        Sentry.setUser({ id: user?.uid });

        if (user) {
          await refreshUser(user.uid);
        }

        this.refresh();
      },
      (error) => this.onError(error),
      () => this.onComplete()
    );
  }
}

export default AuthResource;
