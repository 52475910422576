import * as _ from "utils";
import { Plugins } from "@capacitor/core";

import { useEffect } from "react";

const { App } = Plugins;

const DeepLinkObserver = () => {
  useEffect(() => {
    App.addListener("appUrlOpen", (data) => {
      const slug = data.url.split(".nl").pop();
      if (slug) {
        _.navigate(slug, { replace: true });
      }
    });
  }, []);

  return null;
};

export default DeepLinkObserver;
