import * as _ from "utils";

import React from "react";
import useCurrentUser from "hooks/useCurrentUser";
import useTranslation from "hooks/useTranslation";

import withAuth from "hocs/withAuth";

import SimpleLayout from "layouts/Simple";

import * as Icons from "components/Icons";
import { Button } from "components/UI";
import FinancialIcon from "components/Transactions/FinancialIcon";

export default withAuth(() => {
  const { t } = useTranslation();
  const [currentUser] = useCurrentUser();

  return (
    <SimpleLayout
      title={t`settings.payments.title`}
      prevLabel={t`settings.title`}
    >
      <div className="space-y-4">
        {currentUser.paymentAddresses?.length > 0 ? (
          <p>{t`settings.payments.description`}</p>
        ) : (
          <div className="py-4 space-y-4">
            <div className="mx-auto w-24 h-24 accent-warning text-warning flex items-center justify-center rounded-full">
              <Icons.Empty size={3} />
            </div>
            <p className="text-center text-caption">{t`settings.payments.empty`}</p>
          </div>
        )}
        {currentUser.paymentAddresses?.map((address) => (
          <div
            key={address.id}
            className="flex justify-space-between items-center p-4 shadow-sm rounded-md btn--card cursor-pointer"
            onClick={() =>
              _.pushModal(`/settings/payment-address/${address.id}`)
            }
          >
            <div>
              <div className="flex items-center space-x-2 text-caption">
                <FinancialIcon type={address.type} size={0.75} />
                <p className="text-xs">
                  {t(`settings.payments.${address.type}`)}
                </p>
              </div>
              <p>
                {_.paymentAddresses
                  .find(({ type }) => type === address.type)
                  ?.formatText(address.value)}
              </p>
            </div>
            <Icons.Caret direction="right" className="text-caption" />
          </div>
        ))}
        <div className="flex justify-flex-end">
          <Button
            color="primary"
            size="sm"
            className="w-full sm:w-auto"
            onClick={() => _.pushModal(`/settings/new-payment-address`)}
          >{t`settings.payments.addAddress`}</Button>
        </div>
      </div>
    </SimpleLayout>
  );
});
