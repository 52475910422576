import React from "react";
import useTranslation from "hooks/useTranslation";

import { DropDown } from "components/UI";

export default ({ value, onChange, nth }) => {
  const { t, i18n } = useTranslation();

  const options = Object.values(
    (i18n.getDataByLanguage(i18n.language) || i18n.getDataByLanguage("en"))
      .translation.list.courses.type
  ).map((value) => ({ value, label: value }));

  return (
    <span className="flex course__title__bg">
      <DropDown
        variant="bare"
        emphasis
        placeholder={t(`list.courses.nth.${nth}`)}
        value={value}
        onChange={onChange}
        options={options}
      />
    </span>
  );
};
