import React from "react";
import { NavigationContext } from "contexts";

import useNavigation from "hooks/useNavigation";

const Navigation = ({ children }) => {
  const value = useNavigation();
  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export default Navigation;
