import * as _ from "utils";
import { LIST_TYPES } from "statics.json";

import React, { useEffect } from "react";
import useCSSTransition from "hooks/useCSSTransition";
import useListContext from "hooks/useListContext";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";
import useUser from "hooks/useUser";

import withList from "hocs/withList";

import ModalLayout from "layouts/Modal";

import * as Icons from "components/Icons";
import { Button, IconButton } from "components/UI";
import Courses from "components/List/Courses";

const ChoicesModal = ({ participantId }) => {
  const { t } = useTranslation();
  const [{ isOpen, timeout }, { onClose }] = usePageContext();
  const [list] = useListContext();

  const messageRef = useCSSTransition(isOpen, { appear: true, timeout });
  const coursesRef = useCSSTransition(isOpen, { appear: true, timeout });

  const currentParticipant =
    list.participants.find(({ id }) => id === participantId) || null;
  const [currentParticipantUser] = useUser(currentParticipant);

  useEffect(() => {
    if (!currentParticipant) {
      _.popModal(`/l/${list.id}`);
    }
  }, [list.id, currentParticipant]);

  return (
    <ModalLayout>
      <div className="h-screen flex flex-col flex-grow">
        <div className="w-full max-w-2xl mx-auto">
          <div
            className="rounded-md relative z-10 flex items-center p-2 mt-safe space-x-2 bg-card shadow-sm slide-down"
            ref={messageRef}
          >
            <IconButton size="sm" variant="text" onClick={onClose}>
              <Icons.Cross />
            </IconButton>
            <p className="font-bold">
              {t(
                list.type === LIST_TYPES.ORDER
                  ? `form.editingOrderFor`
                  : `form.editingChoicesFor`,
                {
                  name: currentParticipantUser?.name,
                }
              )}
            </p>
          </div>
        </div>
        <div className="flex-grow py-4 overflow-y-auto">
          <div
            ref={coursesRef}
            className="max-w-2xl mx-auto px-4 slide-up space-y-4"
          >
            <Courses currentParticipantId={participantId} />
            <div className="flex justify-flex-end">
              <Button
                color="primary"
                onClick={onClose}
              >{t`action.confirmChoices`}</Button>
            </div>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default withList(ChoicesModal);
