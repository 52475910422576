import { v4 as uuidv4 } from "uuid";

import useLocallyStoredState from "./useLocallyStoredState";

export default function useDeviceToken() {
  return useLocallyStoredState("___deviceToken___", uuidv4(), {
    parse: (x) => x,
    stringify: (x) => x,
  });
}
