import React from "react";
import { Feature, Bugfix } from "./components";

export default (
  <div key={3}>
    <h2 className="text-caption font-black uppercase text-lg">Update 3</h2>
    <ul className="space-y-2">
      <Feature>
        Tasks. In any group, you can now create recurring tasks. These tasks can
        range from doing the dishes to getting new beer. Anything you want to
        hold your groupmates accountable for.
      </Feature>
      <Bugfix>
        On android inviting guests caused the app to crash. That is fixed now,
        and it works faster.
      </Bugfix>
    </ul>
  </div>
);
