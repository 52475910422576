import * as _ from "utils";
import cx from "classnames";
import queryString from "query-string";

import React from "react";
import { useDoc } from "react-firestore";
import useRegularMoment from "hooks/useRegularMoment";

import { Avatar } from "components/UI";

import GroupName from "components/Group/Name";
import ListSummary from "components/List/Summary";

export default ({ list, showGroup = true, fadeOccurred }) => {
  const [group] = useDoc("groups", list.groupId);
  const [regularMoment] = useRegularMoment(list.regularMomentId);

  const hasResponded = _.hasResponded(list.myParticipation);

  const newListWithParams = () => {
    const params = queryString.stringify({
      groupId: list.groupId || null,
      regularMomentId: list.regularMomentId || null,
      title: regularMoment.title || null,
      occursAt_date: list.occursAt.date,
      role: "none",
    });

    _.pushModal(`/new-list/?${params}`);
  };

  return (
    <div
      className={cx(
        "relative p-2 rounded-md cursor-pointer",
        hasResponded
          ? "shadow-sm bg-card"
          : "border border-dashed border-accent",
        {
          "opacity-50": fadeOccurred && list.hasOccurred,
        }
      )}
      onClick={list.id ? () => _.pushModal(`/l/${list.id}`) : newListWithParams}
    >
      <div className="absolute top-0 left-0 right-0 flex space-x-2 mx-2 mt--2 justify-flex-end">
        {group.exists && showGroup && (
          <div
            className="flex items-center min-w-0 space-x-2 pr-2 rounded-full text-xs text-primary accent-primary"
            onClick={() => _.pushModal(`/g/${group.id}`)}
          >
            <Avatar user={{ name: group.name, avatar: group.image }} size={1} />
            <span className="truncate">
              <GroupName group={group} />
            </span>
          </div>
        )}
      </div>
      {list.title && <h3>{list.title}</h3>}
      <ListSummary list={list} showRecurring />
    </div>
  );
};
