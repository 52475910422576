import * as _ from "utils";

import React from "react";
import { useCollection } from "react-firestore";
import useTranslation from "hooks/useTranslation";

import withAuth from "hocs/withAuth";

import SimpleLayout from "layouts/Simple";

import InfiniteList from "components/InfiniteList";
import TransactionThumb from "components/Transactions/Thumb";

const renderTransaction = (transaction) => (
  <TransactionThumb key={transaction.id} transaction={transaction} />
);

const currentUserParticipates = (transaction) =>
  _.isCurrentUser(transaction.from) || _.isCurrentUser(transaction.to);

const byCreatedAt = _.firstBy(
  (transaction) => transaction.createdAt.toMillis(),
  -1
);

export default withAuth(() => {
  const { t } = useTranslation();
  const transactions = useCollection("transactions", {
    filter: currentUserParticipates,
    sort: byCreatedAt,
  });

  return (
    <SimpleLayout title={t`transactions.title`}>
      <div className="space-y-4">
        <InfiniteList items={transactions} renderItem={renderTransaction} />
      </div>
    </SimpleLayout>
  );
});
