import * as _ from "utils";
import React from "react";
import { useCollection } from "react-firestore";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";

const isIncompleteAndCurrentUserParticipates = (transaction) =>
  !transaction.isCompleted &&
  (_.isCurrentUser(transaction.from) || _.isCurrentUser(transaction.to));

export default () => {
  const { t } = useTranslation();
  const openTransactions = useCollection("transactions", {
    filter: isIncompleteAndCurrentUserParticipates,
  });
  const transactionsToPayCount = openTransactions.filter(
    (transaction) => _.isCurrentUser(transaction.from) && !transaction.isPaid
  ).length;
  const transactionsToReceiveCount = openTransactions.filter(
    (transaction) => _.isCurrentUser(transaction.to) && !transaction.isReceived
  ).length;
  const openTransactionCount =
    transactionsToPayCount + transactionsToReceiveCount;

  return (
    openTransactionCount > 0 && (
      <div className="mx-4">
        <button
          className="flex items-center justify-space-between pl-4 p-2 border-0 rounded-md shadow-md btn--primary cursor-pointer text-left w-full"
          onClick={() => _.pushModal("/transactions")}
        >
          <div>
            <p>{t(`transactions.open`, { count: openTransactionCount })}</p>
            <p className="text-xs opacity-75">
              {[
                transactionsToPayCount > 0 &&
                  t(`transactions.toPay`, { count: transactionsToPayCount }),
                transactionsToReceiveCount > 0 &&
                  t(`transactions.toReceive`, {
                    count: transactionsToReceiveCount,
                  }),
              ]
                .filter(Boolean)
                .join(" • ")}
            </p>
          </div>
          <Icons.Caret direction="right" className="opacity-75" />
        </button>
      </div>
    )
  );
};
