import * as _ from "utils";
import cx from "classnames";

import React from "react";
import useTranslation from "hooks/useTranslation";
import useLocallyStoredState from "hooks/useLocallyStoredState";

import * as Icons from "components/Icons";
import { Button } from "components/UI";

export default ({ group }) => {
  const { t } = useTranslation();
  const [skippedSteps, setSkippedSteps] = useLocallyStoredState(
    `ikeetmee_group_${group.id}_setup`,
    []
  );

  const groupSetupSteps = [
    {
      id: "members",
      icon: <Icons.Group className="my-1" />,
      title: t`group_setup.addMembers.title`,
      description: t`group_setup.addMembers.description`,
      action: () => _.pushModal(`/g/${group.id}/members`),
      isCompleted: group.members.length > 1,
    },
    {
      id: "recurring-lists",
      icon: <Icons.Repeat className="my-1" />,
      title: t`group_setup.recurringLists.title`,
      description: t`group_setup.recurringLists.description`,
      action: () => _.pushModal(`/g/${group.id}/settings#recurring-lists`),
      isCompleted: group.regularMoments.length > 0,
    },
    {
      id: "recurring-tasks",
      icon: (
        <div className="flex space-x--3 mr--1 my-1">
          <Icons.Check />
          <Icons.Check />
        </div>
      ),
      title: t`group_setup.recurringTasks.title`,
      description: t`group_setup.recurringTasks.description`,
      action: () => _.pushModal(`/g/${group.id}/settings#recurring-tasks`),
      isCompleted: group.recurringTasks.length > 0,
    },
  ];

  return (
    _.isAdmin(group.myMembership) &&
    groupSetupSteps.some(
      (step) => !step.isCompleted && !skippedSteps.includes(step.id)
    ) && (
      <div className="space-y-2">
        <div className="flex space-x-2 px-4">
          <Icons.Flash className="my-1" />
          <div>
            <strong>{t`group_setup.setup`}</strong>
            <p className="text-xs text-caption">
              {t(`group_setup.stepsLeft`, {
                count: groupSetupSteps.filter(
                  (step) => !step.isCompleted && !skippedSteps.includes(step.id)
                ).length,
              })}
            </p>
          </div>
        </div>
        <div
          className="relative flex space-x-4 px-4 overflow-x-auto fade--horizontal"
          style={{ zIndex: 2 }}
        >
          {groupSetupSteps
            .slice()
            .sort(
              _.firstBy((step) =>
                step.isCompleted || skippedSteps.includes(step.id) ? 1 : 0
              )
            )
            .map((step) => (
              <div
                key={step.id}
                className={cx(
                  "border border-solid border-accent rounded-md flex flex-shrink-0 w-64 space-x-4 px-4 py-2 bg-card",
                  {
                    "opacity-50":
                      step.isCompleted || skippedSteps.includes(step.id),
                  }
                )}
              >
                {step.icon}
                <div className="flex flex-col justify-space-between space-y-4">
                  <div>
                    <p>{step.title}</p>
                    <p className="text-caption text-xs">{step.description}</p>
                  </div>
                  {step.isCompleted ? (
                    <div className="flex items-center justify-flex-end space-x-2">
                      <Icons.Check className="text-success" />
                      <p>{t`label.completed`}</p>
                    </div>
                  ) : skippedSteps.includes(step.id) ? (
                    <div className="flex items-center justify-flex-end space-x-2">
                      <Icons.Cross className="text-error" />
                      <p>{t`label.skipped`}</p>
                    </div>
                  ) : (
                    <div className="flex justify-flex-end space-x-4">
                      <Button
                        variant="text"
                        onClick={() => {
                          setSkippedSteps((skippedSteps) => [
                            ...skippedSteps,
                            step.id,
                          ]);
                          _.track("group_setup_skip", { step_id: step.id });
                        }}
                      >{t`action.skip`}</Button>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={step.action}
                      >
                        {t`action.setupNow`}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          <div className="flex-shrink-0 w-px h-px" />
        </div>
      </div>
    )
  );
};
