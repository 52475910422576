import React from "react";

import SettlementProvider from "containers/SettlementProvider";

const withSettlement = (Component) => (props) => (
  <SettlementProvider settlementId={props.settlementId}>
    <Component {...props} />
  </SettlementProvider>
);

export default withSettlement;
