import cx from "classnames";

import React, { forwardRef } from "react";

import BaseInput from "./BaseInput";

const MaskedInput = ({ placeholder, mask, className, ...props }) => {
  return (
    <>
      <input className={cx("input--masked", className)} {...props} />
      <div className="input__mask input__mask--hidden absolute inset-0">
        {props.value ? (
          <p>{mask}</p>
        ) : (
          <p className="text-caption">{placeholder}</p>
        )}
      </div>
    </>
  );
};

export default forwardRef((props, ref) => (
  <BaseInput ref={ref} component={MaskedInput} {...props} />
));
