import * as _ from "utils";

import React, { useCallback } from "react";
import { useCollection } from "react-firestore";
import useGroupRecencyContext from "hooks/useGroupRecencyContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { IconButton } from "components/UI";
import GroupGraphic from "components/Group/Graphic";
import GroupName from "components/Group/Name";

const Group = ({ group }) => (
  <div
    onClick={() => _.pushModal(`/g/${group.id}`)}
    className="cursor-pointer space-y-2 w-24"
  >
    {group.image ? (
      <div
        className="bg-center bg-cover shadow-sm w-24 h-24 rounded-full"
        style={{ backgroundImage: `url(${group.image})` }}
      />
    ) : (
      <div className="bg-glow w-24 h-24 rounded-full">
        <GroupGraphic group={group} />
      </div>
    )}
    <p className="text-xs text-caption truncate text-center">
      <GroupName group={group} />
    </p>
  </div>
);

const groupExists = (group) => group.isSetup && group.exists;

export default () => {
  const { t } = useTranslation();
  const [recentGroupIds] = useGroupRecencyContext();
  const sortByRecency = useCallback(
    _.firstBy((group) =>
      recentGroupIds.indexOf(group.id) === -1
        ? Infinity
        : recentGroupIds.indexOf(group.id)
    ),
    [recentGroupIds]
  );
  const groups = useCollection("groups", {
    filter: groupExists,
    sort: sortByRecency,
  });

  return (
    <div className="relative" style={{ zIndex: 2 }}>
      <div className="flex justify-space-between items-center p-4">
        <span className="text-2xl font-black">{t`dashboard.groups`}</span>
        <IconButton
          color="card"
          size="sm"
          onClick={() => _.pushModal("/new-group")}
        >
          <Icons.Plus />
        </IconButton>
      </div>
      {groups.length > 0 ? (
        <div className="flex items-center space-x-4 px-4 pb-4 overflow-x-auto">
          {groups.map((group, i) => (
            <Group key={i} group={group} />
          ))}
          <div className="w-px h-4 flex-shrink-0" />
        </div>
      ) : (
        <p className="px-4 pb-4 text-caption">{t`label.noGroupsYet`}</p>
      )}
    </div>
  );
};
