import * as _ from "utils";
import { APN_WEBSITE_PUSH_ID, APN_WEBSITE_SERVICE_URL } from "statics.json";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";

import FirebaseBaseResource from "./FirebaseBaseResource";

import { Capacitor, Plugins, PermissionType } from "@capacitor/core";

const { Device, Permissions, PushNotifications } = Plugins;

const getMessagingProvider = async () =>
  typeof window === "undefined"
    ? null
    : Capacitor.isPluginAvailable("PushNotifications")
    ? (await Device.getInfo()).platform === "ios"
      ? "apn-ios"
      : "capacitor"
    : firebase.messaging && firebase.messaging.isSupported()
    ? "fcm"
    : "safari" in window && "pushNotification" in window.safari
    ? "apn"
    : null;

export default class MessagingResource extends FirebaseBaseResource {
  token = null;
  messages = [];
  messagingProvider;
  permission = "pending";

  constructor() {
    super();

    this.initialize();
  }

  async initialize() {
    this.messagingProvider = await getMessagingProvider();

    switch (this.messagingProvider) {
      case "capacitor":
      case "apn-ios":
        this.permission = "undefined";
        Permissions.query({
          name: PermissionType.Notifications,
        }).then((response) => {
          this.permission = response.state;
        });
        break;
      case "fcm":
        this.permission =
          typeof Notification === "undefined"
            ? "unsupported"
            : Notification.permission;
        this.listenOnce();
        break;
      case "apn":
        const permissionData = window.safari.pushNotification.permission(
          APN_WEBSITE_PUSH_ID
        );
        this.token = permissionData.deviceToken;
        this.permission = permissionData.permission;
        break;
      default:
        this.permission = "unsupported";
        break;
    }
  }

  onNext() {
    super.onNext({
      permission: this.permission,
      token: this.token,
      messages: this.messages,
      messagingProvider: this.messagingProvider,
    });
  }

  listenContinuously() {
    this.listenOnce();

    switch (this.messagingProvider) {
      case "capacitor":
      case "apn-ios": {
        const unsubscribeFromRegistration = PushNotifications.addListener(
          "registration",
          (token) => {
            console.log(token);
            this.token = token.value;
            this.onNext();
          }
        );

        const unsubscribeFromRegistrationErrors = PushNotifications.addListener(
          "registrationError",
          (error) => {
            console.log(error);
            this.permission = "unsupported";
          }
        );

        if (this.permission === "granted" && !this.token) {
          PushNotifications.register();
        }

        return () => {
          unsubscribeFromRegistration();
          unsubscribeFromRegistrationErrors();
        };
      }
      case "fcm": {
        const unsubscribeFromTokenRefresh = _.messaging.onTokenRefresh(
          async () => {
            try {
              this.token = await _.messaging.getToken();
              this.onNext();
            } catch (error) {
              this.onError(error);
            }
          },
          (error) => this.onError(error),
          () => this.onComplete()
        );

        const unsubscribeFromMessage = _.messaging.onMessage((payload) => {
          console.log("message received", payload);
          this.showMessage(payload);
        });

        return () => {
          unsubscribeFromTokenRefresh();
          console.log("Not listening to messages anymore");
          unsubscribeFromMessage();
        };
      }

      default:
        return () => {};
    }
  }

  async listenOnce() {
    switch (this.messagingProvider) {
      case "fcm":
        try {
          this.token =
            typeof Notification !== "undefined" &&
            Notification.permission === "granted"
              ? await _.messaging.getToken()
              : null;
          this.onNext();
        } catch (error) {
          this.onError(error);
        }
        break;

      default:
        this.onNext();
        break;
    }
  }

  async requestNotificationPermission(userId) {
    try {
      switch (this.messagingProvider) {
        case "capacitor":
        case "apn-ios":
          {
            const result = await PushNotifications.requestPermission();
            this.permission = result.granted ? "granted" : "denied";
            if (result.granted) {
              PushNotifications.register();
            }
          }
          break;

        case "fcm":
          await Notification.requestPermission();
          this.permission = Notification.permission;
          break;

        case "apn":
          {
            const permissionData = await new Promise((resolve) => {
              window.safari.pushNotification.requestPermission(
                APN_WEBSITE_SERVICE_URL,
                APN_WEBSITE_PUSH_ID,
                { userId },
                resolve
              );
            });

            this.permission = permissionData.permission;
            if (permissionData.permission === "granted") {
              this.token = permissionData.deviceToken;
            }
          }
          break;

        default:
          break;
      }
    } catch (error) {
      this.onError(error);
    }
    this.listenOnce();
  }

  async deleteToken() {
    switch (this.messagingProvider) {
      case "fcm":
        try {
          const currentToken = await _.messaging.getToken();
          try {
            await _.messaging.deleteToken(currentToken);
          } catch (error) {
            console.log(error);
          }
          this.token = null;
          this.onNext();
        } catch (error) {
          this.onError(error);
        }
        break;

      default:
        break;
    }
  }

  showMessage(payload, timeout = 3000) {
    const id = uuidv4();
    this.messages = [
      ...this.messages,
      {
        id,
        payload,
      },
    ];
    if (timeout > 0) {
      setTimeout(() => this.closeMessage(id), timeout);
    }
    this.onNext();
  }

  closeMessage(messageId) {
    this.messages = this.messages.filter((message) => message.id !== messageId);
    this.onNext();
  }
}
