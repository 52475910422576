import * as _ from "utils";

import { useCallback, useMemo } from "react";
import { useDoc, useCollection } from "react-firestore";

const processList = (members, list) => {
  const participatingMembers = list.participants.filter(
    _.and(_.isParticipant, (participant) =>
      members.some(_.isSameUser(participant))
    )
  );
  const cookingMembers = list.participants.filter(
    _.and(_.isChef, (participant) => members.some(_.isSameUser(participant)))
  );

  return cookingMembers.length > 0 && participatingMembers.length > 0
    ? members.map((member) => {
        const isChef = cookingMembers.some(_.isSameUser(member));
        const isParticipant = participatingMembers.some(_.isSameUser(member));

        return {
          ...member,
          statistics: {
            ...member.statistics,
            cookBalance:
              member.statistics.cookBalance +
              (isChef
                ? participatingMembers.length / cookingMembers.length
                : 0) -
              (isParticipant ? 1 : 0),
            chefCount: member.statistics.chefCount + (isChef ? 1 : 0),
            participationCount:
              member.statistics.participationCount + (isParticipant ? 1 : 0),
          },
        };
      })
    : members;
};

const processExpense = (members, expense) =>
  expense.parts > 0
    ? members.map((member) => ({
        ...member,
        statistics: {
          ...member.statistics,
          monetaryBalance:
            member.statistics.monetaryBalance +
            (_.isSameUser(member)({
              userIdentifiers: expense.creditorUserIdentifiers,
            })
              ? expense.cents
              : 0) -
            (expense.cents *
              (expense.debtors.find(_.isSameUser(member))?.parts || 0)) /
              expense.parts,
        },
      }))
    : members;

const withStatistics = (members) =>
  members.map((member) => ({
    ...member,
    statistics: {
      monetaryBalance: 0,
      cookBalance: 0,
      chefCount: 0,
      participationCount: 0,
    },
  }));

const processLists = (lists) => (members) => lists.reduce(processList, members);
const processExpenses = (expenses) => (members) =>
  expenses.reduce(processExpense, members);

const listExists = (groupId) => (list) =>
  list.exists && (list.isSetup || list.isShared) && list.groupId === groupId;

const byCreatedAt = _.firstBy((entity) => entity.createdAt.toMillis(), -1);

export default function useGroupStatistics(groupId) {
  const [group] = useDoc("groups", groupId);

  const listExistsForGroup = useCallback(listExists(groupId), [groupId]);
  const lists = useCollection("events", { filter: listExistsForGroup });

  const isRelatedAndUnsettled = useCallback(
    (expense) =>
      expense.exists && !expense.isSettled && expense.groupId === groupId,
    [groupId]
  );
  const expenses = useCollection("expenses", {
    filter: isRelatedAndUnsettled,
    sort: byCreatedAt,
  });

  return useMemo(
    () =>
      group.exists
        ? _.pipe(
            withStatistics,
            processLists(lists),
            processExpenses(expenses)
          )(group.members)
        : [],
    [group, lists, expenses]
  );
}
