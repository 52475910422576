import { useEffect, useState } from "react";

import reactFirestore from "./ReactFirestore";

const useDoc = (collection, docIdentifier) => {
  const [, forceUpdate] = useState();

  useEffect(
    () => reactFirestore.observeDoc(collection, docIdentifier, forceUpdate),
    [collection, docIdentifier]
  );

  const doc = reactFirestore.readDoc(collection, docIdentifier);
  const model = reactFirestore.getModel(collection, doc.id);

  return [doc, model];
};

export default useDoc;
