import { PARTICIPANT_ROLES } from "statics.json";
import * as _ from "utils";

import React, { useCallback } from "react";
import { useDoc } from "react-firestore";
import useCurrentUser from "./useCurrentUser";
import useListContext from "./useListContext";
import useTranslation from "./useTranslation";

import * as Icons from "components/Icons";

export default function useParticipantUtils() {
  const { t } = useTranslation();
  const [currentUser, currentUserResource] = useCurrentUser();
  const [list, listResource] = useListContext();
  const [group] = useDoc("groups", list.groupId);

  const getActions = useCallback(
    (participant, parentParticipantId) =>
      [
        {
          isVisible:
            _.canEdit(list) &&
            Array.isArray(participant.roles) &&
            !(
              group?.exists &&
              participant.userIdentifiers &&
              group.members.some(_.isSameUser(participant))
            ),
          icon: <Icons.Bin />,
          label:
            participant?.roles?.length > 0
              ? t`action.delete`
              : t`action.rejectInvitation`,
          color: "error",
          onAct: () => listResource.deleteParticipant(participant.id),
        },
        {
          isVisible:
            _.canEdit(list) &&
            participant.userIdentifiers?.length > 0 &&
            !Array.isArray(participant.roles),
          icon: <Icons.Share />,
          label: t`action.invite`,
          onAct: async () => {
            await listResource.respond({
              userContainer: participant,
              response: null,
              parentId: parentParticipantId,
              showChoices: list.containsCoursesWithInteraction,
            });
            // if (participant.userIdentifiers) {
            //   await listResource.sendInviteMessage(participant);
            // }

            _.track("participant_invite");
          },
        },
        {
          isVisible:
            list.groupId &&
            list.allowPlusOnes &&
            _.isParticipant(participant) &&
            !participant.parentId,
          icon: <Icons.Plus />,
          label: t`action.plusOne`,
          onAct: () => _.pushModal(`/l/${list.id}/invite/${participant.id}`),
        },
        {
          isVisible:
            _.canEdit(list) &&
            list.containsCoursesWithInteraction &&
            _.isParticipant(participant),
          icon: <Icons.Pizza />,
          label: participant.hasChosen
            ? t`action.editChoices`
            : t`action.addChoices`,
          onAct: () => {
            _.pushModal(`/l/${list.id}/choose/${participant.id}`);
            _.track("event_choose_other_user");
          },
        },
        {
          isVisible:
            _.canEdit(list) &&
            participant.userIdentifiers?.length > 0 &&
            !_.isNonParticipant(participant),
          icon: <Icons.Cross />,
          label: t`action.doesntJoin`,
          onAct: async () => {
            await listResource.respond({
              userContainer: participant,
              response: PARTICIPANT_ROLES.NON_EATER,
              parentId: parentParticipantId,
              showChoices: list.containsCoursesWithInteraction,
            });

            _.track("participant_doesnt_join");
          },
        },
        {
          isVisible: _.canEdit(list) && !_.isParticipant(participant),
          icon: <Icons.Cutlery />,
          label: t`action.joins`,
          onAct: async () => {
            await listResource.respond({
              userContainer: participant,
              response: PARTICIPANT_ROLES.EATER,
              parentId: parentParticipantId,
              showChoices: list.containsCoursesWithInteraction,
            });
            if (participant.userIdentifiers) {
              await listResource.sendParticipationMessage([participant]);
            }

            _.track("participant_joins");
          },
        },
        {
          isVisible: !participant.userIdentifiers,
          icon: <Icons.Person />,
          label: t`action.claimParticipant`,
          onAct: async () => {
            if (!currentUser.name) {
              currentUserResource.update({ name: participant.name });
            }
            await listResource.deleteParticipant(list.myParticipation?.id);
            await listResource.setParticipant(participant.id, (pp) => ({
              ...pp,
              ...list.myParticipation,
            }));
            _.track("participant_claim");
          },
        },
        {
          isVisible: _.canEdit(list) && _.isChef(participant),
          icon: <Icons.Undo />,
          label: t`action.unmakeChef`,
          onAct: () => {
            listResource.setParticipant(participant.id, (p) => ({
              ...p,
              roles: (p.roles || []).filter(
                (role) => role !== PARTICIPANT_ROLES.CHEF
              ),
            }));
            _.track("participant_doesnt_cook");
          },
        },
        {
          isVisible: _.canEdit(list) && !_.isChef(participant),
          icon: <Icons.Chef />,
          label: t`action.makeChef`,
          onAct: () => {
            listResource.respond({
              userContainer: participant,
              response: PARTICIPANT_ROLES.CHEF,
              parentId: parentParticipantId,
              showChoices: list.containsCoursesWithInteraction,
            });
            _.track("participant_cooks");
          },
        },
        {
          isVisible:
            participant.id &&
            participant.userIdentifiers.length <= (participant.email ? 1 : 0),
          icon: <Icons.Diet />,
          label: t`action.editDietaryPreferences`,
          onAct: () =>
            _.pushModal(`/l/${list.id}/preferences/${participant.id}`),
        },
      ].filter((p) => p.isVisible),
    [t, currentUser, list, group, listResource, currentUserResource]
  );

  const getStatusBadge = useCallback(
    (participant) =>
      list.containsCoursesWithRequiredInteraction &&
      _.isParticipant(participant)
        ? participant.hasChosen
          ? {
              color: "success",
              icon: Icons.Check,
              label: t`label.choiceMade`,
            }
          : {
              color: "warning",
              icon: Icons.Maybe,
              label: t`label.noChoiceYet`,
            }
        : null,
    [list.containsCoursesWithRequiredInteraction, t]
  );

  const getRoleBadge = useCallback(
    (participant) =>
      _.isChef(participant)
        ? {
            color: "primary",
            icon: Icons.Chef,
            label: t`participationStatus.cooks`,
          }
        : _.isParticipant(participant)
        ? {
            color: "success",
            icon: Icons.Check,
            label: t`participationStatus.joins`,
          }
        : _.isNonParticipant(participant)
        ? {
            color: "error",
            icon: Icons.Cross,
            label: t`participationStatus.doesntJoin`,
          }
        : participant && Array.isArray(participant.roles)
        ? {
            color: "warning",
            icon: Icons.Maybe,
            label: t`participationStatus.invited`,
          }
        : null,
    [t]
  );

  return {
    getActions,
    getStatusBadge,
    getRoleBadge,
  };
}
