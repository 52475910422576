import * as _ from "utils";

import React, { forwardRef } from "react";
import useRecurringTask from "hooks/useRecurringTask";
import useTranslation from "hooks/useTranslation";
import useUsers from "hooks/useUsers";

import * as Icons from "components/Icons";
import { Avatar } from "components/UI";
import Concatenation from "components/Concatenation";
import UserName from "components/User/Name";

export default forwardRef(function TaskSummary(
  { task, showRecurring, className },
  ref
) {
  const { t } = useTranslation();
  const [recurringTask] = useRecurringTask(task.recurringTaskId);
  const assigneeUsers = useUsers(task.assignees);

  return (
    <div ref={ref} className={className}>
      <p>{task.title}</p>
      <div className="flex space-x-2 items-center text-xs overflow-hidden whitespace-no-wrap">
        {showRecurring && recurringTask.exists && (
          <Icons.Repeat strokeWidth={2} size={0.75} className="opacity-75" />
        )}
        {assigneeUsers.length > 0 ? (
          <div className="flex items-center space-x-1">
            <div className="flex space-x--1">
              {assigneeUsers.map((user) => (
                <Avatar key={user.id} user={user} size={1} />
              ))}
            </div>
            <p className="opacity-75">
              <Concatenation
                items={assigneeUsers.map((user) =>
                  _.isCurrentUser(user) ? (
                    _.capitalize(t`label.I`)
                  ) : (
                    <UserName key={user.id} user={user} />
                  )
                )}
                connective={t`common.and`}
              />
            </p>
          </div>
        ) : (
          <p className="opacity-75">{t`task.noAssignees`}</p>
        )}
        {task.checklist?.length > 0 && (
          <div className="flex items-center space-x-1 opacity-75">
            <Icons.Check />
            <span>
              {task.id
                ? task.checklist.filter((item) => item.isChecked).length
                : 0}
              /{task.checklist.length}
            </span>
          </div>
        )}
        {task.comments?.length > 0 && (
          <span className="flex items-center space-x-1">
            <Icons.Comment size={0.75} />
            <span>{task.comments?.length}</span>
          </span>
        )}
      </div>
    </div>
  );
});
