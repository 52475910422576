import "./Simple.scss";

import * as _ from "utils";
import cx from "classnames";

import React, { Suspense, useCallback, useRef } from "react";
import useCSSTransition from "hooks/useCSSTransition";
import usePageContext from "hooks/usePageContext";
import usePageScroll from "hooks/usePageScroll";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Button, NavBar } from "components/UI";
import SEO from "components/SEO";

const HeaderContent = ({
  parentLocation = "/",
  title,
  prev,
  prevLabel,
  onPrev,
  headerAction,
}) => {
  const { t } = useTranslation();

  const titleRef = useRef(null);
  const onScroll = useCallback((scrollTop) => {
    const title = titleRef.current;
    if (title) {
      title.style.opacity = scrollTop / 48;
    }
  }, []);
  usePageScroll(onScroll);

  return (
    <div className="flex items-center justify-space-between p-2 space-x-4">
      <div className="flex items-center space-x-4 min-w-0">
        {prev || (
          <Button
            variant="text"
            color="primary"
            spacing={0}
            importance="tertiary"
            lead={<Icons.Caret direction="left" size={0.75} />}
            onClick={onPrev || (() => _.popModal(parentLocation))}
          >
            {prevLabel || t`label.back`}
          </Button>
        )}
        {title &&
          (typeof title === "string" ? (
            <p ref={titleRef} className="font-bold truncate min-w-0">
              {title}
            </p>
          ) : (
            title
          ))}
      </div>
      {headerAction}
    </div>
  );
};

const Header = ({ Components, ...props }) => (
  <NavBar>
    <Components.HeaderContent {...props} />
  </NavBar>
);

const Body = ({ className, ...props }) => (
  <div
    className={cx(
      "simple-layout__body w-full max-w-2xl mx-auto px-4",
      className
    )}
    {...props}
  />
);

const defaultComponents = {
  Header,
  HeaderContent,
  Body,
};

export { defaultComponents as components };

export default function SimpleLayout({
  children,
  components,
  hideTitle,
  ...props
}) {
  const [{ isOpen, timeout }] = usePageContext();
  const Components = { ...defaultComponents, ...components };

  const layoutRef = useCSSTransition(isOpen, { timeout, appear: true });

  return (
    <div
      ref={layoutRef}
      className="simple-layout shift-in min-h-full bg-background"
    >
      <Suspense fallback={<div className="loader absolute inset-0 m-auto" />}>
        <SEO title={props.title} />
        <Components.Header {...props} Components={Components} />
        <Components.Body>
          {!hideTitle && props.title && (
            <>
              <h1>{props.title}</h1>
              <div className="h-4" />
            </>
          )}
          {children}
        </Components.Body>
      </Suspense>
    </div>
  );
}
