import "./Setup.scss";
import cx from "classnames";

import React from "react";

import SimpleLayout from "./Simple";

const Header = () => null;
const Body = ({ className, ...props }) => (
  <div
    className={cx("setup-layout__body w-full max-w-2xl mx-auto", className)}
    {...props}
  />
);

const SetupLayout = ({ components, ...props }) => (
  <SimpleLayout
    components={{ Header, Body, ...components }}
    hideTitle
    {...props}
  />
);

export default SetupLayout;
