import "./AssigneeOrder.scss";
import cx from "classnames";

import React, { useCallback, useEffect, useRef } from "react";
import useGroupContext from "hooks/useGroupContext";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import UserDetail from "components/User/Detail";

export default ({ value, onChange }) => {
  const draggablesRef = useRef({});
  const draggedMemberIdRef = useRef(null);
  const [{ pageRef }] = usePageContext();
  const [group] = useGroupContext();
  const { t } = useTranslation();

  const onScroll = useCallback(() => {
    const draggedMemberId = draggedMemberIdRef.current;
    const draggables = draggablesRef.current;
    if (draggedMemberId && draggables[draggedMemberId]) {
      const page = pageRef.current;
      const scrollContainer = page?.childNodes[0];
      draggables[draggedMemberId].style.marginTop = `${
        scrollContainer.scrollTop - 16
      }px`;
    }
  }, [pageRef]);

  useEffect(() => {
    const page = pageRef.current;
    const scrollContainer = page?.childNodes[0];

    if (scrollContainer) {
      onScroll();

      scrollContainer.addEventListener("scroll", onScroll);
      return () => {
        scrollContainer.removeEventListener("scroll", onScroll);
      };
    }
  }, [pageRef, onScroll]);

  const onDragStart = ({ draggableId }) => {
    draggedMemberIdRef.current = draggableId;
    onScroll();
  };

  const onDragEnd = (result) => {
    draggedMemberIdRef.current = null;
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    onChange((value) => {
      const newValue = value;
      const [removed] = newValue[source.droppableId].splice(source.index, 1);
      newValue[destination.droppableId].splice(destination.index, 0, removed);
      return newValue;
    });
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <div className="space-y-4">
        <div>
          <h3>{t`task.assigneeOrder.title`}</h3>
          <p className="text-caption">{t`task.assigneeOrder.description`}</p>
        </div>
        <Droppable droppableId="assignedMemberIds">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={cx("mx--2 rounded-md", {
                "assignee-order--drag-over": snapshot.isDraggingOver,
              })}
              {...provided.droppableProps}
            >
              {value.assignedMemberIds.map((memberId, index) => (
                <Draggable key={memberId} draggableId={memberId} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={(node) => {
                        provided.innerRef(node);
                        draggablesRef.current[memberId] = node;
                      }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={cx(
                        "flex items-center space-x-4 p-2",
                        {
                          "assignee--dragged rounded-md bg-card shadow-md":
                            snapshot.isDragging,
                        },
                        { "assignee--idle": !snapshot.isDragging }
                      )}
                      style={{
                        ...provided.draggableProps.style,
                        position: snapshot.isDragging ? "absolute" : undefined,
                      }}
                    >
                      <div className="drag-handle" />
                      <UserDetail
                        userContainer={group.members.find(
                          (member) => member.id === memberId
                        )}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <div>
          <h3>{t`task.notAssigned.title`}</h3>
          <p className="text-caption">{t`task.notAssigned.description`}</p>
        </div>
        <Droppable droppableId="unassignedMemberIds">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={cx("mx--2 rounded-md", {
                "assignee-order--drag-over": snapshot.isDraggingOver,
              })}
              {...provided.droppableProps}
            >
              {value.unassignedMemberIds.map((memberId, index) => (
                <Draggable key={memberId} draggableId={memberId} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={(node) => {
                        provided.innerRef(node);
                        draggablesRef.current[memberId] = node;
                      }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={cx(
                        "flex items-center space-x-4 p-2",
                        {
                          "assignee--dragged rounded-md bg-card shadow-md":
                            snapshot.isDragging,
                        },
                        { "assignee--idle": !snapshot.isDragging }
                      )}
                      style={{
                        ...provided.draggableProps.style,
                        position: snapshot.isDragging ? "absolute" : undefined,
                      }}
                    >
                      <div className="drag-handle" />
                      <UserDetail
                        userContainer={group.members.find(
                          (member) => member.id === memberId
                        )}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};
