import * as Sentry from "@sentry/browser";
import isUrl from "is-valid-http-url";

import { useEffect } from "react";
import useDebounce from "hooks/useDebounce";

export default function useRecipe({ recipe, setRecipe, title, isEditing }) {
  const debouncedTitle = useDebounce(title, 500);

  useEffect(() => {
    const fetchRecipeDetails = async ({ url, signal }) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/getRecipeDetails`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            redirect: "follow",
            body: JSON.stringify({ url }),
            signal,
          }
        );
        const { success, ...recipe } = await response.json();
        if (success) {
          setRecipe(recipe);
        }
      } catch (error) {
        Sentry.captureException(error, { event: "Fetch recipe details" });
        console.log(error);
      }
    };

    if (isEditing !== false) {
      const titleIsUrl =
        isUrl(debouncedTitle) || isUrl(`https://${debouncedTitle}`);

      if (titleIsUrl) {
        const abortController = new AbortController();

        const url = isUrl(debouncedTitle)
          ? debouncedTitle
          : `https://${debouncedTitle}`;

        if (!recipe || recipe.url !== url) {
          fetchRecipeDetails({ url, signal: abortController.signal });
          return () => abortController.abort();
        }
      } else if (recipe) {
        setRecipe(null);
      }
    }
  }, [recipe, setRecipe, debouncedTitle, isEditing]);
}
