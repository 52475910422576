import React from "react";
import useGroupContext from "hooks/useGroupContext";
import useNotificationsPrompt from "hooks/useNotificationsPrompt";
import useTranslation from "hooks/useTranslation";

import GroupProvider from "containers/GroupProvider";

import SEO from "components/SEO";

const GroupHeader = () => {
  const { t } = useTranslation();
  const [group] = useGroupContext();
  useNotificationsPrompt();

  return <SEO title={group.name || t`group.nameless`} />;
};

const withGroup = (Component) => (props) => (
  <GroupProvider groupId={props.groupId}>
    <GroupHeader />
    <Component {...props} />
  </GroupProvider>
);

export default withGroup;
