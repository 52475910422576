import * as _ from "utils";

import { useEffect, useMemo } from "react";
import reactFirestore, { nonExistentDoc, useCollection } from "react-firestore";

export default function useUser(userContainer) {
  const users = useCollection("users");

  const userIdentifiers =
    userContainer?.userIdentifiers.filter(
      (userIdentifier) => userIdentifier !== "__unused_user_identifier__"
    ) || [];

  useEffect(() => {
    _.fetchUser(userIdentifiers);
  }, [userIdentifiers]);

  const user = useMemo(
    () => ({
      ...userContainer,
      ...(users.find(_.isSameUser(userContainer)) || nonExistentDoc),
    }),
    [userContainer, users]
  );

  return [user, reactFirestore.getModel("users", user.uid)];
}
