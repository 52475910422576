import React from "react";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";
import useListSharing from "hooks/useListSharing";
import useUser from "hooks/useUser";

import withList from "hocs/withList";

import SimpleLayout from "layouts/Simple";

import * as Icons from "components/Icons";
import { Button } from "components/UI";
import UserName from "components/User/Name";
import ListParticipantManagement from "components/List/Participants/Management";

export default withList(({ parentId }) => {
  const { t } = useTranslation();
  const [list] = useListContext();
  const [, { tryShareNative }] = useListSharing(list.id);

  const parent = list.maybeParticipants.find(
    (participant) => participant.id === parentId
  );
  const [parentUser] = useUser(parent);

  return (
    <SimpleLayout
      title={
        parentId
          ? parentId === list.myParticipation?.id
            ? t`list.invite.yourPlusOnes`
            : t(`list.invite.otherPlusOnes`, {
                for: UserName({ user: parentUser }),
              })
          : t`list.invite.title`
      }
      parentLocation={`/l/${list.id}`}
      headerAction={
        <Button
          color="secondary"
          size="xs"
          lead={<Icons.Share />}
          onClick={() => tryShareNative()}
        >{t`action.shareLink`}</Button>
      }
    >
      <div className="space-y-4">
        <ListParticipantManagement parentId={parentId} />
      </div>
    </SimpleLayout>
  );
});
