import React from "react";
import useCurrentUser from "hooks/useCurrentUser";
import useTranslation from "hooks/useTranslation";

import withAuth from "hocs/withAuth";

import SimpleLayout from "layouts/Simple";

const languages = [
  { label: "Nederlands", code: "nl" },
  { label: "English", code: "en" },
];

export default withAuth(() => {
  const { t } = useTranslation();
  const [currentUser, currentUserResource] = useCurrentUser();

  return (
    <SimpleLayout
      title={t`settings.language.title`}
      prevLabel={t`settings.title`}
    >
      <div className="space-y-4">
        {languages.map(({ label, code }) => (
          <label key={code} className="flex items-center space-x-2">
            <input
              type="radio"
              name="language"
              checked={currentUser?.settings?.preferredLanguage === code}
              onChange={(event) => {
                if (event.target.checked) {
                  currentUserResource.update({
                    "settings.preferredLanguage": code,
                  });
                }
              }}
            />
            <p>{label}</p>
          </label>
        ))}
      </div>
    </SimpleLayout>
  );
});
