import * as _ from "utils";

import React, { useCallback } from "react";
import { useCollection } from "react-firestore";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Button } from "components/UI";
import ExpenseList from "components/Expenses/ExpenseList";
import ExpensesEmptyState from "components/Expenses/EmptyState";

const byCreatedAt = _.firstBy((entity) => entity.createdAt.toMillis(), -1);

export default ({ groupId }) => {
  const { t } = useTranslation();
  const isInGroup = useCallback(
    (settlement) => settlement.groupId === groupId,
    [groupId]
  );
  const isUnsettledAndInGroup = useCallback(
    (expense) =>
      expense.exists && expense.groupId === groupId && !expense.isSettled,
    [groupId]
  );
  const unsettledExpenses = useCollection("expenses", {
    filter: isUnsettledAndInGroup,
    sort: byCreatedAt,
  });
  const settlements = useCollection("settlements", { filter: isInGroup });

  return (
    <div className="px-4 pb-20 space-y-4">
      <div className="collapse flex flex-col items-flex-end space-y-2 sm:flex-row sm:justify-flex-end sm:items-center sm:space-x-4 sm:space-y-0">
        {settlements.length > 0 && (
          <Button
            variant="text"
            color="primary"
            onClick={() => _.pushModal(`/g/${groupId}/settlements`)}
            trail={<Icons.Arrow direction="right" />}
          >
            {t`expense.viewSettlements`}
          </Button>
        )}
        {unsettledExpenses.length > 0 && (
          <Button
            color="primary"
            size="sm"
            className="w-full sm:w-auto"
            onClick={() => _.pushModal(`/g/${groupId}/settle`)}
          >{t`expense.newSettlement`}</Button>
        )}
      </div>
      {unsettledExpenses.length === 0 && <ExpensesEmptyState explain />}
      <ExpenseList expenses={unsettledExpenses} showList showDate />
    </div>
  );
};
