import cx from "classnames";
import { v4 as uuidv4 } from "uuid";

import React, { useEffect, useState } from "react";
import useTaskContext from "hooks/useTaskContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import CollapsibleContainer from "components/CollapsibleContainer";
import ChecklistInput from "./ChecklistInput";

export default function Checklist({ isEditing }) {
  const { t } = useTranslation();
  const [task, taskResource] = useTaskContext();

  const completed = task.checklist.filter((item) => item.isChecked).length;
  const items = task.checklist.length;

  const [checklist, setChecklist] = useState(
    task.checklist.length > 0 ? task.checklist.map((item) => item.label) : [""]
  );
  useEffect(() => {
    if (isEditing) {
      setChecklist(
        taskResource.rawData.checklist.length > 0
          ? taskResource.rawData.checklist.map((item) => item.label)
          : [""]
      );
      return () => {
        setChecklist((checklist) => {
          taskResource.update({
            checklist: checklist.filter(Boolean).map(
              (label) =>
                taskResource.rawData.checklist.find(
                  (item) => item.label === label
                ) || {
                  id: uuidv4(),
                  label,
                  isChecked: false,
                }
            ),
          });
          return checklist;
        });
      };
    }
  }, [isEditing, taskResource]);

  return (
    <CollapsibleContainer isVisible={items > 0 || isEditing}>
      <div className="space-y-2">
        <div className="flex items-center justify-space-between">
          <h2>{t`task.checklist`}</h2>
          {!isEditing && (
            <div
              className={cx(
                "flex items-center space-x-1 px-2 rounded-full font-bold",
                items > 0 && completed === items
                  ? "bg-success"
                  : "accent-success text-success"
              )}
            >
              <Icons.Check />
              <span>
                {completed}/{items}
              </span>
            </div>
          )}
        </div>
        {isEditing ? (
          <ChecklistInput
            value={{ checklist }}
            onChange={(f) =>
              setChecklist((checklist) => f({ checklist }).checklist)
            }
          />
        ) : (
          task.checklist.map((item) => (
            <label key={item.id} className="flex items-center space-x-2">
              <input
                type="checkbox"
                onChange={(event) =>
                  taskResource.toggleItem(item.id, event.target.checked)
                }
                checked={item.isChecked}
                disabled={task.isCompleted || task.isExpired}
              />
              <p>{item.label}</p>
            </label>
          ))
        )}
      </div>
    </CollapsibleContainer>
  );
}
