import * as _ from "utils";
import { useEffect, useMemo, useState } from "react";

import reactFirestore from "./ReactFirestore";

const trueFilter = _.constant(true);
const constSort = _.constant(1);

export default function useCollection(collection, options) {
  const { filter = trueFilter, sort = constSort } = options || {};
  const [state, setState] = useState(undefined);
  const collectionData = reactFirestore.readCollection(collection);
  const initialData = useMemo(() => collectionData.filter(filter).sort(sort), [
    collectionData,
    filter,
    sort,
  ]);

  useEffect(
    () =>
      reactFirestore.observeCollection(collection, (docs) => {
        setState(docs.filter(filter).sort(sort));
      }),
    [collection, filter, sort]
  );

  return state === undefined ? initialData : state;
}
