import "./DateInput.scss";

import cx from "classnames";
import { format } from "date-fns";

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import useTranslation from "hooks/useTranslation";

import DayPicker from "react-day-picker";
import BaseInput from "./BaseInput";
import FlyOut from "./FlyOut";

const DateInput = forwardRef(
  ({ className, placeholder, value, onChange, autoFocus, ...props }, ref) => {
    const [{ isOpen, canClose }, setState] = useState({
      isOpen: false,
      canClose: true,
    });
    const buttonRef = useRef();
    const timeoutRef = useRef();
    const { locale } = useTranslation();

    useEffect(() => {
      if (autoFocus) {
        buttonRef.current.focus();
        setState({ isOpen: true, canClose: true });
      }
    }, [autoFocus]);

    const close = useCallback(() => {
      clearTimeout(timeoutRef.current);
      setState({ isOpen: false, canClose: true });
    }, []);

    return (
      <>
        <button
          ref={(node) => {
            if (ref) {
              ref.current = node;
            }
            buttonRef.current = node;
          }}
          className={cx("input text-left cursor-pointer", className)}
          onFocus={() => {
            console.log("opening..");
            setState({ isOpen: true, canClose: false });
            timeoutRef.current = setTimeout(
              () => setState({ isOpen: true, canClose: true }),
              100
            );
          }}
          onKeyDown={(event) => {
            if (event.key === "Tab") {
              close();
            }
          }}
          {...props}
        >
          {value ? (
            <p>{format(new Date(value), "PPP", { locale })}</p>
          ) : (
            <p className="text-caption">{placeholder}</p>
          )}
        </button>
        <FlyOut
          originRef={buttonRef}
          className="px-4 py-2"
          persistOnClick
          isOpen={isOpen}
          onClick={() => buttonRef.current.focus()}
          onClose={
            canClose
              ? () => setState({ isOpen: false, canClose: true })
              : undefined
          }
        >
          <DayPicker
            selectedDays={value ? [new Date(value)] : null}
            onDayClick={(value) => {
              onChange(format(value, "yyyy-MM-dd"));
              close();
            }}
            locale={locale}
          />
        </FlyOut>
      </>
    );
  }
);

export default forwardRef((props, ref) => (
  <BaseInput ref={ref} component={DateInput} {...props} />
));
