import * as _ from "utils";

import React, { useCallback } from "react";
import { useDoc, useCollection } from "react-firestore";
import useListContext from "hooks/useListContext";

import withList from "hocs/withList";

import SettlementPreview from "components/Expenses/SettlementPreview";

const byCreatedAt = _.firstBy((entity) => entity.createdAt.toMillis(), -1);

export default withList(() => {
  const [list] = useListContext();
  const [group] = useDoc("groups", list.groupId);
  const toBeSettledInList = useCallback(
    (expense) =>
      expense.exists &&
      !expense.isSettled &&
      expense.listId === list.id &&
      (!group.exists || expense.groupId !== group.id),
    [list.id, group.exists, group.id]
  );
  const expensesToBeSettled = useCollection("expenses", {
    filter: toBeSettledInList,
    sort: byCreatedAt,
  });
  const involvedUserContainers = expensesToBeSettled
    .flatMap((expense) => expense.debtors)
    .reduce(
      (involvedUserContainers, debtor) =>
        involvedUserContainers.some(_.isSameUser(debtor))
          ? involvedUserContainers
          : involvedUserContainers.concat(debtor),
      []
    )
    .map(
      (userContainer) =>
        (group.exists
          ? group.members.find(_.isSameUser(userContainer))
          : list.maybeParticipants.find(_.isSameUser(userContainer))) ||
        userContainer
    );
  return (
    <SettlementPreview
      involvedUserContainers={involvedUserContainers}
      expensesToBeSettled={expensesToBeSettled}
      listId={list.id}
    />
  );
});
