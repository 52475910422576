import * as _ from "utils";
import BaseModel from "./Base";
import reactFirestore from "react-firestore";

const normalizeState = (transaction) => ({
  ...transaction,
  isPaid: !!transaction.paidAt,
  isReceived: !!transaction.receivedAt,
  isCompleted: !!transaction.receivedAt,
});

export default class Transaction extends BaseModel {
  static normalize(transaction) {
    return normalizeState(transaction);
  }

  async markReceived() {
    await this.update({ receivedAt: new Date() });
    _.track("transaction_mark_received");
    this.sendMessage(this.rawData.from, ({ t, currentUser }) => ({
      title: t(`transactions.notifications.markReceived.title`),
      body: t(`transactions.notifications.markReceived.body`, {
        by: currentUser.name,
        amount: (this.rawData.amount / 100).toFixed(2),
      }),
    }));
  }

  async markNotReceived() {
    await this.update({ receivedAt: null });
    _.track("transaction_mark_not_received");
    this.sendMessage(this.rawData.from, ({ t, currentUser }) => ({
      title: t(`transactions.notifications.markNotReceived.title`),
      body: t(`transactions.notifications.markNotReceived.body`, {
        by: currentUser.name,
        amount: (this.rawData.amount / 100).toFixed(2),
      }),
    }));
  }

  async markPaid() {
    await this.update({ paidAt: new Date() });
    _.track("transaction_mark_paid");
    this.sendMessage(this.rawData.to, ({ t, currentUser }) => ({
      title: t(`transactions.notifications.markPaid.title`),
      body: t(`transactions.notifications.markPaid.body`, {
        by: currentUser.name,
        amount: (this.rawData.amount / 100).toFixed(2),
      }),
    }));
  }

  async markNotPaid() {
    await this.update({ paidAt: null });
    _.track("transaction_mark_not_paid");
    this.sendMessage(this.rawData.to, ({ t, currentUser }) => ({
      title: t(`transactions.notifications.markNotPaid.title`),
      body: t(`transactions.notifications.markNotPaid.body`, {
        by: currentUser.name,
        amount: (this.rawData.amount / 100).toFixed(2),
      }),
    }));
  }

  async sendMessage(to, createMessage) {
    const targetUser = _.withMessagingInfo(to);
    const currentUser = reactFirestore.data.users.find(
      (user) => user.uid === _.auth.currentUser.uid
    ) || { exists: false };

    await _.sendSegmentedMessage([targetUser], (params) => ({
      data: {
        transactionId: this.rawData.id,
      },
      ...createMessage({ ...params, currentUser }),
    }));
  }
}
