import * as _ from "utils";

import React, { useLayoutEffect } from "react";
import { useDoc, useQuery } from "react-firestore";
import { SettlementContext } from "contexts";

import withAuth from "hocs/withAuth";

import NotFoundPage from "pages/404";

const SettlementProvider = ({ settlementId, children }) => {
  const value = useDoc("settlements", settlementId);

  useQuery(_.transactionsCollection.where("settlementId", "==", settlementId));

  useLayoutEffect(() => {
    _.setAnalyticsContext({ settlement_id: settlementId });
    return () => {
      _.setAnalyticsContext({ settlement_id: undefined });
    };
  }, [settlementId]);

  return value[0].hasExisted ? (
    <SettlementContext.Provider value={value}>
      {children}
    </SettlementContext.Provider>
  ) : (
    <NotFoundPage />
  );
};

export default withAuth(SettlementProvider);
