import cx from "classnames";

import React from "react";

const Message = ({ color, className, children }) => (
  <div
    className={cx("rounded-md", `accent-${color}`, `text-${color}`, className)}
  >
    {children}
  </div>
);

export default Message;
