import * as _ from "utils";

import { useEffect } from "react";
import useAuth from "hooks/useAuth";
import useNavigationContext from "hooks/useNavigationContext";

export default function AuthenticationBoundary() {
  const [{ isAuthenticated }] = useAuth();
  const { stack } = useNavigationContext();

  useEffect(() => {
    if (
      !isAuthenticated &&
      !stack.some((stackFrame) =>
        stackFrame.location.pathname.startsWith("/auth")
      )
    ) {
      _.pushModal("/auth/register");
    }
  }, [isAuthenticated, stack]);
  return null;
}
