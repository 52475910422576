import React from "react";

import Comment from "./Comment";
import CommentInput from "./Input";

export default function Comments({ comments, resource }) {
  return (
    <div className="space-y-4">
      {comments.map((comment) => (
        <Comment key={comment.id} comment={comment} />
      ))}
      <CommentInput resource={resource} />
    </div>
  );
}
