import React from "react";
import useGroupContext from "hooks/useGroupContext";
import useTranslation from "hooks/useTranslation";
import useGroupSharing from "hooks/useGroupSharing";

import withGroup from "hocs/withGroup";

import SimpleLayout from "layouts/Simple";

import * as Icons from "components/Icons";
import { Button } from "components/UI";
import GroupMemberManagement from "components/Group/MemberManagement";

const GroupMembersPage = () => {
  const { t } = useTranslation();
  const [group] = useGroupContext();
  const [, { tryShareNative }] = useGroupSharing();

  return (
    <SimpleLayout
      title={t(`group.addMembers`, {
        count: group.members.length,
        group: group.name,
      })}
      parentLocation={`/g/${group.id}/settings`}
      headerAction={
        <Button
          color="secondary"
          size="xs"
          lead={<Icons.Share />}
          onClick={() => tryShareNative()}
        >{t`action.shareLink`}</Button>
      }
    >
      <div className="space-y-4">
        <GroupMemberManagement />
      </div>
    </SimpleLayout>
  );
};

export default withGroup(GroupMembersPage);
