import * as _ from "utils";

import React from "react";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";

import UserTree from "./UserTree";
import ParticipantSettings from "./Settings";

export default function Participants() {
  const { t } = useTranslation();
  const [list] = useListContext();

  return (
    <div className="space-y-4">
      <div className="m--2">{_.canEdit(list) && <ParticipantSettings />}</div>
      <h3>
        {t("list.join", {
          count: list.participants.length,
          context: _.toContext([
            ["past", list.hasOccurred],
            ["zero", list.participants.length === 0],
          ]),
        })}
      </h3>
      <UserTree
        participants={list.participants}
        groupParticipants
        i18nTemplates={{
          invisiblePersons: "label.hiddenParticipants",
        }}
      />
      <h3>
        {t("list.hasnt_responded", {
          count: list.invitedParticipants.length,
          context: _.toContext([
            ["past", list.hasOccurred],
            ["zero", list.invitedParticipants.length === 0],
          ]),
        })}
      </h3>
      <UserTree
        participants={list.invitedParticipants}
        i18nTemplates={{
          invisiblePersons: "label.hiddenPersons",
        }}
      />
      <h3>
        {t("list.dont_join", {
          count: list.nonParticipants.length,
          context: _.toContext([
            ["past", list.hasOccurred],
            ["zero", list.nonParticipants.length === 0],
          ]),
        })}
      </h3>
      <UserTree
        participants={list.nonParticipants}
        i18nTemplates={{
          invisiblePersons: "label.hiddenPersons",
        }}
      />
    </div>
  );
}
