import * as _ from "utils";

import React, { Suspense } from "react";
import { useQueries } from "react-firestore";
import useGroupContext from "hooks/useGroupContext";
import useTranslation from "hooks/useTranslation";

import withGroup from "hocs/withGroup";

import SimpleLayout from "layouts/Simple";

import * as Icons from "components/Icons";
import { Button, Tabs } from "components/UI";
import GroupGraphic from "components/Group/Graphic";
import GroupName from "components/Group/Name";
import GroupSetup from "components/Group/Setup";
import GroupActions from "components/Group/Actions";

const Body = (props) => (
  <div className="max-w-2xl mx-auto space-y-4" {...props} />
);

export default withGroup(({ children }) => {
  const { t } = useTranslation();
  const [group] = useGroupContext();
  useQueries([_.listsCollection.where("groupId", "==", group.id)]);

  return (
    <SimpleLayout
      title={group.name}
      hideTitle
      components={{ Body }}
      headerAction={
        <Button
          variant="text"
          lead={<Icons.Bell />}
          onClick={() => _.pushModal(`/g/${group.id}/notifications`)}
        >{t`group_notifications.title`}</Button>
      }
    >
      <div
        className="relative cursor-pointer"
        onClick={() => _.pushModal(`/g/${group.id}/settings`)}
        style={{ zIndex: 2 }}
      >
        <GroupGraphic group={group} />
        <p className="flex items-center justify-center space-x-1 w-64 mx-auto">
          <span className="truncate">
            <GroupName group={group} />
          </span>
          <Icons.Caret direction="right" />
        </p>
      </div>
      <GroupSetup group={group} />
      <Tabs.Container>
        <Tabs.Tab to="planned">{t`group.planned`}</Tabs.Tab>
        <Tabs.Tab to="past">{t`group.past`}</Tabs.Tab>
        {process.env.REACT_APP_EXPENSES_ENABLED && (
          <Tabs.Tab to="expenses">{t`group.expenses`}</Tabs.Tab>
        )}
        <Tabs.Tab to="statistics">{t`group.statistics`}</Tabs.Tab>
      </Tabs.Container>
      <Suspense fallback={<div className="loader mx-auto" />}>
        {children}
      </Suspense>
      <GroupActions />
    </SimpleLayout>
  );
});
