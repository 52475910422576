import { DATE_TYPES } from "statics.json";
import moment from "moment";
import { pipe } from "./functions";

const formatDate = ({ date, time, lang }) =>
  pipe(
    (m) => (lang ? m.locale(lang) : m),
    (m) => m.calendar(),
    (c) => (time ? c : c.replace(" om 00:00", "").replace(" at 12:00 AM", ""))
  )(time ? moment(`${date} ${time}`) : moment(date));

const nthToNumber = (nth) => {
  switch (nth) {
    case "first":
      return 0;
    case "second":
      return 1;
    case "third":
      return 2;
    case "fourth":
      return 3;
    case "last":
    default:
      return -1;
  }
};

const setWeekAndDay = (m, nth, dayOfWeek) => {
  const n = nthToNumber(nth);

  if (n === -1) {
    m.endOf("month");
    const weekDayMonthEnd = m.weekday();
    m.date(m.daysInMonth() - ((7 - (dayOfWeek - weekDayMonthEnd)) % 7));
  } else {
    m.startOf("month");
    const weekDayMonthStart = m.weekday();
    m.date(((7 - (weekDayMonthStart - dayOfWeek)) % 7) + 7 * n + 1);
  }

  return m;
};

const isValidRegularMoment = (regularMoment) => {
  if (isNaN(regularMoment.frequency)) {
    return false;
  }

  switch (regularMoment.frequencyPeriod) {
    case "day":
    case "month":
    case "year":
      return true;
    case "week":
      return regularMoment.weekDays.some(Boolean);
    default:
      return false;
  }
};

const nthMatchIndex = (n) => (xs) => {
  let count = -1;
  for (let i = 0; i < xs.length; i++) {
    if (xs[i]) {
      count++;
      if (count === n) {
        return i;
      }
    }
  }
  return -1;
};

const getOccurrence = (recurringTask, n) => {
  const next = moment(recurringTask.startDate.date);

  switch (recurringTask.frequencyPeriod) {
    case "day":
      next.add(recurringTask.frequency * n, "days");
      return next;
    case "week":
      next.add(
        recurringTask.frequency *
          Math.floor(n / recurringTask.weekDays.filter(Boolean).length),
        "weeks"
      );
      next.weekday(
        nthMatchIndex(n % recurringTask.weekDays.filter(Boolean).length)(
          recurringTask.weekDays
        )
      );
      return next;
    case "month":
      next.add(recurringTask.frequency * n, "months");
      if (recurringTask.dateType === DATE_TYPES.DAY) {
        next.date(
          Math.min(
            next.daysInMonth(),
            recurringTask.dayOfMonth === null
              ? 100
              : recurringTask.dayOfMonth + 1
          )
        );
      } else {
        setWeekAndDay(next, recurringTask.nth, recurringTask.dayOfWeek);
      }
      return next;
    case "year":
      next.add(recurringTask.frequency * n, "years");
      return next;
    default:
      return null;
  }
};

export { formatDate, isValidRegularMoment, getOccurrence };
