import * as _ from "utils";

import React, { useCallback } from "react";
import { useCollection } from "react-firestore";
import useGroupContext from "hooks/useGroupContext";
import useTranslation from "hooks/useTranslation";

import withGroup from "hocs/withGroup";

import SimpleLayout from "layouts/Simple";

import InfiniteList from "components/InfiniteList";
import SettlementThumb from "components/Settlements/Thumb";

const renderSettlement = (settlement) => (
  <SettlementThumb key={settlement.id} settlement={settlement} />
);

const sort = _.firstBy((settlement) => settlement.createdAt.toMillis(), -1);

export default withGroup(() => {
  const { t } = useTranslation();
  const [group] = useGroupContext();
  const filter = useCallback((settlement) => settlement.groupId === group.id, [
    group.id,
  ]);
  const settlements = useCollection("settlements", { filter, sort });

  return (
    <SimpleLayout
      parentLocation={`/g/${group.id}`}
      title={t`settlements.title`}
    >
      <div className="space-y-4">
        <InfiniteList items={settlements} renderItem={renderSettlement} />
      </div>
    </SimpleLayout>
  );
});
