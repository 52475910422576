import * as _ from "utils";
import { PARTICIPANT_ROLES, LIST_TYPES, COURSE_FORMATS } from "statics.json";
import * as Sentry from "@sentry/browser";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import firebase from "firebase/app";

import BaseModel from "./Base";

import reactFirestore from "react-firestore";

const defaultSettings = {
  everyoneCanManageParticipants: true,
  allowPlusOnes: true,
  showGuestList: true,
};

const getNotificationTitle = ({ t, lang, list, group, regularMoment }) =>
  list.title ||
  t(`notifications.common.title`, {
    date: _.formatDate({ date: list.occursAt.date, time: null, lang }),
    group: group.name,
    regularMoment: regularMoment.title,
    context: _.toContext([
      ["group", group.exists],
      ["regularMoment", regularMoment.exists && regularMoment.title],
    ]),
  });

const parseRoles = (prevRoles, newRole) => {
  switch (newRole) {
    case PARTICIPANT_ROLES.EATER:
      return _.unique(
        prevRoles
          .filter(
            (r) =>
              ![PARTICIPANT_ROLES.NON_EATER, PARTICIPANT_ROLES.CHEF].includes(r)
          )
          .concat([PARTICIPANT_ROLES.EATER])
      );
    case PARTICIPANT_ROLES.NON_EATER:
      return _.unique(
        prevRoles
          .filter(
            (r) =>
              ![PARTICIPANT_ROLES.EATER, PARTICIPANT_ROLES.CHEF].includes(r)
          )
          .concat([PARTICIPANT_ROLES.NON_EATER])
      );
    case PARTICIPANT_ROLES.CHEF:
      return _.unique(
        prevRoles
          .filter((r) => r !== PARTICIPANT_ROLES.NON_EATER)
          .concat([PARTICIPANT_ROLES.EATER, PARTICIPANT_ROLES.CHEF])
      );
    default:
      return prevRoles;
  }
};

const normalizeGroupId = ({ groupId, groupRef, ...list }) => ({
  ...list,
  ...(groupId === undefined
    ? groupRef?.id === undefined
      ? {}
      : { groupId: groupRef.id }
    : { groupId }),
});

const normalizeCourses = (list) => ({
  ...list,
  courses: list.courses
    ? list.courses.map((course) => ({
        ...course,
        options: (course.options || []).map((option) => ({
          ...option,
          votes: option.votes || [],
        })),
      }))
    : list.type === LIST_TYPES.ORDER
    ? [
        {
          id: uuidv4(),
          format: COURSE_FORMATS.ORDER,
          options: _.unique(
            list.participants.map((p) => p.order).filter(Boolean)
          ).map((title) => ({
            id: uuidv4(),
            title,
            dietaryInformation: null,
            image: null,
            votes: list.participants
              .filter((p) => p.order === title)
              .map((p) => p.id),
          })),
        },
      ]
    : ["dish", "dietaryInformation", "image", "recipe"].some((key) => list[key])
    ? [
        {
          id: uuidv4(),
          type: null,
          format: COURSE_FORMATS.SINGLE_OPTION,
          options: [
            {
              id: uuidv4(),
              title: list.dish,
              dietaryInformation: list.dietaryInformation || null,
              image: list.image,
              recipe: list.recipe,
              votes: [],
            },
          ],
        },
      ]
    : [],
});

const normalizeImage = (list) => ({
  ...list,
  image:
    list.image ||
    list.courses
      .flatMap((course) => course.options)
      .map((option) => option.image || option.recipe?.image)
      .find(Boolean),
});

const normalizeParticipants = (list) => {
  const coursesWithInteraction = list.courses.filter((course) =>
    [
      COURSE_FORMATS.MULTIPLE_OPTIONS,
      COURSE_FORMATS.POLL,
      COURSE_FORMATS.ORDER,
    ].includes(course.format)
  );

  const coursesWithRequiredInteraction = list.courses.filter((course) =>
    [COURSE_FORMATS.MULTIPLE_OPTIONS, COURSE_FORMATS.ORDER].includes(
      course.format
    )
  );

  const maybeParticipants = list.participants
    .filter((participant) => "roles" in participant)
    .map((participant) => ({
      hasSeen: false,
      ...participant,
      userIdentifiers: participant.userIdentifiers || [participant.userRef.id],
      hasChosen: coursesWithRequiredInteraction.every((course) =>
        course.options.some((option) =>
          option.votes.some((vote) => vote === participant.id)
        )
      ),
    }));

  const myParticipation = maybeParticipants.find(_.isCurrentUser);
  const participants = maybeParticipants.filter(_.isParticipant);
  const nonParticipants = maybeParticipants.filter(_.isNonParticipant);
  const chefs = maybeParticipants.filter(_.isChef).sort(_.currentUserLast);

  const invitedParticipants = maybeParticipants.filter(
    _.neg(_.or(_.isParticipant, _.isNonParticipant))
  );

  const unseenParticipants = invitedParticipants.filter(_.neg(_.hasSeen));

  return {
    ...list,
    containsCoursesWithInteraction: coursesWithInteraction.length > 0,
    containsCoursesWithRequiredInteraction:
      coursesWithRequiredInteraction.length > 0,
    maybeParticipants,
    chefs,
    participants,
    nonParticipants,
    invitedParticipants,
    unseenParticipants,
    participantUserIdentifiers: maybeParticipants.flatMap(
      (participant) => participant.userIdentifiers
    ),
    participantPreferences: participants.reduce(
      (acc, { preferences }) =>
        preferences
          ? preferences.reduce(
              (ps, p) => ({ ...ps, [p]: (ps[p] || 0) + 1 }),
              acc
            )
          : acc,
      {}
    ),
    myParticipation,
  };
};

const normalizeTime = (list) => {
  const hasDate = Boolean(list.occursAt.date);
  const hasDeadline = Boolean(list.closesAt?.date && list.closesAt?.time);

  const hasOccurred =
    hasDate &&
    moment(`${list.occursAt.date} ${list.occursAt.time || "23:59"}`) <=
      moment();

  const calendarDate = list.occursAt.date
    ? list.occursAt.time
      ? moment(`${list.occursAt.date} ${list.occursAt.time}`).toDate()
      : moment(list.occursAt.date).toDate()
    : new Date();

  return {
    ...list,
    hasDate,
    hasOccurred,
    hasDeadline,
    isClosed: hasDeadline
      ? moment(`${list.closesAt.date} ${list.closesAt.time}`) <= moment()
      : false,
    calendarDate,
  };
};

const normalizeComments = (list) => ({
  ...list,
  comments: list.comments || [],
});

export default class List extends BaseModel {
  static create(params) {
    _.track("event_create", {
      recurring_event_id: params.groupId ? params.regularMomentId : null,
      group_id: params.groupId,
    });

    const type = params.type || LIST_TYPES.COOK;
    const occursAt = {
      date: params.occursAt_date || null,
      time: params.occursAt_time || null,
    };

    const myParticipation = {
      id: uuidv4(),
      userIdentifiers: _.getUserIdentifiers(_.auth.currentUser),
      hasSeen: true,
      roles:
        params.role === "none"
          ? [PARTICIPANT_ROLES.CREATOR]
          : params.role === PARTICIPANT_ROLES.EATER
          ? [PARTICIPANT_ROLES.EATER, PARTICIPANT_ROLES.CREATOR]
          : [
              PARTICIPANT_ROLES.EATER,
              PARTICIPANT_ROLES.CHEF,
              PARTICIPANT_ROLES.CREATOR,
            ],
    };

    const group = reactFirestore.getDoc("groups", params.groupId);
    const participants = group.exists
      ? [
          ...group.members
            .filter((member) => !_.isSameUser(member)(myParticipation))
            .map((member) => ({
              id: uuidv4(),
              userIdentifiers: member.userIdentifiers || [],
              roles: [],
              hasSeen: false,
              ...(member.email && !member.name ? { email: member.email } : {}),
            })),
          myParticipation,
        ]
      : [myParticipation];

    return {
      type,
      title: params.title,
      groupId:
        reactFirestore.getCollection("groups").length > 0
          ? params.groupId
          : null,
      regularMomentId: params.groupId ? params.regularMomentId : undefined,
      isSetup: !!(params.groupId && params.regularMomentId),
      courses: [],
      closesAt: null,
      occursAt,
      participants,
      participantUserIdentifiers: participants.flatMap(
        (participant) => participant.userIdentifiers
      ),
      ...defaultSettings,
    };
  }

  static normalize(list) {
    return _.pipe(
      normalizeGroupId,
      normalizeCourses,
      normalizeImage,
      normalizeParticipants,
      normalizeTime,
      normalizeComments
    )(list);
  }

  async addParticipant({
    name = "",
    userIdentifiers = [],
    roles = [],
    parentId,
  }) {
    const id = uuidv4();
    await this.docRef.update({
      participants: firebase.firestore.FieldValue.arrayUnion({
        id,
        name,
        userIdentifiers,
        roles,
        parentId: parentId || null,
      }),
      ...(userIdentifiers.length > 0
        ? {
            participantUserIdentifiers:
              firebase.firestore.FieldValue.arrayUnion(...userIdentifiers),
          }
        : {}),
    });
    _.track("participant_create");
    return id;
  }

  async setParticipant(participantId, v) {
    try {
      await _.db.runTransaction(async (tx) => {
        const listDoc = await tx.get(this.docRef);
        if (!listDoc.exists) {
          throw Error(`List ${this.docRef.id} does not exist`);
        }
        const participants = listDoc.data().participants;
        const newParticipants = participants.some(
          (participant) => participant.id === participantId
        )
          ? participants.map((participant) =>
              participant.id === participantId
                ? typeof v === "function"
                  ? v(participant)
                  : v
                : participant
            )
          : [...participants, v({})];

        tx.update(this.docRef, { participants: newParticipants });
      });
      _.track("participant_update");
    } catch (error) {
      console.error(error);
    }
    return participantId;
  }

  async deleteParticipant(participantId) {
    try {
      await _.db.runTransaction(async (tx) => {
        const listDoc = await tx.get(this.docRef);
        if (!listDoc.exists) {
          throw Error(`List ${this.docRef.id} does not exist`);
        }
        const participants = listDoc
          .data()
          .participants.filter(({ id }) => id !== participantId);

        tx.update(this.docRef, {
          participants,
          participantUserIdentifiers: participants.flatMap(
            ({ userIdentifiers }) => userIdentifiers
          ),
        });
      });
      _.track("participant_delete");
    } catch (error) {
      console.error(error);
    }
  }

  async respond({ userContainer, response, parentId = null, showChoices }) {
    const participantId = userContainer.id || uuidv4();

    try {
      await _.db.runTransaction(async (tx) => {
        const listDoc = await tx.get(this.docRef);
        if (!listDoc.exists) {
          throw Error(`List ${this.docRef.id} does not exist`);
        }
        const participants = listDoc.data().participants;

        if (
          participants.some((participant) => participant.id === participantId)
        ) {
          tx.update(this.docRef, {
            participants: participants.map((participant) =>
              participant.id === participantId
                ? {
                    ...participant,
                    roles: parseRoles(participant?.roles || [], response),
                  }
                : participant
            ),
          });
        } else {
          const participantsUpdate = firebase.firestore.FieldValue.arrayUnion({
            id: participantId,
            name: userContainer.name || null,
            email: userContainer.email || null,
            userIdentifiers: userContainer.userIdentifiers || [],
            roles: response ? [response] : [],
            hasSeen: false,
            parentId,
          });
          tx.update(
            this.docRef,
            userContainer.userIdentifiers?.length > 0
              ? {
                  participants: participantsUpdate,
                  participantUserIdentifiers:
                    firebase.firestore.FieldValue.arrayUnion(
                      ...(userContainer.userIdentifiers || [])
                    ),
                }
              : { participants: participantsUpdate }
          );
        }
      });
    } catch (error) {
      Sentry.captureException(error, { tags: { event: "Respond" } });
      console.error(error);
    }

    if (showChoices && response === PARTICIPANT_ROLES.EATER) {
      _.pushModal(`/l/${this.docRef.id}/choose/${participantId}`);
    }

    return participantId;
  }

  async changeCourses(v) {
    this.rawData.courses =
      typeof v === "function" ? v(this.rawData.courses) : v;

    await this.docRef.update({ courses: this.rawData.courses });
  }

  async addCourse() {
    const newCourse = {
      id: uuidv4(),
      format: COURSE_FORMATS.SINGLE_OPTION,
      type: null,
      everyoneCanAddOptions: false,
      options: [
        {
          id: uuidv4(),
          title: "",
          dietaryInformation: null,
          image: null,
          recipe: null,
          votes: [],
        },
      ],
    };

    await this.update({
      courses: firebase.firestore.FieldValue.arrayUnion(newCourse),
    });
    _.track("course_create");
    return newCourse;
  }

  async addDescription() {
    this.update({ description: "" });
    _.track("event_description_create");
  }

  async addLocation() {
    this.update({ location: "" });
    _.track("event_location_create");
  }

  async addDeadline() {
    this.update({ closesAt: { date: null, time: null } });
    _.track("event_deadline_create");
  }

  async addOccasion() {
    this.update({ title: "" });
    _.track("event_occasion_create");
  }

  async changeCourse(courseId, v) {
    await this.changeCourses((courses) =>
      courses.map((course) =>
        course.id === courseId
          ? typeof v === "function"
            ? v(course)
            : v
          : course
      )
    );
    _.track("course_update");
  }

  async deleteCourse(courseId) {
    await this.changeCourses((courses) =>
      courses.filter(({ id }) => id !== courseId)
    );
    _.track("course_delete");
  }

  async comment(payload) {
    const id = uuidv4();
    const image = payload.image
      ? await _.uploadFileAsDataUrl(
          _.storage.ref(`events/${this.rawData.id}/comments/${id}`),
          payload.image
        )
      : null;
    await this.docRef.update({
      comments: firebase.firestore.FieldValue.arrayUnion({
        id,
        text: payload.text,
        image,
        userIdentifiers: _.getUserIdentifiers(_.auth.currentUser),
        createdAt: new Date(),
      }),
    });
    _.track("event_comment", { text: !!payload.text, image: !!image });
    this.sendGenericMessage(
      "comment",
      this.rawData.participants.filter(_.isParticipant),
      ({ currentUser, t }) => ({
        ...(image ? { image } : {}),
        body: t(
          payload.text ? "comments.textMessage" : "comments.imageMessage",
          {
            name: currentUser.name,
            text: payload.text,
          }
        ),
      })
    );
  }

  // Messages
  getDescription(lang) {
    const list = reactFirestore.getDoc("events", this.rawData.id);
    return _.getListDescription({
      list,
      chefUsers: list.chefs.map((chef) =>
        reactFirestore.data.users.find(_.isSameUser(chef))
      ),
      lang,
      notif: true,
    });
  }

  async sendGenericMessage(message, userContainers, createMessage) {
    const list = reactFirestore.getDoc("events", this.rawData.id);
    const group = reactFirestore.getDoc("groups", list.groupId);
    const userContainersWithMemberInformation = (
      Array.isArray(userContainers) ? userContainers : [userContainers]
    ).map(
      (userContainer) =>
        group.members?.find(_.isSameUser(userContainer)) || userContainer
    );
    const targetUsers = _.getUsersToBeNotified(message)(
      userContainersWithMemberInformation
    );
    const regularMoment = group.regularMoments?.find(
      ({ id }) => id === list.regularMomentId
    ) || { exists: false };
    const currentUser = reactFirestore.data.users.find(
      (user) => user.uid === _.auth.currentUser.uid
    ) || { exists: false };

    await _.sendSegmentedMessage(targetUsers, (params) => ({
      title: getNotificationTitle({ ...params, list, group, regularMoment }),
      image: list.image,
      data: {
        listId: list.id,
      },
      ...createMessage({ ...params, list, currentUser }),
    }));
  }

  async sendParticipationMessage(userContainers) {
    await this.sendGenericMessage(
      "whenAddedToList",
      userContainers,
      ({ t, lang, currentUser }) => ({
        body: `${t("notifications.whenAddedToList.prefix", {
          addedBy: currentUser.name,
        })} ${this.getDescription(lang)}`,
      })
    );
  }

  async sendInviteMessage(userContainers) {
    await this.sendGenericMessage(
      "whenInvitedToList",
      userContainers,
      ({ t, lang, currentUser, list }) => ({
        body: `${t("notifications.whenInvitedToList.prefix", {
          invitedBy: currentUser.name,
        })} ${this.getDescription(lang)}`,
        actions: [
          {
            action: "acceptInvite",
            title: t`notifications.whenSomeoneCooks.acceptInvite`,
          },
          {
            action: "declineInvite",
            title: t`notifications.whenSomeoneCooks.declineInvite`,
          },
          ...(list.chefs.length === 0
            ? [
                {
                  action: "cook",
                  title: t`notifications.whenSomeoneCooks.iCook`,
                },
              ]
            : []),
        ],
      })
    );
  }

  async sendInviteUpdateMessage(userContainers) {
    await this.sendGenericMessage(
      "whenSomeoneCooks",
      userContainers,
      ({ t, lang }) => ({
        body: `${this.getDescription(
          lang
        )} ${t`notifications.whenSomeoneCooks.question`}`,
        actions: [
          {
            action: "acceptInvite",
            title: t`notifications.whenSomeoneCooks.acceptInvite`,
          },
          {
            action: "declineInvite",
            title: t`notifications.whenSomeoneCooks.declineInvite`,
          },
        ],
      })
    );
  }

  async sendParticipationUpdateMessage(userContainers) {
    await this.sendGenericMessage(
      "whenSomeoneCooks",
      userContainers,
      ({ t, lang }) => ({
        body: `${t`notifications.whenSomeoneCooks.assertion`} ${this.getDescription(
          lang
        )}`,
      })
    );
  }

  async sendRequiredChoiceMessage(userContainers) {
    await this.sendGenericMessage(
      "whenRequiredChoice",
      userContainers,
      ({ t, currentUser }) => ({
        body: t("notifications.whenRequiredChoice.body", {
          addedBy: currentUser.name,
        }),
      })
    );
  }
}
