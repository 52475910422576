import "./Graphic.scss";
import * as _ from "utils";

import React, { useMemo } from "react";
import GraphicMember from "./GraphicMember";

export default function GroupGraphic({ group }) {
  const visibleMembers = useMemo(() => _.chooseNRandom(group.members, 6), [
    group.members,
  ]);

  return (
    <div className="relative w-24 h-24 mx-auto">
      {group.image && (
        <div
          className="group-graphic__image shadow-sm w-24 h-24 mx-auto"
          style={{ backgroundImage: `url(${group.image})` }}
        />
      )}
      {visibleMembers.map((member, i) => (
        <GraphicMember
          key={member.id}
          member={member}
          position={i}
          hasImage={group.image}
        />
      ))}
    </div>
  );
}
