import "./Transactions.scss";

import * as _ from "utils";

import React from "react";
import useTranslation from "hooks/useTranslation";
import useUser from "hooks/useUser";

import { Avatar } from "components/UI";
import UserName from "components/User/Name";
import MonetaryLiteral from "components/Expenses/MonetaryLiteral";

const processTransaction = (usersWithBalance, transaction) =>
  usersWithBalance.map(({ user, balance }) => ({
    user,
    balance:
      balance +
      transaction.amount *
        (_.isSameUser(user)(transaction.to)
          ? 1
          : _.isSameUser(user)(transaction.from)
          ? -1
          : 0),
  }));

const getUsersWithBalance = (involvedUsers, transactions) =>
  transactions
    .reduce(
      processTransaction,
      involvedUsers.map((user) => ({ user, balance: 0 }))
    )
    .sort(_.firstBy(_.getKey("balance"), -1))
    .filter(({ balance }) => balance !== 0);

const Child = ({ userContainer, role, amount }) => {
  const [user] = useUser(userContainer);
  return (
    <div
      className={`flex items-center justify-space-between space-x-2 transaction__child transaction__child--${role}`}
    >
      <div className="flex space-x-2 min-w-0 items-center">
        <Avatar user={user} size={1.5} />
        <span className="truncate">
          <UserName user={user} indicateCurrentUser />
        </span>
      </div>
      <MonetaryLiteral cents={amount} />
    </div>
  );
};

export default ({ involvedUsers, transactions }) => {
  const { t } = useTranslation();
  const usersWithBalances = getUsersWithBalance(involvedUsers, transactions);

  return usersWithBalances.map(({ user, balance }) => (
    <div key={user.userIdentifiers} className="space-y-2">
      <div
        className={`flex space-x-2 transaction__parent transaction__parent--${
          balance > 0 ? "receives" : "pays"
        }`}
      >
        <Avatar user={user} size={1.5} />
        <div>
          <p className="truncate">
            <UserName user={user} indicateCurrentUser />
          </p>
          <p className="text-xs text-caption">
            {balance > 0 ? t`expense.receives` : t`expense.pays`}
          </p>
        </div>
      </div>
      {balance > 0
        ? transactions
            .filter(({ to }) => _.isSameUser(to)(user))
            .map(({ from, amount }) => (
              <Child
                key={from.userIdentifiers}
                role="pays"
                userContainer={from}
                amount={amount}
              />
            ))
        : transactions
            .filter(({ from }) => _.isSameUser(from)(user))
            .map(({ to, amount }) => (
              <Child
                key={to.userIdentifiers}
                role="receives"
                userContainer={to}
                amount={amount}
              />
            ))}
    </div>
  ));
};
