import * as _ from "utils";

import React, { forwardRef } from "react";
import useNavigationContext from "hooks/useNavigationContext";
import usePageContext from "hooks/usePageContext";

const shouldNavigate = (event) =>
  !event.defaultPrevented &&
  event.button === 0 &&
  !(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

const ForwardedLink = forwardRef(function Link(
  { to, state, replace, getProps, children, ...anchorProps },
  ref
) {
  const { stack } = useNavigationContext();
  const [{ depth }] = usePageContext();
  const location = stack[depth]?.location;

  const href = to;
  const encodedHref = encodeURI(href);
  const isCurrent = location?.pathname === encodedHref;
  const isPartiallyCurrent = location?.pathname.includes(encodedHref);

  return (
    <a
      ref={ref}
      aria-current={isCurrent ? "page" : undefined}
      href={href}
      onClick={(event) => {
        if (anchorProps.onClick) {
          anchorProps.onClick(event);
        }
        if (shouldNavigate(event)) {
          event.preventDefault();
          _.navigate(href, {
            state,
            replace: replace || isCurrent,
          });
        }
      }}
      {...anchorProps}
      {...getProps({ isCurrent, isPartiallyCurrent, href, location })}
    >
      {children}
    </a>
  );
});
ForwardedLink.defaultProps = {
  getProps: () => {},
};

export default ForwardedLink;
