import * as _ from "utils";
import * as Sentry from "@sentry/browser";
import * as queryString from "query-string";

import { useLayoutEffect } from "react";
import reactFirestore from "react-firestore";
import useAuth from "hooks/useAuth";

export default ({ location }) => {
  const [{ isAuthenticated }] = useAuth();

  useLayoutEffect(() => {
    const create = async (params) => {
      try {
        const listDocRef = await reactFirestore.createDoc("events", params);
        _.navigate(`/l/${listDocRef.id}`, { replace: true });
      } catch (error) {
        console.log(error);
        Sentry.captureException(error, { tags: { event: "Create list" } });
        _.popModal("/planned");
      }
    };
    if (isAuthenticated) {
      create(queryString.parse(location.search));
    }
  }, [isAuthenticated, location.search]);

  return null;
};
