import * as _ from "utils";

import { useCallback } from "react";
import useTranslation from "./useTranslation";

const MAX_CHARACTERS = 32;
const MAX_CHARACTERS_BARE = 20;

export default function useListSummarize() {
  const { t } = useTranslation();

  const summarize = useCallback(
    (unorderedParticipants) => {
      const getName = (p) => (_.isCurrentUser(p) ? t`label.I` : p.name);

      const participants = unorderedParticipants.sort(_.currentUserLast);

      const fullNames = _.concatSentence(
        participants.filter(_.isNonChef).map(getName),
        t`common.and`
      );

      if (fullNames.length <= MAX_CHARACTERS) {
        return fullNames;
      }

      const nonChefs = participants.filter(_.isNonChef);
      let others = nonChefs.length;
      let s = "";

      for (let nonChef of nonChefs) {
        const name = getName(nonChef);
        if (s === "") {
          s = name;
          others--;
        } else if (`${s}, ${name}`.length <= MAX_CHARACTERS_BARE) {
          s = `${s}, ${name}`;
          others--;
        }
      }

      return `${s} ${t`common.and`} ${t("common.others", { count: others })}`;
    },
    [t]
  );

  return summarize;
}
