import * as _ from "utils";
import moment from "moment";
import reactFirestore from "react-firestore";

import BaseModel from "./Base";

const getAmount = (expense, debtor) =>
  expense.parts > 0
    ? (_.isSameUser(debtor)({
        userIdentifiers: expense.creditorUserIdentifiers,
      })
        ? expense.cents
        : 0) -
      (expense.cents * debtor.parts) / expense.parts
    : 0;

const normalizeDebtors = (expense) => {
  if (expense.divideAutomatically) {
    const group = reactFirestore.getDoc("groups", expense.groupId);
    const list = reactFirestore.getDoc("events", expense.listId);

    const possibleDebtors = group.exists
      ? group.members
      : list.exists
      ? list.maybeParticipants.filter(
          ({ userIdentifiers }) => userIdentifiers.length > 0
        )
      : [];

    return list.exists
      ? {
          ...expense,
          debtors: possibleDebtors.map((debtor) => ({
            id: debtor.id,
            name: debtor.name,
            email: debtor.email,
            userIdentifiers: debtor.userIdentifiers,
            parts: list.participants
              .map(
                (participant) =>
                  list.participants.find(
                    ({ id }) => id === participant.parentId
                  ) || participant
              )
              .filter(_.isSameUser(debtor)).length,
          })),
        }
      : {
          ...expense,
          debtors: expense.debtors || [],
        };
  }

  return {
    ...expense,
    debtors: expense.debtors || [],
  };
};

const normalizeParts = (expense) => ({
  ...expense,
  parts: expense.debtors.reduce((total, { parts }) => total + parts, 0),
});

const normalizeState = (expense) => ({
  ...expense,
  isSettled: !!expense.settlementId,
});

export default class Expense extends BaseModel {
  static create(params) {
    _.track("expense_create", {
      event_id: params.listId,
      group_id: params.groupId,
      divideAutomatically: !!params.divideAutomatically,
    });

    return {
      listId: null,
      groupId: null,
      settlementId: null,
      divideAutomatically: false,
      ...params,
    };
  }

  static normalize(expense) {
    return _.pipe(normalizeDebtors, normalizeParts, normalizeState)(expense);
  }

  async sendCreationMessage() {
    const expense = Expense.normalize(this.rawData);
    const targetUsers = expense.debtors.map(_.withMessagingInfo);
    const creditor = reactFirestore.data.users.find(
      _.isSameUser({ userIdentifiers: this.rawData.creditorUserIdentifiers })
    ) || { exists: false };

    const list = reactFirestore.getDoc("events", this.rawData.listId);
    const group = reactFirestore.getDoc("groups", this.rawData.groupId);

    await Promise.all(
      targetUsers
        .filter((user) => user.parts !== 0 || _.isSameUser(creditor)(user))
        .map((user) =>
          _.sendSegmentedMessage([user], ({ t }) => ({
            data: {
              path: list.exists
                ? `/l/${list.id}#expenses`
                : `/g/${group.id}/expenses`,
            },
            title: t(`expense.notifications.created.title`, {
              context: list.exists
                ? list.title ||
                  t(`expense.notifications.ofList`, {
                    date: moment(list.createdAt.toDate()).format("ll"),
                  })
                : group.name,
            }),
            body: _.isSameUser(creditor)(user)
              ? t(`expense.notifications.created.bodyByYou`, {
                  amount: (expense.cents / 100).toFixed(2),
                  description: this.rawData.description,
                })
              : t(`expense.notifications.created.body`, {
                  creditor: creditor.name,
                  amount: (-getAmount(expense, user) / 100).toFixed(2),
                  description: this.rawData.description,
                }),
          }))
        )
    );
  }

  async sendUpdateMessage() {
    const targetUsers = Expense.normalize(this.rawData).debtors.map(
      _.withMessagingInfo
    );
    const currentUser = reactFirestore.data.users.find(
      (user) => user.uid === _.auth.currentUser.uid
    ) || { exists: false };
    const list = reactFirestore.getDoc("events", this.rawData.listId);
    const group = reactFirestore.getDoc("groups", this.rawData.groupId);

    await _.sendSegmentedMessage(targetUsers, ({ t }) => ({
      data: {
        path: list.exists
          ? `/l/${list.id}#expenses`
          : `/g/${group.id}/expenses`,
      },
      title: t(`expense.notifications.updated.title`, {
        context: list.exists
          ? list.title ||
            t(`expense.notifications.ofList`, {
              date: moment(list.createdAt.toDate()).format("ll"),
            })
          : group.name,
      }),
      body: t(`expense.notifications.updated.body`, {
        user: currentUser.name,
        description: this.rawData.description,
      }),
    }));
  }
}
