import * as Sentry from "@sentry/browser";
import SentryRRWeb from "@sentry/rrweb";
import { Integrations } from "@sentry/tracing";

const integrations = [
  new Integrations.BrowserTracing(),
  new SentryRRWeb({
    checkoutEveryNms: 1000 * 30,
    maskAllInputs: false,
  }),
];

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  integrations,
  tracesSampleRate: 0.2,
});

Sentry.setTag("rrweb.active", "yes");
