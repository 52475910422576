import * as _ from "utils";

import { useCallback } from "react";
import useGroupContext from "./useGroupContext";
import useInvite from "./useInvite";
import useTranslation from "./useTranslation";

export default function useGroupSharing() {
  const { t } = useTranslation();
  const [group, groupResource] = useGroupContext();
  const invite = useInvite({ type: "group", groupId: group.id });

  const shareMessage = t(`group.shareMessage`, {
    group: group.name || t`group.nameless`,
    url: `${process.env.REACT_APP_SHARE_URL}/i/${invite.id}`,
  });

  const shareNative = useCallback(async () => {
    await navigator.share({
      text: shareMessage,
    });
    groupResource.update({ isShared: true });
    _.track("group_share", {
      groupId: group.id,
      method: "native",
    });
  }, [group.id, groupResource, shareMessage]);

  const tryShareNative = useCallback(
    async (options) => {
      try {
        await shareNative();
      } catch (error) {
        _.pushModal(`/g/${group.id}/share`, { timeout: 200, ...options });
      }
    },
    [group.id, shareNative]
  );

  const shareViaWhatsApp = useCallback(() => {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(shareMessage)}`,
      "_blank"
    );
    groupResource.update({ isShared: true });
  }, [groupResource, shareMessage]);

  const copyLink = useCallback(async () => {
    _.track("group_share", {
      groupId: group.id,
      method: "copy",
    });

    if (await _.copyTextToClipboard(shareMessage)) {
      groupResource.update({ isShared: true });
      return true;
    } else {
      return false;
    }
  }, [group.id, groupResource, shareMessage]);

  return [
    { message: shareMessage },
    {
      tryShareNative,
      shareViaWhatsApp,
      copyLink,
    },
  ];
}
