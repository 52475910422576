import React from "react";
import { Bugfix, Feature, Notifications } from "./components";

export default (
  <div key={4}>
    <h2 className="text-caption font-black uppercase text-lg">Update 4</h2>
    <ul className="space-y-2">
      <Feature>
        Updated app structure. Access your groups and upcoming dinners from a
        single page.
      </Feature>
      <Notifications>
        Notifications should now be delivered on some devices.
        <br />
        <span className="text-xs text-caption">
          If you are still unable to receive notifications, please reach out.
        </span>
      </Notifications>
      <Feature>
        Streamlined dinner setup. All steps in a single view, you choose which
        information you want to provide, and which not.
      </Feature>
      <Feature>
        Updated profile and settings. It includes neat statistics...
      </Feature>
      <Bugfix>Many small improvements and bugfixes.</Bugfix>
    </ul>
  </div>
);
