import * as _ from "utils";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";
import reactFirestore from "react-firestore";

import BaseModel from "./Base";

const normalizeValidity = (task) => ({ ...task, isValid: !!task.title });

const normalizeTimestamps = (task) => ({
  type: "task",
  ...task,
  isExpired: task.expiresAt?.toDate() < new Date(),
  isDue: task.dueAt?.toDate() >= new Date(),
  isCompleted: !!task.completedAt,
  calendarDate: task.dueAt?.toDate() ?? task.completedAt?.toDate() ?? null,
});

const normalizeComments = (task) => ({
  ...task,
  comments: task.comments || [],
});

const normalizeArchive = (task) => ({
  ...task,
  isArchived: task.isExpired || (task.isCompleted && !task.isDue),
});

export default class Task extends BaseModel {
  static create({ assignees = [], checklist = [], ...params }) {
    _.track("task_create");
    return {
      assigneeUserIdentifiers: assignees.flatMap(
        ({ userIdentifiers }) => userIdentifiers
      ),
      assignees: assignees.map(({ userIdentifiers }) => ({
        id: uuidv4(),
        userIdentifiers,
      })),
      checklist: checklist.map((item) => ({
        id: uuidv4(),
        isChecked: false,
        label: item,
      })),
      ...params,
    };
  }

  static normalize(task) {
    return _.pipe(
      normalizeValidity,
      normalizeTimestamps,
      normalizeComments,
      normalizeArchive
    )(task);
  }

  async setAssignees(assignees) {
    _.track("task_assign", { count: assignees.length });
    await this.update({
      assigneeUserIdentifiers: assignees.flatMap(
        ({ userIdentifiers }) => userIdentifiers
      ),
      assignees: assignees.map(({ userIdentifiers }) => ({
        id: uuidv4(),
        userIdentifiers,
      })),
    });
  }

  async toggleItem(itemId, isChecked) {
    _.track(isChecked ? "task_item_complete" : "task_item_undo_complete");
    await this.update({
      checklist: this.rawData.checklist.map((item) =>
        item.id === itemId
          ? {
              ...item,
              isChecked,
            }
          : item
      ),
    });
  }

  async complete() {
    await this.update({ completedAt: new Date() });
    _.track("task_complete");
  }

  async undoCompletion() {
    await this.update({ completedAt: null });
    _.track("task_undo_complete");
  }

  async comment(payload) {
    const id = uuidv4();
    const image = payload.image
      ? await _.uploadFileAsDataUrl(
          _.storage.ref(`tasks/${this.rawData.id}/comments/${id}`),
          payload.image
        )
      : null;
    await this.docRef.update({
      comments: firebase.firestore.FieldValue.arrayUnion({
        id,
        text: payload.text,
        image,
        userIdentifiers: _.getUserIdentifiers(_.auth.currentUser),
        createdAt: new Date(),
      }),
    });
    _.track("task_comment", { text: !!payload.text, image: !!image });
    this.sendMessage(({ currentUser, t }) => ({
      ...(image ? { image } : {}),
      body: t(payload.text ? "comments.textMessage" : "comments.imageMessage", {
        name: currentUser.name,
        text: payload.text,
      }),
    }));
  }

  async sendMessage(createMessage) {
    const group = reactFirestore.getDoc("groups", this.rawData.groupId);
    const targetUsers = group.members.map(_.withMessagingInfo);
    const currentUser = reactFirestore.data.users.find(
      (user) => user.uid === _.auth.currentUser.uid
    ) || { exists: false };

    await _.sendSegmentedMessage(targetUsers, (params) => ({
      data: {
        taskId: this.rawData.id,
      },
      title: this.rawData.dueAt
        ? `${this.rawData.title} (${moment(this.rawData.dueAt.toDate()).format(
            "DD-MM"
          )}) - ${group.name}`
        : `${this.rawData.title} - ${group.name}`,
      ...createMessage({ ...params, currentUser }),
    }));
  }
}
