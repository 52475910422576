import "./WrappingBox.scss";
import cx from "classnames";

import React from "react";

export default ({ spacing = 2, className, ...props }) => (
  <div
    className={cx(`flex flex-wrap wrapping-box--${spacing}`, className)}
    {...props}
  />
);
