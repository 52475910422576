import "./Courses.scss";
import * as _ from "utils";
import { v4 as uuidv4 } from "uuid";

import React, { useEffect, useState } from "react";
import useListContext from "hooks/useListContext";

import * as Icons from "components/Icons";
import CollapsibleContainer from "components/CollapsibleContainer";
import ExpandedCourses from "./Expanded";
import CollapsedCourses from "./Collapsed";

export default function Courses(props) {
  const [list, listResource] = useListContext();
  const hasExtensiveMenu = _.hasExtensiveMenu(list);

  const [isExpanded, setExpanded] = useState(hasExtensiveMenu);

  const courseCount = list.courses?.length || 0;
  const dishCount = courseCount > 0 ? list.courses[0].options?.length || 0 : 0;

  useEffect(() => {
    if (courseCount === 0) {
      setExpanded(false);
      listResource.addCourse();
    } else if (dishCount === 0 && !isExpanded) {
      setExpanded(false);
      listResource.update({
        courses: [
          {
            ...listResource.rawData.courses[0],
            options: [
              {
                id: uuidv4(),
                title: "",
                dietaryInformation: null,
                image: null,
                recipe: null,
                votes: [],
              },
            ],
          },
        ],
      });
    }
  }, [courseCount, dishCount, listResource, isExpanded]);

  return (
    <CollapsibleContainer
      isVisible={props.isEditing || hasExtensiveMenu}
      icon={isExpanded ? null : Icons.Cutlery}
    >
      {hasExtensiveMenu || isExpanded ? (
        <ExpandedCourses {...props} />
      ) : (
        list.courses.length > 0 &&
        list.courses[0].options.length > 0 && (
          <CollapsedCourses {...props} onExpand={() => setExpanded(true)} />
        )
      )}
    </CollapsibleContainer>
  );
}
