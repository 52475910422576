import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";
import { pruneObject } from "utils/objects";
import nonExistentDoc from "./nonExistentDoc";

const firestore = firebase.firestore();
const auth = firebase.auth();

class ReactFirestore {
  observers = [];
  resolvers = [];
  queries = [];
  models = {};
  modelInstances = {};
  data = {};

  addModel(collection, model) {
    this.models[collection] = model;
    this.modelInstances[collection] = {};
    this.data[collection] = [];
  }

  init() {}

  processData(snapshot) {
    const touchedCollections = new Set();
    const touchedDocs = new Set();

    if (snapshot._key && snapshot.exists) {
      console.log(snapshot);
      console.log("doc");
      // {
      //   id: snapshot.id,
      //   exists: true,
      //   ...snapshot.data(),
      // }
    } else if (!snapshot.empty && snapshot.docChanges) {
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const collection = doc.ref.parent.id;
        const rawData = { id: doc.id, ...doc.data() };
        const idx = this.data[collection].findIndex(({ id }) => id === doc.id);
        touchedCollections.add(collection);
        touchedDocs.add(doc.id);
        switch (change.type) {
          case "added":
          case "modified":
            this.getModel(collection, doc.id).setRawData(rawData);
            const data = this.models[collection].normalize({
              ...rawData,
              exists: true,
              hasExisted: true,
            });

            if (idx === -1) {
              this.data[collection].push(data);
            } else {
              this.data[collection][idx] = data;
            }
            break;
          case "removed": {
            if (idx !== -1) {
              this.data[collection][idx].exists = false;
            }
            break;
          }
          default:
            break;
        }
      });
    }

    const queriesResolved = !this.queries.some((query) => query.pending);
    this.resolvers.forEach((resolver) => {
      if (
        queriesResolved ||
        touchedCollections.has(resolver.key) ||
        touchedDocs.has(resolver.key)
      ) {
        resolver.resolve(Date.now());
      }
    });
    this.observers.forEach((observer) => {
      if (touchedCollections.has(observer.key)) {
        observer.onNext(this.data[observer.key] || []);
      }
      if (touchedDocs.has(observer.key)) {
        observer.onNext(Date.now());
      }
    });
  }

  listenToQuery(query) {
    return query.firestoreQuery.onSnapshot(
      (snapshot) => {
        query.pending = false;
        this.resolvers.forEach((resolver) => {
          if (query.key === resolver.key) {
            resolver.resolve(Date.now());
          }
        });
        this.processData(snapshot);
      },
      (error) => {
        query.pending = false;
        query.error = error;
        console.log(query.key, error);
        if (!auth.currentUser) {
          this.observers = [];
          this.resolvers = [];
          this.queries = [];
          for (let key in this.modelInstances) {
            this.modelInstances[key] = {};
          }
          for (let key in this.data) {
            this.data[key] = [];
          }
        }
      }
    );
  }

  activateQuery(firestoreQuery) {
    const existingQuery = this.queries.find((query) =>
      firestoreQuery.isEqual(query.firestoreQuery)
    );

    if (existingQuery) {
      return existingQuery;
    }

    const query = {
      key: uuidv4(),
      firestoreQuery,
      pending: true,
      error: null,
    };
    query.unlisten = this.listenToQuery(query);
    this.queries.push(query);

    return query;
  }

  readQueries(firestoreQueries) {
    const queries = firestoreQueries.map((firestoreQuery) =>
      this.activateQuery(firestoreQuery)
    );

    if (queries.some((query) => query.pending)) {
      throw new Promise((resolve) =>
        queries.forEach((query) => {
          this.resolvers.push({
            key: query.key,
            resolve,
          });
        })
      );
    }
  }

  async performQuery(firestoreQuery, preprocess = (x) => x) {
    try {
      const data = await firestoreQuery.get();
      this.processData(preprocess(data));
    } catch (error) {
      console.log(error);
    }
  }

  async createDoc(collection, params) {
    return await firestore.collection(collection).add(
      pruneObject({
        createdAt: new Date(),
        updatedAt: new Date(),
        ...this.models[collection].create(params || {}),
      })
    );
  }

  observeCollection(collection, onNext) {
    const observer = {
      key: collection,
      onNext,
    };
    if (observer.key) {
      this.observers.push(observer);
      return () => {
        this.observers = this.observers.filter((other) => other !== observer);
      };
    } else {
      return () => {};
    }
  }

  observeDoc(collection, docIdentifier, onNext) {
    const observer = {
      key: typeof docIdentifier === "function" ? collection : docIdentifier,
      onNext,
    };
    if (observer.key) {
      this.observers.push(observer);
      return () => {
        this.observers = this.observers.filter((other) => other !== observer);
      };
    } else {
      return () => {};
    }
  }

  getDoc(collection, docIdentifier) {
    const collectionData = this.data[collection];
    return (
      (typeof docIdentifier === "function"
        ? collectionData.find(docIdentifier)
        : collectionData.find(({ id }) => id === docIdentifier)) ||
      nonExistentDoc
    );
  }

  readDoc(collection, docIdentifier) {
    if (this.queries.some((query) => query.pending)) {
      throw new Promise((resolve) =>
        this.resolvers.push({
          key: typeof docIdentifier === "function" ? collection : docIdentifier,
          resolve,
        })
      );
    }

    return this.getDoc(collection, docIdentifier);
  }

  getCollection(collection) {
    return this.data[collection] || [];
  }

  readCollection(collection) {
    if (this.queries.some((query) => query.pending)) {
      throw new Promise((resolve) =>
        this.resolvers.push({
          key: collection,
          resolve,
        })
      );
    }

    return this.getCollection(collection);
  }

  getModel(collection, docId) {
    if (!docId) {
      return null;
    }

    if (!(docId in this.modelInstances[collection])) {
      this.modelInstances[collection][docId] = new this.models[collection](
        firestore.collection(collection).doc(docId)
      );
    }
    return this.modelInstances[collection][docId];
  }
}

export { ReactFirestore };

export default new ReactFirestore();
