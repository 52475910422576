import * as _ from "utils";

import React, { useEffect, useRef, useState } from "react";
import useListSharing from "hooks/useListSharing";
import useTranslation from "hooks/useTranslation";

import withList from "hocs/withList";

import { BottomModalLayout } from "layouts/Modal";

import * as Icons from "components/Icons";
import { Button, IconButton, ToolTip } from "components/UI";
import WhatsAppFormattedText from "components/WhatsAppFormattedText";

export default withList(({ listId }) => {
  const { t } = useTranslation();
  const [{ message }, { shareViaWhatsApp, copyLink }] = useListSharing(listId);

  const actionButtonRef = useRef(null);
  const [state, setState] = useState(null);

  useEffect(() => {
    if (state) {
      const t = setTimeout(() => setState(null), 1000);
      return () => clearTimeout(t);
    }
  }, [state]);

  return (
    <BottomModalLayout>
      <div className="rounded-b-none space-y-4 p-4 bg-card rounded-md">
        <div>
          <h2>{t`label.example`}</h2>
          <WhatsAppFormattedText>{message}</WhatsAppFormattedText>
        </div>
        <div className="flex items-center space-x-4">
          <Button
            color="whatsapp"
            onClick={() => {
              shareViaWhatsApp();
              _.popModal(`/l/${listId}`);
            }}
          >
            WhatsApp
          </Button>
          <IconButton
            ref={actionButtonRef}
            color="card"
            onClick={async () => {
              const success = await copyLink();
              setState(success ? "success" : "failed");
            }}
          >
            <Icons.Copy />
          </IconButton>
          <ToolTip originRef={actionButtonRef}>
            {state === "failed"
              ? t`label.copyFailed`
              : state === "success"
              ? t`label.copySuccessful`
              : t`action.copyLink`}
          </ToolTip>
        </div>
      </div>
    </BottomModalLayout>
  );
});
