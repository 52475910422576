import { useCallback, useMemo } from "react";
import useCourseContext from "./useCourseContext";

export default function useCourseOption(courseOptionId) {
  const [
    course,
    {
      onChangeOption,
      onDeleteOption,
      onChangeOptionImage,
      onDeleteOptionImage,
    },
  ] = useCourseContext();

  const option = useMemo(
    () => course.options.find(({ id }) => id === courseOptionId),
    [course.options, courseOptionId]
  );

  const onChange = useCallback((v) => onChangeOption(courseOptionId, v), [
    onChangeOption,
    courseOptionId,
  ]);
  const onDelete = useCallback(() => onDeleteOption(courseOptionId), [
    onDeleteOption,
    courseOptionId,
  ]);

  const onChangeImage = useCallback(
    (v) => onChangeOptionImage(courseOptionId, v),
    [onChangeOptionImage, courseOptionId]
  );
  const onDeleteImage = useCallback(() => onDeleteOptionImage(courseOptionId), [
    onDeleteOptionImage,
    courseOptionId,
  ]);

  return [
    option,
    {
      onChange,
      onDelete,
      onChangeImage,
      onDeleteImage,
    },
  ];
}
