import { Plugins, StatusBarStyle } from "@capacitor/core";

import React, { useLayoutEffect } from "react";
import { ColorModeContext } from "contexts";
import useColorMode from "hooks/useColorMode";

const { StatusBar } = Plugins;

async function updateStatusBar(colorMode) {
  try {
    await StatusBar.setStyle({
      style: colorMode === "dark" ? StatusBarStyle.Dark : StatusBarStyle.Light,
    });
    await StatusBar.setBackgroundColor({
      color: colorMode === "dark" ? "#212121" : "#ffffff",
    });
  } catch (error) {}
}

export default function ColorModeProvider({ children }) {
  const value = useColorMode();

  useLayoutEffect(() => {
    const colorMode = value[0];
    updateStatusBar(colorMode);
    document.body.classList.toggle("dark", colorMode === "dark");
    document.body.classList.toggle("light", colorMode === "light");
  }, [value]);

  return (
    <ColorModeContext.Provider value={value}>
      {children}
    </ColorModeContext.Provider>
  );
}
