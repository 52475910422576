import * as _ from "utils";

import React, { useState } from "react";
import useGroupContext from "hooks/useGroupContext";
import useGroupStatistics from "hooks/useGroupStatistics";
import useTranslation from "hooks/useTranslation";

import { Button, DropDown } from "components/UI";
import UserDetail from "components/User/Detail";
import MonetaryLiteral from "components/Expenses/MonetaryLiteral";

const Statistic = ({ statistic, value }) => {
  switch (statistic) {
    case "monetaryBalance":
      return <MonetaryLiteral cents={value} />;
    case "cookBalance":
      return (
        <strong
          className={`text-${
            value > 0 ? "success" : value < 0 ? "error" : "caption"
          }`}
        >
          {_.signed(Math.round(value * 2) / 2)}
        </strong>
      );
    default:
      return <strong className="text-text">{value}</strong>;
  }
};

export default () => {
  const { t } = useTranslation();
  const [group] = useGroupContext();
  const [statistic, setStatistic] = useState(
    process.env.REACT_APP_EXPENSES_ENABLED ? "monetaryBalance" : "cookBalance"
  );
  const membersWithStatistics = useGroupStatistics(group.id);

  const membersByStatistic = membersWithStatistics
    .map((member) => ({
      ...member,
      statistic: member.statistics ? member.statistics[statistic] : 0,
    }))
    .sort(_.firstBy(_.getKey("statistic"), -1));

  return (
    <div className="px-4 pb-4 space-y-4">
      <div className="space-y-2">
        <DropDown
          size="sm"
          options={[
            ...(process.env.REACT_APP_EXPENSES_ENABLED
              ? [
                  {
                    value: "monetaryBalance",
                    label: t`group_statistics.monetaryBalance`,
                  },
                ]
              : []),
            { value: "cookBalance", label: t`group_statistics.cookBalance` },
            { value: "chefCount", label: t`group_statistics.chefCount` },
            {
              value: "participationCount",
              label: t`group_statistics.participationCount`,
            },
          ]}
          value={statistic}
          onChange={setStatistic}
          required
        />
        <p className="text-xs text-caption">
          {t(`group_statistics.${statistic}Explanation`)}
        </p>
      </div>
      {membersByStatistic.map((member) => (
        <div
          key={member.id}
          className="flex items-center justify-space-between"
        >
          <UserDetail userContainer={member} />
          <Statistic statistic={statistic} value={member.statistic} />
        </div>
      ))}
      {statistic === "monetaryBalance" && (
        <div className="flex justify-flex-end">
          <Button
            color="primary"
            onClick={() => _.pushModal(`/g/${group.id}/settle`)}
          >{t`expense.newSettlement`}</Button>
        </div>
      )}
    </div>
  );
};
