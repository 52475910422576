import * as _ from "utils";

import React, { useLayoutEffect } from "react";
import { useDoc } from "react-firestore";
import { ExpenseContext } from "contexts";

import withAuth from "hocs/withAuth";

import NotFoundPage from "pages/404";

const ExpenseProvider = ({ expenseId, children }) => {
  const value = useDoc("expenses", expenseId);

  useLayoutEffect(() => {
    _.setAnalyticsContext({ expense_id: expenseId });
    return () => {
      _.setAnalyticsContext({ expense_id: undefined });
    };
  }, [expenseId]);

  return value[0].hasExisted ? (
    <ExpenseContext.Provider value={value}>{children}</ExpenseContext.Provider>
  ) : (
    <NotFoundPage />
  );
};

export default withAuth(ExpenseProvider);
