import * as _ from "utils";
import cx from "classnames";

import React from "react";
import useTranslation from "hooks/useTranslation";
import useUser from "hooks/useUser";

import { Avatar } from "components/UI";

export default function Comment({ comment }) {
  const { moment } = useTranslation();

  const isMine = _.isCurrentUser(comment);
  const [user] = useUser(comment);

  return (
    <div
      className={cx(
        "flex items-flex-end space-x-4",
        `justify-flex-${isMine ? "end" : "start"}`
      )}
    >
      {isMine ? null : (
        <div className="sticky bottom-0 pb-4">
          <Avatar size={2.5} user={user} />
        </div>
      )}
      <div
        className={cx("message__container min-w-0", isMine ? "ml-12" : "mr-4")}
      >
        <div
          className={cx(
            "rounded-md shadow-sm bg-card overflow-hidden",
            isMine ? "rounded-br-none" : "rounded-bl-none"
          )}
        >
          {comment.image && (
            <img
              className="block w-full"
              src={comment.image}
              alt={comment.text}
            />
          )}
          {comment.text && (
            <p className={"px-4 py-2 break-words whitespace-pre-line"}>
              {comment.text}
            </p>
          )}
        </div>
        <p className="text-xs text-caption">
          {moment(comment.createdAt.toDate()).fromNow()}
        </p>
      </div>
    </div>
  );
}
