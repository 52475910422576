import * as _ from "utils";

import React from "react";
import useTranslation from "hooks/useTranslation";
import { useDoc } from "react-firestore";

import * as Icons from "components/Icons";

export default function SettlementThumb({ settlement }) {
  const { moment } = useTranslation();
  const [group] = useDoc("groups", settlement.groupId);
  const [list] = useDoc("events", settlement.listId);

  return (
    <div
      key={settlement.id}
      className="flex items-center justify-space-between space-x-2 p-2 pl-4 rounded-md btn--card cursor-pointer shadow-sm"
      onClick={() => _.pushModal(`/s/${settlement.id}`)}
    >
      <div>
        <p>{moment(settlement.createdAt.toDate()).format("ll")}</p>
        <p className="text-xs text-caption">
          {group.exists ? group.name : list.exists ? list.title : null}
        </p>
      </div>
      <Icons.Caret direction="right" className="text-caption" />
    </div>
  );
}
