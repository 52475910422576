import * as _ from "utils";

import React from "react";
import useGroupContext from "hooks/useGroupContext";
import useTranslation from "hooks/useTranslation";
import useUpstreamState from "hooks/useUpstreamState";

import withGroup from "hocs/withGroup";

import * as Icons from "components/Icons";
import { Button, Field, ImageInput } from "components/UI";
import SimpleLayout from "layouts/Simple";
import RecurringLists from "components/Group/RecurringLists";
import RecurringTasks from "components/Group/RecurringTasks";
import Members from "components/Group/Members";

export default withGroup(() => {
  const { t } = useTranslation();
  const [group, groupResource] = useGroupContext();

  const [name, setName] = useUpstreamState(group.name || "");

  const onLeave = () => {
    groupResource.leave();
    _.popToTop("/");
  };

  return (
    <SimpleLayout
      parentLocation={`/g/${group.id}`}
      title={group.name || t`group.nameless`}
    >
      <div className="space-y-4">
        <ImageInput
          className="w-48 h-48 rounded-full mx-auto image-input--textured"
          value={group.image}
          onChange={(v) => groupResource.setImage(v)}
          onDelete={() => groupResource.update({ image: null })}
          compression={{
            maxArea: 320 * 320,
            q: 0.75,
            type: "image/jpeg",
          }}
        />
        <Field
          size="lg"
          variant="contained"
          label={t`group_setup.name.label`}
          placeholder={t`group_setup.name.placeholder`}
          value={name}
          onChange={(event) => setName(event.target.value)}
          onBlur={() => groupResource.update({ name: name })}
        />
        <RecurringLists />
        <RecurringTasks />
        <Members />
        <h2>{t`group.miscelaneous`}</h2>
        <Button
          color="error"
          className="w-full"
          onClick={onLeave}
          lead={<Icons.Door />}
        >
          {t`action.leaveGroup`}
        </Button>
      </div>
    </SimpleLayout>
  );
});
