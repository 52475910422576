import * as _ from "utils";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";

import React, { useEffect, useState } from "react";
import useContentHeight from "hooks/useContentHeight";
import useCurrentUser from "hooks/useCurrentUser";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";

import withAuth from "hocs/withAuth";

import { BottomModalLayout } from "layouts/Modal";

import * as Icons from "components/Icons";
import { Button, Field } from "components/UI";
import FinancialIcon from "components/Transactions/FinancialIcon";

const add32 = (x) => x + 32;

export default withAuth(() => {
  const { t } = useTranslation();
  const [currentUser, currentUserResource] = useCurrentUser();
  const [, { onClose }] = usePageContext();
  const containerRef = useContentHeight(add32);

  const [type, setType] = useState(null);
  const [value, setValue] = useState("");
  const [name, setName] = useState("");
  const [hasError, setError] = useState(false);
  const chosenAddress = _.paymentAddresses.find(
    (address) => address.type === type
  );
  const isValid =
    value &&
    chosenAddress &&
    (typeof chosenAddress.validate !== "function" ||
      chosenAddress.validate(value));

  useEffect(() => {
    if (!type || isValid) {
      setError(false);
    }
  }, [isValid, type]);

  useEffect(() => {
    if (!type) {
      setValue("");
    }
  }, [type]);

  return (
    <BottomModalLayout>
      <div
        ref={containerRef}
        className="transition-dimension duration-200 ease-in-out rounded-b-none space-y-4 p-4 rounded-md bg-card overflow-y-auto"
      >
        <h2>{t`settings.payments.newAddress`}</h2>
        {chosenAddress ? (
          <>
            {chosenAddress.type === "misc" && (
              <p>{t`settings.payments.warning`}</p>
            )}
            <div className="space-y-2">
              <Field
                autoFocus
                variant="contained"
                prefix={chosenAddress.prefix}
                label={t(`settings.payments.${type}`)}
                lead={<FinancialIcon size={0.75} type={type} />}
                value={value}
                onChange={(event) =>
                  setValue(chosenAddress.formatValue(event.target.value))
                }
                onBlur={() => !isValid && setError(true)}
                error={
                  hasError
                    ? value
                      ? t`settings.payments.invalidIBAN`
                      : t`settings.payments.fieldCannotBeEmpty`
                    : null
                }
              />
              <div className="flex justify-flex-end">
                <Button
                  variant="text"
                  lead={<Icons.Undo size={0.75} />}
                  className="text-xs"
                  onClick={() => setType(null)}
                >{t`settings.payments.differentAddress`}</Button>
              </div>
            </div>
            {chosenAddress.type === "iban" && (
              <Field
                variant="contained"
                label={t`settings.payments.inNameOf`}
                placeholder={currentUser.name}
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            )}
            <div className="flex flex-col-reverse items-normal sm:flex-row sm:justify-flex-end sm:items-center">
              <Button
                variant="text"
                className="mt-2 sm:mt-0 sm:mr-4"
                onClick={() => onClose()}
              >{t`action.cancel`}</Button>
              <Button
                color="primary"
                size="sm"
                onClick={async () => {
                  await currentUserResource.update({
                    paymentAddresses: firebase.firestore.FieldValue.arrayUnion({
                      id: uuidv4(),
                      type,
                      value,
                      name,
                    }),
                  });
                  _.track("account_payment_address_create", { type });
                  onClose();
                }}
                disabled={!isValid}
              >{t`action.confirm`}</Button>
            </div>
          </>
        ) : (
          _.paymentAddresses.map((address) => (
            <div
              key={address.type}
              className="flex justify-space-between items-center clickable"
              onClick={() => setType(address.type)}
            >
              <div className="flex items-center space-x-2">
                <FinancialIcon type={address.type} />
                <p>{t(`settings.payments.${address.type}`)}</p>
              </div>
              <Icons.Caret direction="right" className="text-caption" />
            </div>
          ))
        )}
      </div>
    </BottomModalLayout>
  );
});
