import React from "react";
import useListContext from "hooks/useListContext";
import useCourseContext from "hooks/useCourseContext";

export default ({ courseOption, currentParticipantId }) => {
  const [list] = useListContext();
  const [, { onChange }] = useCourseContext();

  const isParticipant = list.participants.some(
    (participant) => participant.id === currentParticipantId
  );

  return (
    <input
      type="radio"
      disabled={!isParticipant}
      checked={
        isParticipant &&
        courseOption.votes.some(
          (vote) =>
            vote === currentParticipantId ||
            vote.participantId === currentParticipantId
        )
      }
      onChange={() =>
        onChange((course) => ({
          ...course,
          options: course.options.map((option) =>
            option.id === courseOption.id
              ? {
                  ...option,
                  votes: option.votes.some(
                    (vote) =>
                      vote === currentParticipantId ||
                      vote.participantId === currentParticipantId
                  )
                    ? option.votes
                    : option.votes.concat(currentParticipantId),
                }
              : {
                  ...option,
                  votes: option.votes.filter(
                    (vote) =>
                      vote !== currentParticipantId &&
                      vote.participantId !== currentParticipantId
                  ),
                }
          ),
        }))
      }
    />
  );
};
