import cx from "classnames";

import React from "react";
import { useDoc } from "react-firestore";
import useTaskContext from "hooks/useTaskContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Button } from "components/UI";

export default function TaskActions({ className }) {
  const { t } = useTranslation();
  const [task, taskResource] = useTaskContext();
  const [group] = useDoc("groups", task.groupId);

  return (
    <div className={cx("sticky bottom-safe pb-4", className)}>
      {task.isCompleted ? (
        <Button
          color="card"
          lead={<Icons.Undo />}
          onClick={() => taskResource.undoCompletion()}
          disabled={task.isExpired}
          className="w-full"
        >{t`task.undoCompletion`}</Button>
      ) : task.assignees.length > 0 ? (
        <Button
          color="secondary"
          lead={<Icons.Check />}
          onClick={() => taskResource.complete()}
          disabled={task.isExpired}
          className="w-full"
        >{t`task.complete`}</Button>
      ) : (
        <Button
          color="secondary"
          onClick={() => taskResource.setAssignees([group.myMembership])}
          disabled={task.isExpired}
          className="w-full"
        >{t`task.selfAssign`}</Button>
      )}
    </div>
  );
}
