import * as _ from "utils";
import Expense from "models/Expense";

import React from "react";
import useUsers from "hooks/useUsers";

import * as Icons from "components/Icons";
import { Avatar, IconButton } from "components/UI";
import UserName from "components/User/Name";
import MonetaryLiteral from "./MonetaryLiteral";

export default ({ value, onChange, disabled }) => {
  const updateDebtor = (debtorId, v) =>
    onChange((value) => ({
      ...value,
      debtors: (value.debtors || []).map((debtor) =>
        debtor.id === debtorId
          ? typeof v === "function"
            ? v(debtor)
            : v
          : debtor
      ),
    }));

  const { debtors, parts } = value.divideAutomatically
    ? Expense.normalize(value)
    : {
        ...value,
        parts: (value.debtors || []).reduce(
          (acc, debtor) => acc + debtor.parts,
          0
        ),
      };
  const users = useUsers(debtors).sort(
    _.firstBy((user) => user.name || user.email || "")
  );

  const getMutation = (debtor) =>
    parts > 0
      ? (_.isSameUser(debtor)({
          userIdentifiers: value.creditorUserIdentifiers,
        })
          ? value.cents
          : 0) -
        (value.cents * debtor.parts) / parts
      : 0;

  return (
    <div className="flex flex-wrap wrapping-box--4">
      {users.map((user) => (
        <div
          key={user.id}
          className="debtor w-full flex items-center justify-space-between space-x-4"
        >
          <div className="flex items-center min-w-0 space-x-2">
            <Avatar user={user} size={1.5} />
            <p className="truncate">
              <UserName user={user} />
            </p>
          </div>
          <div className="flex items-center space-x-2">
            {!disabled && (
              <IconButton
                color="card"
                size="xs"
                disabled={user.parts <= 0}
                onClick={() =>
                  updateDebtor(user.id, (user) => ({
                    ...user,
                    parts: Math.max(0, user.parts - 1),
                  }))
                }
              >
                <Icons.Min size={0.75} />
              </IconButton>
            )}
            <span className="inline-block w-4">{user.parts}x</span>
            {!disabled && (
              <IconButton
                color="card"
                size="xs"
                onClick={() =>
                  updateDebtor(user.id, (user) => ({
                    ...user,
                    parts: user.parts + 1,
                  }))
                }
              >
                <Icons.Plus size={0.75} />
              </IconButton>
            )}
            <MonetaryLiteral cents={getMutation(user)} allwaysShowSign />
          </div>
        </div>
      ))}
    </div>
  );
};
