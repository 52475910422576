import React from "react";
import useNavigationContext from "hooks/useNavigationContext";

import { Router } from "@reach/router";
import PageRoot from "containers/PageRoot";

export default function StackRouter({ children, ...props }) {
  const { stack, pointer } = useNavigationContext();

  return stack.map(({ key, location, isOpen, timeout }, i) => (
    <PageRoot
      key={key}
      location={location}
      isOpen={isOpen}
      depth={i}
      isCurrent={pointer === i}
      timeout={timeout}
    >
      <Router {...props} location={location} primary={false}>
        {children}
      </Router>
    </PageRoot>
  ));
}
