import cx from "classnames";

import React, { useState } from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { IconButton, ImageInput, TextArea } from "components/UI";

export default function CommentInput({ resource }) {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        if (text || image) {
          setLoading(true);
          await resource.comment({ text, image });
          setText("");
          setImage(null);
          setLoading(false);
        }
      }}
      className="flex space-x-4 items-flex-end"
    >
      <TextArea
        variant="contained"
        placeholder={
          image ? t`comments.placeholderWithImage` : t`comments.placeholder`
        }
        value={text}
        onChange={(event) => setText(event.target.value)}
        disabled={loading}
        trail={
          <ImageInput
            className={cx(
              "my--1 mr--3 flex-shrink-0 rounded-md",
              image ? "w-12 h-12" : "w-8 h-8"
            )}
            {...(image
              ? {}
              : { style: { background: "transparent", border: "none" } })}
            value={image}
            onChange={(value) => setImage(value)}
            onDelete={() => setImage(null)}
            disabled={loading}
          />
        }
      />
      <IconButton color="primary" disabled={loading || !(text || image)}>
        <Icons.Share />
      </IconButton>
    </form>
  );
}
