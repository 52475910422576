import * as _ from "utils";
import cx from "classnames";

import React from "react";
import useTranslation from "hooks/useTranslation";

import CalendarItem from "./CalendarItem";
import EmptyCalendarDay from "./EmptyCalendarDay";

export default ({ day, items, ...props }) => {
  const { moment } = useTranslation();

  const isToday = moment().startOf("day").isSame(day);

  const sortedItems = items.sort(_.firstBy((item) => item.calendarDate));

  return (
    <div className="flex items-flex-start space-x-4 px-4">
      <div className="calendar__day">
        <p
          className={cx("calendar__day-of-week", {
            "calendar__day-of-week--today": isToday,
          })}
        >
          {day.format("dd")}
        </p>
        <p
          className={cx("calendar__day-of-month", {
            "calendar__day-of-month--today": isToday,
          })}
        >
          {day.format("DD")}
        </p>
      </div>
      <div className="space-y-4 flex-grow min-w-0">
        {sortedItems.length === 0 && <EmptyCalendarDay {...props} />}
        {sortedItems.map((item, i) => (
          <CalendarItem key={i} item={item} {...props} />
        ))}
      </div>
    </div>
  );
};
