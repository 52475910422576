import auth from "./auth.json";
import base from "./base.json";
import comments from "./comments.json";
import errors from "./errors.json";
import expense from "./expense.json";
import frequency from "./frequency.json";
import group from "./group.json";
import group_notifications from "./group_notifications.json";
import group_setup from "./group_setup.json";
import group_statistics from "./group_statistics.json";
import invite from "./invite.json";
import list from "./list.json";
import notifications from "./notifications.json";
import profile from "./profile.json";
import settings from "./settings.json";
import settlements from "./settlements.json";
import status from "./status.json";
import task from "./task.json";
import transactions from "./transactions.json";

export default {
  auth,
  ...base,
  comments,
  errors,
  expense,
  frequency,
  group,
  group_notifications,
  group_setup,
  group_statistics,
  invite,
  list,
  notifications,
  profile,
  settings,
  settlements,
  status,
  task,
  transactions,
};
