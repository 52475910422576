import * as _ from "utils";

import BaseResource from "./BaseResource";

import { Plugins } from "@capacitor/core";

const { Contacts, Storage } = Plugins;

const withUserIdentifiers = (contact) =>
  contact.emails
    .filter((email) => typeof email === "string")
    .map((email) => ({
      ...contact,
      name: contact.displayName?.trim(),
      email,
      userIdentifiers: [_.md5(email)],
    }));

class ContactsResource extends BaseResource {
  constructor() {
    super();
    this.data = {
      permission: null,
      contacts: [],
    };
    this.initialize();
  }

  async initialize() {
    const { value } = await Storage.get({ key: "contacts_permission" });
    this.data.permission = _.isNothing(value) ? null : JSON.parse(value);
    await this.fetch();
  }

  onNext(data) {
    if (this.data.permission !== data.permission) {
      Storage.set({
        key: "contacts_permission",
        value: JSON.stringify(data.permission),
      });
    }

    const contacts = data.contacts
      .flatMap(withUserIdentifiers)
      .filter((contact) => contact.userIdentifiers.length > 0 && contact.name)
      .sort(_.firstBy((contact) => contact.name));
    super.onNext({ ...data, contacts });
  }

  async requestPermission() {
    try {
      const permission = await Contacts.getPermissions();
      if (!permission) {
        this.onNext({ ...this.data, permission: "granted" });
      } else if (permission.granted) {
        this.onNext({
          ...this.data,
          permission: "granted",
          contacts: (await Contacts.getContacts()).contacts,
        });
      } else {
        this.onNext({
          ...this.data,
          permission: "denied",
        });
      }
    } catch (error) {
      this.onNext({ ...this.data, permission: "unsupported" });
    }
  }

  async fetch() {
    if (this.data.permission === "granted") {
      this.onNext({
        ...this.data,
        contacts: (await Contacts.getContacts()).contacts,
      });
    } else {
      this.onNext(this.data);
    }
  }
}

export default ContactsResource;
