import * as _ from "utils";
import { v4 as uuidv4 } from "uuid";

import React from "react";
import reactFirestore, { useCollection } from "react-firestore";
import useGroupRecencyContext from "hooks/useGroupRecencyContext";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";

import { Avatar, WrappingBox } from "components/UI";

const groupExists = (group) => group.isSetup && group.exists;

export default () => {
  const { t } = useTranslation();
  const [list, listResource] = useListContext();
  const groups = useCollection("groups", { filter: groupExists });
  const [recentGroupIds] = useGroupRecencyContext();

  const sortedGroups = groups
    .slice()
    .sort(
      _.firstBy((group) =>
        recentGroupIds.indexOf(group.id) === -1
          ? Infinity
          : recentGroupIds.indexOf(group.id)
      )
    );

  const selectGroup = (groupId) => {
    const group = reactFirestore.getDoc("groups", groupId);
    const participants = list.maybeParticipants.filter(
      (participant) => participant.roles?.length > 0
    );

    if (group.exists) {
      group.members.forEach((member) => {
        if (!participants.some(_.isSameUser(member))) {
          participants.push({
            id: uuidv4(),
            userIdentifiers: member.userIdentifiers || [],
            roles: [],
            hasSeen: false,
            ...(member.name || !member.email ? {} : { email: member.email }),
          });
        }
      });
    }

    listResource.update({
      groupId: groupId || null,
      participants,
      participantUserIdentifiers: participants.flatMap(
        (participant) => participant.userIdentifiers
      ),
    });
  };

  return (
    <div className="rounded-md bg-card w-full p-4 space-y-4 shadow-sm">
      <h3>{t`list.selectGroup`}</h3>
      <WrappingBox spacing={2}>
        <button
          className="cursor-pointer border-0 rounded-full px2 accent-text focusable"
          onClick={() => selectGroup(null)}
        >{t`label.noGroup`}</button>
        {sortedGroups.map((group) => (
          <button
            key={group.id}
            className="cursor-pointer flex items-center p-0 pr-2 space-x-2 border-none rounded-full text-primary accent-primary focusable"
            tabIndex={0}
            onClick={() => selectGroup(group.id)}
          >
            <Avatar user={{ ...group, avatar: group.image }} size={1.5} />
            <span>{group.name}</span>
          </button>
        ))}
      </WrappingBox>
    </div>
  );
};
