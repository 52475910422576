import { isJust } from "./functions";

const DEFAULT_TIMEOUT = 300;

const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

const getLocation = () => {
  const {
    search,
    hash,
    href,
    origin,
    protocol,
    host,
    hostname,
    port,
  } = window.location;
  let { pathname } = window.location;

  if (!pathname && href && canUseDOM) {
    const url = new URL(href);
    pathname = url.pathname;
  }

  return {
    pathname: encodeURI(decodeURI(pathname)),
    search,
    hash,
    href,
    origin,
    protocol,
    host,
    hostname,
    port,
    state: window.history.state,
    key: (window.history.state && window.history.state.key) || "initial",
  };
};

const getLocationState = (location) => ({
  stack: [
    {
      location: JSON.parse(JSON.stringify(location)),
      isOpen: true,
      key: "initial",
    },
  ],
  pointer: 0,
  ...location.state,
});

const dispatchNavigationState = (state) => {
  const popStateEvent = new PopStateEvent("popstate", { state });
  dispatchEvent(popStateEvent);
};

const pushModal = async (to, options) => {
  const location = getLocation();
  const { stack, pointer, ...state } = getLocationState(location);

  if (options?.replace) {
    window.history.replaceState(state, null, to);
  } else {
    window.history.pushState(state, null, to);
  }

  const stackPageIndex = stack.findIndex(
    ({ location }) => location.pathname === window.location.pathname
  );
  const stackPage = stackPageIndex > -1 ? stack[stackPageIndex] : null;

  const newState = stackPage
    ? {
        ...state,
        ...options?.state,
        stack: stack.map((page, i) => ({
          ...page,
          isOpen:
            i === stackPageIndex
              ? true
              : i > stackPageIndex
              ? false
              : page.isOpen,
        })),
        pointer: stackPageIndex,
      }
    : {
        ...state,
        ...options?.state,
        stack: [
          ...stack,
          {
            location: {
              ...getLocation(),
              key: Date.now() + "",
            },
            isOpen: true,
            timeout: options?.timeout || DEFAULT_TIMEOUT,
            key: Date.now() + "",
          },
        ],
        pointer: stack.length,
      };
  window.history.replaceState(newState, null);
  dispatchNavigationState(newState);
};

const popModal = async (fallback, options) => {
  const location = getLocation();
  const { stack, pointer, ...state } = getLocationState(location);
  const depth = isJust(options?.depth) ? options.depth : pointer;
  const stackFrame = stack[depth];
  const below = stack
    .map((stackFrame, i) => stackFrame.isOpen && i < depth)
    .lastIndexOf(true);
  if (stackFrame?.isOpen) {
    if (below === -1) {
      if (options?.replace) {
        window.history.replaceState(state, null, fallback);
      } else {
        window.history.pushState(state, null, fallback);
      }
    }

    const updatedStack = stack.map((stackFrame, i) =>
      i === depth ? { ...stackFrame, isOpen: false } : stackFrame
    );

    const newState = {
      stack:
        below === -1
          ? [
              {
                location: {
                  key: Date.now() + "",
                  ...getLocation(),
                },
                isOpen: true,
                key: Date.now() + "",
              },
              ...updatedStack,
            ]
          : updatedStack,
      pointer: depth === pointer ? (below === -1 ? 0 : below) : pointer,
    };

    const newPath = newState.stack[newState.pointer]?.location.pathname;
    if (below !== -1 && !options?.replace) {
      window.history.pushState(newState, null, newPath);
    } else {
      window.history.replaceState(newState, null, newPath);
    }
    dispatchNavigationState(newState);
  }
};

const replaceModal = async (to, options) => {
  await popModal("/");
  await pushModal(to, options);
};

const popToTop = async (fallback, options) => {
  const location = getLocation();
  const { stack, pointer, ...state } = getLocationState(location);
  const depth = isJust(options?.depth) ? options.depth : pointer;
  const stackFrame = stack[depth];
  const bottom = stack.findIndex(
    (stackFrame, i) => stackFrame.isOpen && i < depth
  );

  if (stackFrame?.isOpen) {
    if (bottom === -1) {
      if (options?.replace) {
        window.history.replaceState(state, null, fallback);
      } else {
        window.history.pushState(state, null, fallback);
      }
    }

    const updatedStack = stack.map((stackFrame, i) =>
      i > 0 || bottom === -1 ? { ...stackFrame, isOpen: false } : stackFrame
    );

    const newState = {
      stack:
        bottom === -1
          ? [
              {
                location: {
                  key: Date.now() + "",
                  ...getLocation(),
                },
                isOpen: true,
                key: Date.now() + "",
              },
              ...updatedStack,
            ]
          : updatedStack,
      pointer: 0,
    };

    if (bottom !== -1 && !options?.replace) {
      window.history.pushState(newState, null, fallback);
    } else {
      window.history.replaceState(newState, null, fallback);
    }

    dispatchNavigationState(newState);
  }
};

const navigate = async (to, options) => {
  if (isNaN(to)) {
    const location = getLocation();
    const { stack, pointer, ...state } = getLocationState(location);

    if (options?.replace) {
      window.history.replaceState(state, null, to);
    } else {
      window.history.pushState(state, null, to);
    }

    const newState = {
      ...state,
      ...options?.state,
      stack: stack.map((stackFrame, i) =>
        i === (isJust(options?.depth) ? options.depth : pointer)
          ? {
              ...stackFrame,
              location: {
                ...getLocation(),
                key: Date.now() + "",
              },
            }
          : stackFrame
      ),
      pointer,
    };
    window.history.replaceState(
      newState,
      null,
      newState.stack[pointer]?.location.pathname
    );
    dispatchNavigationState(newState);
  } else {
    window.history.go(to);
  }
};

export {
  getLocation,
  getLocationState,
  dispatchNavigationState,
  pushModal,
  popModal,
  popToTop,
  replaceModal,
  navigate,
};
