import * as _ from "utils";
import firebase from "firebase/app";

import React from "react";
import { useDoc } from "react-firestore";
import useListContext from "hooks/useListContext";

import * as Icons from "components/Icons";
import { Avatar, IconButton, WrappingBox } from "components/UI";
import GroupName from "components/Group/Name";
import GroupSelection from "components/List/GroupSelection";

export default ({ isEditing }) => {
  const [list, listResource] = useListContext();
  const [group] = useDoc("groups", list.groupId);

  return list.groupId === undefined ? (
    <GroupSelection />
  ) : (
    <WrappingBox spacing={2}>
      {group.exists && (
        <>
          <div
            className="flex items-center space-x-2 pr-2 rounded-full text-sm accent-primary text-primary cursor-pointer"
            onClick={isEditing ? null : () => _.pushModal(`/g/${group.id}`)}
          >
            <Avatar
              user={{ name: group.name, avatar: group.image }}
              size={1.25}
            />
            <span className="truncate">
              <GroupName group={group} />
            </span>
          </div>
          {!(list.isSetup || list.isShared) && (
            <IconButton
              size="xs"
              color="card"
              onClick={() =>
                listResource.update({
                  groupId: firebase.firestore.FieldValue.delete(),
                })
              }
            >
              <Icons.Undo size={0.75} />
            </IconButton>
          )}
        </>
      )}
    </WrappingBox>
  );
};
