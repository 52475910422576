import * as _ from "utils";
import cx from "classnames";

import React from "react";
import useUser from "hooks/useUser";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Avatar } from "components/UI";
import UserName from "components/User/Name";
import MonetaryLiteral from "components/Expenses/MonetaryLiteral";

export default function TransactionThumb({ transaction }) {
  const { moment, t } = useTranslation();
  const currentUserReceives = _.isCurrentUser(transaction.to);
  const currentUserPays = _.isCurrentUser(transaction.from);
  const [receivingUser] = useUser(transaction.to);
  const [payingUser] = useUser(transaction.from);

  const transactionCompletedForCurrentUser =
    transaction.isReceived || (currentUserPays && transaction.isPaid);

  return (
    (currentUserReceives || currentUserPays) && (
      <div
        className={cx(
          "transition-colors duration-200 flex justify-space-between items-center rounded-md shadow-sm p-2 space-x-2 cursor-pointer",
          transactionCompletedForCurrentUser ? "btn--success" : "btn--card"
        )}
        onClick={() => _.pushModal(`/tx/${transaction.id}`)}
      >
        <div className="space-y-1 min-w-0 flex-grow">
          <div className="flex space-x-1 items-center">
            <div
              className={cx("flex items-center space-x--2", {
                "text-primary": !transactionCompletedForCurrentUser,
              })}
            >
              {transaction.isReceived && <Icons.Check size={0.75} />}
              {(transaction.isPaid || transaction.isReceived) && (
                <Icons.Check size={0.75} />
              )}
            </div>
            <p className="text-xs opacity-75">
              {currentUserReceives
                ? transaction.isReceived
                  ? t`transactions.receivedFrom`
                  : t`transactions.toReceiveFrom`
                : currentUserPays
                ? transaction.isPaid || transaction.isReceived
                  ? t`transactions.paidTo`
                  : t`transactions.toPayTo`
                : null}
            </p>
          </div>
          <div className="flex items-center justify-space-between">
            <div className="flex items-center space-x-2 min-w-0">
              <Avatar
                size={1}
                user={currentUserReceives ? payingUser : receivingUser}
              />
              <p className="truncate">
                <UserName
                  user={currentUserReceives ? payingUser : receivingUser}
                />
              </p>
            </div>
            <MonetaryLiteral cents={transaction.amount} />
          </div>
          <p className="text-right text-xs opacity-75">
            {transaction.isReceived
              ? t(`transactions.receivedAt`, {
                  receivedAt: moment(transaction.receivedAt.toDate()).format(
                    "llll"
                  ),
                })
              : transaction.isPaid
              ? t(`transactions.paidAt`, {
                  paidAt: moment(transaction.paidAt.toDate()).format("llll"),
                })
              : t(`transactions.openSince`, {
                  openSince: moment(transaction.createdAt.toDate()).format(
                    "llll"
                  ),
                })}
          </p>
        </div>
        <Icons.Caret direction="right" className="opacity-75" />
      </div>
    )
  );
}
