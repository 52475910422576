import React from "react";

import useTranslation from "hooks/useTranslation";
import { Helmet } from "react-helmet-async";

const HtmlLanguageTag = () => {
  const { language } = useTranslation();
  return (
    <Helmet>
      <html lang={language} />
    </Helmet>
  );
};

export default HtmlLanguageTag;
