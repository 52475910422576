import * as _ from "utils";

import React from "react";
import { useCollection } from "react-firestore";

import Calendar from "components/Calendar";

const listDeclined = (list) =>
  list.exists &&
  (list.isSetup || list.isShared) &&
  _.isNonParticipant(list.myParticipation);

export default function DeclinedLists() {
  const lists = useCollection("events", { filter: listDeclined });

  return <Calendar lists={lists} direction={-1} alwaysShowToday={false} />;
}
