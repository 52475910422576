import * as _ from "utils";
import moment from "moment";
import { SUPPORTED_LANGUAGES } from "statics.json";

import { useCallback } from "react";
import { useTranslation as useI18nTranslation } from "react-i18next";
import nl from "date-fns/locale/nl";
import en from "date-fns/locale/en-US";

export default function useTranslation(...args) {
  const translation = useI18nTranslation(...args);

  const language = translation.i18n.language;
  _.auth.language = language;

  const setLanguage = useCallback(
    (lang) => {
      if (
        SUPPORTED_LANGUAGES.includes(lang) &&
        translation.i18n.language !== lang
      ) {
        translation.i18n.changeLanguage(lang);
        moment.locale(lang);
        _.auth.language = lang;
      }
    },
    [translation.i18n]
  );

  return {
    ...translation,
    moment,
    locale: language === "nl" ? nl : en,
    language,
    setLanguage,
  };
}
