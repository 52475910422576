import { useCollection } from "react-firestore";

const groupExists = (group) => group.isSetup && group.exists;

export default function useRegularMoment(regularMomentId) {
  const groups = useCollection("groups", { filter: groupExists });

  const regularMoment = groups
    .flatMap((group) => group.regularMoments)
    .find((regularMoment) => regularMoment.id === regularMomentId);

  return [
    regularMoment ? { ...regularMoment, exists: true } : { exists: false },
  ];
}
