import * as _ from "utils";

import React from "react";
import useTranslation from "hooks/useTranslation";

export default function EmptyCalendarDay({ isEditable }) {
  const { t } = useTranslation();

  return isEditable ? (
    <p
      className="text-xs text-caption mt-8 mb-2 cursor-pointer"
      onClick={() => _.pushModal("/new-list")}
    >
      {t`dashboard.emptyDayEditable`}
    </p>
  ) : (
    <p className="text-xs text-caption mt-8 mb-2">{t`dashboard.emptyDay`}</p>
  );
}
