import React from "react";
import useTranslation from "hooks/useTranslation";

import SimpleLayout from "layouts/Simple";

import * as Icons from "components/Icons";
import Link from "components/Link";

const Header = () => null;
const Body = ({ children }) => children;

export default () => {
  const { t } = useTranslation();

  return (
    <SimpleLayout components={{ Header, Body }}>
      <div className="flex flex-col items-center justify-center h-screen py-8 space-y-8">
        <Icons.IkEetMee size={10} strokeWidth={8} className="text-secondary" />
        <h1 className="text-center px-4">{t`notFound.title`}</h1>
        <Link to="/">{t`label.back`}</Link>
      </div>
    </SimpleLayout>
  );
};
