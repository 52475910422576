import cx from "classnames";
import React from "react";

export default ({ size, color = "text", className, ...props }) => (
  <p
    className={cx(
      "rounded-xl px-2",
      `accent-${color} text-${color}`,
      className
    )}
    {...props}
  />
);
