import React, { useCallback, useState } from "react";
import useIdle from "hooks/useIdle";
import useTaskContext from "hooks/useTaskContext";
import useTranslation from "hooks/useTranslation";

import { BaseInput } from "components/UI";
import CollapsibleContainer from "components/CollapsibleContainer";

export default function Title({ isEditing }) {
  const { t } = useTranslation();
  const [task, taskResource] = useTaskContext();
  const [title, setTitle] = useState(task.title);
  const flush = useCallback(
    () =>
      title !== taskResource.rawData.title && taskResource.update({ title }),
    [title, taskResource]
  );
  const touch = useIdle(flush, 500);

  return (
    <CollapsibleContainer isVisible={Boolean(isEditing || task.title)}>
      {isEditing ? (
        <BaseInput
          autoFocus={!title}
          className="font-black text-2xl"
          placeholder={t`task.title`}
          value={title || ""}
          onChange={(event) => {
            setTitle(event.target.value);
            touch();
          }}
        />
      ) : task.title ? (
        <h1 className="text-2xl">{task.title}</h1>
      ) : (
        <h1 className="text-2xl text-caption">{t`task.title`}</h1>
      )}
    </CollapsibleContainer>
  );
}
