import * as _ from "utils";

import React, { useCallback } from "react";
import useGroupContext from "./useGroupContext";
import useTranslation from "./useTranslation";

import * as Icons from "components/Icons";

export default function useMemberUtils() {
  const { t } = useTranslation();
  const [{ myMembership }, groupResource] = useGroupContext();

  const getActions = useCallback(
    (member) =>
      [
        {
          isVisible: _.isAdmin(myMembership),
          icon: <Icons.Bin />,
          label: t`action.delete`,
          color: "error",
          onAct: async () => {
            await groupResource.deleteMember(member.id);
            _.track("member_delete");
          },
        },
        {
          isVisible: _.isAdmin(myMembership) && _.isAdmin(member),
          icon: <Icons.Undo />,
          label: t`group.degrade`,
          onAct: async () => {
            await groupResource.degradeMember(member.id);
            _.track("member_degrade");
          },
        },
        {
          isVisible: _.isAdmin(myMembership) && !_.isAdmin(member),
          icon: <Icons.Star />,
          label: t`group.promote`,
          onAct: async () => {
            await groupResource.promoteMember(member.id);
            _.track("member_promote");
          },
        },
      ].filter((a) => a.isVisible),
    [myMembership, groupResource, t]
  );

  const getRoleBadge = useCallback(
    (member) =>
      _.isAdmin(member)
        ? { color: "secondary", label: t`group.admin`, icon: Icons.Star }
        : null,
    [t]
  );

  return { getRoleBadge, getActions };
}
