import * as _ from "utils";
import * as Sentry from "@sentry/browser";

import { useLayoutEffect } from "react";
import reactFirestore from "react-firestore";
import useAuth from "hooks/useAuth";

export default ({ groupId }) => {
  const [{ isAuthenticated }] = useAuth();

  useLayoutEffect(() => {
    const create = async () => {
      try {
        const taskDocRef = await reactFirestore.createDoc("tasks", { groupId });
        _.navigate(`/t/${taskDocRef.id}`, { replace: true });
      } catch (error) {
        Sentry.captureException(error, { tags: { event: "Create task" } });
        _.popModal(`/g/${groupId}`);
      }
    };
    if (isAuthenticated) {
      create();
    }
  }, [isAuthenticated, groupId]);

  return null;
};
