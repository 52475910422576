import * as _ from "utils";

import React from "react";
import usePageContext from "hooks/usePageContext";

import ModalLayout from "layouts/Modal";

import * as Icons from "components/Icons";
import { Button } from "components/UI";

import updates from "updates";

const LatestUpdatePage = () => {
  const [, { onClose }] = usePageContext();

  return (
    <ModalLayout title="Updates">
      <div className="w-full max-w-2xl mx-auto px-4">
        <div className="flex flex-col items-normal py-4 space-y-2">
          <div className="p-4 rounded-md bg-card">
            <h1>New in Ikeetmee</h1>
            <div className="space-y-4">
              {updates[updates.length - 1]}
              <div className="flex justify-flex-end">
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => _.replaceModal("/updates")}
                  trail={<Icons.Caret direction="right" />}
                >
                  View all updates
                </Button>
              </div>
            </div>
          </div>
          <Button
            color="card"
            onClick={() =>
              window.open("https://forms.gle/ma6CjcHmrJSAGP6AA", "_blank")
            }
          >
            Send feedback
          </Button>
          <Button color="card" onClick={() => onClose("/")}>
            Dismiss
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default LatestUpdatePage;
