import { LIST_TYPES } from "statics.json";

import React from "react";

import ListCalendarItem from "./ListCalendarItem";
import TaskCalendarItem from "./TaskCalendarItem";

export default function CalendarItem({ item, ...props }) {
  switch (item.type) {
    case LIST_TYPES.COOK:
    case LIST_TYPES.ORDER:
      return <ListCalendarItem list={item} {...props} />;
    case "task":
      return <TaskCalendarItem task={item} {...props} />;
    default:
      return null;
  }
}
CalendarItem.defaultProps = {
  showGroup: true,
  type: "list",
};
