import { DATE_TYPES, PERIODS } from "statics.json";
import * as _ from "utils";
import useTranslation from "hooks/useTranslation";

const everyWeekDay = (ds) => ds.every((day) => day);
const exactlyWorkingDays = (ds) => ds.every((day, i) => (i < 5 ? day : !day));

export default function FormattedRegularMoment({ item, short }) {
  const { frequency, frequencyPeriod } = item;

  const { t, moment } = useTranslation();

  const base = _.capitalize(
    frequency === 1
      ? t(`frequency.periodExt.${frequencyPeriod}`)
      : `${t`frequency.every`} ${frequency} ${t(
          `frequency.period.${frequencyPeriod}`,
          { count: frequency }
        )}`
  );

  const formatDayOfMonth = () => {
    switch (item.dateType) {
      case DATE_TYPES.NTH:
        return `${t(`frequency.nth.${item.nth}`)} ${moment()
          .weekday(item.dayOfWeek)
          .format("dddd")}`;
      case DATE_TYPES.DAY:
        return item.dayOfMonth
          ? moment()
              .month(0)
              .date(item.dayOfMonth + 1)
              .format("Do")
          : t`frequency.lastDay`;
      default:
        return null;
    }
  };

  const startDate = moment(item.startDate.date);

  let shortString;

  switch (frequencyPeriod) {
    case PERIODS.DAY: {
      shortString = base;
      break;
    }
    case PERIODS.WEEK: {
      const formattedWeekDays = everyWeekDay(item.weekDays)
        ? t`frequency.everyWeekDay`
        : exactlyWorkingDays(item.weekDays)
        ? t`frequency.workingDays`
        : item.weekDays
            .map((day, i) => (day ? moment().weekday(i).format("dddd") : ""))
            .filter((x) => x !== "")
            .join(", ");

      shortString = `${base} ${t`frequency.on`} ${formattedWeekDays}`;
      break;
    }
    case PERIODS.MONTH: {
      const formattedDayOfMonth = formatDayOfMonth();
      shortString = `${base} ${t`frequency.on`} ${t`frequency.the`} ${formattedDayOfMonth}`;
      break;
    }
    case PERIODS.YEAR: {
      const formattedDayOfYear = startDate
        .format("LL")
        .replace(startDate.format("YYYY"), "")
        .replace(",", "")
        .trim();
      shortString = `${base} ${t`frequency.on`} ${formattedDayOfYear}`;
      break;
    }
    default:
      return null;
  }

  if (short) {
    return shortString;
  } else {
    switch (frequencyPeriod) {
      case PERIODS.DAY:
      case PERIODS.WEEK:
      case PERIODS.MONTH: {
        return `${shortString}, ${t`frequency.starting`} ${startDate.format(
          "LL"
        )}`;
      }
      case PERIODS.YEAR: {
        return `${shortString}, ${t`frequency.starting`} ${startDate.format(
          "YYYY"
        )}`;
      }
      default:
        return null;
    }
  }
}
