import cx from "classnames";

import React from "react";
import useListContext from "hooks/useListContext";
import useCourseContext from "hooks/useCourseContext";

import * as Icons from "components/Icons";

const VoteButton = ({ isSelected, color, ...props }) => (
  <button
    className={cx(
      "flex border-0 rounded-full icon-btn--sm cursor-pointer",
      isSelected ? `icon-btn--${color}` : `accent-${color} text-${color}`
    )}
    {...props}
  />
);

export default ({ courseOption, currentParticipantId }) => {
  const [list] = useListContext();
  const [, { onChangeOption }] = useCourseContext();

  const isParticipant = list.participants.some(
    (participant) => participant.id === currentParticipantId
  );

  const _currentVote =
    isParticipant &&
    courseOption.votes.find(
      (vote) =>
        vote.participantId === currentParticipantId ||
        vote === currentParticipantId
    );
  const currentVote = _currentVote
    ? _currentVote.participantId
      ? _currentVote
      : { participantId: _currentVote, weight: 1 }
    : null;

  const vote = (weight) => {
    onChangeOption(courseOption.id, (option) => ({
      ...option,
      votes: currentVote
        ? option.votes.map((vote) =>
            vote.participantId === currentVote.participantId
              ? { ...vote, weight }
              : vote === currentVote.participantId
              ? { participantId: vote, weight }
              : vote
          )
        : [...option.votes, { participantId: currentParticipantId, weight }],
    }));
  };

  return (
    <div className="flex space-x-2">
      <VoteButton
        color="error"
        isSelected={currentVote?.weight === -1}
        onClick={() => vote(-1)}
        disabled={!isParticipant}
      >
        <Icons.Cross size={0.75} />
      </VoteButton>
      <VoteButton
        color="warning"
        isSelected={currentVote?.weight === 0}
        onClick={() => vote(0)}
        disabled={!isParticipant}
      >
        <Icons.Maybe size={0.75} />
      </VoteButton>
      <VoteButton
        color="success"
        isSelected={currentVote?.weight === 1}
        onClick={() => vote(1)}
        disabled={!currentParticipantId}
      >
        <Icons.Check size={0.75} />
      </VoteButton>
    </div>
  );
};
