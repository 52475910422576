import React from "react";

import StackRouter from "./StackRouter";
import Redirect from "./Redirect";

import Index from "pages/index";

import PlannedLists from "pages/calendar/planned";
import ArchivedLists from "pages/calendar/archived";
import DeclinedLists from "pages/calendar/declined";

import Profile from "pages/profile";

import Settings from "pages/settings";
import AuthSettings from "pages/settings/auth";
import LanguageSettings from "pages/settings/language";
import ColorModeSettings from "pages/settings/color-mode";
import NotificationSettings from "pages/settings/notifications";
import EmailSettings from "pages/settings/email";
import PasswordSettings from "pages/settings/password";
import PaymentSettings from "pages/settings/payments";
import NewPaymentAddress from "pages/settings/payment-address/new";
import EditPaymentAddress from "pages/settings/payment-address/edit";

import Updates from "pages/updates";
import LatestUpdate from "pages/latest-update";

import Invite from "pages/invite";

import NewList from "pages/list/new";
import List from "pages/list";
import NewListExpense from "pages/list/new-expense";
import SettleList from "pages/list/settle";
import ShareList from "pages/list/share";
import ListParticipants from "pages/list/participants";
import ListInvite from "pages/list/invite";
import CourseChoices from "pages/list/choices";
import CourseSettings from "pages/list/course-settings";
import ParticipantDietaryPreferences from "pages/list/participant-dietary-preferences";
import ListSettlements from "pages/list/settlements";

import NewGroup from "pages/group/new";
import ViewGroup from "pages/group/view";
import GroupCalendar from "pages/group/calendar";
import GroupArchive from "pages/group/archive";
import GroupExpenses from "pages/group/expenses";
import GroupStatistics from "pages/group/statistics";
import GroupSetup from "pages/group/setup";
import GroupBasicSetup from "pages/group/setup/basic";
import GroupMembersSetup from "pages/group/setup/members";
import GroupCompletionSetup from "pages/group/setup/complete";
import NewGroupExpense from "pages/group/new-expense";
import SettleGroup from "pages/group/settle";
import GroupSettlements from "pages/group/settlements";
import GroupMembers from "pages/group/members";
import GroupNotifications from "pages/group/notifications";
import GroupSettings from "pages/group/settings";
import NewRecurringList from "pages/group/recurring-list/new";
import EditRecurringList from "pages/group/recurring-list/edit";
import NewRecurringTask from "pages/group/recurring-task/new";
import EditRecurringTask from "pages/group/recurring-task/edit";
import ShareGroup from "pages/group/share";

import NewTask from "pages/task/new";
import Task from "pages/task";

import Expense from "pages/expense";
import Settlement from "pages/settlement";
import Transactions from "pages/transactions";
import Transaction from "pages/transaction";

import RequestNotifications from "pages/request-notifications";

import NotFound from "pages/404";

import Auth from "pages/auth";
import Login from "pages/auth/login";
import Amnesia from "pages/auth/amnesia";
import Register from "pages/auth/register";
import ChooseRegistrationMethod from "pages/auth/choose";
import EmailRegister from "pages/auth/email";
import NameRegister from "pages/auth/name";

export default () => (
  <StackRouter>
    <Index path="/">
      <PlannedLists path="planned" />
      <ArchivedLists path="archived" />
      <DeclinedLists path="declined" />
      <Redirect path="/" to="planned" />
    </Index>
    <Profile path="profile" />
    <RequestNotifications path="/request-notifications" />
    <Settings path="/settings" />
    <AuthSettings path="/settings/auth" />
    <LanguageSettings path="/settings/language" />
    <ColorModeSettings path="/settings/color-mode" />
    <NotificationSettings path="/settings/notifications" />
    <EmailSettings path="/settings/auth/email" />
    <PasswordSettings path="/settings/auth/password" />
    <PaymentSettings path="/settings/payments" />
    <NewPaymentAddress path="/settings/new-payment-address" />
    <EditPaymentAddress path="/settings/payment-address/:addressId" />
    <Updates path="/updates" />
    <LatestUpdate path="/latest-update" />
    <Invite path="/i/:inviteId" />
    <NewList path="/new-list" />
    <List path="/l/:listId" />
    <ShareList path="/l/:listId/share" />
    <ListParticipants path="/l/:listId/participants" />
    <ListInvite path="/l/:listId/invite" />
    <ListInvite path="/l/:listId/invite/:parentId" />
    <CourseSettings path="/l/:listId/course/:courseId/settings" />
    <CourseChoices path="/l/:listId/choose/:participantId" />
    <ParticipantDietaryPreferences path="/l/:listId/preferences/:participantId" />
    <NewListExpense path="/l/:listId/new-expense" />
    <SettleList path="/l/:listId/settle" />
    <ListSettlements path="/l/:listId/settlements" />
    <NewGroup path="/new-group" />
    <GroupSetup path="/g/:groupId/setup">
      <Redirect path="/" to="basic" />
      <GroupBasicSetup path="basic" />
      <GroupMembersSetup path="members" />
      <GroupCompletionSetup path="complete" />
    </GroupSetup>
    <ViewGroup path="/g/:groupId">
      <GroupCalendar path="planned" />
      <GroupArchive path="past" />
      <GroupExpenses path="expenses" />
      <GroupStatistics path="statistics" />
      <Redirect path="/" to="planned" />
    </ViewGroup>
    <NewGroupExpense path="/g/:groupId/new-expense" />
    <SettleGroup path="/g/:groupId/settle" />
    <GroupSettlements path="/g/:groupId/settlements" />
    <ShareGroup path="/g/:groupId/share" />
    <GroupMembers path="/g/:groupId/members" />
    <GroupNotifications path="/g/:groupId/notifications" />
    <GroupSettings path="/g/:groupId/settings" />
    <NewRecurringList path="/g/:groupId/new-recurring-list" />
    <EditRecurringList path="/g/:groupId/recurring-list/:recurringListId" />
    <NewRecurringTask path="/g/:groupId/new-recurring-task" />
    <EditRecurringTask path="/g/:groupId/recurring-task/:recurringTaskId" />
    <NewTask path="/g/:groupId/new-task" />
    <Task path="/t/:taskId" />
    <Expense path="/ex/:expenseId" />
    <Settlement path="/s/:settlementId" />
    <Transactions path="/transactions" />
    <Transaction path="/tx/:transactionId" />
    <Auth path="/auth">
      <Login path="login" />
      <Register path="register">
        <ChooseRegistrationMethod path="/" />
        <EmailRegister path="email" />
        <NameRegister path="name" />
      </Register>
      <Amnesia path="amnesia" />
    </Auth>
    <NotFound default />
  </StackRouter>
);
