import * as _ from "utils";

import { useEffect } from "react";
import { nonExistentDoc, useCollection } from "react-firestore";

export default function useUsers(userContainers) {
  const users = useCollection("users");

  useEffect(() => {
    userContainers.forEach(({ userIdentifiers }) =>
      _.fetchUser(
        userIdentifiers.filter(
          (userIdentifier) => userIdentifier !== "__unused_user_identifier__"
        )
      )
    );
  }, [userContainers]);

  return userContainers.map((userContainer) => {
    const matchingUser = users.find(_.isSameUser(userContainer));
    return {
      ...userContainer,
      ...(matchingUser?.exists ? matchingUser : nonExistentDoc),
      id: userContainer.id,
    };
  });
}
