import nonExistentDoc from "./nonExistentDoc";
import reactFirestore from "./ReactFirestore";
import useCollection from "./useCollection";
import useDoc from "./useDoc";
import useQueries from "./useQueries";
import useQuery from "./useQuery";

export {
  nonExistentDoc,
  reactFirestore,
  useCollection,
  useDoc,
  useQueries,
  useQuery,
};

export default reactFirestore;

if (process.env.NODE_ENV === "development") {
  window.reactFirestore = reactFirestore;
}
