import * as _ from "utils";
import cx from "classnames";

import React from "react";
import useTransactionContext from "hooks/useTransactionContext";
import useTranslation from "hooks/useTranslation";
import useUser from "hooks/useUser";

import withTransaction from "hocs/withTransaction";

import { BottomModalLayout } from "layouts/Modal";

import * as Icons from "components/Icons";
import { Avatar, Button, WrappingBox } from "components/UI";
import UserName from "components/User/Name";
import MonetaryLiteral from "components/Expenses/MonetaryLiteral";
import CopyButton from "components/CopyButton";
import FinancialIcon from "components/Transactions/FinancialIcon";

const PaymentAddress = ({ address, transaction, receivingUser }) => {
  const { t } = useTranslation();

  const addressType = _.paymentAddresses.find(
    ({ type }) => type === address.type
  );
  const amount = (transaction.amount / 100).toFixed(2);

  switch (address.type) {
    case "iban":
      return (
        <div className="p-4 space-y-2 bg-glow rounded-md">
          <p className="flex items-center space-x-2 text-xs text-caption">
            <Icons.Bank size={0.75} />
            <span>{t`transactions.bankTransfer`}</span>
          </p>
          <p className="text-text">
            {t`transactions.transfer1`} <strong>€{amount}</strong>{" "}
            {t`transactions.transfer2`}
            <br />
            <strong>{addressType.formatText(address.value)}</strong>{" "}
            <span className="inline-block align-middle">
              <CopyButton
                value={address.value}
                label={t`transactions.copyIBAN`}
                variant="text"
              />
            </span>
            <br />
            {t`transactions.inNameOf`}{" "}
            <strong>{address.name || receivingUser.name}</strong>
          </p>
        </div>
      );
    case "paypal":
    case "bunq":
    case "misc":
      return (
        <a
          className="flex justify-space-between items-center border border-solid border-accent p-2 rounded-xl btn--card"
          href={addressType.formatLink({
            value: address.value,
            amount,
            description: t`transactions.settleDescription`,
          })}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="flex items-center space-x-2">
            <FinancialIcon type={address.type} />
            <span key={address.id}>
              {addressType.formatText(address.value)}
            </span>
          </span>
          <Icons.Caret direction="right" className="text-caption" />
        </a>
      );
    default:
      return null;
  }
};

export default withTransaction(() => {
  const { moment, t } = useTranslation();
  const [transaction, transactionResource] = useTransactionContext();
  const currentUserReceives = _.isCurrentUser(transaction.to);
  const currentUserPays = _.isCurrentUser(transaction.from);
  const [receivingUser] = useUser(transaction.to);
  const [payingUser] = useUser(transaction.from);

  return (
    <BottomModalLayout>
      <div className="rounded-b-none space-y-4 p-4 rounded-md bg-card overflow-y-auto">
        <div className="space-y-1 min-w-0 flex-grow">
          <div className="flex space-x-1 items-center">
            <div className={cx("flex items-center space-x--2 text-primary")}>
              {transaction.isReceived && <Icons.Check size={0.75} />}
              {(transaction.isPaid || transaction.isReceived) && (
                <Icons.Check size={0.75} />
              )}
            </div>
            <p className="text-xs opacity-75">
              {currentUserReceives
                ? transaction.isReceived
                  ? t`transactions.receivedFrom`
                  : t`transactions.toReceiveFrom`
                : currentUserPays
                ? transaction.isPaid
                  ? t`transactions.paidTo`
                  : t`transactions.toPayTo`
                : null}
            </p>
          </div>
          <div className="flex items-center justify-space-between">
            <div className="flex items-center space-x-2 min-w-0">
              <Avatar
                size={1}
                user={currentUserReceives ? payingUser : receivingUser}
              />
              <p className="truncate">
                <UserName
                  user={currentUserReceives ? payingUser : receivingUser}
                />
              </p>
            </div>
            <MonetaryLiteral cents={transaction.amount} />
          </div>
          <p className="text-right text-xs opacity-75">
            {transaction.isReceived
              ? t(`transactions.receivedAt`, {
                  receivedAt: moment(transaction.receivedAt.toDate()).format(
                    "llll"
                  ),
                })
              : transaction.isPaid
              ? t(`transactions.paidAt`, {
                  paidAt: moment(transaction.paidAt.toDate()).format("llll"),
                })
              : t(`transactions.openSince`, {
                  openSince: moment(transaction.createdAt.toDate()).format(
                    "llll"
                  ),
                })}
          </p>
        </div>
        {currentUserReceives && (
          <>
            {!payingUser.email && (
              <div className="rounded-md bg-glow text-text p-4">
                <UserName user={payingUser} />{" "}
                {t`transactions.hasNoContactMethods`}
              </div>
            )}
            {!receivingUser.paymentAddresses?.length > 0 && (
              <div className="rounded-md bg-glow text-text p-4 space-y-4">
                <p>{t`transactions.youHaveNoPaymentAddresses`}</p>
                <div className="flex justify-flex-end">
                  <Button
                    color="primary"
                    size="sm"
                    className="w-full sm:w-auto"
                    onClick={() => _.pushModal("/settings/payments")}
                  >{t`settings.payments.addAddress`}</Button>
                </div>
              </div>
            )}
          </>
        )}
        {currentUserPays && !transaction.isPaid && (
          <div className="space-y-2">
            {!receivingUser.paymentAddresses?.length > 0 && (
              <div className="rounded-md bg-glow text-text p-4">
                <UserName user={receivingUser} />{" "}
                {t`transactions.hasNoPaymentAddresses`}
              </div>
            )}
            {receivingUser.paymentAddresses?.length > 1 && (
              <p>{t`transactions.payInOneOf`}</p>
            )}
            {receivingUser.paymentAddresses?.map((address) => (
              <PaymentAddress
                key={address.id}
                address={address}
                transaction={transaction}
                receivingUser={receivingUser}
              />
            ))}
          </div>
        )}
        <WrappingBox
          spacing={2}
          className="justify-flex-start sm:flex-row-reverse"
        >
          {currentUserReceives && (
            <>
              {transaction.isReceived ? (
                <Button
                  className="w-full sm:w-auto"
                  size="sm"
                  color="card"
                  onClick={() => transactionResource.markNotReceived()}
                  lead={<Icons.Undo />}
                >
                  {t`transactions.markNotReceived`}
                </Button>
              ) : (
                <Button
                  className="w-full sm:w-auto"
                  size="sm"
                  color="primary"
                  onClick={() => transactionResource.markReceived()}
                >
                  {t`transactions.markReceived`}
                </Button>
              )}
              {payingUser.email && (
                <a
                  className="btn btn--sm btn--card font-bold w-full sm:w-auto"
                  href={`mailto:${payingUser.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >{t`transactions.sendEmail`}</a>
              )}
            </>
          )}
          {currentUserPays && (
            <>
              {transaction.isPaid ? (
                !transaction.isReceived && (
                  <Button
                    className="w-full sm:w-auto"
                    size="sm"
                    color="card"
                    onClick={() => transactionResource.markNotPaid()}
                    lead={<Icons.Undo />}
                  >
                    {t`transactions.markNotPaid`}
                  </Button>
                )
              ) : (
                <Button
                  className="w-full sm:w-auto"
                  size="sm"
                  color="primary"
                  onClick={() => transactionResource.markPaid()}
                >
                  {t`transactions.markPaid`}
                </Button>
              )}
              {receivingUser.email && (
                <a
                  className="btn btn--sm btn--card font-bold w-full sm:w-auto"
                  href={`mailto:${receivingUser.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >{t`transactions.sendEmail`}</a>
              )}
            </>
          )}
        </WrappingBox>
      </div>
    </BottomModalLayout>
  );
});
