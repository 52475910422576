import * as _ from "utils";
import cx from "classnames";

import React, { useCallback, useEffect, useRef, useState } from "react";
import usePageScroll from "hooks/usePageScroll";
import useTaskContext from "hooks/useTaskContext";
import useTranslation from "hooks/useTranslation";

import withTask from "hocs/withTask";

import SimpleLayout from "layouts/Simple";

import * as Icons from "components/Icons";
import { Button, FlyOut, IconButton } from "components/UI";
import Comments from "components/Comments";

import Actions from "components/Task/Actions";
import Assignees from "components/Task/Assignees";
import Checklist from "components/Task/Checklist";
import DueAt from "components/Task/DueAt";
import Extension from "components/Task/Extension";
import Summary from "components/Task/Summary";
import Title from "components/Task/Title";

export default withTask(() => {
  const { t } = useTranslation();
  const [task, taskResource] = useTaskContext();
  const [isEditing, setIsEditing] = useState(!task.isValid);

  const miscActionsOriginRef = useRef(null);
  const titleRef = useRef();
  const onScroll = useCallback((scrollTop) => {
    const container = titleRef.current;
    container.style.opacity = scrollTop / 48 || 0;
  }, []);
  usePageScroll(onScroll);

  const reconcileChanges = useCallback(async () => {
    if (!taskResource.rawData.isSetup) {
      await taskResource.update({ isSetup: !!taskResource.rawData.title });
    }
  }, [taskResource]);

  useEffect(() => () => !taskResource.rawData.title && taskResource.delete(), [
    taskResource,
  ]);

  return (
    <SimpleLayout
      parentLocation={
        task.isExpired || task.isCompleted
          ? `/g/${task.groupId}/past`
          : `/g/${task.groupId}`
      }
      title={<Summary ref={titleRef} task={task} className="my--1" />}
      hideTitle
      prev={
        isEditing && (task.isValid || task.recurringTaskId || task.isSetup) ? (
          <Button
            color="primary"
            size="xs"
            disabled={!task.isValid}
            onClick={async () => {
              await reconcileChanges();
              setIsEditing(false);
            }}
          >
            {t`label.done`}
          </Button>
        ) : undefined
      }
      headerAction={
        isEditing
          ? (task.isValid || task.isSetup) &&
            !task.recurringTaskId && (
              <>
                <IconButton variant="text" ref={miscActionsOriginRef}>
                  <Icons.Dots direction="vertical" />
                </IconButton>
                <FlyOut originRef={miscActionsOriginRef}>
                  <FlyOut.Item
                    color="error"
                    lead={<Icons.Bin />}
                    onClick={async () => {
                      await taskResource.delete();
                      _.track("task_delete");
                      _.popToTop(`/g/${task.groupId}`);
                    }}
                  >{t`task.delete`}</FlyOut.Item>
                </FlyOut>
              </>
            )
          : !task.isCompleted && (
              <Button
                color="primary"
                importance="tertiary"
                variant="text"
                onClick={() => setIsEditing(true)}
              >
                {t`action.edit`}
              </Button>
            )
      }
    >
      <div className="space-y-4">
        <Title isEditing={isEditing} />
        <Assignees isEditing={isEditing && task.isValid} />
        <DueAt isEditing={isEditing && task.isValid} />
        <Extension isEditing={isEditing && task.isValid && task.dueAt} />
        <Checklist isEditing={isEditing && task.isValid} />
        <Actions
          className={cx("transition-opacity duration-200 ease-in-out", {
            "opacity-0 pointer-events-none": isEditing,
          })}
        />
        <div
          className={cx("transition-opacity duration-200 ease-in-out", {
            "opacity-0 pointer-events-none": isEditing,
          })}
        >
          <Comments comments={task.comments} resource={taskResource} />
        </div>
      </div>
    </SimpleLayout>
  );
});
