import * as _ from "utils";

import React from "react";
import useGroupContext from "hooks/useGroupContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Button } from "components/UI";
import RecurringItem from "./RecurringItem";

export default () => {
  const { t } = useTranslation();
  const [group] = useGroupContext();

  return (
    <>
      <div className="space-y-2">
        <h2 id="recurring-tasks">{t`group.recurringTasks`}</h2>
        {group.recurringTasks.length === 0 && (
          <p className="text-caption">{t`group.noRecurringTasksTitle`}</p>
        )}
      </div>
      {group.recurringTasks.map((recurringTask) => (
        <RecurringItem
          key={recurringTask.id}
          item={recurringTask}
          onEdit={
            _.isAdmin(group.myMembership)
              ? () =>
                  _.pushModal(
                    `/g/${group.id}/recurring-task/${recurringTask.id}`
                  )
              : null
          }
        />
      ))}
      {_.isAdmin(group.myMembership) && (
        <div className="flex justify-flex-end">
          <Button
            size="sm"
            color="card"
            lead={<Icons.Plus />}
            onClick={() => _.pushModal(`/g/${group.id}/new-recurring-task`)}
          >
            {t`group.addRecurringTask`}
          </Button>
        </div>
      )}
    </>
  );
};
