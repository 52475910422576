import * as _ from "utils";
import reactFirestore from "react-firestore";

import React, { useState } from "react";
import useCurrentUser from "hooks/useCurrentUser";
import useListContext from "hooks/useListContext";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";

import withList from "hocs/withList";

import { BottomModalLayout } from "layouts/Modal";

import { Button } from "components/UI";
import ExpenseInput from "components/Expenses/Input";

export default withList(() => {
  const { t } = useTranslation();
  const [, { onClose }] = usePageContext();
  const [list] = useListContext();
  const [currentUser] = useCurrentUser();
  const [state, setState] = useState({
    listId: list.id,
    groupId: list.groupId,
    creditorUserIdentifiers: currentUser.userIdentifiers,
    divideAutomatically: true,
    debtors: undefined,
  });

  return (
    <BottomModalLayout>
      <div className="expenses__container rounded-b-none max-h-screen rounded-md bg-card overflow-y-auto">
        <div className="p-4 space-y-4">
          <h2>{t`expense.new`}</h2>
          <ExpenseInput value={state} onChange={setState} />
        </div>
        <div className="sticky z-10 bottom-0 flex justify-flex-end space-x-4 px-4 py-2 border-solid border-0 border-t border-accent bg-card">
          <Button variant="text" onClick={onClose}>
            {t`action.cancel`}
          </Button>
          <Button
            color="primary"
            size="sm"
            disabled={!_.isValidExpense(state)}
            onClick={async () => {
              const expenseDocRef = await reactFirestore.createDoc(
                "expenses",
                state
              );
              const expenseResource = reactFirestore.getModel(
                "expenses",
                expenseDocRef.id
              );
              expenseResource.sendCreationMessage();
              onClose();
              setTimeout(() => {
                const expenses = document.getElementById("expenses");
                if (expenses) {
                  expenses.scrollIntoView({ behavior: "smooth" });
                }
              }, 50);
            }}
          >
            {t`action.confirm`}
          </Button>
        </div>
      </div>
    </BottomModalLayout>
  );
});
