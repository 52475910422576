import * as _ from "utils";
import { addWeeks, isBefore, startOfToday } from "date-fns";

import React, { useCallback, useEffect, useRef, useState } from "react";
import reactFirestore, { useCollection } from "react-firestore";

import Calendar from "components/Calendar";

const today = startOfToday();
const groupExists = (group) => group.isSetup && group.exists;

const listPlanned = (list) =>
  list.exists &&
  (list.isSetup || list.isShared) &&
  !isBefore(new Date(list.occursAt.date), today);

export default () => {
  const groups = useCollection("groups", { filter: groupExists });
  const lists = useCollection("events", { filter: listPlanned });
  const endDateRef = useRef(addWeeks(new Date(), 1));

  const getUpcomingLists = useCallback(
    (endDate) => {
      const groupResources = groups.map((group) =>
        reactFirestore.getModel("groups", group.id)
      );
      const recurringListInstances = groupResources.flatMap((groupResource) =>
        groupResource.getRecurringListInstances(endDate)
      );

      return recurringListInstances
        .filter((list) => !isBefore(list.calendarDate, today))
        .reduce(
          (lists, rl) =>
            lists.some(
              (l) =>
                l.regularMomentId === rl.regularMomentId &&
                l.occursAt.date === rl.occursAt.date
            )
              ? lists
              : [...lists, rl],
          lists
        )
        .filter((list) => !_.isNonParticipant(list.myParticipation));
    },
    [groups, lists]
  );

  const [upcomingLists, setUpcomingLists] = useState(
    getUpcomingLists(endDateRef.current)
  );

  const loadPage = useCallback(() => {
    endDateRef.current = addWeeks(endDateRef.current, 1);
    setUpcomingLists(getUpcomingLists(endDateRef.current));
  }, [getUpcomingLists]);

  useEffect(() => {
    setUpcomingLists(getUpcomingLists(endDateRef.current));
  }, [getUpcomingLists]);

  return (
    <Calendar
      lists={upcomingLists}
      loadPage={
        groups.some((group) => group.regularMoments.length > 0)
          ? loadPage
          : null
      }
    />
  );
};
