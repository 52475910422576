import React from "react";

export const LI = ({ label, emoji, children }) => (
  <li className="block">
    <span role="img" aria-label={label} className="inline-block w-4 ml--8 mr-4">
      {emoji}
    </span>
    {children}
  </li>
);

export const Bugfix = (props) => <LI label="Bugfix" emoji="🐛" {...props} />;

export const Feature = (props) => <LI label="Feature" emoji="⭐️" {...props} />;

export const Media = (props) => <LI label="Media" emoji="📷" {...props} />;

export const Notifications = (props) => (
  <LI label="Notifications" emoji="📣" {...props} />
);

export const Financial = (props) => (
  <LI label="Financial" emoji="💵" {...props} />
);

export const Performance = (props) => (
  <LI label="Performance" emoji="⚙️" {...props} />
);
