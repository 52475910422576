import cx from "classnames";

import React from "react";
import useColorModeContext from "hooks/useColorModeContext";
import useTranslation from "hooks/useTranslation";

import withAuth from "hocs/withAuth";

import * as Icons from "components/Icons";
import { Button } from "components/UI";
import SimpleLayout from "layouts/Simple";

const ColorModeOption = ({ colorMode }) => {
  const { t } = useTranslation();
  const [currentColorMode, setColorMode] = useColorModeContext();

  return (
    <div
      className={cx(
        colorMode,
        "flex items-center justify-center py-8 px-4 rounded-md bg-background",
        {
          "border-2 border-solid border-secondary":
            colorMode === currentColorMode,
        }
      )}
      onClick={() => setColorMode(colorMode)}
    >
      <Button
        color="primary"
        lead={colorMode === currentColorMode && <Icons.Check />}
      >
        {t(`settings.colorMode.${colorMode}`)}
      </Button>
    </div>
  );
};

const Settings = () => {
  const { t } = useTranslation();

  return (
    <SimpleLayout
      title={t`settings.colorMode.title`}
      prevLabel={t`settings.title`}
    >
      <div className="space-y-4">
        <ColorModeOption colorMode="light" />
        <ColorModeOption colorMode="dark" />
      </div>
    </SimpleLayout>
  );
};

export default withAuth(Settings);
