import firebase from "firebase/app";

export const auth = firebase.auth && firebase.auth();
export const storage = firebase.storage && firebase.storage();
export const db = firebase.firestore && firebase.firestore();
export const messaging =
  firebase.messaging && firebase.messaging.isSupported()
    ? firebase.messaging()
    : null;

export const expensesCollection = db && db.collection("expenses");
export const groupsCollection = db && db.collection("groups");
export const listsCollection = db && db.collection("events");
export const settlementsCollection = db && db.collection("settlements");
export const tasksCollection = db && db.collection("tasks");
export const transactionsCollection = db && db.collection("transactions");
export const usersCollection = db && db.collection("users");

export const getCurrentUserRef = () =>
  usersCollection &&
  auth.currentUser &&
  usersCollection.doc(auth.currentUser.uid);
