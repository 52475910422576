import * as _ from "utils";
import cx from "classnames";
import reactFirestore from "react-firestore";

import React from "react";

import TaskSummary from "components/Task/Summary";

export default function TaskCalendarItem({ task }) {
  const newTaskWithParams = async () => {
    const {
      dueAt,
      expiresAt,
      isDue,
      isExpired,
      type,
      calendarDate,
      ...params
    } = task;
    const taskDocRef = await reactFirestore.createDoc("tasks", {
      ...params,
      dueAt: dueAt.toDate(),
      expiresAt: expiresAt.toDate(),
    });
    _.pushModal(`/t/${taskDocRef.id}`);
  };

  return (
    <div
      className={cx(
        "relative p-2 rounded-md shadow-sm",
        task.isCompleted
          ? "bg-success"
          : task.isExpired
          ? "bg-error"
          : task.dueAt && !task.isDue
          ? "bg-warning"
          : "bg-card",
        "cursor-pointer"
      )}
      onClick={task.id ? () => _.pushModal(`/t/${task.id}`) : newTaskWithParams}
    >
      <TaskSummary task={task} showRecurring />
    </div>
  );
}
