import * as _ from "utils";

import React from "react";
import useGroupContext from "hooks/useGroupContext";
import useTranslation from "hooks/useTranslation";
import useUpstreamState from "hooks/useUpstreamState";

import * as Icons from "components/Icons";
import { Button, Field, FloatingActions, ImageInput } from "components/UI";

export default () => {
  const { t } = useTranslation();
  const [group, groupResource] = useGroupContext();
  const [name, setName] = useUpstreamState(group.name || "");

  return (
    <div className="space-y-4">
      <h1>{t`group_setup.title`}</h1>
      <ImageInput
        className="w-48 h-48 mx-auto rounded-full"
        value={group.image}
        onChange={(v) => groupResource.setImage(v)}
        onDelete={() => groupResource.update({ image: null })}
        compression={{
          maxArea: 640 * 160,
          q: 0.75,
          type: "image/jpeg",
        }}
      />
      <Field
        autoFocus
        variant="contained"
        label={t`group_setup.name.label`}
        placeholder={t`group_setup.name.placeholder`}
        value={name}
        onChange={(event) => setName(event.target.value)}
        onBlur={() => groupResource.update({ name: name })}
      />
      <FloatingActions>
        <Button
          onClick={() => {
            _.popModal("/");
            if (!group.isSetup) {
              groupResource.delete();
            }
          }}
          color="card"
        >
          {t`action.cancel`}
        </Button>
        <Button
          onClick={() => _.navigate(`/g/${group.id}/setup/members`)}
          color="primary"
          variant="contained"
          trail={<Icons.Arrow direction="right" />}
          disabled={name === ""}
        >
          {t`label.next`}
        </Button>
      </FloatingActions>
    </div>
  );
};
