import * as _ from "utils";
import { v4 as uuidv4 } from "uuid";

import React, { useState } from "react";
import useContacts from "hooks/useContacts";
import useListContext from "hooks/useListContext";
import useParticipantUtils from "hooks/useParticipantUtils";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { BaseInput } from "components/UI";
import InfiniteList from "components/InfiniteList";
import UserDetail from "components/User/Detail";
import UserThumb from "components/User/Thumb";

const Contact = ({ userContainer, parentId, isEditable }) => {
  const [list] = useListContext();
  const participant = list.maybeParticipants.find(_.isSameUser(userContainer));
  const userContainerWithParticipant = { ...userContainer, ...participant };
  const participantUtils = useParticipantUtils();

  return (
    <UserDetail
      userContainer={userContainerWithParticipant}
      detailed
      badge={participantUtils.getRoleBadge(userContainerWithParticipant)}
      actionButton={
        isEditable
          ? {
              icon: Icons.Plus,
              color: "card",
            }
          : null
      }
      actions={
        isEditable
          ? participantUtils.getActions(userContainerWithParticipant, parentId)
          : []
      }
    />
  );
};

const renderContact = ({
  parentId,
  matchingParticipants,
  maybeParticipants,
}) => (contact) => (
  <Contact
    key={contact.userIdentifiers[0]}
    userContainer={contact}
    parentId={parentId}
    isEditable={
      matchingParticipants.some(_.isSameUser(contact)) ||
      !maybeParticipants.some(_.isSameUser(contact))
    }
  />
);

export default ({ parentId }) => {
  const { t } = useTranslation();
  const [list] = useListContext();

  const [{ contacts }] = useContacts();
  const [query, setQuery] = useState("");
  const queryLower = query.toLowerCase();
  const queryHash = _.md5(queryLower);

  const participantUtils = useParticipantUtils();

  const matchingContacts = _.findMatchingContacts(query, contacts);

  const matchingParticipants = parentId
    ? list.maybeParticipants.filter(
        (participant) => participant.parentId === parentId
      )
    : list.maybeParticipants;

  return (
    <>
      <div className="flex flex-wrap wrapping-box--2">
        {matchingParticipants.map((participant) => (
          <UserThumb
            key={participant.id}
            userContainer={participant}
            badge={participantUtils.getRoleBadge(participant)}
            actions={participantUtils.getActions(participant, parentId)}
          />
        ))}
      </div>
      <BaseInput
        autoFocus
        type="email"
        variant="contained"
        placeholder={t`list.participants.placeholder`}
        lead={<Icons.Loop />}
        value={query}
        onChange={(event) => setQuery(event.target.value)}
      />
      {!_.isEmail(query) && query.length > 0 && (
        <Contact
          userContainer={{ id: uuidv4(), name: query, userIdentifiers: [] }}
          detailed
          parentId={parentId}
          isEditable
        />
      )}
      <InfiniteList
        items={matchingContacts}
        renderItem={renderContact({
          parentId,
          maybeParticipants: list.maybeParticipants,
          matchingParticipants,
        })}
      />
      {_.isEmail(query) &&
        !matchingContacts.some(
          (contact) => contact.email?.toLowerCase() === queryLower
        ) && (
          <Contact
            userContainer={{
              email: queryLower,
              userIdentifiers: [queryHash],
            }}
            detailed
            parentId={parentId}
            isEditable={
              matchingParticipants.some(
                _.isSameUser({ userIdentifiers: [queryHash] })
              ) ||
              !list.maybeParticipants.some(
                _.isSameUser({ userIdentifiers: [queryHash] })
              )
            }
          />
        )}
    </>
  );
};
