import { LIST_TYPES, PARTICIPANT_ROLES } from "statics.json";
import * as _ from "utils";
import queryString from "query-string";

import React from "react";
import useCSSTransition from "hooks/useCSSTransition";
import usePageContext from "hooks/usePageContext";

import * as Icons from "components/Icons";
import { FloatingActions, IconButton } from "components/UI";

export default () => {
  const [{ isCurrent }] = usePageContext();
  const buttonRef = useCSSTransition(isCurrent, {
    timeout: 200,
    appear: true,
  });

  return (
    <FloatingActions>
      <IconButton
        ref={buttonRef}
        color="secondary"
        size="md"
        className="scale-up"
        onClick={() =>
          _.pushModal(
            `/new-list/?${queryString.stringify({
              role: PARTICIPANT_ROLES.CHEF,
              type: LIST_TYPES.COOK,
            })}`
          )
        }
      >
        <Icons.Plus size={1.5} />
      </IconButton>
    </FloatingActions>
  );
};
