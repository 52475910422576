import "./Courses.scss";
import * as _ from "utils";

import React, { useCallback, useRef, useState } from "react";
import useIdle from "hooks/useIdle";
import useListContext from "hooks/useListContext";
import useRecipe from "hooks/useRecipe";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { BaseInput, FlyOut, IconButton } from "components/UI";

export default function CollapsedCourses({ isEditing, onExpand }) {
  const { t } = useTranslation();
  const [list, listResource] = useListContext();
  const moreOptionsOriginRef = useRef(null);

  const [option, setOption] = useState(list.courses[0]?.options[0]);

  const flush = useCallback(() => {
    listResource.update({
      courses: [{ ...listResource.rawData.courses[0], options: [option] }],
    });
  }, [listResource, option]);
  const touch = useIdle(flush, 500);

  useRecipe({
    isEditing,
    title: option.title,
    recipe: option.recipe,
    setRecipe: (recipe) => setOption((option) => ({ ...option, recipe })),
  });

  return (
    <div className="flex items-flex-start space-x-4">
      <div className="flex-grow">
        <BaseInput
          placeholder={t`list.courses.dishOrRecipeUrl`}
          value={option.title}
          onChange={({ target: { value } }) => {
            setOption((option) => ({ ...option, title: value }));
            touch();
          }}
          disabled={!isEditing}
        />
        {option.recipe && (
          <a
            href={option.recipe.url}
            target="_blank"
            rel="noopener noreferrer"
            className="block break-words text-xs text-primary"
          >
            {_.formatCourseOption(option)}{" "}
            <Icons.OutboundLink size={0.75} style={{ verticalAlign: "-2px" }} />
          </a>
        )}
      </div>
      <IconButton ref={moreOptionsOriginRef} variant="text" className="my-1">
        <Icons.Dots direction="vertical" />
      </IconButton>
      <FlyOut originRef={moreOptionsOriginRef}>
        <FlyOut.Item
          onClick={onExpand}
        >{t`list.courses.createMenu`}</FlyOut.Item>
        <FlyOut.Item
          onClick={() =>
            listResource.update({
              courses: [
                {
                  ...listResource.rawData.courses[0],
                  everyoneCanAddOptions: true,
                },
              ],
            })
          }
        >
          {t`list.courses.createPoll`}
        </FlyOut.Item>
      </FlyOut>
    </div>
  );
}
