import React from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";

export default function ExpensesEmptyState({ explain }) {
  const { t } = useTranslation();

  return (
    <div className="space-y-4 py-4">
      <div className="mx-auto flex items-center justify-center w-24 h-24 rounded-full accent-success text-3xl font-bold">
        <Icons.Check className="text-success" size={4} />
      </div>
      <div className="text-center">
        <p className="text-caption">{t`expense.noUnsettledExpenses`}</p>
        {explain && (
          <p className="text-caption text-xs">{t`expense.addExpenseExplanation`}</p>
        )}
      </div>
    </div>
  );
}
