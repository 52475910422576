import * as _ from "utils";
import moment from "moment";

import React, { useState } from "react";
import useGroupContext from "hooks/useGroupContext";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";

import withGroup from "hocs/withGroup";

import { PopupModalLayout } from "layouts/Modal";

import { Button, Field } from "components/UI";
import RegularMomentInput from "components/RegularMomentInput";

const initialState = {
  frequency: 1,
  frequencyPeriod: "week",
  dateType: "day",
  dayOfWeek: moment().weekday(),
  dayOfMonth: moment().date(),
  nth: "first",
  startDate: {
    date: moment().format("YYYY-MM-DD"),
    time: null,
  },
  weekDays: Array(7).fill(false),
};

export default withGroup(() => {
  const { t } = useTranslation();
  const [, { onClose }] = usePageContext();
  const [state, setState] = useState(initialState);
  const [, groupResource] = useGroupContext();

  return (
    <PopupModalLayout>
      <div className="space-y-4">
        <div className="p-4 space-y-4 rounded-md bg-card">
          <h3>{t`frequency.new`}</h3>
          <Field
            label={t`label.title`}
            placeholder={t`group.regularMomentSuggestions`}
            variant="contained"
            size="sm"
            value={state.title || ""}
            onChange={({ target }) =>
              setState((state) => ({ ...state, title: target.value }))
            }
            onBlur={() =>
              setState((state) => ({
                ...state,
                title: state.title?.trim() ?? null,
              }))
            }
          />
          <RegularMomentInput value={state} onChange={setState} />
        </div>
        <div className="flex justify-flex-end space-x-4">
          <Button color="background" onClick={onClose}>
            {t`action.cancel`}
          </Button>
          <Button
            color="primary"
            disabled={!_.isValidRegularMoment(state)}
            onClick={() => {
              groupResource.createRegularMoment(state);
              onClose();
            }}
          >
            {t`action.create`}
          </Button>
        </div>
      </div>
    </PopupModalLayout>
  );
});
