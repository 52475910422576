import React from "react";

import * as Icons from "components/Icons";

export default function FinancialIcon({ type, ...props }) {
  switch (type) {
    case "bunq":
      return <Icons.Bunq {...props} />;
    case "paypal":
      return <Icons.PayPal {...props} />;
    case "iban":
      return <Icons.Bank {...props} />;
    default:
      return <Icons.OutboundLink {...props} />;
  }
}
