import * as Sentry from "@sentry/browser";

import React, { useEffect, useState } from "react";
import useTranslation from "hooks/useTranslation";

import { ErrorBoundary } from "react-error-boundary";
import { Button } from "components/UI";

const ErrorFallback = ({ error, componentStack, resetErrorBoundary }) => {
  const { t } = useTranslation();
  const [eventId, setEventId] = useState(null);

  useEffect(() => {
    Sentry.withScope((scope) => {
      scope.setExtras({ componentStack });
      setEventId(Sentry.captureException(error));
    });
  }, [error, componentStack]);

  return (
    <div className="flex flex-col items-center justify-center h-full p-4 space-y-4">
      <h2>{t`errors.title`}</h2>
      <p className="max-w-xs">{t`errors.description`}</p>
      <div className="flex items-center space-x-4">
        <Button
          variant="text"
          onClick={() => Sentry.showReportDialog({ eventId })}
        >
          {t`errors.giveFeedback`}
        </Button>
        <Button color="primary" onClick={() => resetErrorBoundary()}>
          {t`errors.tryAgain`}
        </Button>
      </div>
    </div>
  );
};

export default (props) => (
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      window.location.reload();
    }}
    {...props}
  />
);
