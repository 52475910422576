import "./Actions.scss";

import { LIST_TYPES, PARTICIPANT_ROLES } from "statics.json";
import * as _ from "utils";
import cx from "classnames";
import queryString from "query-string";

import React, { useEffect, useState } from "react";
import { useMatch } from "@reach/router";
import useClickOutside from "hooks/useClickOutside";
import useCSSTransition from "hooks/useCSSTransition";
import useGroupContext from "hooks/useGroupContext";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { FloatingActions, IconButton } from "components/UI";

const Action = ({ action, isExpanded, nth }) => {
  const { Icon, label, onAct } = action;

  return (
    <div
      className={cx("group-actions__button__position", {
        "group-actions__button__position--expanded": isExpanded,
      })}
      style={{
        transitionDelay: `${100 * (nth + 1)}ms`,
        top: `${-3.5 * (nth + 1) + 0.25}rem`,
      }}
    >
      <IconButton onClick={onAct} color="card">
        <Icon />
      </IconButton>
      <p className="group-actions__button__label">{label}</p>
    </div>
  );
};

export default () => {
  const { t } = useTranslation();

  const [group] = useGroupContext();
  const [{ isCurrent }] = usePageContext();
  const match = useMatch("/g/:groupId/statistics");
  const isVisible = isCurrent && !match;
  const [isExpanded, setExpanded] = useState(false);
  const buttonRef = useCSSTransition(isVisible, {
    timeout: 200,
    appear: true,
  });
  useClickOutside(buttonRef, () => setExpanded(false));

  useEffect(() => {
    if (!isVisible) {
      setExpanded(false);
    }
  }, [isVisible]);

  const actions = [
    {
      isVisible: true,
      Icon: Icons.Cutlery,
      label: t`group.newDinner`,
      onAct: () =>
        _.pushModal(
          `/new-list/?${queryString.stringify({
            role: PARTICIPANT_ROLES.CHEF,
            type: LIST_TYPES.COOK,
            groupId: group.id,
          })}`
        ),
    },
    {
      isVisible: process.env.REACT_APP_TASKS_ENABLED,
      Icon: Icons.Check,
      label: t`group.newTask`,
      onAct: () => _.pushModal(`/g/${group.id}/new-task`),
    },
    {
      isVisible: process.env.REACT_APP_EXPENSES_ENABLED,
      Icon: () => (
        <span className="block text-center font-bold w-4 h-4">€</span>
      ),
      label: t`group.newExpense`,
      onAct: () => _.pushModal(`/g/${group.id}/new-expense`),
    },
  ].filter(({ isVisible }) => isVisible);

  return (
    actions.length > 0 && (
      <FloatingActions>
        {actions.length === 1 ? (
          <IconButton
            ref={buttonRef}
            color="secondary"
            size="md"
            className="scale-up"
            onClick={actions[0].onAct}
          >
            <Icons.Plus size={1.5} />
          </IconButton>
        ) : (
          <div
            className={cx("group-actions__container", {
              "group-actions__container--expanded": isExpanded,
            })}
          >
            <IconButton
              ref={buttonRef}
              color="secondary"
              size="md"
              className="scale-up"
              style={isExpanded ? { transform: "rotate(45deg)" } : undefined}
              onClick={() => setExpanded((isExpanded) => !isExpanded)}
            >
              <Icons.Plus size={1.5} />
            </IconButton>
            {actions.map((action, i) => (
              <Action key={i} action={action} nth={i} isExpanded={isExpanded} />
            ))}
          </div>
        )}
      </FloatingActions>
    )
  );
};
