import React, { useCallback, useState } from "react";
import useIdle from "hooks/useIdle";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { TextArea } from "components/UI";
import CollapsibleContainer from "components/CollapsibleContainer";

export default ({ isEditing }) => {
  const { t } = useTranslation();
  const [list, listResource] = useListContext();
  const [description, setDescription] = useState(list.description);
  const flush = useCallback(
    () =>
      description !== listResource.rawData.description &&
      listResource.update({ description }),
    [description, listResource]
  );
  const touch = useIdle(flush, 500);

  return (
    <CollapsibleContainer
      icon={Icons.Paragraph}
      isVisible={Boolean(isEditing || list.description)}
    >
      {isEditing ? (
        <TextArea
          placeholder={t`list.description`}
          value={description || ""}
          rows={1}
          onChange={(event) => {
            setDescription(event.target.value);
            touch();
          }}
        />
      ) : list.description ? (
        <p className="hyphenate whitespace-pre-line">{list.description}</p>
      ) : (
        <p className="text-caption">{t`list.description`}</p>
      )}
    </CollapsibleContainer>
  );
};
