import "./MultiAvatar.scss";

import React from "react";

import { Avatar } from "components/UI";

export default function MultiAvatar({ users = [], size, ...otherProps }) {
  const visibleUsers = users
    .filter(({ avatar, name }) => avatar || name)
    .slice(0, 4);
  return (
    <div
      className={`relative flex-shrink-0 w-${size * 4} h-${size * 4}`}
      {...otherProps}
    >
      {visibleUsers.length > 0 ? (
        visibleUsers.map((user, i) => (
          <Avatar
            key={i}
            user={user}
            size={size}
            className={`multi-avatar multi-avatar--${i + 1}/${
              visibleUsers.length
            }`}
          />
        ))
      ) : (
        <Avatar size={size} user={{}} />
      )}
    </div>
  );
}
