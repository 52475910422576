import React from "react";

import SimpleLayout from "layouts/Simple";

import updates from "updates";

export default () => (
  <SimpleLayout title="Updates">
    <div>{updates.slice().reverse()}</div>
  </SimpleLayout>
);
