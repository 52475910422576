import * as _ from "utils";

import React, { useLayoutEffect } from "react";
import { useDoc } from "react-firestore";
import { TransactionContext } from "contexts";

import withAuth from "hocs/withAuth";

import NotFoundPage from "pages/404";

const TransactionProvider = ({ transactionId, children }) => {
  const value = useDoc("transactions", transactionId);

  useLayoutEffect(() => {
    _.setAnalyticsContext({ transaction_id: transactionId });
    return () => {
      _.setAnalyticsContext({ transaction_id: undefined });
    };
  }, [transactionId]);

  return value[0].hasExisted ? (
    <TransactionContext.Provider value={value}>
      {children}
    </TransactionContext.Provider>
  ) : (
    <NotFoundPage />
  );
};

export default withAuth(TransactionProvider);
