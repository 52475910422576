import * as _ from "utils";

export default class BaseModel {
  docRef = null;
  rawData = null;

  constructor(docRef) {
    this.docRef = docRef;
  }

  static create(params) {
    return params;
  }

  static normalize(rawData) {
    return rawData;
  }

  setRawData(rawData) {
    this.rawData = rawData;
  }

  async update(v) {
    await this.docRef.update(_.pruneObject({ ...v, updatedAt: new Date() }));
  }

  async delete() {
    await this.docRef.delete();
  }
}
