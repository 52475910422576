import React from "react";
import { Feature } from "./components";

export default (
  <div key={1}>
    <h2 className="text-caption font-black uppercase text-lg">Update 1</h2>
    <ul className="space-y-2">
      <Feature>
        Late for dinner? You can easily indicate this by leaving a comment under
        a list.
      </Feature>
      <Feature>
        Take a look at the new groups. Their layout is simplified and features a
        fresh animation.
      </Feature>
    </ul>
  </div>
);
