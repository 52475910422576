import "./Icon.scss";
import cx from "classnames";

import React from "react";

export default function Icon({
  size,
  strokeWidth,
  color,
  className,
  ...props
}) {
  return (
    <svg
      className={cx(
        "icon",
        `stroke-${strokeWidth}`,
        `h-${size * 4}`,
        className
      )}
      {...props}
    />
  );
}
Icon.defaultProps = {
  size: 1,
  strokeWidth: 3,
};
