import * as _ from "utils";
import cx from "classnames";

import React from "react";
import useTaskContext from "hooks/useTaskContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import CollapsibleContainer from "components/CollapsibleContainer";

export default function Extension() {
  const { t, moment } = useTranslation();
  const [task] = useTaskContext();

  return (
    <CollapsibleContainer
      isVisible={Boolean(task.isCompleted || task.expiresAt)}
      icon={task.isCompleted ? Icons.Check : Icons.Share}
      iconClassName={cx("w-6 mr--2 text-center", {
        "text-success": task.isCompleted,
      })}
    >
      {task.isCompleted ? (
        <div>
          <p>
            {t(`task.completedAt`, {
              completedAt: _.formatDate({
                date: moment(task.completedAt.toDate()).format("YYYY-MM-DD"),
              }),
            })}
          </p>
          {task.dueAt && (
            <p className="text-xs text-caption">
              {task.completedAt > task.dueAt
                ? t(`task.overdue`, {
                    difference: moment
                      .duration(
                        moment(task.completedAt.toDate()).diff(
                          task.dueAt.toDate()
                        )
                      )
                      .humanize(),
                  })
                : t(`task.onTime`, {
                    difference: moment
                      .duration(
                        moment(task.dueAt.toDate()).diff(
                          task.completedAt.toDate()
                        )
                      )
                      .humanize(),
                  })}
            </p>
          )}
        </div>
      ) : (
        <p>
          {t(`task.extensionTil`, {
            expiresAt: moment(task.expiresAt?.toDate()).calendar(),
          })}
        </p>
      )}
    </CollapsibleContainer>
  );
}
