import * as _ from "utils";

import { useEffect } from "react";
import useLocallyStoredState from "./useLocallyStoredState";
import useCurrentUser from "./useCurrentUser";
import useNotifications from "./useNotifications";

export default function useNotificationsPrompt() {
  const [
    hasPromptedNotifications,
    setHasPromptedNotifications,
  ] = useLocallyStoredState("___notificationsPrompt___", false);
  const [currentUser] = useCurrentUser();
  const [{ permission }] = useNotifications();

  useEffect(() => {
    if (
      !currentUser.vapidToken &&
      !hasPromptedNotifications &&
      ["prompt", "default"].includes(permission)
    ) {
      setHasPromptedNotifications(true);
      _.pushModal("/request-notifications");
    }
  }, [
    currentUser.vapidToken,
    hasPromptedNotifications,
    permission,
    setHasPromptedNotifications,
  ]);

  const retry = () => setHasPromptedNotifications(false);

  return retry;
}
