import React, { Suspense } from "react";

import { HelmetProvider } from "react-helmet-async";
import { LocationProvider } from "@reach/router";

import AnalyticsTracker from "./AnalyticsTracker";
import AppTrackingTransparencyProvider from "./AppTrackingTransparencyProvider";
import AuthenticationBoundary from "./AuthenticationBoundary";
import ColorModeProvider from "./ColorModeProvider";
import DeepLinkObserver from "./DeepLinkObserver";
import ErrorBoundary from "./ErrorBoundary";
import FirestoreQueryManager from "./FirestoreQueryManager";
import GroupRecencyProvider from "./GroupRecencyProvider";
import HtmlLanguageTag from "./HtmlLanguageTag";
import MessagesObserver from "./MessagesObserver";
import Navigation from "./Navigation";
import Notifications from "./Notifications";
import Router from "./Router";
import UpdatesManager from "./UpdatesManager";

export default function App() {
  return (
    <Suspense fallback={<div className="loader absolute inset-0 m-auto" />}>
      <GroupRecencyProvider>
        <HelmetProvider>
          <ColorModeProvider>
            <ErrorBoundary>
              <Suspense
                fallback={<div className="loader absolute inset-0 m-auto" />}
              >
                <AppTrackingTransparencyProvider>
                  <FirestoreQueryManager />
                  <LocationProvider>
                    <Navigation>
                      <AuthenticationBoundary />
                      <UpdatesManager />
                      <DeepLinkObserver />
                      <MessagesObserver />
                      <HtmlLanguageTag />
                      <Router />
                      <AnalyticsTracker />
                      <Notifications />
                    </Navigation>
                  </LocationProvider>
                </AppTrackingTransparencyProvider>
              </Suspense>
            </ErrorBoundary>
          </ColorModeProvider>
        </HelmetProvider>
      </GroupRecencyProvider>
    </Suspense>
  );
}
