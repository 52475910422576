import * as _ from "utils";

import React, { Suspense, useState } from "react";
import useAuth from "hooks/useAuth";
import useCurrentUser from "hooks/useCurrentUser";
import useTranslation from "hooks/useTranslation";

import withAuth from "hocs/withAuth";

import SimpleLayout from "layouts/Simple";

import * as Icons from "components/Icons";
import { Button, Field, IconButton, ImageInput, Message } from "components/UI";
import DietaryPreferences from "components/DietaryPreferences";
import UserStatistics from "components/UserStatistics";

const UserName = () => {
  const { t } = useTranslation();
  const [currentUser, currentUserResource] = useCurrentUser();
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(currentUser.name);

  return isEditing ? (
    <form
      className="flex space-x-2"
      onSubmit={(event) => {
        event.preventDefault();
        currentUserResource.update({ name });
        setIsEditing("");
      }}
    >
      <Field
        name="name"
        variant="contained"
        placeholder={t`profile.nameLabel`}
        required
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      <IconButton color="primary" disabled={!name}>
        <Icons.Check />
      </IconButton>
    </form>
  ) : (
    <h2 className="flex items-center justify-center space-x-2 px-8">
      <span className="break-all">
        {currentUser.name || (
          <em className="text-caption">{t`label.nameless`}</em>
        )}
      </span>
      <IconButton
        className="mr--8"
        size="xs"
        color="card"
        onClick={() => {
          setIsEditing(true);
          setName(currentUser.name);
        }}
      >
        <Icons.Pen size={0.75} />
      </IconButton>
    </h2>
  );
};

const UserPreferences = () => {
  const { t } = useTranslation();
  const [currentUser, currentUserResource] = useCurrentUser();
  const preferences = currentUser.preferences || [];
  const [isEditing, setIsEditing] = useState(false);

  return (
    <DietaryPreferences
      dietaryInformation={preferences}
      isEditable={isEditing}
      onChange={(preferences) => currentUserResource.update({ preferences })}
      className={isEditing ? "justify-center" : "justify-center ml-8"}
    >
      {!isEditing && preferences.length === 0 && (
        <em className="text-caption">{t`profile.noPreferences`}</em>
      )}
      {isEditing ? (
        <IconButton size="xs" color="card" onClick={() => setIsEditing(false)}>
          <Icons.Check size={0.75} />
        </IconButton>
      ) : (
        <IconButton size="xs" color="card" onClick={() => setIsEditing(true)}>
          <Icons.Pen size={0.75} />
        </IconButton>
      )}
    </DietaryPreferences>
  );
};

export default withAuth(() => {
  const { t } = useTranslation();
  const [auth] = useAuth();
  const [currentUser, currentUserResource] = useCurrentUser();

  return (
    <SimpleLayout title={currentUser.name} hideTitle>
      <div className="space-y-8 py-4">
        {auth.currentUser.isAnonymous && (
          <Message color="warning" className="p-4 space-y-4">
            <p>{t`profile.anonymous.warning`}</p>
            <Button
              className="w-full"
              color="warning"
              onClick={() => _.pushModal("/settings/auth")}
            >{t`profile.anonymous.addEmail`}</Button>
          </Message>
        )}
        <div className="flex flex-col space-y-2 items-center">
          <ImageInput
            className="w-32 h-32 rounded-full"
            value={currentUser.avatar}
            onChange={(avatar) => currentUserResource.updateAvatar(avatar)}
            onDelete={() => currentUserResource.update({ avatar: null })}
            compression={{
              maxArea: 320 * 320,
              q: 0.75,
              type: "image/jpeg",
            }}
          />
          <UserName />
          <UserPreferences />
        </div>
        <div className="space-y-4">
          <h1>{t`profile.statistics.title`}</h1>
          <Suspense fallback={<div className="loader mx-auto" />}>
            <UserStatistics userIdentifiers={currentUser.userIdentifiers} />
          </Suspense>
        </div>
      </div>
    </SimpleLayout>
  );
});
