import * as _ from "utils";

import React, { useState } from "react";
import useAuth from "hooks/useAuth";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Button, IconButton, Message } from "components/UI";

export default () => {
  const { t } = useTranslation();

  const [
    ,
    { signInWithApple, signInWithFacebook, signInWithGoogle },
    support,
  ] = useAuth();
  const [error, setError] = useState(null);

  const safe = (f) => async (...args) => {
    try {
      await f(...args);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <div className="space-y-4">
      <h1 className="auth__title">{t`auth.title`}</h1>
      {error && error.code && (
        <Message color="error" className="p-4">
          <p>{t(`errors.${error.code}`)}</p>
        </Message>
      )}
      <p className="text-center">{t`auth.signUpWith`}</p>
      <div className="flex justify-center space-x-4">
        {support.methods.includes("apple.com") && (
          <IconButton color="text" onClick={safe(signInWithApple)}>
            <Icons.Apple className="mt--1" />
          </IconButton>
        )}
        <IconButton color="facebook" onClick={safe(signInWithFacebook)}>
          <Icons.Facebook />
        </IconButton>
        <IconButton color="white" onClick={safe(signInWithGoogle)}>
          <Icons.Google />
        </IconButton>
      </div>
      <p>{_.capitalize(t`common.or`)}</p>
      <Button
        color="primary"
        variant="text"
        onClick={() => _.navigate("/auth/register/email")}
      >
        {t`auth.signUpWithEmail`}
      </Button>
      <Button
        color="primary"
        variant="text"
        onClick={() => _.navigate("/auth/register/name")}
      >
        {t`auth.signUpAnonymously`}
      </Button>
      {process.env.NODE_ENV === "development" && (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            _.auth.signInWithCustomToken(event.target.token.value);
          }}
        >
          <input name="token" placeholder="token" />
          <button>Submit</button>
        </form>
      )}
    </div>
  );
};
