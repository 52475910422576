import { useEffect } from "react";
import usePageContext from "./usePageContext";

export default function usePageScroll(onScroll) {
  const [{ pageRef }] = usePageContext();

  useEffect(() => {
    const page = pageRef.current;
    const scrollContainer = page?.childNodes[0];

    if (scrollContainer) {
      const handleScroll = () => onScroll(scrollContainer?.scrollTop || 0);
      handleScroll();

      scrollContainer.addEventListener("scroll", handleScroll);
      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [pageRef, onScroll]);
}
