import { useEffect } from "react";
import useAuth from "./useAuth";
import useDeviceToken from "./useDeviceToken";
import useTranslation from "./useTranslation";
import useUser from "./useUser";

const getLang = (lang) => {
  switch (lang) {
    case "nl":
      return "nl";
    default:
      return "en";
  }
};

export default function useCurrentUser() {
  const [deviceToken] = useDeviceToken();
  const { i18n, setLanguage } = useTranslation();

  const [auth] = useAuth();
  const [user, userResource] = useUser(auth.currentUser);

  useEffect(() => {
    if (user.exists) {
      if (!user?.settings?.preferredLanguage) {
        userResource.update({
          "settings.preferredLanguage": getLang(i18n.language),
        });
      } else if (i18n.language !== user.settings.preferredLanguage) {
        setLanguage(user.settings.preferredLanguage);
      }

      if (
        !user?.messagingDeviceToken ||
        (!user?.vapidToken && user.messagingDeviceToken !== deviceToken)
      ) {
        userResource.update({ messagingDeviceToken: deviceToken });
      }
    }
  }, [deviceToken, setLanguage, i18n.language, user, userResource]);

  return [user, userResource];
}
