import * as _ from "utils";

import React, { useEffect, useState } from "react";
import useListContext from "hooks/useListContext";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";

import withList from "hocs/withList";

import { BottomModalLayout } from "layouts/Modal";

import * as Icons from "components/Icons";
import Button from "components/UI/Button";
import DietaryPreferences from "components/DietaryPreferences";

const ParticipantDietaryPreferencesModal = ({ participantId }) => {
  const { t } = useTranslation();
  const [, { onClose }] = usePageContext();
  const [list, listResource] = useListContext();

  const currentParticipant =
    list.maybeParticipants.find(({ id }) => id === participantId) || null;

  const [preferences, setPreferences] = useState(
    currentParticipant?.preferences || []
  );

  useEffect(() => {
    if (!currentParticipant) {
      _.popModal(`/l/${list.id}`);
    }
  }, [list.id, currentParticipant]);

  return (
    <BottomModalLayout>
      <div className="rounded-b-none max-h-screen rounded-md bg-card overflow-y-auto">
        <div className="p-4 space-y-4">
          <h2>
            {t`profile.preferencesFor`} {currentParticipant?.name}
          </h2>
          <DietaryPreferences
            dietaryInformation={preferences}
            isEditable
            onChange={setPreferences}
          />
          <div className="flex space-x-2 justify-flex-end">
            <Button color="card" onClick={() => onClose()}>
              {t`action.cancel`}
            </Button>
            <Button
              color="primary"
              lead={<Icons.Check />}
              onClick={async () => {
                await listResource.setParticipant(
                  participantId,
                  (participant) => ({ ...participant, preferences })
                );
                onClose();
              }}
            >
              {t`action.save`}
            </Button>
          </div>
        </div>
      </div>
    </BottomModalLayout>
  );
};

export default withList(ParticipantDietaryPreferencesModal);
