import React from "react";
import useNetworkStatus from "hooks/useNetworkStatus";
import useTranslation from "hooks/useTranslation";

import Notification from "components/Notification";

export default function NetworkStatus() {
  const { t } = useTranslation();
  const status = useNetworkStatus();

  return (
    !status.connected && (
      <Notification payload={{ title: t`status.offline`, color: "warning" }} />
    )
  );
}
