import React from "react";
import useTranslation from "hooks/useTranslation";

import withGroup from "hocs/withGroup";

import SetupLayout from "layouts/Setup";

export default withGroup(({ children }) => {
  const { t } = useTranslation();
  return <SetupLayout title={t`group_setup.title`}>{children}</SetupLayout>;
});
