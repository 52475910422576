import { contactsResource } from "resources";

import { useEffect } from "react";
import useResource from "./useResource";

export default function useContacts() {
  const { contacts, permission } = useResource(contactsResource);

  useEffect(() => {
    if ([null, "pending"].includes(permission)) {
      contactsResource.requestPermission();
    } else if (permission === "granted") {
      contactsResource.fetch();
    }
  }, [permission]);

  return [
    {
      contacts,
      permission,
    },
  ];
}
