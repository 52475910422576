import React from "react";

import ExpenseProvider from "containers/ExpenseProvider";

const withExpense = (Component) => (props) => (
  <ExpenseProvider expenseId={props.expenseId}>
    <Component {...props} />
  </ExpenseProvider>
);

export default withExpense;
