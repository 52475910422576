import { COURSE_FORMATS } from "statics.json";

import React from "react";
import useCourseContext from "hooks/useCourseContext";
import useListContext from "hooks/useListContext";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";

import withCourse from "hocs/withCourse";
import withList from "hocs/withList";

import { PopupModalLayout } from "layouts/Modal";

import * as Icons from "components/Icons";
import { Button, Field, IconButton, Toggle } from "components/UI";

const formatOptions = [
  {
    format: COURSE_FORMATS.SINGLE_OPTION,
    illustration: null,
  },
  {
    format: COURSE_FORMATS.MULTIPLE_OPTIONS,
    illustration: (
      <div className="flex pointer-events-none">
        <input tabIndex={-1} type="radio" readOnly checked />
        <div className="w-2 h-2" />
        <input tabIndex={-1} type="radio" readOnly />
      </div>
    ),
  },
  {
    format: COURSE_FORMATS.POLL,
    illustration: (
      <div className="flex space-x-2 pointer-events-none">
        <IconButton tabIndex={-1} size="xs" color="error">
          <Icons.Cross size={0.75} />
        </IconButton>
        <IconButton tabIndex={-1} size="xs" color="warning">
          <Icons.Maybe size={0.75} />
        </IconButton>
        <IconButton tabIndex={-1} size="xs" color="success">
          <Icons.Check size={0.75} />
        </IconButton>
      </div>
    ),
  },
  {
    format: COURSE_FORMATS.ORDER,
    illustration: (
      <div className="flex items-center space-x-2 pointer-events-none">
        <IconButton tabIndex={-1} size="xs" color="card">
          <Icons.Min size={0.75} />
        </IconButton>
        <p className="leading-none">1</p>
        <IconButton tabIndex={-1} size="xs" color="card">
          <Icons.Plus size={0.75} />
        </IconButton>
      </div>
    ),
  },
];

export default withList(
  withCourse(() => {
    const { t } = useTranslation();
    const [list] = useListContext();
    const [course, { onChange, onDelete }] = useCourseContext();
    const [, { onClose }] = usePageContext();

    const nth = list.courses.findIndex((other) => other.id === course.id) + 1;

    return (
      <PopupModalLayout>
        <div className="max-w-sm p-4 space-y-4 mx-auto rounded-md bg-card">
          <h2>
            {course.type || t(`list.courses.nth.${nth > 9 ? "default" : nth}`)}
          </h2>
          {formatOptions.map(({ format, illustration }) => (
            <div
              key={format}
              onClick={() => onChange((course) => ({ ...course, format }))}
              className="clickable"
            >
              <div className="flex items-center space-x-4">
                <Icons.Check
                  className={`text-primary opacity-${
                    course.format === format ? 100 : 0
                  }`}
                />
                <div className="w-full">
                  <div className="flex items-center justify-space-between">
                    <p>{t(`list.courses.format.${format}`)}</p>
                    {illustration}
                  </div>
                  <p className="text-caption text-xs">
                    {t(`list.courses.format.${format}_description`)}
                  </p>
                </div>
              </div>
            </div>
          ))}
          <hr />
          <Field
            type="checkbox"
            label={t`list.courses.participantsCanSuggest`}
            labelLocation="inline-reverse"
            className="items-center justify-space-between"
            checked={course.everyoneCanAddOptions}
            onChange={() =>
              onChange((course) => ({
                ...course,
                everyoneCanAddOptions: !course.everyoneCanAddOptions,
              }))
            }
            component={Toggle}
          />
          <div className="flex justify-flex-end space-x-4">
            <Button
              variant="text"
              color="error"
              onClick={() => {
                onDelete();
                onClose();
              }}
            >{t`list.courses.deleteCourse`}</Button>
            <Button
              variant="text"
              color="primary"
              onClick={() => onClose()}
            >{t`label.done`}</Button>
          </div>
        </div>
      </PopupModalLayout>
    );
  })
);
