import { LIST_TYPES, COURSE_FORMATS } from "statics.json";
import i18n from "i18next";

import { formatDate } from "./dates";
import { concatSentence, toContext } from "./strings";
import { formatCourseOption } from "./recipes";
import { isCurrentUser } from "./users";

const getListDescription = ({ list, chefUsers, lang, notif = false }) => {
  if (!list.exists) {
    return "";
  }

  const t = i18n.getFixedT(lang);
  const formattedDate = formatDate({
    date: list.occursAt.date,
    time: null,
    lang,
  }).toLowerCase();

  const chefNames = concatSentence(
    chefUsers
      .map((user) => (isCurrentUser(user) && !notif ? t`label.I` : user?.name))
      .filter(Boolean),
    t`common.and`
  );

  const iCook = notif ? false : list.chefs.some(isCurrentUser);
  const what =
    list.type === LIST_TYPES.ORDER
      ? list.orderLocation
      : list.courses.length > 0
      ? list.courses.length === 1
        ? list.courses[0].format === COURSE_FORMATS.SINGLE_OPTION
          ? formatCourseOption(list.courses[0].options[0])
          : null
        : t(`form.course`, { count: list.courses.length })
      : null;

  return [
    t(
      `description.${list.type === LIST_TYPES.ORDER ? "order" : "cook"}${
        iCook ? "" : "s"
      }`,
      {
        context: toContext([
          ["notif", notif],
          ["past", list.hasOccurred],
          ["chosen", !!what],
          ["zero", list.chefs.length === 0],
        ]),
        count: list.chefs.length,
        who: chefNames,
        when: formattedDate,
        what,
      }
    ),
    list.occursAt.time
      ? t("description.weEatAt", {
          time: list.occursAt.time,
          context: toContext([["past", list.hasOccurred]]),
        })
      : null,
    list.courses.length === 1 &&
    list.courses[0].format === COURSE_FORMATS.MULTIPLE_OPTIONS
      ? t("description.chooseFrom", {
          options: concatSentence(
            list.courses[0].options.map(
              (option) => `*${formatCourseOption(option)}*`
            ),
            t`common.and`
          ),
        })
      : null,
    list.courses.some((course) => course.format === COURSE_FORMATS.POLL)
      ? t`description.youCanVote`
      : null,
  ]
    .filter(Boolean)
    .join(" ");
};

const hasExtensiveMenu = (list) =>
  list.courses.length > 1 ||
  (list.courses.length > 0 &&
    (list.courses[0]?.options.length > 1 ||
      [COURSE_FORMATS.POLL, COURSE_FORMATS.ORDER].includes(
        list.courses[0]?.format
      ) ||
      list.courses[0].everyoneCanAddOptions));

export { hasExtensiveMenu, getListDescription };
