import * as _ from "utils";

import React from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Tag } from "components/UI";
import DietaryPreferenceThumb from "./DietaryPreferenceThumb";

export default ({ courseOption, isEditing }) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-1">
      <p
        className={
          !courseOption.recipe && !courseOption.title
            ? "text-caption"
            : undefined
        }
      >
        {!isEditing && courseOption.recipe ? (
          <a
            href={courseOption.recipe.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {_.formatCourseOption(courseOption)}{" "}
            <Icons.OutboundLink size={0.75} style={{ verticalAlign: "-1px" }} />
          </a>
        ) : (
          _.formatCourseOption(courseOption) || t`label.noDish`
        )}
      </p>
      <div className="flex flex-wrap wrapping-box--2 text-xs">
        {courseOption.price && <Tag color="primary">€{courseOption.price}</Tag>}
        {courseOption.dietaryInformation?.length > 0 && (
          <Tag className="flex flex-wrap">
            {courseOption.dietaryInformation.map((preference) => (
              <DietaryPreferenceThumb
                key={preference}
                preference={preference}
              />
            ))}
          </Tag>
        )}
      </div>
      {courseOption.description && (
        <p className="text-xs text-caption">
          {courseOption.description
            .split("\n")
            .flatMap((p, i) => [
              <br key={2 * i} />,
              <span key={2 * i + 1}>{p}</span>,
            ])
            .slice(1)}
        </p>
      )}
    </div>
  );
};
