import React from "react";
import { Location } from "@reach/router";

const AnalyticsTracker = () => (
  <Location>
    {({ location }) => {
      if (process.env.NODE_ENV !== `production` || typeof gtag !== `function`) {
        return null;
      }

      const pathIsExcluded =
        location &&
        typeof window.excludeGtagPaths !== `undefined` &&
        window.excludeGtagPaths.some(rx => rx.test(location.pathname));

      if (pathIsExcluded) return null;

      const sendPageView = () => {
        const pagePath = location
          ? location.pathname + location.search + location.hash
          : undefined;
        window.gtag(`event`, `page_view`, { page_path: pagePath });
      };

      if (`requestAnimationFrame` in window) {
        requestAnimationFrame(() => {
          requestAnimationFrame(sendPageView);
        });
      } else {
        // simulate 2 rAF calls
        setTimeout(sendPageView, 32);
      }

      return null;
    }}
  </Location>
);

export default AnalyticsTracker;
