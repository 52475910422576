import * as _ from "utils";

import React from "react";
import useGroupContext from "hooks/useGroupContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Button, FloatingActions } from "components/UI";
import GroupGraphic from "components/Group/Graphic";

export default () => {
  const { t } = useTranslation();
  const [group] = useGroupContext();

  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center w-full max-w-md mx-auto px-4 text-center">
      <div className="flex flex-col space-y-4">
        <GroupGraphic group={group} showName={false} />
        <h2 className="whitespace-pre-line">
          {t(`group_setup.complete.title`, { group: group.name })}
        </h2>
      </div>
      <FloatingActions>
        <Button
          color="primary"
          trail={<Icons.Arrow direction="right" />}
          onClick={() => _.replaceModal(`/g/${group.id}`)}
        >
          {t`group_setup.complete.toGroup`}
        </Button>
      </FloatingActions>
    </div>
  );
};
