import React, { Suspense } from "react";
import useTranslation from "hooks/useTranslation";

import withAuth from "hocs/withAuth";

import IndexLayout from "layouts/Index";

import { Tabs } from "components/UI";
import SEO from "components/SEO";
import OpenTransactions from "components/Home/OpenTransactions";
import Groups from "components/Home/Groups";
import NewListButton from "components/Home/NewListButton";

export default withAuth(({ children }) => {
  const { t } = useTranslation();

  return (
    <IndexLayout>
      <SEO description={t`home.tagline`} />
      <Suspense fallback={null}>
        <OpenTransactions />
      </Suspense>
      <Suspense fallback={<div className="loader mx-auto" />}>
        <Groups />
      </Suspense>
      <Tabs.Container>
        <Tabs.Tab to="planned">{t`dashboard.planned`}</Tabs.Tab>
        <Tabs.Tab to="archived">{t`dashboard.archive`}</Tabs.Tab>
        <Tabs.Tab to="declined">{t`dashboard.declined`}</Tabs.Tab>
      </Tabs.Container>
      <Suspense fallback={<div className="loader mx-auto" />}>
        {children}
      </Suspense>
      <NewListButton />
    </IndexLayout>
  );
});
