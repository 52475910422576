import * as _ from "utils";
import cx from "classnames";

import React, { useRef } from "react";
import useTranslation from "hooks/useTranslation";
import useUser from "hooks/useUser";

import { Avatar, FlyOut } from "components/UI";
import UserName from "./Name";

export default function UserThumb({ userContainer, actions = [], badge }) {
  const containerRef = useRef(null);
  const { t } = useTranslation();

  const [user] = useUser(userContainer);

  const Icon = badge?.icon;

  return (
    <>
      <div
        ref={containerRef}
        className={cx(
          "flex items-center space-x-2 rounded-full cursor-pointer bg-glow text-text",
          badge ? "pr-1" : "pr-2"
        )}
      >
        <Avatar user={user} size={1.5} />
        {_.isCurrentUser(user) ? (
          <p className="font-bold">{t`label.me`}</p>
        ) : (
          <p>
            <UserName user={user} />
          </p>
        )}
        {badge && (
          <div
            className={cx(
              "flex items-center justify-center w-4 h-4 rounded-full",
              `bg-${badge.color}`
            )}
          >
            {Icon && <Icon size={0.75} />}
          </div>
        )}
      </div>
      {actions.length > 0 && (
        <FlyOut originRef={containerRef}>
          {actions.map(({ icon, label, color, disabled, onAct }, i) => (
            <FlyOut.Item
              key={i}
              onClick={disabled ? null : onAct}
              color={color}
              disabled={disabled}
            >
              {icon} {label}
            </FlyOut.Item>
          ))}
        </FlyOut>
      )}
    </>
  );
}
