import { useCollection } from "react-firestore";

const groupExists = (group) => group.isSetup && group.exists;

export default function useRecurringTask(recurringTaskId) {
  const groups = useCollection("groups", { filter: groupExists });

  const recurringTask = groups
    .flatMap((group) => group.recurringTasks)
    .find((recurringTask) => recurringTask.id === recurringTaskId);

  return [
    recurringTask ? { ...recurringTask, exists: true } : { exists: false },
  ];
}
