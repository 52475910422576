import * as _ from "utils";

import React from "react";
import useGroupContext from "hooks/useGroupContext";
import useGroupSharing from "hooks/useGroupSharing";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Button, FloatingActions } from "components/UI";

import GroupMemberManagement from "components/Group/MemberManagement";

export default () => {
  const { t } = useTranslation();
  const [group, groupResource] = useGroupContext();
  const [, { tryShareNative }] = useGroupSharing();

  return (
    <>
      <div
        className="w-full max-w-2xl p-2 mx-auto absolute flex justify-flex-end"
        style={{ top: 0, left: 0, right: 0 }}
      >
        <Button
          color="secondary"
          size="xs"
          className="absolute"
          lead={<Icons.Share />}
          onClick={() => tryShareNative()}
        >{t`action.shareLink`}</Button>
      </div>
      <div className="space-y-4">
        <h1>{t(`group_setup.members.title`, { group: group.name })}</h1>
        <GroupMemberManagement />
      </div>
      <FloatingActions>
        <Button
          onClick={() => _.navigate(`/g/${group.id}/setup/basic`)}
          color="card"
        >
          {t`label.previous`}
        </Button>
        <Button
          onClick={() => {
            groupResource.update({ isSetup: true });
            _.navigate(`/g/${group.id}/setup/complete`);
          }}
          color="primary"
          variant="contained"
          lead={<Icons.Check />}
        >
          {t`label.finish`}
        </Button>
      </FloatingActions>
    </>
  );
};
