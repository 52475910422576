import moment from "moment";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import IntervalPlural from "i18next-intervalplural-postprocessor";

import nl from "translations/nl";
import en from "translations/en";

const format = (value, format, lng) => {
  if (format === "uppercase") {
    return value.toUpperCase();
  }
  if (value instanceof Date) {
    return moment(value).format(format);
  }
  return value;
};

i18n
  .use(IntervalPlural)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      nl: { translation: nl },
      en: { translation: en },
    },
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
      format,
    },
  });

moment.locale(i18n.language);

export default i18n;
