import * as _ from "utils";

import React from "react";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";
import useUsers from "hooks/useUsers";

import * as Icons from "components/Icons";
import { Avatar } from "components/UI";
import CollapsibleContainer from "components/CollapsibleContainer";
import DietaryPreferencesSummary from "./DietaryPreferencesSummary";
import ParticipantSettings from "./Settings";

export default function ParticipantsSummary({ isVisible }) {
  const { t } = useTranslation();
  const [list] = useListContext();
  const participantUsers = useUsers(
    [...list.participants, ...list.invitedParticipants].slice(0, 15)
  );

  return (
    <CollapsibleContainer isVisible={isVisible}>
      <div className="rounded-md shadow-xs bg-card">
        <button
          className="w-full border-0 text-left flex items-center justify-space-between p-2 space-x-2 btn--card rounded-t-md cursor-pointer"
          onClick={() => _.pushModal(`/l/${list.id}/participants`)}
        >
          <div className="flex-grow min-w-0 space-y-2">
            <p className="text-sm">
              {[
                {
                  isVisible: list.participants.length > 0,
                  label: t("list.join", {
                    count: list.participants.length,
                    context: _.toContext([
                      ["past", list.hasOccurred],
                      ["zero", list.participants.length === 0],
                    ]),
                  }),
                },
                {
                  isVisible:
                    (list.participants.length === 0 &&
                      list.nonParticipants.length === 0) ||
                    list.invitedParticipants.length > 0,
                  label: t("list.hasnt_responded", {
                    count: list.invitedParticipants.length,
                    context: _.toContext([
                      ["past", list.hasOccurred],
                      ["zero", list.invitedParticipants.length === 0],
                    ]),
                  }),
                },
                {
                  isVisible: list.nonParticipants.length > 0,
                  label: t("list.dont_join", {
                    count: list.nonParticipants.length,
                    context: _.toContext([
                      ["past", list.hasOccurred],
                      ["zero", list.nonParticipants.length === 0],
                    ]),
                  }),
                },
              ]
                .filter((part) => part.isVisible)
                .map((part) => part.label)
                .join(" • ")}
            </p>
            <div className="collapse flex items-center space-x-2 overflow-hidden fade-r">
              {participantUsers.map((user) => (
                <div key={user.id} className="relative">
                  <Avatar
                    user={user}
                    size={2}
                    className={_.isParticipant(user) ? "cutout-rb" : ""}
                  />
                  {_.isParticipant(user) && (
                    <div className="absolute right-0 bottom-0 flex items-center justify-center w-3 h-3 rounded-full bg-success">
                      <Icons.Check size={0.5} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <DietaryPreferencesSummary userContainers={list.participants} />
          </div>
          <Icons.Caret direction="right" className="opacity-75" />
        </button>
        {_.canEdit(list) && (
          <ParticipantSettings className="rounded-b-md bg-glow text-text" />
        )}
      </div>
    </CollapsibleContainer>
  );
}
