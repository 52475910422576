import * as _ from "utils";

import React, { forwardRef } from "react";
import useRegularMoment from "hooks/useRegularMoment";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import ChefsText from "components/List/ChefsText";
import CoursesSummary from "components/List/CoursesSummary";

export default forwardRef(function ListSummary(
  { list, showRecurring, showOccursAt, className, textClassName },
  ref
) {
  const { t } = useTranslation();
  const [regularMoment] = useRegularMoment(list.regularMomentId);

  return (
    <div className={className} ref={ref}>
      <p className={textClassName}>
        <ChefsText list={list} /> <CoursesSummary list={list} />
      </p>
      <p className="flex space-x-2 items-center text-xs text-caption whitespace-no-wrap overflow-hidden">
        {showRecurring && regularMoment.exists && (
          <Icons.Repeat strokeWidth={2} size={0.75} />
        )}
        {showOccursAt && (
          <span className="flex items-center space-x-1">
            <Icons.Clock size={0.75} />
            <span>{_.capitalize(_.formatDate(list.occursAt))}</span>
          </span>
        )}
        {list.isClosed ? (
          <span className="flex items-center space-x-1">
            <Icons.Lock size={0.75} isLocked />
            <span>{t`label.isClosed`}</span>
          </span>
        ) : (
          list.hasDeadline && (
            <span className="flex items-center space-x-1">
              <Icons.Lock size={0.75} />
              <span>{_.capitalize(_.formatDate(list.closesAt))}</span>
            </span>
          )
        )}
        <span className="flex items-center space-x-1">
          <Icons.Person size={0.75} />
          <span>{list.participants?.length ?? 0}</span>
        </span>
        {list.comments?.length > 0 && (
          <span className="flex items-center space-x-1">
            <Icons.Comment size={0.75} />
            <span>{list.comments?.length}</span>
          </span>
        )}
      </p>
    </div>
  );
});
