import BaseResource from "./BaseResource";

export default class APIResource extends BaseResource {
  constructor(params) {
    super();
    this.fetch(params);
  }

  async fetch({ endpoint, method, body }) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${endpoint}`,
        {
          method,
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          body: JSON.stringify(body),
        }
      );
      const json = await response.json();
      this.onNext(json);
    } catch (error) {
      this.onError(error);
    }
  }
}
