import { format, endOfToday } from "date-fns";

import React, { useCallback, useState } from "react";
import useIdle from "hooks/useIdle";
import useRecurringTask from "hooks/useRecurringTask";
import useTaskContext from "hooks/useTaskContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { DateInput, IconButton, TimeInput } from "components/UI";
import CollapsibleContainer from "components/CollapsibleContainer";
import FormattedRegularMoment from "components/FormattedRegularMoment";

export default function DueAt({ isEditing }) {
  const { t, moment } = useTranslation();
  const [task, taskResource] = useTaskContext();
  const [recurringTask] = useRecurringTask(task.recurringTaskId);

  const [dueAt, setDueAt] = useState(task.dueAt?.toDate());
  const flush = useCallback(
    () =>
      dueAt !== taskResource.rawData.dueAt && taskResource.update({ dueAt }),
    [dueAt, taskResource]
  );
  const touch = useIdle(flush, 500);

  return (
    <CollapsibleContainer
      icon={Icons.Clock}
      iconClassName="w-6 mr--2 text-center"
      className="max-w-xs"
      isVisible={Boolean(isEditing || task.dueAt)}
    >
      {isEditing && !recurringTask.exists ? (
        <div className="flex items-center space-x-2">
          <DateInput
            className="w-0"
            placeholder={t`task.dueDate`}
            required
            value={dueAt}
            onClick={
              dueAt
                ? undefined
                : () => {
                    setDueAt(endOfToday());
                    touch();
                  }
            }
            onChange={(date) => {
              setDueAt((dueAt) => {
                const d = new Date(dueAt || endOfToday());
                const [year, month, day] = date.split("-");
                d.setFullYear(year, month - 1, day);
                return d;
              });
              touch();
            }}
          />
          {dueAt && (
            <div className="flex-grow-0">
              <TimeInput
                required
                value={dueAt ? format(dueAt, "HH:mm") : ""}
                onChange={({ target: { value } }) => {
                  setDueAt((dueAt) => {
                    const d = new Date(dueAt || endOfToday());
                    d.setHours(...value.split(":"));
                    return d;
                  });
                  touch();
                }}
              />
            </div>
          )}
          <IconButton
            variant="text"
            onClick={() => {
              setDueAt(null);
              touch();
            }}
            className={dueAt ? undefined : "opacity-0 pointer-events-none"}
          >
            <Icons.Cross />
          </IconButton>
        </div>
      ) : (
        task.dueAt && (
          <p>
            {t(`task.dueAt`, {
              dueAt: moment(task.dueAt.toDate()).calendar(),
            })}
          </p>
        )
      )}
      {recurringTask.exists && (
        <p className="flex items-center space-x-1 text-xs text-caption">
          <Icons.Repeat size={0.75} />
          <span className="truncate">
            <FormattedRegularMoment item={recurringTask} short />
          </span>
        </p>
      )}
    </CollapsibleContainer>
  );
}
