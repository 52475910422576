import * as _ from "utils";
import cx from "classnames";

import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import useIdle from "hooks/useIdle";
import useRecipe from "hooks/useRecipe";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { IconButton, Field, FlyOut, TextArea } from "components/UI";
import DietaryPreferenceThumb from "./DietaryPreferenceThumb";

import DietaryPreferences from "components/DietaryPreferences";

const MAX_DIETARY_ICONS = 4;

const NumberInput = ({ onChange, decimals = 2, ...props }) => (
  <input
    onChange={(event) => {
      const value =
        Number(event.target.value.replace(".", "").replace(",", "")) *
        Math.pow(0.1, decimals);
      if (!isNaN(value)) {
        onChange({
          ...event,
          target: {
            ...event.target,
            value: value.toFixed(decimals).replace(".", ","),
            validity: { valid: true },
          },
        });
      }
    }}
    {...props}
  />
);

export default ({
  courseOption,
  onChange,
  onDelete,
  onConfirm,
  flyOutRef,
  dishRef,
}) => {
  const { t } = useTranslation();
  const descriptionRef = useRef(null);
  const dietOriginRef = useRef(null);

  const [option, setOption] = useState(courseOption);
  useRecipe({
    title: option.title,
    recipe: option.recipe,
    setRecipe: (recipe) => setOption((option) => ({ ...option, recipe })),
  });
  const flush = useCallback(() => {
    onChange((prevOption) => ({ ...prevOption, ...option }));
  }, [onChange, option]);
  const touch = useIdle(flush, 500);

  useLayoutEffect(() => {
    dishRef.current.focus();
  }, [dishRef]);

  return (
    <div className="space-y-2 w-full">
      <div>
        <Field
          ref={dishRef}
          placeholder={t`list.courses.dishOrRecipeUrl`}
          value={option.title || ""}
          onKeyPress={async (event) => {
            if (event.key === "Enter" && option.title) {
              await onChange((prevOption) => ({ ...prevOption, ...option }));
              onConfirm();
            }
          }}
          onChange={({ target: { value } }) => {
            setOption((option) => ({ ...option, title: value }));
            touch();
          }}
        />
        {option.recipe && (
          <a
            href={option.recipe.url}
            target="_blank"
            rel="noopener noreferrer"
            className="block break-words text-xs text-primary"
          >
            {_.formatCourseOption(option)}{" "}
            <Icons.OutboundLink size={0.75} style={{ verticalAlign: "-2px" }} />
          </a>
        )}
      </div>
      {_.isJust(option.description) && (
        <TextArea
          ref={descriptionRef}
          placeholder={t`course.description`}
          className="text-xs"
          value={option.description}
          onChange={({ target: { value } }) => {
            setOption((option) => ({ ...option, description: value }));
            touch();
          }}
        />
      )}
      <div className="flex space-x-2 justify-flex-end">
        {_.isNothing(option.description) && (
          <IconButton
            variant="text"
            onClick={() => {
              setOption((option) => ({ ...option, description: "" }));
              setTimeout(() => descriptionRef.current.focus(), 30);
            }}
          >
            <Icons.Paragraph />
          </IconButton>
        )}
        <label
          className={cx(
            "relative course__option__price-input relative cursor-pointer",
            {
              "course__option__price-input--filled": option.price,
            }
          )}
        >
          <span className="course__option__price-input__label">
            € {option.price || "0,00"}
          </span>
          <NumberInput
            className="course__option__price-input__input absolute inset-0"
            value={option.price || ""}
            onChange={({ target: { value } }) => {
              setOption((option) => ({
                ...option,
                price: value === "0,00" ? null : value,
              }));
              touch();
            }}
          />
        </label>
        <button
          ref={dietOriginRef}
          className={
            option.dietaryInformation?.length > 0
              ? "flex border border-accent border-solid rounded-full"
              : "icon-link icon-link--md icon-link--text"
          }
        >
          {option.dietaryInformation?.length > 0 ? (
            <>
              {option.dietaryInformation
                .slice(0, MAX_DIETARY_ICONS)
                .map((preference) => (
                  <DietaryPreferenceThumb
                    key={preference}
                    preference={preference}
                    className="pointer-events-none"
                  />
                ))}
              {option.dietaryInformation.length > MAX_DIETARY_ICONS && (
                <p className="text-xs text-caption">
                  +{option.dietaryInformation.length - MAX_DIETARY_ICONS}
                </p>
              )}
            </>
          ) : (
            <Icons.Diet />
          )}
        </button>
        <FlyOut
          ref={flyOutRef}
          originRef={dietOriginRef}
          persistOnClick
          style={{ width: "17.5rem" }}
        >
          <div className="w-full px-4 py-2">
            <DietaryPreferences
              isEditable
              dietaryInformation={option.dietaryInformation || []}
              onChange={(dietaryInformation) => {
                setOption((option) => ({ ...option, dietaryInformation }));
                touch();
              }}
            />
          </div>
        </FlyOut>
        <IconButton variant="text" color="error" onClick={onDelete}>
          <Icons.Bin />
        </IconButton>
      </div>
    </div>
  );
};
