import * as _ from "utils";

import React, { useState } from "react";
import useTranslation from "hooks/useTranslation";
import useUsers from "hooks/useUsers";

import { BottomModalLayout } from "layouts/Modal";

import { Button } from "components/UI";
import BalancingTransactions from "./BalancingTransactions";

const processExpense = (usersWithBalance, expense) => {
  return expense.parts > 0
    ? usersWithBalance.map(({ user, balance }) => ({
        user,
        balance:
          balance +
          (_.isSameUser(user)({
            userIdentifiers: expense.creditorUserIdentifiers,
          })
            ? expense.cents
            : 0) -
          (expense.cents *
            (expense.debtors.find(_.isSameUser(user))?.parts || 0)) /
            expense.parts,
      }))
    : usersWithBalance;
};

const getUsersWithBalance = (involvedUsers, expensesToBeSettled) =>
  expensesToBeSettled
    .reduce(
      processExpense,
      involvedUsers.map((user) => ({ user, balance: 0 }))
    )
    .sort(_.firstBy(_.getKey("balance"), -1))
    .sort(_.currentUserLast)
    .filter(({ balance }) => balance !== 0);

const getBalancingTransactions = (usersWithBalances) => {
  const transactions = [];
  const usersWithBalancesByBalance = usersWithBalances
    .slice()
    .sort(_.firstBy(_.getKey("balance"), -1));

  while (
    usersWithBalancesByBalance.some(({ balance }) => balance > 0) &&
    usersWithBalancesByBalance.some(({ balance }) => balance < 0)
  ) {
    const creditor = usersWithBalancesByBalance[0];
    const debtor =
      usersWithBalancesByBalance[usersWithBalancesByBalance.length - 1];
    const amount = Math.min(creditor.balance, -debtor.balance);

    usersWithBalancesByBalance[0] = {
      ...creditor,
      balance: creditor.balance - amount,
    };
    usersWithBalancesByBalance[usersWithBalancesByBalance.length - 1] = {
      ...debtor,
      balance: debtor.balance + amount,
    };
    usersWithBalancesByBalance.sort(_.firstBy(_.getKey("balance"), -1));

    transactions.push({ from: debtor.user, to: creditor.user, amount });
  }

  return transactions;
};

export default ({
  involvedUserContainers,
  expensesToBeSettled,
  listId = null,
  groupId = null,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  const involvedUsers = useUsers(involvedUserContainers);
  const usersWithBalances = getUsersWithBalance(
    involvedUsers,
    expensesToBeSettled
  );
  const transactions = getBalancingTransactions(usersWithBalances);

  const confirmSettlement = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/createSettlement`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          body: JSON.stringify({ listId, groupId }),
        }
      );
      const settlementId = await response.json();

      _.replaceModal(`/s/${settlementId}`);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <BottomModalLayout>
      <div className="relative min-h-16 max-h-screen bg-card rounded-md rounded-b-none overflow-y-auto">
        <div className="p-4 space-y-4">
          <BalancingTransactions
            involvedUsers={involvedUsers}
            transactions={transactions}
          />
        </div>
        <div className="sticky z-10 bottom-0 flex flex-col items-center sm:flex-row-reverse px-4 py-2 border-solid border-0 border-t border-accent bg-card">
          <Button
            color="primary"
            className="w-full sm:w-auto"
            onClick={confirmSettlement}
          >{t`settlements.confirm`}</Button>
          {error ? (
            <p className="text-xs text-error mt-2 sm:mt-0 sm:mr-4">{t`settlements.somethingWentWrong`}</p>
          ) : (
            <p className="text-xs text-caption mt-2 sm:mt-0 sm:mr-4">{t`settlements.involvedUsersWillBeNotified`}</p>
          )}
        </div>
      </div>
    </BottomModalLayout>
  );
};
