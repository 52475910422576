import "./index.scss";
import cx from "classnames";

import React, { useEffect } from "react";
import useAuth from "hooks/useAuth";
import useTranslation from "hooks/useTranslation";
import usePageContext from "hooks/usePageContext";

import SimpleLayout from "layouts/Simple";

import * as Icons from "components/Icons";

const Header = () => null;

const Body = ({ children }) => (
  <div className="route-container">{children}</div>
);

export const Auth = ({ children }) => {
  const { t } = useTranslation();
  const [{ isAuthenticated }] = useAuth();
  const [, { onClose }] = usePageContext();

  useEffect(() => {
    if (isAuthenticated) {
      onClose("/");
    }
  }, [isAuthenticated, onClose]);

  return (
    <SimpleLayout components={{ Header, Body }}>
      {children}
      <div
        className={cx("flex items-center justify-center", "success-overlay", {
          "success-overlay--visible": isAuthenticated,
        })}
      >
        <p className="text-xl text-success_contrast">
          <Icons.Check size={1.5} /> {t`auth.success`}
        </p>
      </div>
    </SimpleLayout>
  );
};

export default Auth;
