import * as _ from "utils";
import * as Sentry from "@sentry/browser";
import * as queryString from "query-string";

import { useLayoutEffect } from "react";
import reactFirestore from "react-firestore";
import useAuth from "hooks/useAuth";

export default function NewGroupPage({ location }) {
  const [{ isAuthenticated }] = useAuth();
  useLayoutEffect(() => {
    const createNewGroup = async (params) => {
      try {
        const groupRef = await reactFirestore.createDoc("groups", params);
        _.navigate(`/g/${groupRef.id}/setup`, { replace: true });
      } catch (error) {
        Sentry.captureException(error, { tags: { event: "Create group" } });
        _.popModal(`/`);
      }
    };

    if (isAuthenticated) {
      createNewGroup(queryString.parse(location.search));
    }
  }, [isAuthenticated, location.search]);

  return null;
}
