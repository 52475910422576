import "./Courses.scss";

import React, { useEffect, useState } from "react";
import useListContext from "hooks/useListContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Button } from "components/UI";
import Course from "./Course";

export default ({ isEditing, currentParticipantId }) => {
  const { t } = useTranslation();
  const [list, listResource] = useListContext();

  const [focus, setFocus] = useState(false);
  useEffect(() => {
    if (!isEditing) {
      setFocus(null);
    }
  }, [isEditing, listResource]);

  return (
    <div className="py-4 space-y-4 rounded-md bg-card shadow-xs">
      {list.courses.map((course, i) => (
        <Course
          key={course.id}
          nth={i + 1 > 9 ? "default" : i + 1}
          courseId={course.id}
          isEditing={isEditing}
          currentParticipantId={currentParticipantId}
          focus={focus}
          setFocus={setFocus}
        />
      ))}
      {isEditing && (
        <label className="course__title justify-center">
          <span className="course__title__bg">
            <Button
              variant="text"
              color="caption"
              onClick={async () => {
                const newCourse = await listResource.addCourse();
                setFocus(newCourse.options[0].id);
              }}
              lead={<Icons.Plus />}
            >
              {t(`list.courses.addCourse`)}
            </Button>
          </span>
        </label>
      )}
    </div>
  );
};
