import React, { useState } from "react";
import useAuth from "hooks/useAuth";
import usePageContext from "hooks/usePageContext";
import useTranslation from "hooks/useTranslation";
import useUpstreamState from "hooks/useUpstreamState";

import withAuth from "hocs/withAuth";

import ModalLayout from "layouts/Modal";

import { Button, Field, Message } from "components/UI";

export default withAuth(() => {
  const { t } = useTranslation();
  const [, { onClose }] = usePageContext();
  const [, { updatePassword }] = useAuth();

  const [error, setError] = useState(null);
  const [password, setPassword] = useUpstreamState("");

  return (
    <ModalLayout>
      <div className="w-full max-w-2xl mx-auto px-4">
        <form
          className="flex flex-col items-normal py-4 space-y-2"
          onSubmit={async (event) => {
            event.preventDefault();
            try {
              setError(null);
              await updatePassword(password);
              onClose("/settings/auth");
            } catch (error) {
              setError(error);
            }
          }}
        >
          <div className="space-y-4 p-4 rounded-md bg-card">
            <h1>{t`settings.auth.updatePassword`}</h1>
            {error && (
              <Message color="error" className="p-4">
                {t(`errors.${error.code}`)}
              </Message>
            )}
            <Field
              variant="contained"
              label={t`settings.auth.newPassword`}
              placeholder={t`settings.auth.passwordPlaceholder`}
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <Button color="primary">{t`action.confirm`}</Button>
          <Button
            type="button"
            color="card"
            onClick={() => onClose("/settings/auth")}
          >
            {t`action.cancel`}
          </Button>
        </form>
      </div>
    </ModalLayout>
  );
});
