import { friendlyFormatIBAN, isValidIBAN } from "ibantools";

const isValidExpense = (expense) =>
  expense.creditorUserIdentifiers.length > 0 &&
  expense.cents !== 0 &&
  expense.description &&
  (expense.divideAutomatically ||
    expense.debtors?.some(({ parts }) => parts > 0));

const stringToFixed = (string, decimals) =>
  (
    Number((string || "").replace(".", "").replace(",", "")) *
    Math.pow(0.1, decimals)
  ).toFixed(decimals);

const paymentAddresses = [
  {
    type: "iban",
    validate: isValidIBAN,
    formatText: friendlyFormatIBAN,
    formatValue(value) {
      return value.replace(/\W/g, "").toUpperCase();
    },
  },
  {
    type: "paypal",
    prefix: "https://paypal.me/",
    formatLink({ value, amount }) {
      return `https://paypal.me/${value}/${amount}`;
    },
    formatText(value) {
      return `paypal.me/${value}`;
    },
    formatValue(value) {
      return value;
    },
  },
  {
    type: "bunq",
    prefix: "https://bunq.me/",
    formatLink({ value, amount, description }) {
      return `https://bunq.me/${value}/${amount}/${description}`;
    },
    formatText(value) {
      return `bunq.me/${value}`;
    },
    formatValue(value) {
      return value;
    },
  },
  {
    type: "misc",
    formatLink({ value }) {
      return value;
    },
    formatText(value) {
      return value;
    },
    formatValue(value) {
      return value;
    },
  },
];

export { isValidExpense, stringToFixed, paymentAddresses };
