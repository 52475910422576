import React from "react";
import Icon from "../Icon";

export default (props) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M11 3h8a8 8 0 018 8v6a5 5 0 01-5 5h0v5l-5-5h-6a8 8 0 01-8-8v-3a8 8 0 018-8z"
      fill="none"
    />
  </Icon>
);
