import reactFirestore from "react-firestore";
import { intersection } from "./arrays";
import { md5 } from "./crypto";
import { auth, usersCollection } from "./firebase";
import { v4 as uuidv4 } from "uuid";

const getUserField = (key) =>
  auth.currentUser[key] ||
  auth.currentUser.providerData
    .map((data) => data[key])
    .find((value) => !!value) ||
  null;

const getUserIdentifiers = (user) =>
  [user?.uid, typeof user?.email === "string" ? md5(user.email) : null].filter(
    Boolean
  );

const isSameUser = (a) => (b) =>
  intersection(a?.userIdentifiers || [], b?.userIdentifiers || []).length > 0;

const isCurrentUser = (userContainer) =>
  isSameUser({ userIdentifiers: getUserIdentifiers(auth.currentUser) })(
    userContainer
  );

const currentUserLast = (a, b) =>
  isCurrentUser(a) ? 1 : isCurrentUser(b) ? -1 : 0;

const receivesNotification = (notification) => (user) =>
  user &&
  !user.userIdentifiers.includes(auth.currentUser.uid) &&
  user.messagingProvider &&
  (!user.settings || user.settings.notifications[notification] !== false);

const fetchUser = async (userIdentifiers) => {
  if (
    userIdentifiers?.length > 0 &&
    !reactFirestore.data.users.some(isSameUser({ userIdentifiers }))
  ) {
    reactFirestore.data.users.push({
      id: uuidv4(),
      exists: false,
      isPlaceholder: true,
      userIdentifiers,
    });
    await reactFirestore.performQuery(
      usersCollection.where(
        "userIdentifiers",
        "array-contains-any",
        userIdentifiers
      ),
      (data) => {
        reactFirestore.data.users = reactFirestore.data.users.filter(
          (user) =>
            !user.isPlaceholder || user.userIdentifiers !== userIdentifiers
        );
        return data;
      }
    );
  }
};

export {
  fetchUser,
  getUserField,
  getUserIdentifiers,
  isSameUser,
  isCurrentUser,
  currentUserLast,
  receivesNotification,
};
