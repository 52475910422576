import "./index.scss";
import * as _ from "utils";
import cx from "classnames";

import React, { Suspense, useLayoutEffect, useRef } from "react";
import { PageContext } from "contexts";

export default function PageRoot({
  isCurrent,
  isOpen,
  timeout,
  depth,
  children,
  ...props
}) {
  const pageRef = useRef(null);
  useLayoutEffect(() => {
    const children = pageRef.current?.childen;
    const page = children ? children[0] : null;
    if (page) {
      page.style.zIndex = 10 + depth;
    }
  });
  return (
    <PageContext.Provider
      value={[
        { isCurrent, isOpen, timeout, pageRef, depth },
        { onClose: (root, options) => _.popModal(root, { ...options, depth }) },
      ]}
    >
      <div
        ref={pageRef}
        className={cx("page-root", "select-none", {
          "pointer-events-none": !isCurrent,
        })}
        {...props}
      >
        <Suspense fallback={<div className="loader absolute inset-0 m-auto" />}>
          {children}
        </Suspense>
      </div>
    </PageContext.Provider>
  );
}
