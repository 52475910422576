import BaseResource from "./BaseResource";

import { Plugins } from "@capacitor/core";

const { Network } = Plugins;

class NetworkResource extends BaseResource {
  constructor() {
    super();
    this.fetch();
  }
  listenContinuously() {
    return Network.addListener("networkStatusChange", (status) =>
      this.onNext(status)
    );
  }
  async fetch() {
    this.onNext(await Network.getStatus());
  }
}

export default NetworkResource;
