import reactFirestore from "react-firestore";

import Expense from "models/Expense";
import Group from "models/Group";
import List from "models/List";
import Settlement from "models/Settlement";
import Task from "models/Task";
import Transaction from "models/Transaction";
import User from "models/User";

reactFirestore.addModel("expenses", Expense);
reactFirestore.addModel("groups", Group);
reactFirestore.addModel("events", List);
reactFirestore.addModel("settlements", Settlement);
reactFirestore.addModel("tasks", Task);
reactFirestore.addModel("transactions", Transaction);
reactFirestore.addModel("users", User);
reactFirestore.init();
