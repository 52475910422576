import { PARTICIPANT_ROLES } from "statics.json";
import * as _ from "utils";
import { Capacitor, Plugins } from "@capacitor/core";

import { useEffect, useState } from "react";
import { useDoc } from "react-firestore";

import withAuth from "hocs/withAuth";

const { PushNotifications } = Plugins;

export default withAuth(function MessagesObserver() {
  const [responseIntent, setResponseIntent] = useState(null);
  const [list, listResource] = useDoc("events", responseIntent?.listId);

  useEffect(() => {
    const handleResponse = async () => {
      await listResource.respond({
        userContainer: list.myParticipation,
        response: responseIntent.response,
        containsCoursesWithInteraction: list.containsCoursesWithInteraction,
      });

      setResponseIntent(null);
    };

    if (list.exists && list.myParticipation && responseIntent) {
      handleResponse();
    }
  }, [list, listResource, responseIntent]);

  useEffect(() => {
    if (Capacitor.isPluginAvailable("PushNotifications")) {
      const pushListenerHandle = PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          console.log(notification);
        }
      );

      const pushActionListenerHandle = PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          const expenseId = notification?.notification?.data?.expenseId;
          const groupId = notification?.notification?.data?.groupId;
          const listId = notification?.notification?.data?.listId;
          const settlementId = notification?.notification?.data?.settlementId;
          const taskId = notification?.notification?.data?.taskId;
          const transactionId = notification?.notification?.data?.transactionId;

          const path = notification?.notification?.data?.path;

          if (expenseId) {
            _.pushModal(`/ex/${expenseId}`);
          }

          if (groupId) {
            _.pushModal(`/g/${groupId}`);
          }

          if (listId) {
            _.pushModal(`/l/${listId}`);
          }

          if (settlementId) {
            _.pushModal(`/s/${settlementId}`);
          }

          if (taskId) {
            _.pushModal(`/t/${taskId}`);
          }

          if (transactionId) {
            _.pushModal(`/tx/${transactionId}`);
          }

          if (path) {
            _.pushModal(path);
          }
        }
      );

      return () => {
        pushListenerHandle.remove();
        pushActionListenerHandle.remove();
      };
    } else {
      const onMessage = (event) => {
        const data = event.data;

        console.log("postMessage received", data);
        if (data.navigateTo) {
          _.navigate(data.navigateTo);
        }

        if (
          data?.data?.listId &&
          ["acceptInvite", "declineInvite", "cook"].includes(data.action)
        ) {
          setResponseIntent({
            listId: data.data.listId,
            response:
              data.action === "acceptInvite"
                ? PARTICIPANT_ROLES.EATER
                : data.action === "declineInvite"
                ? PARTICIPANT_ROLES.NON_EATER
                : PARTICIPANT_ROLES.CHEF,
          });
        }

        if (data?.data?.groupId) {
          setResponseIntent({ groupId: data.data.groupId });
        }
      };

      try {
        navigator.serviceWorker.addEventListener("message", onMessage);

        return () => {
          navigator.serviceWorker.removeEventListener("message", onMessage);
        };
      } catch (e) {
        console.log("push messages not supported");
      }
    }
  }, []);

  return null;
});
