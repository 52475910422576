import * as _ from "utils";

import React, { useCallback } from "react";
import { useCollection } from "react-firestore";
import useGroupContext from "hooks/useGroupContext";

import withGroup from "hocs/withGroup";

import SettlementPreview from "components/Expenses/SettlementPreview";

const byCreatedAt = _.firstBy((entity) => entity.createdAt.toMillis(), -1);

export default withGroup(() => {
  const [group] = useGroupContext();
  const toBeSettledInGroup = useCallback(
    (expense) =>
      expense.exists && !expense.isSettled && expense.groupId === group.id,
    [group.id]
  );
  const expensesToBeSettled = useCollection("expenses", {
    filter: toBeSettledInGroup,
    sort: byCreatedAt,
  });
  const involvedUserContainers = expensesToBeSettled
    .flatMap((expense) => expense.debtors)
    .reduce(
      (involvedUserContainers, debtor) =>
        involvedUserContainers.some(_.isSameUser(debtor))
          ? involvedUserContainers
          : involvedUserContainers.concat(debtor),
      []
    )
    .map(
      (userContainer) =>
        group.members.find(_.isSameUser(userContainer)) || userContainer
    );
  return (
    <SettlementPreview
      involvedUserContainers={involvedUserContainers}
      expensesToBeSettled={expensesToBeSettled}
      groupId={group.id}
    />
  );
});
