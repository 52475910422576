import * as _ from "utils";
import { messagingResource } from "resources";

import { useEffect } from "react";
import useResource from "./useResource";
import useDeviceToken from "./useDeviceToken";
import useCurrentUser from "./useCurrentUser";

export default function useNotifications() {
  const { permission, token, messages, messagingProvider } = useResource(
    messagingResource
  );

  const [deviceToken] = useDeviceToken();
  const [currentUser, currentUserResource] = useCurrentUser();

  const requestPermission = () => {
    messagingResource.requestNotificationPermission(currentUser.uid);
    currentUserResource.update({
      messagingDeviceToken: deviceToken,
      messagingProvider,
    });
    _.track("notification_enable");
  };

  // Apply default settings
  useEffect(() => {
    if (currentUser.exists) {
      const update = {};
      if (currentUser.settings?.notifications?.whenAddedToList === undefined) {
        update["settings.notifications.whenAddedToList"] = true;
      }
      if (currentUser.settings?.notifications?.whenSomeoneCooks === undefined) {
        update["settings.notifications.whenSomeoneCooks"] = true;
      }
      if (
        currentUser.settings?.notifications?.whenRequiredChoice === undefined
      ) {
        update["settings.notifications.whenRequiredChoice"] = true;
      }

      if (Object.keys(update).length > 0) {
        currentUserResource.update(update);
      }
    }
  }, [currentUserResource, currentUser.exists, currentUser.settings]);

  // Update user VAPID key
  useEffect(() => {
    if (
      currentUser.exists &&
      permission !== "unsupported" &&
      token !== currentUser.vapidToken &&
      deviceToken === currentUser.messagingDeviceToken
    ) {
      currentUserResource.update({ vapidToken: token, messagingProvider });
    }
  }, [
    permission,
    currentUserResource,
    currentUser,
    token,
    deviceToken,
    messagingProvider,
  ]);

  return [
    { permission, token, messages },
    { requestPermission, sendMessage: _.sendMessage, messagingResource },
  ];
}
