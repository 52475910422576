import * as _ from "utils";

import React, { useState } from "react";
import useAuth from "hooks/useAuth";
import useCurrentUser from "hooks/useCurrentUser";
import useTranslation from "hooks/useTranslation";

import withAuth from "hocs/withAuth";

import SimpleLayout from "layouts/Simple";

import * as Icons from "components/Icons";
import { Button, Message } from "components/UI";

export default withAuth(() => {
  const { t } = useTranslation();
  const [
    auth,
    {
      linkApple,
      linkFacebook,
      linkGoogle,
      unlinkApple,
      unlinkFacebook,
      unlinkGoogle,
    },
    support,
  ] = useAuth();
  const [currentUser] = useCurrentUser();

  const [error, setError] = useState(null);

  const isAppleConnected = _.auth.currentUser?.providerData.some(
    ({ providerId }) => providerId === "apple.com"
  );
  const isFacebookConnected = _.auth.currentUser?.providerData.some(
    ({ providerId }) => providerId === "facebook.com"
  );
  const isGoogleConnected = _.auth.currentUser?.providerData.some(
    ({ providerId }) => providerId === "google.com"
  );

  const safe = (f) => async (...args) => {
    try {
      setError(null);
      await f(...args);
    } catch (error) {
      if (error.code !== "auth/popup-closed-by-user") {
        setError(error);
      }
    }
  };

  return (
    <SimpleLayout title={t`settings.auth.title`} prevLabel={t`settings.title`}>
      <div className="space-y-4">
        {error && (
          <Message color="error" className="p-4">
            {error.message}
          </Message>
        )}
        {auth.currentUser.isAnonymous && (
          <Message
            color="warning"
            className="p-4"
          >{t`profile.anonymous.warning`}</Message>
        )}
        {currentUser.email ? (
          <>
            <div
              className="clickable flex items-center justify-space-between space-x-4"
              onClick={() => _.pushModal("/settings/auth/email")}
            >
              <Icons.Email size={1.5} />
              <div className="mr-auto">
                <p>{t`settings.auth.updateEmail`}</p>
                <p className="text-xs text-caption">{currentUser.email}</p>
              </div>
              <Icons.Caret direction="right" color="accent" />
            </div>
            <div
              className="clickable flex items-center justify-space-between space-x-4"
              onClick={() => _.pushModal("/settings/auth/password")}
            >
              <Icons.Lock size={1.5} />
              <p className="mr-auto">{t`settings.auth.updatePassword`}</p>
              <Icons.Caret direction="right" color="accent" />
            </div>
          </>
        ) : (
          <div className="flex items-center justify-space-between space-x-4">
            <Icons.Email size={1.5} />
            <p className="mr-auto">{t`profile.signInWithEmail`}</p>
            <Button
              size="sm"
              color="card"
              onClick={() => _.pushModal("/settings/auth/email")}
            >
              {t`auth.connect`}
            </Button>
          </div>
        )}
        {(isAppleConnected || support.methods.includes("apple.com")) && (
          <div className="flex items-center space-x-4">
            <Icons.Apple size={1.5} />
            <div className="mr-auto">
              <p>{t`profile.signInWithApple`}</p>
              {isAppleConnected && (
                <p className="text-xs text-caption">{t`profile.isConnected`}</p>
              )}
            </div>
            <Button
              className="whitespace-no-wrap"
              size="sm"
              color="card"
              onClick={safe(isAppleConnected ? unlinkApple : linkApple)}
            >
              {t(isAppleConnected ? "auth.disconnect" : "auth.connect")}
            </Button>
          </div>
        )}
        <div className="flex items-center space-x-4">
          <Icons.Facebook color="facebook" size={1.5} />
          <div className="mr-auto">
            <p>{t`profile.signInWithFacebook`}</p>
            {isFacebookConnected && (
              <p className="text-xs text-caption">{t`profile.isConnected`}</p>
            )}
          </div>
          <Button
            className="whitespace-no-wrap"
            size="sm"
            color="card"
            onClick={safe(isFacebookConnected ? unlinkFacebook : linkFacebook)}
          >
            {t(isFacebookConnected ? "auth.disconnect" : "auth.connect")}
          </Button>
        </div>
        <div className="flex items-center space-x-4">
          <Icons.Google size={1.5} />
          <div className="mr-auto">
            <p>{t`profile.signInWithGoogle`}</p>
            {isGoogleConnected && (
              <p className="text-xs text-caption">{t`profile.isConnected`}</p>
            )}
          </div>
          <Button
            className="whitespace-no-wrap"
            size="sm"
            color="card"
            onClick={safe(isGoogleConnected ? unlinkGoogle : linkGoogle)}
          >
            {t(isGoogleConnected ? "auth.disconnect" : "auth.connect")}
          </Button>
        </div>
      </div>
    </SimpleLayout>
  );
});
