import * as _ from "utils";
import cx from "classnames";

import React from "react";
import useCurrentUser from "hooks/useCurrentUser";

import SimpleLayout from "./Simple";

import * as Icons from "components/Icons";
import { Avatar, IconButton } from "components/UI";

const HeaderContent = () => {
  const [currentUser] = useCurrentUser();
  return (
    <div className="flex items-center justify-space-between px-4 py-2">
      <IconButton variant="text" onClick={() => _.pushModal("/settings")}>
        <Icons.Menu />
      </IconButton>
      <div className="flex items-center space-x-2">
        <Icons.IkEetMee className="text-secondary mb-1" strokeWidth={9} />
        <span className="font-black">Ikeetmee</span>
      </div>
      <Avatar
        className="cursor-pointer shadow-sm"
        user={currentUser}
        size={2}
        onClick={() => _.pushModal("/profile")}
      />
    </div>
  );
};

const Body = ({ className, ...props }) => (
  <div className={cx("max-w-2xl mx-auto", className)} {...props} />
);

export default (props) => (
  <SimpleLayout components={{ HeaderContent, Body }} {...props} />
);
