import * as _ from "utils";
import cx from "classnames";
import moment from "moment";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDoc } from "react-firestore";
import useListContext from "hooks/useListContext";
import usePageScroll from "hooks/usePageScroll";
import usePlannedList from "hooks/usePlannedList";
import useTranslation from "hooks/useTranslation";
import useUsers from "hooks/useUsers";

import { messagingResource } from "resources";

import withList from "hocs/withList";

import SimpleLayout from "layouts/Simple";

import * as Icons from "components/Icons";
import { Button, FlyOut, IconButton } from "components/UI";
import MultiAvatar from "components/MultiAvatar";
import Comments from "components/Comments";

import ChefsText from "components/List/ChefsText";
import ClosesAt from "components/List/ClosesAt";
import Courses from "components/List/Courses";
import CoursesSummary from "components/List/CoursesSummary";
import Description from "components/List/Description";
import Expenses from "components/List/Expenses";
import ListSummary from "components/List/Summary";
import Location from "components/List/Location";
import Meta from "components/List/Meta";
import Occasion from "components/List/Occasion";
import OccursAt from "components/List/OccursAt";
import ParticipantsSummary from "components/List/Participants/Summary";
import ParticipationActions from "components/List/ParticipationActions";
import RecurringListSelection from "components/List/RecurringListSelection";

export default withList(() => {
  const { t } = useTranslation();
  const miscActionsOriginRef = useRef(null);
  const titleRef = useRef();
  const onScroll = useCallback((scrollTop) => {
    const container = titleRef.current;
    container.style.opacity = scrollTop / 48 || 0;
  }, []);
  usePageScroll(onScroll);

  const [list, listResource] = useListContext();
  const currentUserIsChef = _.isChef(list.myParticipation);
  const [isEditing, setIsEditing] = useState(
    currentUserIsChef &&
      !list.hasOccurred &&
      (!list.occursAt?.date || !list.isShared)
  );
  const [group] = useDoc("groups", list.groupId);
  const chefUsers = useUsers(list.chefs);

  const plannedList = usePlannedList({
    listId: list.id,
    groupId: list.groupId,
    date: list.occursAt?.date,
  });

  useEffect(() => {
    return () => {
      if (!listResource.rawData.occursAt?.date) {
        listResource.delete();
      }
    };
  }, [listResource]);

  useEffect(() => {
    if (
      !isEditing &&
      currentUserIsChef &&
      !list.hasOccurred &&
      (!list.occursAt?.date || !list.isShared)
    ) {
      setIsEditing(true);
    }
  }, [
    listResource.rawData,
    isEditing,
    currentUserIsChef,
    list.hasOccurred,
    list.occursAt,
    list.isShared,
  ]);

  const myParticipationId = list.myParticipation?.id;
  const currentUserHasSeen = _.hasSeen(list.myParticipation);
  useEffect(() => {
    if (!currentUserHasSeen) {
      listResource.setParticipant(myParticipationId, (p) => ({
        ...p,
        hasSeen: true,
      }));
    }
  }, [listResource, myParticipationId, currentUserHasSeen]);

  const reconcileChanges = useCallback(async () => {
    await listResource.update({ isShared: true });

    if (!list.isShared) {
      if (list.maybeParticipants.length > 1) {
        await listResource.sendParticipationUpdateMessage(list.participants);
        await listResource.sendInviteUpdateMessage(list.invitedParticipants);
        messagingResource.showMessage({
          title: t`list.participantsHaveBeenNotified`,
        });
      }
    }
  }, [t, listResource, list]);

  const canEditProperties =
    isEditing &&
    list.occursAt?.date &&
    (!plannedList || list.regularMomentId !== undefined);

  return (
    <SimpleLayout
      hideTitle
      parentLocation={
        group.exists
          ? moment(list.occursAt.date) < moment().startOf("day")
            ? `/g/${group.id}/past`
            : `/g/${group.id}`
          : moment(list.occursAt.date) < moment().startOf("day")
          ? "/archived"
          : "/"
      }
      prev={
        canEditProperties ? (
          <Button
            color="primary"
            size="xs"
            onClick={async () => {
              await reconcileChanges();
              setIsEditing(false);
            }}
          >
            {t`label.done`}
          </Button>
        ) : undefined
      }
      title={
        <ListSummary
          ref={titleRef}
          className="min-w-0 my--1 mr-auto whitespace-no-wrap"
          textClassName="truncate"
          list={list}
          showOccursAt
        />
      }
      headerAction={
        _.isEditor(list.myParticipation) &&
        (isEditing ? (
          list.occursAt?.date && (
            <>
              <IconButton variant="text" ref={miscActionsOriginRef}>
                <Icons.Dots direction="vertical" />
              </IconButton>
              <FlyOut originRef={miscActionsOriginRef}>
                <FlyOut.Item
                  color="error"
                  lead={<Icons.Bin />}
                  onClick={() => {
                    listResource.delete();
                    _.track("event_delete");
                    _.popToTop(group.exists ? `/g/${group.id}` : "/");
                  }}
                >{t`action.deleteList`}</FlyOut.Item>
              </FlyOut>
            </>
          )
        ) : (
          <Button
            color="primary"
            importance="tertiary"
            variant="text"
            onClick={() => setIsEditing(true)}
          >
            {t`action.edit`}
          </Button>
        ))
      }
    >
      <div className="space-y-4">
        <Occasion isEditing={canEditProperties} />
        <div className="flex items-flex-start justify-flex-start space-x-4">
          {chefUsers.length > 0 && <MultiAvatar size={2} users={chefUsers} />}
          <div className="w-full space-y-1">
            <p className="text-xl">
              <span>
                <ChefsText list={list} />
              </span>
              <br />
              <strong>
                <CoursesSummary list={list} interactive visual />
              </strong>
            </p>
            <Meta isEditing={isEditing} />
          </div>
        </div>
        {list.groupId !== undefined && <OccursAt isEditing={isEditing} />}
        {isEditing &&
          group.exists &&
          list.occursAt?.date &&
          list.regularMomentId === undefined &&
          plannedList && <RecurringListSelection plannedList={plannedList} />}
        <ClosesAt isEditing={canEditProperties} />
        <Location isEditing={canEditProperties} />
        <ParticipantsSummary isVisible={!isEditing} />
        <Courses
          isEditing={canEditProperties}
          currentParticipantId={list.myParticipation?.id}
        />
        <Description isEditing={canEditProperties} />
        {list.myParticipation && (
          <ParticipationActions
            className={cx("transition-opacity duration-200 ease-in-out", {
              "opacity-0 pointer-events-none": isEditing,
            })}
          />
        )}
        <div
          className={cx(
            "space-y-4 transition-opacity duration-200 ease-in-out",
            { "opacity-0 pointer-events-none": isEditing }
          )}
        >
          {process.env.REACT_APP_EXPENSES_ENABLED && <Expenses />}
          <Comments comments={list.comments} resource={listResource} />
        </div>
      </div>
    </SimpleLayout>
  );
});
