import * as _ from "utils";

import { apiResourceCache } from "resources";

import React, { useEffect } from "react";
import useAuth from "hooks/useAuth";
import useTranslation from "hooks/useTranslation";

import withAuth from "hocs/withAuth";

import ModalLayout from "layouts/Modal";
import Link from "components/Link";

export default withAuth(({ inviteId }) => {
  const { t } = useTranslation();
  const [{ currentUser }] = useAuth();

  const inviteResource = apiResourceCache.get({
    endpoint: "processInvite",
    method: "POST",
    body: {
      userIdentifiers: currentUser.userIdentifiers,
      inviteId,
    },
  });
  const invite = inviteResource.read();

  useEffect(() => {
    if (invite.exists && !invite.expired) {
      switch (invite.type) {
        case "list":
          _.navigate(`/l/${invite.listId}`);
          break;
        case "group":
          _.navigate(`/g/${invite.groupId}`);
          break;
        default:
          break;
      }
    }
  }, [invite]);

  return (
    <ModalLayout>
      {invite.exists ? (
        invite.expired ? (
          <div className="space-y-4 text-center">
            <p>{t`invite.expired`}</p>
            <Link to="/">{t`label.back`}</Link>
          </div>
        ) : (
          <div className="space-y-4 text-center">
            <p>{t`invite.accepted`}</p>
            <Link to="/">{t`label.back`}</Link>
          </div>
        )
      ) : (
        <div className="space-y-4 text-center">
          <p>{t`invite.doesNotExist`}</p>
          <Link to="/">{t`label.back`}</Link>
        </div>
      )}
    </ModalLayout>
  );
});
