import * as _ from "utils";

import React, { useState } from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { Button, Field, Message } from "components/UI";

export default () => {
  const { t } = useTranslation();
  const [{ isSent, error }, setState] = useState({
    isSent: false,
    error: null,
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    try {
      await _.auth.sendPasswordResetEmail(form.email.value, {
        url: `${process.env.REACT_APP_SHARE_URL}/auth/login`,
      });
      setState({ isSent: true });
    } catch (error) {
      if (error.code) {
        setState({ error: error.code });
      } else {
        throw error;
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-space-between h-full space-y-4 p-4">
      <a href="https://ikeetmee.nl" className="font-bold m-safe">
        <Icons.IkEetMee className="text-secondary" strokeWidth={8} />{" "}
        ikeetmee.nl
      </a>
      {error && (
        <Message className="p-4" color="error">
          {t(`errors.${error}`)}
        </Message>
      )}
      {isSent ? (
        <Message
          className="p-4"
          color="success"
        >{t`auth.amnesia.sent`}</Message>
      ) : (
        <form className="space-y-4 w-full max-w-md" onSubmit={onSubmit}>
          <h1>{t`auth.amnesia.title`}</h1>
          <p className="text-caption">{t`auth.amnesia.description`}</p>
          <Field name="email" required label="email" variant="contained" />
          <div className="sm:flex sm:justify-flex-end">
            <Button
              className="w-full sm:w-auto"
              color="primary"
              importance="primary"
            >{t`auth.sendRecoveryLink`}</Button>
          </div>
        </form>
      )}
      <p className="rounded-md text-center w-full max-w-xs p-4 bg-card shadow-sm">
        {t`auth.rememberPassword`}{" "}
        <span
          className="inline-block text-primary"
          tabIndex={0}
          onClick={() => _.navigate("/auth/login")}
        >
          {t`auth.signIn`}
        </span>
      </p>
    </div>
  );
};
