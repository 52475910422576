import React from "react";

import TransactionProvider from "containers/TransactionProvider";

const withTransaction = (Component) => (props) => (
  <TransactionProvider transactionId={props.transactionId}>
    <Component {...props} />
  </TransactionProvider>
);

export default withTransaction;
